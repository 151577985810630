import React from "react";
// node.js library that concatenates classes (strings)



// reactstrap components
import {
  
  Row,
  Col,
  
} from "reactstrap";
import   ContentLoader,{BulletList,Code, Instagram } from 'react-content-loader'
import { ButtonBack, ButtonFirst, ButtonLast, ButtonNext,
  CarouselProvider, DotGroup, ImageWithZoom, Slide, Slider} from 'pure-react-carousel';
import './../../../assets/css/carouselStyle.css'





const ImageCarousel = (props) => {

    const { ImageData,visibleSlides } = props;

    return (
        <Row>
                        <Col lg="12">
                          
                        <CarouselProvider
                              visibleSlides={visibleSlides}
                              totalSlides={Object.keys(ImageData).length }
                              step={1}
                              naturalSlideWidth={800}
                              naturalSlideHeight={500}
                              hasMasterSpinner
                              infinite
                              //isPlaying
                            >
                              
                              <Slider   
                              
                              trayProps={{
                                draggable: true,
                                onClick: (ev)=>{
                                
                                //alert(JSON.stringify(ev))

                                 // console.log(ev.type, ev.target);

                                },
                                }}   spinner={() => <Instagram/>}>

                                {
                                  ( (Object.keys(ImageData).length > 0) 
                                  && ImageData.map((data,i)=>{

                                    return (<Slide index={i}>
                                              <ImageWithZoom  
                                              src={data.src}
                                             spinner={() => <Code/>} />
                                            </Slide>)


                                  }))
                                }


                              </Slider>
                              
                              <DotGroup  dotNumbers/>
                            </CarouselProvider>
                          


                        </Col>

                        </Row>
    )



}

export default ImageCarousel;
import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import CountUp from "react-countup";
import axios from "axios";

// reactstrap components
import {
  Button,
  Card,
  CardImg,
  CardHeader,
  Modal,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledAlert,
} from "reactstrap";

import swal from "sweetalert";
import firebase from "firebase/app";
import { connect } from "react-redux";
import MaterialTable, {
  MTableToolbar,
  MTableBodyRow,
  MTableCell,
} from "material-table";
import { array } from "js-sha512";
import { LoadOneSignalDevices } from "store/actions/userActions";

import {
  dateFormater,
  UserRolePermission,
  SendNotificationToSingleDevices,
} from "utils/functions";

class CoopCloudMessaging extends React.Component {
  state = {
    //propertyBed:this.props.PropertyReducer.propertyOptions[0].beds,
    startSendingMessageLoader: false,
    showPushModal: false,
    title_msg: null,
    body_msg: null,
    showOldPush: true,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount(props) {
    this.props.LoadOneSignalDevices();
  }

  getBadge = (status) => {
    switch (status) {
      case "name":
        return "success";
      case "estate":
        return "danger";
      case "role_profile_name":
        return "warning";
      case "Cancelled":
        return "danger";
      default:
        return "secondary";
    }
  };

  render() {
    const User = this.props.UserReducer;
    return (
      <div class="container" style={{ border: "0px solid red" }}>
        <Modal
          id="model_payment"
          className="modal-dialog-centered"
          isOpen={this.state.showPushModal}
          // style={{ minWidth: 90 + "%" }}
          // toggle={() => this.toggleModal("exampleModal")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              <span id="user_data"></span>
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ showPushModal: false });
              }}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Form>
              <div
                className="edit_form"
                style={{
                  width: 100 + "%",
                }}
              >
                <Row>
                  <Col>
                    <UncontrolledAlert
                      id="validate_form_msg"
                      className=" alert-warning"
                      style={{ display: "none" }}
                      fade={false}
                    >
                      <span className="alert-inner--icon">
                        <i className="ni ni-like-2" />
                      </span>{" "}
                      <span className="alert-inner--text">
                        <strong>Validation!</strong> All fields are mandatory
                      </span>
                    </UncontrolledAlert>
                  </Col>
                </Row>

                <Row>
                  <Col lg="12">
                    <FormGroup id="proTitleD">
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Title
                      </label>
                      <Input
                        className="form-control-alternative"
                        // defaultValue="lucky.jesse"
                        required
                        id="proTitle"
                        placeholder="Title of Message"
                        onChange={(data) => {
                          // console.log(data.target.value)
                          this.setState({ title_msg: data.target.value });
                        }}
                        type="text"
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="12">
                    <FormGroup id="proBodyD">
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Body
                      </label>
                      <Input
                        className="form-control-alternative"
                        // defaultValue="lucky.jesse"
                        required
                        id="proBody"
                        placeholder="Body of Message"
                        onChange={(data) => {
                          // console.log(data.target.value)
                          this.setState({ body_msg: data.target.value });
                        }}
                        type="textarea"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg="6">
                    <Input
                      className="form-control-alternative"
                      id="token"
                      required
                      type="hidden"
                    />

                    <Input
                      className="form-control-alternative"
                      id="email"
                      required
                      type="hidden"
                    />

                    <Input
                      className="form-control-alternative"
                      id="uname"
                      required
                      type="hidden"
                    />

                    <Input
                      className="form-control-alternative"
                      id="uid"
                      required
                      type="hidden"
                    />
                  </Col>

                  <Col>
                    <div
                      id="msg_pop"
                      style={{
                        color: "#ccc",
                        fontSize: 12,
                        textAlign: "right",
                        paddingRight: 20,
                      }}
                    ></div>
                    <Button
                      className="float-right"
                      style={{
                        marginRight: 15,
                        display: UserRolePermission(
                          this.props.currentUser.UserData.role,
                          "send_cloud_message"
                        )
                          ? "none"
                          : "block",
                      }}
                      disabled={this.state.startSendingMessageLoader}
                      color="success"
                      href="#stow"
                      type="submit"
                      onClick={async (e) => {
                        e.preventDefault();

                        document
                          .getElementById("proBodyD")
                          .classList.remove("has-danger");
                        document
                          .getElementById("proTitleD")
                          .classList.remove("has-danger");

                        if (document.getElementById("proTitle").value == "") {
                          document
                            .getElementById("proTitleD")
                            .classList.add("has-danger");
                          document.getElementById("proTitle").focus();

                          if (document.getElementById("validate_form_msg")) {
                            document.getElementById(
                              "validate_form_msg"
                            ).style.display = "block";
                          }
                        } else if (
                          document.getElementById("proBody").value == ""
                        ) {
                          document
                            .getElementById("proBodyD")
                            .classList.add("has-danger");
                          document.getElementById("proBody").focus();

                          if (document.getElementById("validate_form_msg")) {
                            document.getElementById(
                              "validate_form_msg"
                            ).style.display = "block";
                          }
                        } else {
                          this.setState({
                            startSendingMessageLoader: true,
                          });

                          if (document.getElementById("token").value == "all") {
                          } else {
                            /**/
                            const message_ = {
                              token: document.getElementById("token").value,
                              //sound: "default",
                              title: this.state.title_msg,
                              text: this.state.body_msg,
                              //data: { data: "goes here" },
                            }; /* */

                            /* */

                            var message = {
                              app_id: process.env.REACT_APP_ONNESIGNAL_APP_ID,
                              contents: this.state.body_msg,
                              headings: this.state.title_msg,
                              channel: "push",
                              email: document.getElementById("email").value,
                            };

                            //console.log(message);

                            let OneSignal =
                              await SendNotificationToSingleDevices(message);

                            this.setState({
                              startSendingMessageLoader: false,
                            });

                            //console.log(OneSignal);

                            /*  swal(
                              "Server Error",
                              "There has been a problem sending your message  \n\n" +
                                JSON.stringify("error"),
                              "error"
                            ); */

                            /* */
                          }
                        }
                      }}
                    >
                      {this.state.startSendingMessageLoader ? (
                        <>
                          Sending... <i className="fa fa-spinner fa-spin"></i>
                        </>
                      ) : (
                        "Send Message to User"
                      )}
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </Modal>

        <Row>
          <div className="col">
            <MaterialTable
              columns={[
                /*  {
                  title: "ID",
                  field: "id",
                  // filtering: false,
                  render: (rowData) => {
                    return <span style={{fontSize:9}}>{rowData.id}</span>;
                  },
                }, */
                {
                  title: "User ID",
                  filtering: true,
                  //defaultGroupOrder: 0,
                  field: "external_user_id",
                  render: (rowData) => {
                    return rowData.external_user_id;
                  },
                },
                {
                  title: "Device Model",
                  field: "device_model",
                  filtering: false,
                  render: (rowData) => {
                    return rowData.device_model;
                  },
                },
                {
                  title: "IP",
                  field: "ip",
                  //lookup: { looker },

                  render: (rowData) => {
                    return <span style={{ fontSize: 9 }}>{rowData.ip}</span>;
                  },
                },

                {
                  title: "Tags",
                  field: "tags",
                  //lookup: { looker },

                  render: (rowData) => {
                    //return JSON.stringify(rowData.tags);
                    //return Object.keys(rowData.tags).length;
                    //return JSON.stringify(rowData.tags);
                    //return typeof rowData.tags;

                    return Object.keys(rowData.tags).map((item, i) => (
                      <code color={this.getBadge(item)}>
                        {item} - {rowData.tags[item]}
                      </code>
                    ));
                  },
                },
                {
                  title: "Date",
                  field: "created_at",
                  defaultSort: "desc",
                  render: (rowData) => {
                    var timestamp = rowData.created_at;
                    var date2 = new Date(rowData.created_at * 1000);
                    //var date = new Date(timestamp);

                    return (
                      date2.getDate() +
                      "/" +
                      (date2.getMonth() + 1) +
                      "/" +
                      date2.getFullYear() +
                      " " +
                      date2.getHours() +
                      ":" +
                      date2.getMinutes() +
                      ":" +
                      date2.getSeconds()
                    );
                    //return rowData.created_at;
                  },
                },
              ]}
              actions={[
                {
                  icon: (row) => (
                    <Button
                      color="warning"
                      href="#"
                      style={{
                        fontSize: 9,
                        display: UserRolePermission(
                          this.props.currentUser.UserData.role,
                          "send_cloud_message"
                        )
                          ? "none"
                          : "block",
                      }}
                      size="sm"
                    >
                      Send Message
                    </Button>
                  ),

                  tooltip: "Send Message",

                  onClick: (event, rowData) => {
                    // console.log(JSON.stringify(rowData.external_user_id));

                    if (
                      rowData.external_user_id !== "" &&
                      rowData.external_user_id !== null
                    ) {
                      this.setState(
                        {
                          showPushModal: true,
                        },
                        () => {
                          var tim = setTimeout(function () {
                            console.log(rowData);
                            document.getElementById("user_data").innerHTML =
                              "Send Message to " +
                              rowData.external_user_id +
                              " " +
                              rowData.device_model;
                            document.getElementById("token").value = rowData.id;
                            document.getElementById("email").value =
                              rowData.external_user_id;
                            document.getElementById("msg_pop").innerHTML =
                              "sending message to " + rowData.external_user_id;
                            document.getElementById("uid").value = rowData.id;
                            document.getElementById("uname").value =
                              rowData.external_user_id +
                              "  - " +
                              rowData.device_model;
                          }, 600);
                        }
                      );
                    } else {
                      alert(
                        "Cant send Push to a device with out and email identifier"
                      );
                    }
                  },
                },
              ]}
              data={User.DeviceData || []}
              options={{
                //  grouping: true,
                pageSize: 10,
                pageSizeOptions: [5, 10, 20, 30, 50, 75, 100],
                toolbar: true,
                // paging: true,

                rowStyle: {
                  paddingLeft: "1.5rem",
                  paddingRight: "1.5rem",
                  verticalAlign: "middle",

                  fontSize: "0.8125rem",
                  whiteSpace: "nowrap",
                  padding: "1rem",
                  borderTop: "1px solid #e9ecef",
                  fontFamily: "Open Sans, sans-serif",
                },

                headerStyle: {
                  paddingTop: "0.75rem",
                  paddingBottom: "0.75rem",
                  fontSize: "0.65rem",
                  textTransform: "uppercase",
                  letterPpacing: "1px",
                  borderBottom: "1px solid #e9ecef",
                  fontWeight: "bolder",
                  paddingLeft: "1.5rem",
                  paddingRight: "1.5rem",
                  backgroundColor: "#f6f9fc",
                  color: "#8898aa",
                  verticalAlign: "middle",
                  borderColor: "#e9ecef",
                  fontFamily: "Open Sans, sans-serif",
                },
              }}
              title={" "}
              components={{
                Toolbar: (props) => (
                  <p className="h3" style={{ padding: 10 }}>
                    <MTableToolbar {...props} />
                    <div style={{ marginTop: -40, fontSize: 15 }}>
                      {"Total"}{" "}
                      <CountUp
                        end={Object.keys(User?.DeviceData || []).length}
                      />
                    </div>
                  </p>
                ),
              }}
            ></MaterialTable>

            {/* {JSON.stringify(this.props.UserReducer.UserPushActionsData)} */}
          </div>
        </Row>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    LoadOneSignalDevices: (param) => dispatch(LoadOneSignalDevices(param)),
  };
};

const mapStateToProps = (state) => ({
  UserReducer: state.UserReducer,
  currentUser: state.authReducer,
  CoopRegisterReducer: state.CoopRegisterReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(CoopCloudMessaging);

import React, { useEffect, useState } from "react";
// node.js library that concatenates classes (strings)

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  Modal,
  Form,
  FormGroup,
  Input,
  Badge,
  Alert,
  UncontrolledAlert,
} from "reactstrap";

import axios from "axios";

import moment from "moment";

import firebase from "firebase/app";
import "firebase/storage";
import { connect } from "react-redux";

import ContentLoader, { BulletList, Code } from "react-content-loader";

import api from "../../../utils/config";
import { currencyFormat, dateFormater, UserRolePermission } from "../../../utils/functions";

//import "firebase/auth";
import { isEmpty } from "lodash";
import swal from "sweetalert";
import { updatePropertyPaymentPlan } from "store/actions/propertyActions";
import { propertyPaymentPlanAction } from "store/actions/propertyActions";

function AddEditPaymentPlans(props) {
  const [generalState, setgeneralState] = useState({
    startSavingLoader: false,
    idValue: "",
    paymentPlanState: {
      id: "",
      order: 0,
      details: ["", "", "", ""],
      discount: 0,
      label: "",
      surcharge: 0,
      title: "",
      multiplier: 0,
      threshold: {
        0: {
          result: "",
          equity: 0,
        },
        1: {
          result: "",
          equity: 0,
          timeline: "",
        },
        2: {
          result: "",
          timeline: "",
          equity: 1,
        },
      },
      initialDepositPercentage: 0,
    },
    thresholdArray: [{}, {}, {}],
  });

  const handleInputChange = (prop) => (event) => {
    event.persist();
    setgeneralState((prev) => ({
      ...prev,
      paymentPlanState: {
        ...prev?.paymentPlanState,
        [prop]: event?.target?.value,
      },
    }));
  };
  const [validation, setValidation] = useState({
    title: false,
    initialDepositPercentage: false,
    multiplier: false,
    order: false,
    surcharge: false,
    discount: false,
    equity0: false,
    equity1: false,
    equity2: false,
    result0: false,
    result1: false,
    result2: false,
    timeline0: false,
    timeline1: false,
    timeline2: false,
  });

  const idArray = [
    "title",
    "initialDepositPercentage",
    "multiplier",
    "order",
    "surcharge",
    "discount",
    "equity0",
    "equity1",
    "equity2",
    "result0",
    "result1",
    "result2",
    "timeline0",
    "timeline1",
    "timeline2",
  ];
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const documentName = url.searchParams.get("id");
  const collectionName = window.location.hash.slice(1) || "";

  const validateForm = () => {
    const updatedValidation = {};

    for (const id of idArray) {
      const value = document.getElementById(id).value.trim();
      updatedValidation[id] = value === ""; // true if value is empty, false otherwise
    }

    setValidation(updatedValidation);

    // Check if any field is empty
    if (Object.values(updatedValidation).some((isEmpty) => isEmpty)) {
      document.getElementById("validate_form_msg").style.display = "block";
      return; // Stop execution if validation fails
    }
  };

  function generateFinalThreshold(generalState) {
    let finalThreshold = {};

    for (let i = 0; i < generalState.thresholdArray.length; i++) {
      let resultKey = `result${i}`;
      let equityKey = `equity${i}`;
      let timelineKey = `timeline${i}`;

      finalThreshold[i] = {
        result: generalState.paymentPlanState[resultKey] || generalState.thresholdArray[i].result,
        equity: generalState.paymentPlanState[equityKey] || generalState.thresholdArray[i].equity,
        timeline: generalState.paymentPlanState[timelineKey] || generalState.thresholdArray[i].timeline,
      };
    }

    return finalThreshold;
  }

  const handleAddEditPaymentPlan = async (e) => {
    e.preventDefault();

    validateForm();

    let finalThreshold = generateFinalThreshold(generalState);
    const isValidationSuccessful = Object.values(validation).every((value) => value === false);
    if (isValidationSuccessful) {
      const newData = {
        discount: generalState.paymentPlanState.discount,
        initialDepositPercentage: generalState.paymentPlanState.initialDepositPercentage,
        multiplier: generalState.paymentPlanState.multiplier,
        order: generalState.paymentPlanState.order,
        surcharge: generalState.paymentPlanState.surcharge,
        title: generalState.paymentPlanState.title,
        threshold: finalThreshold,
      };
      console.log({ collectionName, documentName, newData });
      
      props.updatePropertyPaymentPlan(collectionName, documentName, newData);

      props.propertyPaymentPlanAction(collectionName);

    } else {
      console.log("Nothing submitted, not completed");
    }
  };

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      const searchParams = new URLSearchParams(window.location.search);
      // Get the value of the "id" parameter
      const idValue = searchParams.get("id");
      setgeneralState((prev) => ({ ...prev, idValue }));

      if (!firebase.apps.length) {
        firebase.initializeApp({
          apiKey: api.REACT_APP_FIREBASE_APP_ID,
          authDomain: api.REACT_APP_FIREBASE_DOMAIN,
          databaseURL: api.REACT_APP_FIREBASE_DATABASE,
          projectId: api.REACT_APP_FIREBASE_PROJECT_ID,
          storageBucket: api.REACT_APP_FIREBASE_STORAGE_BUCKET,
          messagingSenderId: api.REACT_APP_FIREBASE_SENDER_ID,
          appId: api.REACT_APP_FIREBASE_APP_ID,
          measurementId: api.REACT_APP_FIREBASE_MEASUREMENT_ID,
        });
      }

      const selectedPropertyData = props.selectedPropertyData;

      const singlePropertyPaymentPlanArray = selectedPropertyData.filter((item) => item.id === idValue);
      const singlePropertyPaymentPlanObject =
        singlePropertyPaymentPlanArray?.length > 0 ? singlePropertyPaymentPlanArray[0] : {};
      const threshold = singlePropertyPaymentPlanObject.threshold;
      const thresholdArray = typeof threshold === "object" ? Object.values(threshold) : [{}, {}, {}];
      if (props.modalType === "edit") {
        // Use the callback function in setState to ensure the most up-to-date state
        setgeneralState((prevState) => ({
          ...prevState,
          paymentPlanState: singlePropertyPaymentPlanObject,
          thresholdArray: thresholdArray,
        }));
      }

      return () => {
        isCancelled = true;
      };
    }
  }, [props.modalType]);

  console.log({ generalState });

  return (
    <Card className="bg-secondary shadow">
      <CardHeader>
        <UncontrolledAlert id="validate_form_msg" className=" alert-warning" style={{ display: "none" }} fade={false}>
          <span className="alert-inner--icon">
            <i className="ni ni-like-2" />
          </span>{" "}
          <span className="alert-inner--text">
            <strong>Validation!</strong> Some important fields are not filled out properly
          </span>
        </UncontrolledAlert>
      </CardHeader>
      <CardBody>
        <Form>
          <div
            className="edit_form"
            style={{
              width: 100 + "%",
            }}
          >
            <Row>
              <Col lg="4">
                <FormGroup id="titleD">
                  <label className="form-control-label" htmlFor="title">
                    Title
                  </label>
                  <Input
                    className={`form-control-alternative ${validation.title ? "is-invalid" : ""}`}
                    value={generalState.paymentPlanState.title}
                    onChange={handleInputChange("title")}
                    required
                    id="title"
                    placeholder="Title"
                    type="text"
                  />
                </FormGroup>
              </Col>

              <Col lg="4">
                <FormGroup id="initialDepositD">
                  <label className="form-control-label" htmlFor="initialDepositPercentage">
                    Inital Deposit Percentage
                  </label>
                  <Input
                    className={`form-control-alternative ${validation.initialDepositPercentage ? "is-invalid" : ""}`}
                    value={generalState.paymentPlanState.initialDepositPercentage}
                    onChange={handleInputChange("initialDepositPercentage")}
                    required
                    id="initialDepositPercentage"
                    placeholder="Inital Deposit Percentage"
                    type="number"
                  />
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup id="multiplierD">
                  <label className="form-control-label" htmlFor="multiplier">
                    Multiplier
                  </label>
                  <Input
                    className={`form-control-alternative ${validation.multiplier ? "is-invalid" : ""}`}
                    value={generalState.paymentPlanState.multiplier}
                    onChange={handleInputChange("multiplier")}
                    required
                    id="multiplier"
                    placeholder="Multiplier"
                    type="number"
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col lg="4">
                <FormGroup id="orderD">
                  <label className="form-control-label" htmlFor="input-username">
                    Order
                  </label>
                  <Input
                    className={`form-control-alternative ${validation.order ? "is-invalid" : ""}`}
                    value={generalState.paymentPlanState.order}
                    onChange={handleInputChange("order")}
                    required
                    id="order"
                    placeholder="order"
                    type="number"
                  />
                </FormGroup>
              </Col>

              <Col lg="4">
                <FormGroup id="surchargeD">
                  <label className="form-control-label" htmlFor="surcharge">
                    Surcharge
                  </label>
                  <Input
                    className={`form-control-alternative ${validation.surcharge ? "is-invalid" : ""}`}
                    value={generalState.paymentPlanState.surcharge}
                    onChange={handleInputChange("surcharge")}
                    required
                    id="surcharge"
                    placeholder="Surcharge"
                    type="number"
                  />
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup id="discountD">
                  <label className="form-control-label" htmlFor="discount">
                    Discount
                  </label>
                  <Input
                    className={`form-control-alternative ${validation.discount ? "is-invalid" : ""}`}
                    value={generalState.paymentPlanState.discount}
                    onChange={handleInputChange("discount")}
                    required
                    id="discount"
                    placeholder="Discount"
                    type="number"
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col xs="12">
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "#f3f3f3",
                    padding: "10px",
                    //   marginBottom: "1.3rem",
                    // border:"1px solid gray",
                    borderRadius: 4,
                    fontSize: "0.875rem",
                    textAlign: "center",
                  }}
                >
                  <strong>THRESHOLD DETAILS</strong>
                </div>
              </Col>
            </Row>

            <Row>
              <Table
                className="align-items-center table-flush"
                style={{
                  margin: 50,
                  marginTop: 30,
                  width: 90 + "%",
                }}
                responsive
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col" className="form-control-label">
                      <strong
                        style={{
                          fontSize: "0.875rem",
                          paddingLeft: "0.5rem",
                        }}
                      >
                        Equity
                      </strong>
                    </th>
                    <th scope="col" className="form-control-label">
                      <strong
                        style={{
                          fontSize: "0.875rem",
                          paddingLeft: "0.5rem",
                        }}
                      >
                        Result
                      </strong>
                    </th>
                    <th scope="col" className="form-control-label">
                      <strong
                        style={{
                          fontSize: "0.875rem",
                          paddingLeft: "0.5rem",
                        }}
                      >
                        Timeline
                      </strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {generalState.thresholdArray.length > 0 &&
                    generalState.thresholdArray.map((data, index) => {
                      //return (JSON.stringify(data))

                      return (
                        <tr key={index}>
                          <td>
                            <Col>
                              <FormGroup id={`equity${index}D`} style={{ marginBottom: 0 }}>
                                <Input
                                  className={`form-control-alternative ${
                                    validation?.[`equity${[index]}`] ? "is-invalid" : ""
                                  }`}
                                  value={generalState?.paymentPlanState?.[`equity${[index]}`] || data?.equity}
                                  onChange={handleInputChange(`equity${index}`)}
                                  required
                                  id={`equity${index}`}
                                  placeholder="Equity"
                                  type="number"
                                />
                              </FormGroup>
                            </Col>
                          </td>
                          <td>
                            <Col>
                              <FormGroup id={`result${index}D`} style={{ marginBottom: 0 }}>
                                <Input
                                  className={`form-control-alternative ${
                                    validation?.[`result${[index]}`] ? "is-invalid" : ""
                                  }`}
                                  value={generalState?.paymentPlanState?.[`result${[index]}`] || data?.result}
                                  onChange={handleInputChange(`result${index}`)}
                                  required
                                  id={`result${index}`}
                                  placeholder="Result"
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                          </td>
                          <td>
                            <Col>
                              <FormGroup id={`timeline${index}D`} style={{ marginBottom: 0 }}>
                                <Input
                                  className={`form-control-alternative ${
                                    validation?.[`timeline${[index]}`] ? "is-invalid" : ""
                                  }`}
                                  value={generalState?.paymentPlanState?.[`timeline${[index]}`] || data?.timeline}
                                  onChange={handleInputChange(`timeline${index}`)}
                                  required
                                  id={`timeline${index}`}
                                  placeholder="Timeline"
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Row>

            <Row>
              <Col lg="12">
                <Button
                  color="primary btn-block"
                  href="#"
                  // style={{ padding: 12 }}
                  size="md"
                  disabled={UserRolePermission(props.currentUser.UserData.role, "add_developer")}
                  onClick={handleAddEditPaymentPlan}
                  style={{
                    fontSize: 12,
                    display: UserRolePermission(props.currentUser.UserData.role, "add_payment_plan") ? "none" : "block",
                  }}
                >
                  {generalState.startSavingLoader ? (
                    // <img src={require("assets/img/loader.gif")} />
                    <>
                      {props?.modalType}ing payment plan... <i className="fa fa-spinner fa-spin"></i>
                    </>
                  ) : (
                    <span
                      style={{
                        textTransform: "uppercase",
                      }}
                    >{`${props?.modalType} Payment Plan`}</span>
                  )}
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    //PropertySingle: (param) => dispatch(PropertySingle(param)),
    updatePropertyPaymentPlan: (collectionName, documentName, newData) =>
      dispatch(updatePropertyPaymentPlan(collectionName, documentName, newData)),
      propertyPaymentPlanAction: (param) => dispatch(propertyPaymentPlanAction(param)),
  };
};

const mapStateToProps = (state) => ({
  PropertyReducer: state.PropertyReducer,
  PromoReducer: state.PromoReducer,
  currentUser: state.authReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditPaymentPlans);


import RegistrationsGroup from "views/group/registration";
import Activation from "views/coop/activation";
import Deposit from "views/coop/deposit";
import PaymentGroup from "views/group/payment/"

var routesGroup = [
  {
    path: "/group/registration",
    name: "Group Users",
    icon: "ni ni-circle-08 text-info",
    component: RegistrationsGroup,
    layout: "/admin",
  },

  {
    path: "/group/payment",
    name: "Group Payments",
    icon: "ni ni-palette text-info",
     component: PaymentGroup,
    layout: "/admin",
  },

  {
    path: "/group/others",
    name: "Others",
    icon: "ni ni-delivery-fast text-info",
    // component: Deposit,
    layout: "/admin",
  },

  /*  {
    path: "/coop/settings",
    name: "Settings",
    icon: "ni ni-user-run text-warning",
    component: null,
    layout: "/admin",
  }, */
];
export default routesGroup;

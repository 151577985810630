import { isObject } from "lodash";
import isEmpty from "lodash/isEmpty";

const iniState = {
  //propertysData: {},
  propLoading: true,
  propError: true,
  propErrorMsg: "",
  propLoadingCompleted: false,
  propertyCount: 0,
  propertyPaymentPlanUpdate: {
    status: false,
    message: "",
  },
};

const PropertyReducer = (state = iniState, action) => {
  switch (action.type) {
    case "PROP_COUNT":
      return {
        ...state,
        propertyCount: action.payload,
      };

    case "PROP_EDIT":
      return {
        ...state,
        propertyEdit: action.payload,
      };

    case "PROP_SINGLE":
      return {
        ...state,
        propertySingle: action.payload,
      };

    case "PROP_OPTIONS":
      return {
        ...state,
        propertyOptions: action.payload,
      };

    case "PROP_FEATURES":
      return {
        ...state,
        propertyFeatures: action.payload,
      };

    //

    case "PROP_PROGRESS":
      return {
        ...state,
        propertyProgress: action.payload,
      };

    case "PROP_PROGRESS_EMPTY":
      return {
        ...state,
        propertyProgress: [],
      };

    //PROP_PROGRESS_EMPTY

    case "PROP_DEVELOPER":
      return {
        ...state,
        propertyDeveloper: action.payload,
      };
    case "PROP_PAYMENT_PLAN":
      return {
        ...state,
        propertyPaymentPlan: action.payload,
      };
    case "UPDATE_PROP_PAYMENT_PLAN":
      return {
        ...state,
        propertyPaymentPlanUpdate: action.payload,
      };
    case "PROPERTY_LOADING":
      return {
        ...state,
        propLoading: action.propLoading,
        propError: false,
        propLoadingCompleted: false,
        //propErrorMsg:''
      };

    case "PROPERTY_SUCCESS":
      //alert(JSON.stringify(action.payload))
      return {
        ...state,
        propertyData: action.payload,
        //propertyCount: (isObject(action.payload)?(Object.keys(action.payload).length):0),
        propError: false,
        propLoadingCompleted: true,
        propLoading: false,
      };
    case "PROPERTY_ERROR":
      //alert('error')
      return {
        ...state,
        propError: true,
        propLoadingCompleted: false,
        propErrorMsg: action.payload,
        propLoaded: false,
      };

    default:
      return state;
  }
};

export default PropertyReducer;

import React from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import CountUp from "react-countup";

class Header extends React.Component {
  isCoop(path) {
    return path.includes("coop") ? "bg-gradient-warning" : "bg-gradient-info";
  }

  render() {
    return (
      <>
        <div id="headerContainer" className={`header  ${this.isCoop(window.location.pathname)} pb-8 pt-5 pt-md-8`}>
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row
                style={{
                  display: window.location.pathname != "/admin/index" ? "none" : "flex",
                }}
              >
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                            TRANSACTION
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            <CountUp
                              end={
                                this.props.transactionReducer.hasOwnProperty("transactionsData")
                                  ? Object.keys(this.props.transactionReducer.transactionsData).length
                                  : 0
                              }
                            />
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fas fa-chart-bar" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">{/*   <i className="fa fa-arrow-up" /> 3.48% */}</span>
                        <span className="text-nowrap" style={{ display: "none" }}>
                          Since last month
                        </span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                            Customers
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            <CountUp
                              end={
                                this.props.UserReducer.hasOwnProperty("UseractionsData")
                                  ? Object.keys(this.props.UserReducer.UseractionsData).length
                                  : 0
                              }
                            />
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                            <i className="fas fa-users" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-danger mr-2">{/*  <i className="fas fa-arrow-down" /> 3.48% */}</span>
                        <span className="text-nowrap" style={{ display: "none" }}>
                          Since last week
                        </span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                            Properties
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            <CountUp
                              end={
                                this.props.PropertyReducer.hasOwnProperty("propertyData")
                                  ? Object.keys(
                                      this.props.PropertyReducer.propertyData.filter((item) => item.active === true)
                                    ).length
                                  : 0
                              }
                            />
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                            <i className="fas fa-building" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-warning mr-2">
                          <i className="fas fa-arrow-down" />
                          {this?.props?.PropertyReducer?.propertyData
                            ? Object.keys(this.props.PropertyReducer.propertyData).length
                            : 0}
                        </span>
                        <span className="text-nowrap" style={{ display: "none" }}>
                          Since yesterday
                        </span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                            PAYMENTS
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            <CountUp
                              end={
                                this.props.PaymentReducer.hasOwnProperty("PaymentactionsData")
                                  ? Object.keys(this.props.PaymentReducer.PaymentactionsData).length
                                  : 0
                              }
                            />
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                            <i className="fas fa-money-bill-alt" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">{/* ₦ 12,238,032.00 */}</span>
                        <span className="text-nowrap" style={{ display: "none" }}>
                          Since last month
                        </span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    //transactionActions: (credentials) => dispatch(transactionActions(credentials))
  };
};

const mapStateToProps = (state) => ({
  transactionReducer: state.transactionReducer,
  UserReducer: state.UserReducer,
  PaymentReducer: state.PaymentReducer,
  PropertyReducer: state.PropertyReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);

import React from "react";
//import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import CountUp from "react-countup";
import axios from "axios";

// reactstrap components
import {
  Button,
  Card,
  CardImg,
  CardHeader,
  Modal,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledAlert,
} from "reactstrap";

import swal from "sweetalert";
import firebase from "firebase/app";
import "firebase/storage";
import { connect } from "react-redux";
import MaterialTable, { MTableToolbar, MTableCell } from "material-table";
import { array } from "js-sha512";
import { dateFormater, UserRolePermission } from "utils/functions";
//import { promoActions } from "../../../store/actions/promoActions";
import { Editor } from "@tinymce/tinymce-react";

import { propertyDeveloperAction } from "../../../store/actions/propertyActions";

class DeveloperData extends React.Component {
  state = {
    devBrand: null,
    devLogo: null,

    devName_edit: null,

    devName_edit_title: "",
    devName_edit_id: null,

    propertyDev: this.props.PropertyReducer.hasOwnProperty("propertyDeveloper")
      ? this.props.PropertyReducer.propertyDeveloper
      : null,
    startDevUpload: false,
    showEditDeveloperModal: false,
    startDeveUpdate:false,
  };

  uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  extractFileExtension = (filename) => {
    //alert(filename)
    var ext = /(?:\.([^.]+))?$/.exec(filename);
    if (ext != null && ext[0] != null) {
      return ext[0];
    } else {
      return "";
    }
  };

  componentDidMount() {
    this.props.propertyDeveloperAction();
  }

  render() {
    return (
      <div>
        <Form>
          <div
            className="edit_form"
            style={{
              width: 100 + "%",
            }}
          >
            <Row>
              <Col>
                <UncontrolledAlert
                  id="validate_form_msg"
                  className=" alert-warning"
                  style={{ display: "none" }}
                  fade={false}
                >
                  <span className="alert-inner--icon">
                    <i className="ni ni-like-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    <strong>Validation!</strong> All fields are mandatory
                  </span>
                </UncontrolledAlert>
              </Col>
            </Row>

            <Card style={{ padding: 20, border: "1px solid #ccc" }}>
              <Row style={{ backgroundColor: "black" }}>
                <Col lg="12">
                  <FormGroup id="proTitleD">
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                      style={{
                        fontWeight: "normal",
                        fontSize: 11,
                        color: "white",
                      }}
                    >
                      Name
                    </label>
                    <Input
                      className="form-control-alternative"
                      defaultValue={this.state.editPromoTitle}
                      required
                      id="devBrand"
                      placeholder="Developer Name"
                      onChange={(data) => {
                        this.setState({
                          devBrand: data.target.value,
                        });
                      }}
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row style={{ backgroundColor: "black" }}>
                <Col lg="8">
                  <FormGroup id="devLogoD">
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                      style={{
                        fontWeight: "normal",
                        fontSize: 11,
                        color: "white",
                      }}
                    >
                      Logo
                    </label>
                    <Input
                      className="form-control alternative"
                      style={{ fontSize: 10 }}
                      id="devLogo"
                      accept="image/*"
                      onChange={(data) => {
                        this.setState({ devLogo: data.target.files[0] });
                      }}
                      type="file"
                    />
                  </FormGroup>
                </Col>

                <Col lg="4" style={{ paddingTop: 35 }}>
                  <Button
                    color="primary btn-block"
                    href="#"
                    style={{ padding: 12 }}
                    size="md"
                    disabled={UserRolePermission(
                      this.props.currentUser.UserData.role,
                      "add_developer"
                    )}
                    onClick={(e) => {
                      e.preventDefault();

                      if (
                        this.state.devBrand == null ||
                        this.state.devLogo == null
                      ) {
                        swal({
                          title: "Some fields are still empty",
                          text: "Kindly provide the developers name and Logo to continue",
                          icon: "error",
                        });
                      } else {
                        var home = this;

                        const image = this.state.devLogo;
                        const fileName =
                          this.uuidv4() +
                          this.extractFileExtension(this.state.devLogo.name);
                        const storage = firebase.storage();
                        const uploadPhotoTask = storage
                          .ref("developer/" + fileName)
                          .put(image);

                        home.setState({ startDevUpload: true });

                        var now = new Date();
                        var formattedToday =
                          now
                            .toLocaleDateString("en-GB", {
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                            })
                            .split(" ")
                            .join("-") + "";

                        uploadPhotoTask.on(
                          "state_changed",
                          (snapshot) => {},
                          (error) => {
                            swal({
                              title: "Developer Logo Upload Error",
                              text: "An unexpected error occured while uploading your  developer image.\n Kindly confirm your internet connection or contact an admin",
                              icon: "error",
                            });

                            //console.log(error)
                          },
                          async () => {
                            storage
                              .ref("developer")
                              .child(fileName)
                              .getDownloadURL()
                              .then(async (url) => {
                                var DevData = {
                                  devLogo: url,
                                  date_time: formattedToday,
                                  devName: this.state.devBrand,
                                };

                                await firebase
                                  .firestore()
                                  .collection("developer")
                                  //.doc(this.state.editPromoId)
                                  .add(DevData)
                                  .then(function () {
                                    home.setState({
                                      startDevUpload: false,
                                    });

                                    swal(
                                      "Developer added to STOW",
                                      "You have successfully added the " +
                                        home.state.devBrand +
                                        " to STOW",
                                      "success"
                                    ).then((value) => {
                                      document.location.reload();
                                    });
                                  });
                              });
                          }
                        );
                      }
                    }}
                    style={{
                      fontSize: 12,
                      display: UserRolePermission(
                        this.props.currentUser.UserData.role,
                        "add_developer"
                      )
                        ? "none"
                        : "block",
                    }}
                  >
                    {this.state.startDevUpload ? (
                      <>
                        saving... <i className="fa fa-spinner fa-spin"></i>
                      </>
                    ) : (
                      "Add Developer"
                    )}
                  </Button>
                </Col>
              </Row>

              <hr style={{ padding: 10 }}></hr>

              {this.state.propertyDev &&
                this.state.propertyDev.map((data, i) => {
                  return (
                    <div class="row">
                      <div class="col-1">
                        <img
                          className="text-center"
                          style={{
                            height: 40,
                            width: 50,

                            paddingLeft: 10,
                            paddingRight: 10,
                          }}
                          src={data.devLogo}
                          top
                        />
                      </div>
                      <div
                        class="col-11"
                        style={{
                          background: "#fff",
                          padding: "10px",
                          borderRadius: "5px",
                          boxShadow: "0 0 12px #e2e7ef",
                          marginBottom: "10px",
                          fontSize: 11,
                        }}
                      >
                        <div>
                          &nbsp;&nbsp;&nbsp;
                          <span style={{ fontSize: 14 }}>{data.devName} </span>
                        </div>

                        <Modal
                          id="model_featured_edit"
                          className="modal-dialog-centered"
                          isOpen={this.state.showEditDeveloperModal}
                          // toggle={() => this.toggleModal("exampleModal")}
                        >
                          <div className="modal-header">
                            <h5 className="modal-title" id="">
                              EDIT -{" "}
                              {this.state.devName_edit_title.toUpperCase()}
                            </h5>
                            <button
                              aria-label="Close"
                              className="close"
                              data-dismiss="modal"
                              type="button"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                  showEditDeveloperModal: false,
                                });
                              }}
                            >
                              <span aria-hidden={true}>×</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <Row
                              style={{
                                backgroundColor: "#eee",
                                padding: 10,
                                paddingTop: 20,
                              }}
                            >
                              <Col lg="12">
                                <FormGroup>
                                  <Input
                                    className="form-control-alternative"
                                    id="devName_edit"
                                    placeholder="Developer Name"
                                    defaultValue={this.state.devName_edit_title}
                                    type="text"
                                    onChange={(data) => {
                                      this.setState({
                                        devName_edit: data.target.value,
                                      });
                                    }}
                                  />
                                </FormGroup>
                              </Col>

                              <Col lg="12">
                                <Button
                                  color="primary btn-block"
                                  href="#"
                                  style={{ padding: 12 }}
                                  size="md"
                                  disabled={UserRolePermission(
                                    this.props.currentUser.UserData.role,
                                    "edit_developer"
                                  )}
                                  style={{
                                    fontSize: 12,
                                    display: UserRolePermission(
                                      this.props.currentUser.UserData.role,
                                      "edit_developer"
                                    )
                                      ? "none"
                                      : "block",
                                  }}
                                  // onClick={async () => {
                                  onClick={async (e) => {
                                    if (this.state.devName_edit == null) {
                                      swal({
                                        title: "Some fields are still empty",
                                        text: "Kindly provide the Developer Name to continue Update",
                                        icon: "error",
                                      });
                                    } else {
                                      var DevData = {
                                        devName: this.state.devName_edit,
                                      };

                                      var home = this;

                                      //alert(home.state.EprogId+'====');

                                      home.setState({ startDeveUpdate: true });

                                      await firebase
                                        .firestore()
                                        .collection("developer")

                                        .doc(home.state.devName_edit_id)
                                        .update(DevData)
                                        /*.set(DevData) */
                                        .then(function () {
                                          home.setState({
                                            startProgUpload: false,
                                          });

                                          swal(
                                            "Developer updated",
                                            "You have successfully updated " +
                                              home.state.devName_edit +
                                              " developer",
                                            "success"
                                          ).then((value) => {
                                            home.setState({
                                              startDeveUpdate: false,
                                            });

                                            document.location.reload();
                                          });
                                        });
                                    }
                                  }}
                                >
                                  {this.state.startDeveUpdate ? (
                                    <>
                                      updating...{" "}
                                      <i className="fa fa-spinner fa-spin"></i>
                                    </>
                                  ) : (
                                    "Update"
                                  )}
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Modal>

                        <Button
                          data-devName={data.devName}
                          data-id={data.id}
                          className="mr-20"
                          style={{
                            float: "right",
                            marginTop: -25,
                            marginRight: 70,
                            fontSize: 9,

                            display: UserRolePermission(
                              this.props.currentUser.UserData.role,
                              "edit_developer"
                            )
                              ? "none"
                              : "block",
                          }}
                          title={"Edit  " + data.devName}
                          color="warning"
                          href="#stow"
                          size="sm"
                          onClick={(e) => {
                            this.setState(
                              {
                                showEditDeveloperModal: true,
                                devName_edit_title: data.devName,
                                devName_edit: data.devName,
                                devName_edit_id: data.id,
                              },
                              () => {
                                var tt = setTimeout(function () {
                                  clearTimeout(tt);
                                }, 800);
                              }
                            );
                          }}
                        >
                          Edit
                        </Button>

                        <span
                          id={"delete_dev_" + i}
                          className="float-right"
                          style={{ float: "right" }}
                        ></span>

                        <Button
                          className="mr-2"
                          style={{
                            float: "right",
                            marginTop: -25,
                            fontSize: 9,
                            display: UserRolePermission(
                              this.props.currentUser.UserData.role,
                              "add_developer"
                            )
                              ? "none"
                              : "block",
                          }}
                          title={"Delete  " + data.devName}
                          color="info"
                          href="#stow"
                          onClick={(e) => {
                            swal({
                              title: "Delete Developer ?",
                              // html:true,
                              text: "Delete " + data.devName + " from List",
                              icon: "warning",
                              buttons: true,
                              buttons: {
                                cancel: "Cancel",
                                catch: {
                                  text: "Delete",
                                  //value: "catch",
                                },
                                //defeat: true,
                              },
                              dangerMode: true,
                            }).then(async (willDelete) => {
                              if (willDelete) {
                                e.preventDefault();
                                //let arr = this.state.propertyDev;

                                document.getElementById(
                                  "delete_dev_" + i
                                ).innerHTML =
                                  '<div style="font-size:9px">Deleting Developer... <i class="fa fa-spinner fa-spin"></i></div>';

                                await firebase
                                  .firestore()
                                  .collection("developer")
                                  .doc(data.id)
                                  .delete()
                                  .then(async () => {
                                    //document.getElementById('delete_feature_'+i).innerHTML= '';

                                    swal(
                                      "Developer Deleted",
                                      "You have successfully Deleted the Developer - " +
                                        data.devName,
                                      {
                                        icon: "success",
                                      }
                                    ).then((value) => {
                                      document.location.reload();
                                    });
                                  });
                              }
                            });
                          }}
                          size="sm"
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                  );
                })}
            </Card>
          </div>
        </Form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    //promoActions: (param) => dispatch(promoActions(param)),
    propertyDeveloperAction: (param) =>
      dispatch(propertyDeveloperAction(param)),
  };
};

const mapStateToProps = (state) => ({
  //PromoReducer: state.PromoReducer,
  PropertyReducer: state.PropertyReducer,
  currentUser: state.authReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(DeveloperData);

import { isObject } from "lodash";
import isEmpty from "lodash/isEmpty";

const iniState = {
  //ChatactionsData: {},
 // ChatArchiveactionsData:{},
  ChatLoading: true,
  ChatError: true,
  ChatLoaded: false,
  ChatErrorMsg: "",
  ChatLoadingCompleted: false,
  ChatactionCount: 0,

  ChatArchiveLoading: true,
  ChatArchiveError: true,
  ChatArchiveLoaded: false,
  ChatArchiveErrorMsg: "",
  ChatArchiveLoadingCompleted: false,
  ChatArchiveactionCount: 0,
};

const ChatReducer = (state = iniState, action) => {
  switch (action.type) {
    case "CHAT_COUNT":
      // alert(action.payload)
      return {
        ...state,
        ChatactionCount: action.payload,
      };
    case "CHAT_LOADING":
      return {
        ...state,
        ChatLoading: true,
        ChatError: false,
        ChatLoaded: false,
        ChatLoadingCompleted: false,

        //ChatErrorMsg:''
      };

    case "CHAT_SUCCESS":
      //alert(JSON.stringify(action.payload))
      return {
        ...state,
        ChatactionsData: action.payload,
        ChatError: false,
        ChatLoadingCompleted: true,
        ChatLoading: false,
      };
    case "CHAT_ERROR":
      return {
        ...state,
        ChatError: true,
        ChatLoadingCompleted: false,
        ChatErrorMsg: action.payload,
        ChatLoading: false,
        ChatLoaded: false,
      };

    ///////////////    ///////////////    ///////////////    ///////////////

    case "CHAT_ARCHIVE_COUNT":
      // alert(action.payload)
      return {
        ...state,
        ChatArchiveactionCount: action.payload,
      };
    case "CHAT_ARCHIVE_LOADING":
      return {
        ...state,
        ChatArchiveLoading: true,
        ChatArchiveError: false,
        ChatArchiveLoaded: false,
        ChatArchiveLoadingCompleted: false,

        //ChatErrorMsg:''
      };

    case "CHAT_ARCHIVE_SUCCESS":
      //alert(JSON.stringify(action.payload))
      return {
        ...state,
        ChatArchiveactionsData: action.payload,
        ChatArchiveError: false,
        ChatArchiveLoadingCompleted: true,
        ChatArchiveLoading: false,
      };
    case "CHAT_ARCHIVE_ERROR":
      return {
        ...state,
        ChatArchiveError: true,
        ChatArchiveLoadingCompleted: false,
        ChatArchiveErrorMsg: action.payload,
        ChatArchiveLoading: false,
        ChatArchiveLoaded: false,
      };

    default:
      return state;
  }
};

export default ChatReducer;

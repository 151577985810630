import axios from "axios";

import api from "../../utils/config";

import firebase from "firebase/app";
import "firebase/auth";
import app from "./../../variables/base";
import swal from "sweetalert";
import { isEmpty } from "lodash";

//SinglePropertyFloorPlan

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: api.REACT_APP_FIREBASE_APP_ID,
    authDomain: api.REACT_APP_FIREBASE_DOMAIN,
    databaseURL: api.REACT_APP_FIREBASE_DATABASE,
    projectId: api.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: api.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: api.REACT_APP_FIREBASE_SENDER_ID,
    appId: api.REACT_APP_FIREBASE_APP_ID,
    measurementId: api.REACT_APP_FIREBASE_MEASUREMENT_ID,
  });
}

export const PropertyEdit = (edit) => {
  //alert(edit)
  return (dispatch, getState) => {
    dispatch({ type: "PROP_EDIT", payload: edit });
  };
};

export const PropertyCount = (count) => {
  return (dispatch, getState) => {
    dispatch({ type: "PROP_COUNT", payload: count });
  };
};

export const PropertySingle = (property) => {
  return (dispatch, getState) => {
    //alert(JSON.stringify(dispatch))
    dispatch({ type: "PROP_SINGLE", payload: property });
  };
};

export const setPropertyData = (propertyData) => {
  return {
    type: "PROPERTY_SUCCESS",
    payload: propertyData,
  };
};

export const setPropertyOptionData = (propertyOptionData) => {
  return {
    type: "PROP_OPTIONS",
    payload: propertyOptionData,
  };
};

export const setPropertyFeature = (propertyFeature) => {
  return {
    type: "PROP_FEATURES",
    payload: propertyFeature,
  };
};

export const setPropertyDeveloper = (propertyDeveloper) => {
  return {
    type: "PROP_DEVELOPER",
    payload: propertyDeveloper,
  };
};

export const setPropertyPaymentPlan = (propertyPaymentPlan) => {
  return {
    type: "PROP_PAYMENT_PLAN",
    payload: propertyPaymentPlan,
  };
};

// export const setUpdatePropertyPaymentPlan = (propertyPaymentPlanUpdate) => {
//   return {
//     type: "UPDATE_PROP_PAYMENT_PLAN",
//     payload: propertyPaymentPlanUpdate,
//   };
// };

export const setPropertyProgressEmpty = (propertyFeature) => {
  return {
    type: "PROP_PROGRESS_EMPTY",
    payload: propertyFeature,
  };
};

export const setPropertyProgress = (propertyProgress) => {
  return {
    type: "PROP_PROGRESS",
    payload: propertyProgress,
  };
};

export const propertyFeaturesAction = (credentials) => {
  return async (dispatch, getState) => {
    try {
      await firebase
        .firestore()
        .collection("features")
        //.doc('options')
        .onSnapshot((snapshot) => {
          if (snapshot.empty) {
            return;
          }

          var propertyFeatures = [];
          var User = {};
          snapshot.forEach(async (doc) => {
            propertyFeatures.push({
              id: doc.id,
              ...doc.data(),
            });
          });

          dispatch(setPropertyFeature(propertyFeatures));
        });
    } catch (error) {}
  };
};

//propertyDeveloperAction

export const propertyProgressAction = (credentials) => {
  //alert(credentials+' ===');
  return async (dispatch, getState) => {
    try {
      await firebase
        .firestore()
        .collection("propertyProgress")
        //.doc('options')
        .where("pid", "==", credentials)
        .onSnapshot((snapshot) => {
          // alert('pp')
          if (snapshot.empty) {
            // alert('9999')
            //setPropertyProgressEmpty
            dispatch(setPropertyProgressEmpty(propertyProgress));
            return;
          }

          var propertyProgress = [];
          var User = {};
          snapshot.forEach(async (doc) => {
            propertyProgress.push({
              id: doc.id,
              ...doc.data(),
            });
          });

          dispatch(setPropertyProgress(propertyProgress));
        });
    } catch (error) {}
  };
};

export const updatePropertyPaymentPlan = (collectionName, documentName, newData) => {
  return async (dispatch, getState) => {
    try {
      const db = firebase.firestore();
      const docRef = db.collection(collectionName).doc(documentName);

      // Fetch the existing document data
      const docSnapshot = await docRef.get();
      const existingData = docSnapshot.data();

      // Update the "threshold" field with the new data
      if (
        newData.threshold &&
        newData.discount &&
        newData.initialDepositPercentage &&
        newData.multiplier &&
        newData.order &&
        newData.surcharge &&
        newData.title
      ) {
        const updatedData = {
          ...existingData,
          threshold: newData.threshold,
          discount: newData.discount,
          initialDepositPercentage: newData.initialDepositPercentage,
          multiplier: newData.multiplier,
          order: newData.order,
          surcharge: newData.surcharge,
          title: newData.title,
          label: newData.title,
        };

        // Update the document in Firestore
        await docRef.update(updatedData);
        swal({
          title: "Document Updated Successfully",
          text: `Document "${documentName}" in collection "${collectionName}" updated successfully.`,
          icon: "success",
          //buttons: false,
        }).then(() => {
          // Reload the page when OK is clicked
          window.location.reload();
        });
      }
    } catch (error) {
      console.error("Error updating document:", error);
      swal({
        title: "Error updating document",
        text: `${JSON.stringify(error)})`,
        icon: "failure",
        //buttons: false,
      }).then(() => {
        // Reload the page when OK is clicked
        window.location.reload();
      });
    }
  };
};

export const propertyDeveloperAction = (credentials) => {
  return async (dispatch, getState) => {
    try {
      await firebase
        .firestore()
        .collection("developer")

        //.doc('options')
        .onSnapshot((snapshot) => {
          if (snapshot.empty) {
            return;
          }

          var propertyDeveloper = [];
          var User = {};
          snapshot.forEach(async (doc) => {
            propertyDeveloper.push({
              id: doc.id,
              ...doc.data(),
            });
          });

          dispatch(setPropertyDeveloper(propertyDeveloper));
        });
    } catch (error) {}
  };
};

export const propertyPaymentPlanAction = (paymentPlans) => {
  return async (dispatch, getState) => {
    try {
      const fetchPromises = paymentPlans.map(async (plan) => {
        const snapshot = await firebase.firestore().collection(plan).get();
        const propertyPaymentPlan = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        return { [plan]: propertyPaymentPlan };
      });

      const propertyPaymentPlans = await Promise.all(fetchPromises);
      const mergedPropertyPaymentPlan = Object.assign({}, ...propertyPaymentPlans);

      dispatch(setPropertyPaymentPlan(mergedPropertyPaymentPlan));
    } catch (error) {
      console.error(error);
      return error;
    }
  };
};

export const propertyOptionsAction = (credentials) => {
  return async (dispatch, getState) => {
    try {
      await firebase
        .firestore()
        .collection("data")
        //.doc('options')
        .onSnapshot((snapshot) => {
          if (snapshot.empty) {
            return;
          }

          var propertyData = [];
          var User = {};
          snapshot.forEach(async (doc) => {
            propertyData.push({
              id: doc.id,
              ...doc.data(),
            });
          });

          dispatch(setPropertyOptionData(propertyData));
        });
    } catch (error) {}
  };
};

export const propertyActions = (credentials) => {
  return async (dispatch, getState) => {
    dispatch({ type: "PROPERTY_LOADING", loading: true });

    try {
      await firebase
        .firestore()
        .collection("property")
        .onSnapshot((snapshot) => {
          if (snapshot.empty) {
            return;
          }

          var propertyData = [];
          var User = {};
          snapshot.forEach(async (doc) => {
            propertyData.push({
              id: doc.id,
              ...doc.data(),
            });
          });

          // alert(JSON.stringify(propertyData.slice(0,2)))

          dispatch(setPropertyData(propertyData));
        });
    } catch (error) {
      //alert('error')
      dispatch({ type: "PROP_ERROR", payload: error });
    }
  };
};

//coopRegistrationAction.js

import axios from "axios";

import api from "../../utils/config";

import firebase from "firebase/app";
import "firebase/auth";
import app from "../../variables/base";
import swal from "sweetalert";
import { isEmpty } from "lodash";

//SinglePropertyFloorPlan

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: api.REACT_APP_FIREBASE_APP_ID,
    authDomain: api.REACT_APP_FIREBASE_DOMAIN,
    databaseURL: api.REACT_APP_FIREBASE_DATABASE,
    projectId: api.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: api.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: api.REACT_APP_FIREBASE_SENDER_ID,
    appId: api.REACT_APP_FIREBASE_APP_ID,
    measurementId: api.REACT_APP_FIREBASE_MEASUREMENT_ID,
  });
}

export const setGroup_Inv_Data = (propertyData) => {
  return {
    type: "GROUP_INT_SUCCESS",
    payload: propertyData,
  };
};

export const Group_Inv_Actions = (credentials) => {
  return async (dispatch, getState) => {
    dispatch({ type: "GROUP_INT_LOADING", loading: true });

    try {
      await firebase
        .firestore()
        .collection("stowGroupInvite")
        .onSnapshot((snapshot) => {
          if (snapshot.empty) {
            return;
          }

          var propertyData = [];
          // var User = {};
          snapshot.forEach(async (doc) => {
            propertyData.push({
              id: doc.id,
              ...doc.data(),
            });
          });

          dispatch(setGroup_Inv_Data(propertyData));
        });
    } catch (error) {
      console.log("error :: ", error);
      dispatch({ type: "GROUP_INT_ERROR", payload: error });
    }
  };
};

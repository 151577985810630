import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// reactstrap components
import {
  Button,
  Card,
  CardImg,
  CardHeader,
  Modal,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import swal from "sweetalert";
import firebase from "firebase/app";
import "firebase/storage";
import { connect } from "react-redux";
import { PropertySingle } from "./../../../store/actions/propertyActions";

class Documentation extends React.Component {
  state = {
    startDeletingLoader: false,
  };

  constructor(props) {
    super(props);

    //this.activeRoute.bind(this);
  }

  removeByValue = (array, value) => {
    return array.filter(function (elem, _index) {
      return value != elem;
    });
  };

  uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  extractFileExtension = (filename) => {
    //alert(filename)
    var ext = /(?:\.([^.]+))?$/.exec(filename);
    if (ext != null && ext[0] != null) {
      return ext[0];
    } else {
      return "";
    }
  };

  componentDidMount(props) {
    //this.props.propertyOptionsAction()
  }

  render() {
    return (
      <div className="container">
        <div
          className="bg-dark"
          style={{
            background: "#fff",
            padding: "10px",
            borderRadius: "5px",
            boxShadow: "0 0 12px #e2e7ef",
            marginBottom: "10px",
            fontSize: 11,
            height: "auto",
          }}
        >
          <Row>
            <Col lg="12">
              <FormGroup>
                <label>select and upload a document</label>
                <Input
                  className="form-control alternative"
                  id="proDoc"
                  style={{ marginTop: 15 }}
                  accept="application/pdf"
                  type="file"
                />
              </FormGroup>
            </Col>

            <Col lg="12" style={{ marginTop: -15 }}>
              <span id="save_feature"></span>
              <Button
                block
                className="mr-0"
                style={{ float: "right", cursor: "pointer", margin: 10 }}
                disabled={this.state.startSavingLoader}
                title={"Save Document"}
                color="info"
                href="#stow"
                onClick={async (e) => {
                  var proDoc = document.getElementById("proDoc");
                  var proDocData = document.getElementById("proDoc").files[0];

                  if (proDoc.files.length == 0) {
                    swal({
                      title: "Document field is Empty",
                      text: "You must provide a document  for the property to continue",
                      icon: "error",
                    });
                  } else {
                    this.setState({ startSavingLoader: true });

                    var home = this;

                    const image = proDocData;
                    const fileName =
                      this.uuidv4() + this.extractFileExtension(image.name);
                    const storage = firebase.storage();
                    const uploadPhotoTask = storage
                      .ref("proDoc/" + fileName)
                      .put(image);

                    uploadPhotoTask.on(
                      "state_changed",
                      (snapshot) => {},
                      (error) => {
                        swal({
                          title: "Document Upload Error",
                          text: "An unexpected error occured while uploading your document.\n Kindly confirm your internet connection or contact an admin",
                          icon: "error",
                        });

                        //console.log(error)
                      },

                      async () => {
                        storage
                          .ref("proDoc")
                          .child(fileName)
                          .getDownloadURL()
                          .then(async (url) => {
                            var now = new Date();
                            var formattedToday =
                              now
                                .toLocaleDateString("en-GB", {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                })
                                .split(" ")
                                .join("-") + "";

                            var titleDocument = {
                              titleDocument: url,
                            };

                            await firebase
                              .firestore()
                              .collection("property")
                              .doc(this.props.PropertyReducer.propertySingle.id)
                              .update(titleDocument)
                              .then(async function () {
                                home.setState({
                                  startSavingLoader: false,
                                });

                                const Property = await firebase
                                  .firestore()
                                  .collection("property")
                                  .doc(
                                    home.props.PropertyReducer.propertySingle.id
                                  )
                                  .get();

                                var propertyData = [];
                                var projectId = [];

                                projectId.push({
                                  id: Property.id,
                                });

                                propertyData.push(Property.data());

                                Object.assign(propertyData[0], {
                                  id: Property.id,
                                });

                                home.props.PropertySingle(propertyData[0]);

                                swal(
                                  "Document Saved",
                                  "You have successfully added the document to the specified property",
                                  "success"
                                ).then((value) => {
                                  document.location.reload();
                                });
                              });
                          });
                      }
                    );
                  }
                }}
              >
                {this.state.startSavingLoader ? (
                  // <img src={require("assets/img/loader.gif")} />
                  <>
                    {this.props.PropertyReducer.propertySingle.hasOwnProperty(
                      "titleDocument"
                    )
                      ? "Updating..."
                      : "Saving..."}
                    <i className="fa fa-spinner fa-spin"></i>
                  </>
                ) : this.props.PropertyReducer.propertySingle.hasOwnProperty(
                    "titleDocument"
                  ) ? (
                  "Update Document"
                ) : (
                  "Save Document"
                )}
              </Button>
            </Col>
          </Row>
        </div>

        {this.props.PropertyReducer.propertySingle.hasOwnProperty(
          "titleDocument"
        ) ? (
          this.props.PropertyReducer.propertySingle.titleDocument ==
          "" ? null : (
            <>
              <Button
                block
                href={this.props.PropertyReducer.propertySingle.titleDocument}
                target="_blank"
                className="mr-0 btn-secondary"
                style={{
                  float: "right",
                  cursor: "pointer",
                  margin: 10,
                  fontSize: 12,
                }}
                //color="default"
                //href="#stow"
              >
                View Document
              </Button>

              <Button
                block
                onClick={async (e) => {
                  let home = this;

                  swal({
                    title: "Delete Document?",
                    // html:true,
                    text: "Are you sure you want to delete the selected document ? ",
                    icon: "warning",
                    buttons: true,
                    buttons: {
                      cancel: "Cancel",
                      catch: {
                        text: "Delete",
                        //value: "catch",
                      },
                      //defeat: true,
                    },
                    dangerMode: true,
                  }).then(async (willDelete) => {
                    home.setState({
                      startDeletingLoader: true,
                    });

                    if (willDelete) {
                      const storage = firebase.storage();

                      var fileRef = storage.refFromURL(
                        this.props.PropertyReducer.propertySingle.titleDocument
                      );

                      fileRef.delete();

                      /* const storage = firebase.storage();
                        const delImage = storage.ref("proDoc");
                        delImage
                          .child(
                            this.props.PropertyReducer.propertySingle
                              .titleDocument
                          )
                          .delete(); */

                      var titleDocument = {
                        titleDocument: "",
                      };

                      await firebase
                        .firestore()
                        .collection("property")
                        .doc(this.props.PropertyReducer.propertySingle.id)
                        .update(titleDocument)
                        .then(async function () {
                          const Property = await firebase
                            .firestore()
                            .collection("property")
                            .doc(home.props.PropertyReducer.propertySingle.id)
                            .get();

                          var propertyData = [];
                          var projectId = [];

                          projectId.push({
                            id: Property.id,
                          });

                          propertyData.push(Property.data());

                          Object.assign(propertyData[0], {
                            id: Property.id,
                          });

                          home.props.PropertySingle(propertyData[0]);

                          swal(
                            "Document Deleted",
                            "You have successfully deleted the document to the specified property",
                            "success"
                          ).then((value) => {
                            document.location.reload();
                          });
                        });
                    }
                  });
                }}
                // href={this.props.PropertyReducer.propertySingle.titleDocument}
                target="_blank"
                className="mr-0 btn-danger"
                style={{
                  float: "right",
                  cursor: "pointer",
                  margin: 10,
                  fontSize: 12,
                }}
                //color="default"
                //href="#stow"
              >
                {this.state.startDeletingLoader ? (
                  // <img src={require("assets/img/loader.gif")} />
                  <>
                    Deleting... <i className="fa fa-spinner fa-spin"></i>
                  </>
                ) : (
                  "Delete Document"
                )}
              </Button>
            </>
          )
        ) : null}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    //propertyOptionsAction: (param) => dispatch(propertyOptionsAction(param)),
    //promoActions: (param) => dispatch(promoActions(param)),
    PropertySingle: (param) => dispatch(PropertySingle(param)),
  };
};

const mapStateToProps = (state) => ({
  currentUser: state.authReducer,
  PropertyReducer: state.PropertyReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(Documentation);

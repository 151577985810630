import axios from "axios";

import api from "../../utils/config";

import firebase from "firebase/app";
import "firebase/auth";
import app from "./../../variables/base";
import swal from "sweetalert";
import { isEmpty } from "lodash";

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: api.REACT_APP_FIREBASE_APP_ID,
    authDomain: api.REACT_APP_FIREBASE_DOMAIN,
    databaseURL: api.REACT_APP_FIREBASE_DATABASE,
    projectId: api.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: api.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: api.REACT_APP_FIREBASE_SENDER_ID,
    appId: api.REACT_APP_FIREBASE_APP_ID,
    measurementId: api.REACT_APP_FIREBASE_MEASUREMENT_ID,
  });
}

export const UserCount = (count) => {
  return (dispatch, getState) => {
    dispatch({ type: "USER_COUNT", payload: count });
  };
};

export const setUserCountData = (UserCountData) => {
  return {
    type: "USER_SUCCESS",
    payload: UserCountData,
  };
};

export const setDeviceData = (deviceData) => {
  return {
    type: "DEVICE_SUCCESSFUL",
    payload: deviceData,
  };
};

export const setUserPushData = (UserPushData) => {
  return {
    type: "USER_PUSH_SUCCESS",
    payload: UserPushData,
  };
};

export const setUserGroupPushData = (UserPushData) => {
  return {
    type: "USER_GROUP_PUSH_SUCCESS",
    payload: UserPushData,
  };
};

export const LoadOneSignalDevices = () => {
  return async (dispatch, getState) => {
    dispatch({ type: "DEVICE_LOADING" });
    // dispatch({ type: GLOBAL_LOADING, title: "Loading Devices..." });

    fetch(
      "https://onesignal.com/api/v1/players?app_id=" +
        process.env.REACT_APP_ONNESIGNAL_APP_ID +
        "",
      {
        method: "GET",
        headers: {
          Authorization: `Basic ${process.env.REACT_APP_ONNESIGNAL_REST_API_KEY}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then(async (response) => {
        dispatch(setDeviceData(response.players));
        //        dispatch({ type: 'GLOBAL_SUCCESSFUL' });
      })
      .catch((error) => {
        dispatch({
          type: "DEVICE_ERROR",
          payload: "Error Loading OnSignal Devices",
        });
      });
  };
};

export const UserActions = (credentials) => {
  return async (dispatch, getState) => {
    dispatch({ type: "USER_LOADING", loading: true });

    try {
      await firebase
        .firestore()
        .collection("users")
        //.orderBy("order","asc")
        .onSnapshot((snapshot) => {
          if (snapshot.empty) {
            return;
          }

          var UserCountData = [];
          //var User ={}
          snapshot.forEach(async (doc) => {
            //console.log(User.data())

            UserCountData.push({
              id: doc.id,
              //User: (isEmpty(User)?User:User.data()),
              ...doc.data(),
            });
          });

          // alert(JSON.stringify(UserCountData.slice(0,2)))

          dispatch(setUserCountData(UserCountData));
        });
    } catch (error) {
      //alert('error')
      dispatch({ type: "USER_ERROR", payload: error });
    }
  };
};

export const UserPushActions = (credentials) => {
  return async (dispatch, getState) => {
    dispatch({ type: "USER_PUSH_LOADING", loading: true });

    try {
      //alert('000')

      await firebase
        .firestore()
        .collection("pushNotifications")
        //.orderBy("order","asc")
        .onSnapshot((snapshot) => {
          if (snapshot.empty) {
            return;
          }

          var UserPushData = [];
          //var User ={}
          snapshot.forEach(async (doc) => {
            //console.log(User.data())

            UserPushData.push({
              id: doc.id,
              ...doc.data(),
            });
          });

          //alert(JSON.stringify(UserPushData))
          dispatch(setUserPushData(UserPushData));
        });
    } catch (error) {
      alert("Pusherror");
      dispatch({ type: "USER_PUSH_ERROR", payload: error });
    }
  };
};

export const UserSegmentPushActions = (credentials) => {
  return async (dispatch, getState) => {
    dispatch({ type: "USER_GROUP_PUSH_LOADING", loading: true });

    try {
      //alert('000')

      await firebase
        .firestore()
        .collection("pushSegment")
        //.orderBy("order","asc")
        .onSnapshot((snapshot) => {
          if (snapshot.empty) {
            return;
          }

          var UserPushData = [];
          //var User ={}
          snapshot.forEach(async (doc) => {
            //console.log(User.data())

            UserPushData.push({
              id: doc.id,
              ...doc.data(),
            });
          });

          //alert(JSON.stringify(UserPushData))
          dispatch(setUserGroupPushData(UserPushData));
        });
    } catch (error) {
      alert("Pusherror");
      dispatch({ type: "USER_GROUP_PUSH_ERROR", payload: error });
    }
  };
};

import React from "react";
// node.js library that concatenates classes (strings)



// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  Modal,
  Form,
  FormGroup,
  Input,
  Badge,
  CardImg,
  Alert 
} from "reactstrap";

import Slider from "nouislider";


//import FileUploader from "react-firebase-file-uploader";

import   firebase from "firebase/app";
import  'firebase/storage'; 

import   ContentLoader,{BulletList,Code  } from 'react-content-loader'

import api from "../../../utils/config";
import { connect } from "react-redux";
import { currencyFormat,dateFormater } from '../../../utils/functions';
import { PropertySingle } from "./../../../store/actions/propertyActions";
import Compress from "react-image-file-resizer";


import { isEmpty } from "lodash";
import swal from "sweetalert";



class UploadLogo extends React.Component {

        constructor(props)
        {
            super(props);

            const { data,pid } = props;

            this.state = {
                filenames: null,
                downloadURLs: null,
                isUploading: false,
                isUploadingError:false,
                uploadProgress: 0,
                logoObject:[],
                pid :pid,
                serverMessage:null,
               mobileUri:null,
               mobileFinalUrl:null

              };
        }



 extractFileExtension =(filename)=>{
    //alert(filename)
   var ext = /(?:\.([^.]+))?$/.exec(filename);
   if (ext != null && ext[0] != null) {
     return ext[0];
   } else {
     return '';
   } 
 }
 
 
    uuidv4 =()=> {
     return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
       var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
       return v.toString(16);
     });
   }


   handleUpload =(e)=>{

    //console.log(e.target.files[0])
    const image = e.target.files[0]




  Compress.imageFileResizer(
    image, // the file from input
    300, // width
    'auto', // height
    "JPEG", // compress format WEBP, JPEG, PNG
    100, // quality
    0, // rotation
    (uri) => {
      //console.log(uri);
      this.setState({
        mobileUri:uri
      },()=>{



        var strImage0 = this.state.mobileUri.split(',')[0];
        var strImage1 = this.state.mobileUri.split(',')[1];
        var homeMobile = this



        var message = strImage1;
         

        const fileName = this.uuidv4()+this.extractFileExtension(image.name); 
        const storage = firebase.storage();
        //const Base64Photo = storage.ref('photos_mobile/').child(fileName).putString(message, 'base64',{contentType:'image/jpg'});
        const Base64Photo = storage.ref('logoMobile/'+fileName).putString(message, 'base64',{contentType:'image/jpg'});



        this.setState({
          isUploading:true,
          serverMessage:'Updating Mobile Logo...'
        });


        Base64Photo.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'

            
        function(snapshot){


          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          homeMobile.setState({
                    serverMessage:'Updating Mobile Logo '+ parseInt(progress)+'% ...'
                  });

                   //console.log('Upload is ' + progress + '% done');
                   
                  switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                    // console.log('Upload is paused');

                    homeMobile.setState({
                        serverMessage:'Updating Logo Paused'
                      })

                      break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                      //console.log('Upload is running :: '+progress);
                      

                      break;
                  }


        }, error=>{
        
          swal("Error Updating Mobile Logo", 'Unable to Update Mobile Logo\n with id :: '+homeMobile.state.pid+' \n'+JSON.stringify(error), "danger")

        },
        ()=>{


          storage
          .ref("logoMobile")
          .child(fileName)
          .getDownloadURL()
          .then(url=>{

            //console.log(url)

            homeMobile.setState({
              mobileFinalUrl:url
            })


            const fileName = this.uuidv4()+this.extractFileExtension(image.name); 
          const storage = firebase.storage();
          const uploadPhotoTask = storage.ref('logo/'+fileName).put(image);
          homeMobile.setState({
            filenames:fileName,
            isUploading:true,
            serverMessage:'Uploading Web Logo...'
          })


    uploadPhotoTask.on(
      "state_changed",
      function(snapshot){

        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  
        homeMobile.setState({
                  serverMessage:'Updating Web Logo '+ parseInt(progress)+'% ...'
                });
  
                 //console.log('Upload is ' + progress + '% done');
                 
                switch (snapshot.state) {
                  case firebase.storage.TaskState.PAUSED: // or 'paused'
                  // console.log('Upload is paused');
  
                  homeMobile.setState({
                      serverMessage:'Updating Web Logo Paused'
                    })
  
                    break;
                  case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running :: '+progress);
                    
  
                    break;
                }
  
      },
      error=>{
  
        homeMobile.setState({
          isUploadingError:true,
        })
        swal("Error Updating Web Logo", 'Unable to Update Web Logo\n with id :: '+homeMobile.state.pid+' \n'+JSON.stringify(error), "danger")

        //console.log(error)
      },
      ()=>{
        storage
        .ref("logo")
        .child(fileName)
        .getDownloadURL()
        .then(url=>{
      
          //console.log(url)
          //var home= this
  
          var newPhoto =
                        {
                            "logo":image.name,
                            "src":url,
                            'mobile':this.state.mobileFinalUrl
                             

                          
                        }
              
                        
  
                      
                        homeMobile.setState(prevState => ({
                        logoObject: newPhoto,//[...prevState.logoObject, newPhoto],
                        serverMessage:'Logo Uploaded'
                        
                      }), async()=>
                      {
                        //alert(JSON.stringify(this.state.logoObject))
                       
  
                        const photo ={
                          logo:this.state.logoObject,
                          
                        }
  
                        homeMobile.setState({
                          serverMessage:'Updating Logo Data...'
                        })
                        //console.log(this.state.logoObject) 
                                    //console.log(this.state.id)
  
                        await firebase.firestore()
                                    .collection("property")
                                    .doc(homeMobile.state.pid)
                                    .update(photo).then( async ()=>
                                      {
                                        const Property = await  firebase.firestore()
                                                                .collection("property")
                                                                .doc(homeMobile.state.pid).get()
                                                                
  
                                                                homeMobile.setState({
                                                                  serverMessage:'Logo Data Updated...',
                                                                  isUploading:false,
                                                                })
  
                                                                var propertyData = [];
                                                                var projectId =[];
                                                                
                                                                projectId.push({
                                                                  id: Property.id,
                                                                  
                                                                }); 
  
                                                                propertyData.push(Property.data())
  
                                                                Object.assign(propertyData[0], {id: Property.id});
                                                                
                                                                
                                           
                                           
  
                                            //console.log(propertyData[0])
                                            
                                                               
  
                                                                
  
                                            homeMobile.props.PropertySingle(propertyData[0])
  
  
                                      }
                                    ); 
  
                      swal("Logo Updated", 'You have successfully upload '+image.name +' to this property', "success")
                      .then((value) => {
                        
                        //document.location.reload()
                      });
                                    //alert('You have successfully upload '+image.name +' to this property')
                    
                                    
  
  
                      })
  
          
        })
  
  
  
      }
      )






            ////////////////////////////////


          })


        })



      })
     // alert(uri)
      // You upload logic goes here
    },
    "base64" // blob or base64 default base64
  );
    
    
  
   
  }
   
 



        componentDidMount  (props)
        {

                if (!firebase.apps.length)
                {
                    firebase.initializeApp({
                        apiKey: api.REACT_APP_FIREBASE_APP_ID,
                        authDomain: api.REACT_APP_FIREBASE_DOMAIN,
                        databaseURL: api.REACT_APP_FIREBASE_DATABASE,
                        projectId: api.REACT_APP_FIREBASE_PROJECT_ID,
                        storageBucket: api.REACT_APP_FIREBASE_STORAGE_BUCKET,
                        messagingSenderId: api.REACT_APP_FIREBASE_SENDER_ID,
                        appId: api.REACT_APP_FIREBASE_APP_ID,
                        measurementId: api.REACT_APP_FIREBASE_MEASUREMENT_ID,
                    });          

                }

                

        }


            
    render()
    {

        return(<div>
            <Alert color="default">
                        <span className="alert-inner--icon">
                          <i className="ni ni-image" />
                        </span>{" "} {" "}
                        <span className="alert-inner--text">
                          <strong>Upload Logo</strong> 
                        </span>



                          {
                            (this.state.isUploading)?
                            (<div className='mt-5'>{this.state.serverMessage} <i className="fa fa-spinner fa-spin"></i></div>)
                            :(
                              <Input 
                                style={{
                                  "color": "#212529",
                                  "backgroundColor": "#f7fafc",
                                  "borderColor": "#f7fafc",
                                  "boxShadow": "0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
                                  "display": "inline-block",
                                  "fontWeight": "600",
                                  
                                  "border": "1px solid transparent",
                                  "padding": "0.625rem 1.25rem",
                                  "fontSize": "0.5rem",
                                  "lineHeight": "1.5",
                                  "borderRadius": "0.375rem",
                                  "transition": "color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out"

                                }} 
                              
                                accept="image/*"
                                className="mt-2"
                                type="file"
                                id="logo_upload"
                                onChange={

                                  this.handleUpload


                                }
                             />
                            )
                          }
                        


                       
                    

                      </Alert>
        </div>)



    }



}



const mapDispatchToProps = (dispatch) => {
    return { 
  
      PropertySingle: (param) => dispatch(PropertySingle(param)),
      
     };
  };
  
  const mapStateToProps = (state) => ({
      
     PropertyReducer: state.PropertyReducer,
     
    
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(UploadLogo);
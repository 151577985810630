import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Badge,
  Form,
  Input,
  Container,
  Row,
  Col,
  CardImg,
  CardTitle,
  Modal,
  Alert,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  UncontrolledAlert,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import CurrencyFormat from "react-currency-format";

import swal from "sweetalert";

import MaterialTable, { MTableToolbar, MTableCell } from "material-table";
import CountUp from "react-countup";
import firebase from "firebase/app";
import AddTransaction from "./AddTransaction";

import {
  currencyFormat,
  dateFormater,
  UserRolePermission,
} from "../../utils/functions";

import {
  FI_2Y_Actions,
  FI_4Y_Actions,
  FI_TR_Actions,
} from "store/actions/fractionalnvestmentAction";
import { connect } from "react-redux";

class FIModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      startSavingLoader: false,
      isEmpty: true,
      openTransModel: false,
      defaultValue: {
        pid: this.props.propertyid,
        returnPerAnnualPercentage: 0,
        discountedPrice: 0,
        name: this.props.propertyName,
        projectedValue: 0,
        guaranteedCapitalGain: 0,
        projectedValueCapitalGain: 0,
        availableUnits: 0,
        numberOfInvestors: 0,
        totalEarning: 0,
        createdate: firebase.firestore.FieldValue.serverTimestamp(),
        createdby: this.props.currentUser.currentUserEmail,
      },
      formData: {
        pid: this.props.propertyid,
        returnPerAnnualPercentage: 0,
        discountedPrice: 0,
        name: this.props.propertyName,
        projectedValue: 0,
        guaranteedCapitalGain: 0,
        projectedValueCapitalGain: 0,
        availableUnits: 0,
        numberOfInvestors: 0,
        totalEarning: 0,
      },
    };
  }

  componentDidMount() {
    this.props.FI_2Y_Actions();
    this.props.FI_4Y_Actions();
    this.props.FI_TR_Actions();

    // console.log(this.props.propertyFI+":::::");
    //this.setState({ propertyFI: this.props.propertyFI });
  }

  toggle = (tab) => {
    this.setState({ activeTab: tab });
    //if(activeTab !== tab) setActiveTab(tab);
  };

  updateFormData = (field, value) => {
    // console.log(field + " - " + value);
    let data = this.state.formData;
    data[field] = parseInt(value);
    this.setState({ formData: data });
    // console.log(data);
  };

  toggleFI_By_YEAR_AND_ID = (pid, year) => {
    //console.log(year);

    if (year == 2) {
      let data = this.props?.FI_Reducer?.propFI_2Y.find(
        (data, i) => data.pid === pid
      );

      if (data === undefined) {
        this.setState({ formData: this.state.defaultValue, isEmpty: true });
      } else {
        this.setState({ formData: data, isEmpty: false });
      }
      //console.log(this.state.formData);
    } else if (year == 4) {
      let data = this.props?.FI_Reducer?.propFI_4Y.find(
        (data, i) => data.pid === pid
      );

      if (data === undefined) {
        this.setState({ formData: this.state.defaultValue, isEmpty: true });
      } else {
        this.setState({ formData: data, isEmpty: false });
      }
      //console.log(this.state.formData);
    }
  };

  toggleModalTrans = () => {
    this.setState({ openTransModel: !this.state.openTransModel });
  };

  render() {
    // console.log(JSON.stringify(this.props.propertyFI) + "::::");

    return (
      <>
        <Modal
          id="model_FI_edit"
          // size="lg"
          style={{ minWidth: 80 + "%" }}
          className="modal-dialog-centered"
          isOpen={this.props.modalState}
          // toggle={() => this.toggleModal("exampleModal")}
        >
          <div className="modal-header">
            <h5
              className="modal-title"
              id="_"
              style={{ textTransform: "uppercase" }}
            >
              MANAGE {this.props.propertyName} FRACTIONAL INVESTMENT
            </h5>

            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                this.props.toggleModal();
              }}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>

          <div className="modal-body">
            <Row>
              <Col lg="12">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={this.state.activeTab == 2 ? "active" : null}
                      onClick={() => {
                        this.toggle("2");
                      }}
                    >
                      Form
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      style={{ pointer: "cursor" }}
                      className={this.state.activeTab == 1 ? "active" : null}
                      onClick={() => {
                        this.toggle("1");
                      }}
                    >
                      Transactions
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={this.state.activeTab}>
                  <TabPane
                    tabId="1"
                    className={this.state.activeTab == 1 ? "active" : null}
                  >
                    <div
                      class=""
                      style={{
                        border: "0px solid red",
                        minHeight: 400,
                        paddingTop: 40,
                      }}
                    >
                      <Row>
                        <Col lg="12">
                          <MaterialTable
                            columns={[
                              {
                                title: "Customer",
                                field: "unm",
                              },
                              {
                                title: "Phone",
                                field: "phone",
                              },

                              {
                                title: "Email",
                                field: "email",
                              },
                              {
                                title: "Ref-No",
                                field: "ref",
                              },
                              {
                                title: "Type",
                                field: "payment_type",
                              },
                              {
                                title: "paid",
                                field: "paid",
                                render: (rowData) => {
                                  if (rowData.paid) {
                                    return (
                                      <div>
                                        {currencyFormat(parseInt(rowData.paid))}
                                      </div>
                                    );
                                  } else {
                                    return "₦ 0.00";
                                  }
                                },
                              },
                              {
                                title: "Inv-Length",
                                field: "investmentLength",
                              },
                              {
                                title: "Property",
                                field: "pnm",
                              },
                              {
                                title: "D-Price",
                                field: "discountedPrice",
                                render: (rowData) => {
                                  if (rowData.discountedPrice) {
                                    return (
                                      <div>
                                        {currencyFormat(
                                          parseInt(rowData.discountedPrice)
                                        )}
                                      </div>
                                    );
                                  } else {
                                    return "₦ 0.00";
                                  }
                                },
                              },

                              {
                                title: "Date",
                                field: "createdDate",
                                render: (rowdData) => {
                                   return rowdData.createdDate
                                     .toDate()
                                     .toDateString("en-US");
                                  
                                }
                                 
                              },
                            ]}
                            actions={[
                              {
                                icon: () => (
                                  <>
                                    <AddTransaction
                                      toggleModal={() =>
                                        this.toggleModalTrans()
                                      }
                                      modalState={this.state.openTransModel}
                                      propertyName={this.props.propertyName}
                                      propertyid={this.props.propertyid}
                                    />
                                    <Button
                                      color="primary"
                                      onClick={() => {}}
                                      disabled={UserRolePermission(
                                        this.props.currentUser.UserData.role,
                                        "add_prop"
                                      )}
                                      href="#"
                                      style={{
                                        fontSize: 20,
                                        paddingBottom: 0,
                                        display: UserRolePermission(
                                          this.props.currentUser.UserData.role,
                                          "add_prop"
                                        )
                                          ? "none"
                                          : "block",
                                      }}
                                      size="sm"
                                    >
                                      <i className="ni ni-fat-add"></i>
                                    </Button>
                                  </>
                                ),
                                tooltip: "Manually add a Transaction",
                                isFreeAction: true,
                                onClick: (event) =>
                                  this.setState({
                                    openTransModel: true,
                                  }),
                              },
                            ]}
                            data={this.props.propertyFI || []}
                            options={{
                              exportButton: true,
                              exportFileName: "Property_Data_" + new Date(),
                              //grouping: true,
                              searchText: this.props.propertyName || "",
                              pageSize: 10,
                              pageSizeOptions: [
                                5, 10, 20, 30, 50, 75, 100, 200, 500, 1000,
                                2000, 3000, 5000, 8000, 10000,
                              ],
                              toolbar: true,
                              // paging: true,

                              rowStyle: {
                                paddingLeft: "1.5rem",
                                paddingRight: "1.5rem",
                                verticalAlign: "middle",

                                fontSize: "0.8125rem",
                                whiteSpace: "nowrap",
                                padding: "1rem",
                                borderTop: "1px solid #e9ecef",
                                fontFamily: "Open Sans, sans-serif",
                              },

                              headerStyle: {
                                paddingTop: "0.75rem",
                                paddingBottom: "0.75rem",
                                fontSize: "0.65rem",
                                textTransform: "uppercase",
                                letterPpacing: "1px",
                                borderBottom: "1px solid #e9ecef",
                                fontWeight: "bolder",
                                paddingLeft: "1.5rem",
                                paddingRight: "1.5rem",
                                backgroundColor: "#f6f9fc",
                                color: "#8898aa",
                                verticalAlign: "middle",
                                borderColor: "#e9ecef",
                                fontFamily: "Open Sans, sans-serif",
                              },
                            }}
                            title={" "}
                            components={{
                              Toolbar: (props) => (
                                <p className="h3" style={{ padding: 10 }}>
                                  <MTableToolbar {...props} />
                                  <div style={{ marginTop: -40 }}>
                                    {"Total"}{" "}
                                    <CountUp
                                      end={
                                        Object.keys(this.props.propertyFI)
                                          .length
                                      }
                                    />
                                  </div>
                                </p>
                              ),

                              Cell: (props) => {
                                if (props.columnDef.field == "tid") {
                                  //return <MTableCell {...props} id={'cell_'+(props.rowData.hasOwnProperty('r_txnref')?props.rowData.r_txnref:props.rowData.tid)} />
                                  return <MTableCell {...props} />;
                                } else {
                                  return (
                                    <MTableCell
                                      {...props}
                                      data={JSON.stringify(props.columnDef)}
                                    />
                                  );
                                }
                              },
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  </TabPane>

                  <TabPane
                    tabId="2"
                    className={this.state.activeTab == 2 ? "active" : null}
                  >
                    <div
                      class="container"
                      style={{
                        border: "0px solid red",
                        minHeight: 400,
                        paddingTop: 40,
                      }}
                    >
                      <Row>
                        <Col lg="12">
                          <div className="pl-lg-4 px-10">
                            <Form>
                              <UncontrolledAlert
                                id="validate_form_msg"
                                className=" alert-warning"
                                style={{ display: "none", marginTop: 15 }}
                                fade={false}
                              >
                                <span className="alert-inner--icon">
                                  <i className="ni ni-like-2" />
                                </span>{" "}
                                <span className="alert-inner--text">
                                  <strong>Validation!</strong> Some important
                                  fields are not filled out properly
                                </span>
                              </UncontrolledAlert>
                              <Row>
                                <Col lg="12">
                                  <FormGroup id="yearsD">
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-first-name"
                                    >
                                      How many years
                                    </label>

                                    <select
                                      onChange={(e) =>
                                        this.toggleFI_By_YEAR_AND_ID(
                                          this.props.propertyid,
                                          e.target.value
                                        )
                                      }
                                      className="form-control-alternative form-control"
                                      id="years"
                                      name="years"
                                    >
                                      <option value="">
                                        -- Select number of years --
                                      </option>
                                      <option value={2}>2 Years</option>
                                      <option value={4}>4 Years</option>
                                    </select>
                                  </FormGroup>
                                </Col>
                              </Row>

                              <Row>
                                <Col lg="6">
                                  <FormGroup id="availableUnitsD">
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-username"
                                    >
                                      Available Units
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      id="availableUnits"
                                      name="availableUnits"
                                      onChange={(e) =>
                                        this.updateFormData(
                                          e.target.name,
                                          e.target.value
                                        )
                                      }
                                      placeholder="0"
                                      value={
                                        this.state.formData?.availableUnits
                                      }
                                      defaultValue={
                                        this.state.formData?.availableUnits
                                      }
                                      type="number"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup id="discountedPriceD">
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-email"
                                    >
                                      Discounted Price
                                    </label>

                                    <CurrencyFormat
                                      id="discountedPrice"
                                      name="discountedPrice"
                                      thousandSeparator={true}
                                      prefix={"₦"}
                                      className="form-control-alternative form-control"
                                      value={
                                        this.state.formData?.discountedPrice
                                      }
                                      onValueChange={(values) =>
                                        this.updateFormData(
                                          "discountedPrice",
                                          values.value
                                        )
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <FormGroup id="guaranteedCapitalGainD">
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-first-name"
                                    >
                                      Guaranteed Capital Gain
                                    </label>

                                    <CurrencyFormat
                                      id="guaranteedCapitalGain"
                                      name="guaranteedCapitalGain"
                                      thousandSeparator={true}
                                      prefix={"₦"}
                                      className="form-control-alternative form-control"
                                      value={
                                        this.state.formData
                                          ?.guaranteedCapitalGain
                                      }
                                      onValueChange={(values) =>
                                        this.updateFormData(
                                          "guaranteedCapitalGain",
                                          values.value
                                        )
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup id="numberOfInvestorsD">
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-last-name"
                                    >
                                      Number Of Investors
                                    </label>
                                    <Input
                                      value={
                                        this.state.formData?.numberOfInvestors
                                      }
                                      onChange={(e) =>
                                        this.updateFormData(
                                          e.target.name,
                                          e.target.value
                                        )
                                      }
                                      className="form-control-alternative"
                                      //defaultValue="Jesse"
                                      id="numberOfInvestors"
                                      name="numberOfInvestors"
                                      defaultValue={
                                        this.state.formData?.numberOfInvestors
                                      }
                                      placeholder="0"
                                      type="number"
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>

                              <Row>
                                <Col lg="6">
                                  <FormGroup id="projectedValueD">
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-first-name"
                                    >
                                      Projected Value
                                    </label>

                                    <CurrencyFormat
                                      id="projectedValue"
                                      name="projectedValue"
                                      thousandSeparator={true}
                                      prefix={"₦"}
                                      className="form-control-alternative form-control"
                                      value={
                                        this.state.formData?.projectedValue
                                      }
                                      onValueChange={(values) =>
                                        this.updateFormData(
                                          "projectedValue",
                                          values.value
                                        )
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup id="projectedValueCapitalGainD">
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-last-name"
                                    >
                                      Projected Value Capital Gain
                                    </label>

                                    <CurrencyFormat
                                      id="projectedValueCapitalGain"
                                      name="projectedValueCapitalGain"
                                      thousandSeparator={true}
                                      prefix={"₦"}
                                      className="form-control-alternative form-control"
                                      value={
                                        this.state.formData
                                          ?.projectedValueCapitalGain
                                      }
                                      onValueChange={(values) =>
                                        this.updateFormData(
                                          "projectedValueCapitalGain",
                                          values.value
                                        )
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>

                              <Row>
                                <Col lg="6">
                                  <FormGroup id="totalEarningD">
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-first-name"
                                    >
                                      Total Earning
                                    </label>

                                    <CurrencyFormat
                                      id="totalEarning"
                                      name="totalEarning"
                                      thousandSeparator={true}
                                      prefix={"₦"}
                                      className="form-control-alternative form-control"
                                      value={this.state.formData?.totalEarning}
                                      onValueChange={(values) =>
                                        this.updateFormData(
                                          "totalEarning",
                                          values.value
                                        )
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup id="returnPerAnnualPercentageD">
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-last-name"
                                    >
                                      Return Per-Annual Percentage
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      id="returnPerAnnualPercentage"
                                      name="returnPerAnnualPercentage"
                                      value={
                                        this.state.formData
                                          ?.returnPerAnnualPercentage
                                      }
                                      onChange={(e) =>
                                        this.updateFormData(
                                          e.target.name,
                                          e.target.value
                                        )
                                      }
                                      placeholder="0"
                                      type="number"
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <Button
                                    className="float-left"
                                    style={{ marginLeft: 20 }}
                                    //color="default"
                                    href="#stow"
                                    onClick={(e) => {
                                      e.preventDefault();

                                      this.props.toggleModal();
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                </Col>

                                <Col>
                                  <Button
                                    className="float-right"
                                    style={{ marginRight: 5 }}
                                    color="success"
                                    href="#stow"
                                    type="submit"
                                    onClick={async (e) => {
                                      e.preventDefault();
                                      //this.state.formData?.discountedPrice
                                      document
                                        .getElementById("yearsD")
                                        .classList.remove("has-danger");

                                      document
                                        .getElementById("availableUnitsD")
                                        .classList.remove("has-danger");
                                      document
                                        .getElementById("discountedPriceD")
                                        .classList.remove("has-danger");
                                      document
                                        .getElementById(
                                          "guaranteedCapitalGainD"
                                        )
                                        .classList.remove("has-danger");
                                      document
                                        .getElementById("numberOfInvestorsD")
                                        .classList.remove("has-danger");
                                      document
                                        .getElementById("numberOfInvestorsD")
                                        .classList.remove("has-danger");
                                      document
                                        .getElementById("projectedValueD")
                                        .classList.remove("has-danger");
                                      document
                                        .getElementById(
                                          "projectedValueCapitalGainD"
                                        )
                                        .classList.remove("has-danger");
                                      document
                                        .getElementById("totalEarningD")
                                        .classList.remove("has-danger");
                                      document
                                        .getElementById(
                                          "returnPerAnnualPercentageD"
                                        )
                                        .classList.remove("has-danger");
                                      ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

                                      if (
                                        document.getElementById("years")
                                          .value == ""
                                      ) {
                                        document
                                          .getElementById("yearsD")
                                          .classList.add("has-danger");
                                        document
                                          .getElementById("years")
                                          .focus();
                                        document.getElementById(
                                          "validate_form_msg"
                                        ).style.display = "block";
                                      } else if (
                                        document.getElementById(
                                          "availableUnits"
                                        ).value == ""
                                      ) {
                                        document
                                          .getElementById("availableUnitsD")
                                          .classList.add("has-danger");
                                        document
                                          .getElementById("availableUnits")
                                          .focus();
                                        document.getElementById(
                                          "validate_form_msg"
                                        ).style.display = "block";
                                      } else if (
                                        this.state.formData?.discountedPrice ===
                                          "" ||
                                        this.state.formData?.discountedPrice ==
                                          0
                                      ) {
                                        document
                                          .getElementById("discountedPriceD")
                                          .classList.add("has-danger");
                                        document
                                          .getElementById("discountedPrice")
                                          .focus();
                                        document.getElementById(
                                          "validate_form_msg"
                                        ).style.display = "block";
                                      } else if (
                                        this.state.formData
                                          ?.guaranteedCapitalGain === "" ||
                                        this.state.formData
                                          ?.guaranteedCapitalGain == 0
                                      ) {
                                        document
                                          .getElementById(
                                            "guaranteedCapitalGainD"
                                          )
                                          .classList.add("has-danger");
                                        document
                                          .getElementById(
                                            "guaranteedCapitalGain"
                                          )
                                          .focus();
                                        document.getElementById(
                                          "validate_form_msg"
                                        ).style.display = "block";
                                      } else if (
                                        document.getElementById(
                                          "numberOfInvestors"
                                        ).value === ""
                                      ) {
                                        document
                                          .getElementById("numberOfInvestorsD")
                                          .classList.add("has-danger");
                                        document
                                          .getElementById("numberOfInvestors")
                                          .focus();
                                        document.getElementById(
                                          "validate_form_msg"
                                        ).style.display = "block";
                                      } else if (
                                        this.state.formData?.projectedValue ===
                                          "" ||
                                        this.state.formData?.projectedValue == 0
                                      ) {
                                        document
                                          .getElementById("projectedValueD")
                                          .classList.add("has-danger");
                                        document
                                          .getElementById("projectedValue")
                                          .focus();
                                        document.getElementById(
                                          "validate_form_msg"
                                        ).style.display = "block";
                                      } else if (
                                        this.state.formData
                                          ?.projectedValueCapitalGain === "" ||
                                        this.state.formData
                                          ?.projectedValueCapitalGain == 0
                                      ) {
                                        document
                                          .getElementById(
                                            "projectedValueCapitalGainD"
                                          )
                                          .classList.add("has-danger");
                                        document
                                          .getElementById(
                                            "projectedValueCapitalGain"
                                          )
                                          .focus();
                                        document.getElementById(
                                          "validate_form_msg"
                                        ).style.display = "block";
                                      } else if (
                                        this.state.formData?.totalEarning ===
                                          "" ||
                                        this.state.formData?.totalEarning == 0
                                      ) {
                                        document
                                          .getElementById("totalEarningD")
                                          .classList.add("has-danger");
                                        document
                                          .getElementById("totalEarning")
                                          .focus();
                                        document.getElementById(
                                          "validate_form_msg"
                                        ).style.display = "block";
                                      } else if (
                                        document.getElementById(
                                          "returnPerAnnualPercentage"
                                        ).value === "" ||
                                        document.getElementById(
                                          "returnPerAnnualPercentage"
                                        ).value == 0
                                      ) {
                                        document
                                          .getElementById(
                                            "returnPerAnnualPercentageD"
                                          )
                                          .classList.add("has-danger");
                                        document
                                          .getElementById(
                                            "returnPerAnnualPercentage"
                                          )
                                          .focus();
                                        document.getElementById(
                                          "validate_form_msg"
                                        ).style.display = "block";
                                      } else {
                                        this.setState({
                                          startSavingLoader: true,
                                        });
                                        document.getElementById(
                                          "validate_form_msg"
                                        ).style.display = "none";

                                        const payload = {
                                          pid: this.props.propertyid,
                                          name: this.props.propertyName,
                                          availableUnits:
                                            this.state.formData.availableUnits,
                                          discountedPrice:
                                            this.state.formData.discountedPrice,
                                          guaranteedCapitalGain:
                                            this.state.formData
                                              .guaranteedCapitalGain,
                                          numberOfInvestors:
                                            this.state.formData
                                              .numberOfInvestors,
                                          projectedValue:
                                            this.state.formData.projectedValue,
                                          projectedValueCapitalGain:
                                            this.state.formData
                                              .projectedValueCapitalGain,
                                          totalEarning:
                                            this.state.formData.totalEarning,
                                          returnPerAnnualPercentage:
                                            this.state.formData
                                              .returnPerAnnualPercentage,
                                          createdate:
                                            firebase.firestore.FieldValue.serverTimestamp(),
                                          createdby:
                                            this.props.currentUser
                                              .currentUserEmail,
                                        };

                                        // console.log(payload);

                                        let collection = "";
                                        if (
                                          document.getElementById("years")
                                            .value == 2
                                        ) {
                                          collection =
                                            "fractionalPropertiesTwoYears";
                                        } else if (
                                          document.getElementById("years")
                                            .value == 4
                                        ) {
                                          collection =
                                            "fractionalPropertiesFourYears";
                                        }

                                        console.log(collection);
                                        var home = this;

                                        console.log(this.state.isEmpty);
                                        console.log(this.state.formData);

                                        if (this.state.isEmpty) {
                                          await firebase
                                            .firestore()
                                            .collection(collection)
                                            .add(payload)
                                            .then((data) => {
                                              console.log(data);
                                              home.setState({
                                                startSavingLoader: false,
                                              });

                                              swal({
                                                title:
                                                  "Fractional Investment Added",
                                                text:
                                                  "You have successfully added a " +
                                                  document.getElementById(
                                                    "years"
                                                  ).value +
                                                  " Years Fractional Investment record for " +
                                                  this.props.propertyName,
                                                icon: "success",
                                                //buttons: false,
                                              })
                                                .then(() => {
                                                  document.location.reload();
                                                })
                                                .catch((error) => {
                                                  console.log(error);
                                                  home.setState({
                                                    startSavingLoader: false,
                                                  });
                                                });

                                              //home.props.history.push("/admin/property");
                                            });
                                        } else {
                                          //update code

                                          if (this.state.formData?.id) {
                                            firebase
                                              .firestore()
                                              .collection(collection)
                                              .doc(this.state.formData?.id)
                                              .update(payload)
                                              .then(function () {
                                                home.setState({
                                                  startSavingLoader: false,
                                                });

                                                swal({
                                                  title:
                                                    "Fractional Investment Updated",
                                                  text:
                                                    "You have successfully updated  " +
                                                    home.props.propertyName +
                                                    " property fractional investment record for " +
                                                    document.getElementById(
                                                      "years"
                                                    ).value +
                                                    " years",
                                                  icon: "success",
                                                  //buttons: false,
                                                }).then(() => {
                                                  document.location.reload();
                                                });
                                              })
                                              .catch((error) => {
                                                console.log(error);
                                                home.setState({
                                                  startSavingLoader: false,
                                                });
                                              });
                                          } else {
                                            swal({
                                              title:
                                                "Application Error Detected",
                                              text: "Property record not properly formed. Kindly contact an admin or refresh your page",
                                              icon: "error",
                                              //buttons: false,
                                            });
                                          }
                                        }
                                      }
                                    }}
                                  >
                                    {this.state.startSavingLoader ? (
                                      // <img src={require("assets/img/loader.gif")} />
                                      <>
                                        Updating...{" "}
                                        <i className="fa fa-spinner fa-spin"></i>
                                      </>
                                    ) : (
                                      "Update Form"
                                    )}
                                  </Button>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </div>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    FI_2Y_Actions: (param) => dispatch(FI_2Y_Actions(param)),
    FI_4Y_Actions: (param) => dispatch(FI_4Y_Actions(param)),
    FI_TR_Actions: (param) => dispatch(FI_TR_Actions(param)),
    // PropertyEdit: (param) => dispatch(PropertyEdit(param)),
    // PropertySingle: (param) => dispatch(PropertySingle(param))
  };
};

const mapStateToProps = (state) => ({
  currentUser: state.authReducer,
  PropertyReducer: state.PropertyReducer,
  FI_Reducer: state.FI_Reducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(FIModal);

import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import CountUp from "react-countup";
import axios from "axios";

// reactstrap components
import {
  Button,
  Card,
  CardImg,
  CardHeader,
  Modal,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  ListGroup,
  ListGroupItem,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledAlert,
} from "reactstrap";

import { TabContent, TabPane } from "reactstrap";

import swal from "sweetalert";
import firebase from "firebase/app";
import { connect } from "react-redux";
import MaterialTable, { MTableToolbar, MTableCell } from "material-table";
import { array } from "js-sha512";
import { dateFormater } from "utils/functions";

import {
  ChatActions,
  ChatArchiveActions,
} from "../../../store/actions/ChatActions";

//import { UserPushActions } from "../../../store/actions/userActions";

import { isEmpty, isArray } from "lodash";

class Chat extends React.Component {
  state = {
    setChatThread: [],
    userName: null,
    userEmail: null,
    adminEmail: "admin@octo5.co",
    setMessages: [],
    setPushMessages: [],

    userName2: null,
    userEmail2: null,
    adminEmail2: "admin@octo5.co",
    setMessages2: [],

    setMessagesLoader: false,
    activeTab: 1,
  };

  constructor(props) {
    super(props);
    //this.activeRoute.bind(this);
  }

  removeDuplicates = (data, key) => {
    return [...new Map(data.map((item) => [key(item), item])).values()];
  };
  componentDidMount(props) {
    //this.props.UserPushActions();
    //this.props.propertyOptionsAction()

    var home = this;
    home.props.ChatActions();

    this.props.ChatArchiveActions();
  }

  componentWillUnmount() {
    // use intervalId from the state to clear the interval
    //clearInterval(this.intervalId);
  }

  formatAMPM = (timeStamp) => {
    if (timeStamp == "now") {
      const fireBaseTime = new Date();

      const mine = fireBaseTime.toLocaleString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        //second: "numeric",
      });

      return mine;
    } else {
      const fireBaseTime = new Date(
        timeStamp.seconds * 1000 + timeStamp.nanoseconds / 1000000
      );

      const mine = fireBaseTime.toLocaleString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        //second: "numeric",
      });

      return mine;
    }
  };

  handleChatDelete = async (email, name) => {
    swal({
      title: "Delete " + name + "'s Chat",
      // html:true,
      text:
        "Are you sure you want to delete " +
        email +
        "'s Chat ? This process can not be reversed",
      icon: "warning",
      buttons: true,
      buttons: {
        cancel: "Cancel",
        catch: {
          text: "Delete",
          //value: "catch",
        },
        //defeat: true,
      },
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        await firebase
          .firestore()
          .collection("chats")
          .where("user.userEmail", "==", email)
          .orderBy("createdAt", "desc")
          .onSnapshot((snapshot) => {
            if (snapshot.empty) {
              return;
            }

            snapshot.forEach(async (doc) => {
              const res = await firebase
                .firestore()
                .collection("chats")
                .doc(doc.id)
                .delete();
            });
          });
      }
    });
  };

  handleChatWith = async (email, name) => {
    this.setState({ userName: name, userEmail: email });
    //alert(email+' - '+name);

    //setPushMessages
    firebase
      .firestore()
      .collection("pushNotifications")
      .where("email", "==", email)
      //.orderBy("createdAt", "desc")
      .onSnapshot((snapshot) => {
        if (snapshot.empty) {
          console.log("ERROR");
          return;
        } else {
          this.setState(
            {
              setPushMessages: snapshot.docs.map((doc) => ({
                email: doc.data().email,
                token: doc.data().token,
                device: doc.data().device,
              })),
            },
            () => {
              //console.log("pushNotifications => " + email);
              //console.log(this.state.setPushMessages);
            }
          );

          //console.log(snapshot.data());
        }
      });

    this.setState({ setMessagesLoader: true, setMessages: [] }, () => {
      firebase
        .firestore()
        .collection("chats")
        .where("user.userEmail", "==", email)
        .orderBy("createdAt", "desc")
        //     // .limit(1)
        //     // .where('user._id', '==', props.userData.email)

        .onSnapshot((snapshot) => {
          this.setState(
            {
              setMessages: snapshot.docs.map((doc) => ({
                _id: doc.data()._id,
                createdAt: doc.data().createdAt,
                text: doc.data().text,
                user: doc.data().user,
              })),
            },
            () => {
              // console.log(this.state.setMessages);
            }
          );
        });

      this.setState({ setMessagesLoader: false });
    });

    //return unsubscribe;
  };

  handleChatWith2 = async (email, name) => {
    this.setState({ userName2: name, userEmail2: email });
    //alert(email+' - '+name);

    this.setState({ setMessagesLoader: true, setMessages2: [] }, () => {
      firebase
        .firestore()
        .collection("chatsArchive")
        .where("user.userEmail", "==", email)
        .orderBy("createdAt", "desc")
        //     // .limit(1)
        //     // .where('user._id', '==', props.userData.email)

        .onSnapshot((snapshot) => {
          this.setState(
            {
              setMessages2: snapshot.docs.map((doc) => ({
                _id: doc.data()._id,
                createdAt: doc.data().createdAt,
                text: doc.data().text,
                user: doc.data().user,
              })),
            },
            () => {
              //alert(this.state.setMessages2);
              // console.log(this.state.setMessages2);
            }
          );
        });

      this.setState({ setMessagesLoader: false });
    });

    //return unsubscribe;
  };

  toggle = (tab) => {
    this.setState({ activeTab: tab });
    //if(activeTab !== tab) setActiveTab(tab);
  };

  render() {
    return (
      <div
        className="container"
        style={{ border: "0px solid red", width: "100%" }}
      >
        <Nav tabs>
          <NavItem>
            <NavLink
              style={{ pointer: "cursor" }}
              className={this.state.activeTab == 1 ? "active" : null}
              onClick={() => {
                this.toggle("1");
              }}
            >
              Active Chats
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={this.state.activeTab == 2 ? "active" : null}
              onClick={() => {
                this.toggle("2");
              }}
            >
              Archive Chats
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane
            tabId="1"
            className={this.state.activeTab == 1 ? "active" : null}
          >
            <Row className="mt-10" style={{ marginTop: 20 }}>
              <Col className="mb-5 mb-xl-0" xl="4">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <div className="col">
                        <h3 className="mb-0">Chat History</h3>
                      </div>
                      <div className="col text-right">
                        <Button
                          color="primary"
                          href="#stow"
                          onClick={(e) => {
                            e.preventDefault();

                            // this.props.history.push("/admin/transaction");
                          }}
                          size="sm"
                        >
                          <i className="ni ni-chat-round" />
                        </Button>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {this.props.ChatReducer.hasOwnProperty(
                      "ChatactionsData"
                    ) ? (
                      parseInt(
                        JSON.stringify(
                          this.props.ChatReducer.ChatactionsData.length
                        )
                      ) <= 0 ? (
                        <span style={{ fontSize: 10 }}>
                          loading chat...{" "}
                          <i className="fa fa-spinner fa-spin"></i>
                        </span>
                      ) : (
                        <ListGroup>
                          {this.props.ChatReducer.ChatactionsData.map(
                            (item, index) => {
                              const { userEmail, name } = item.user;
                              return isEmpty(userEmail) ? null : (
                                <a href="#" className="btn-block ">
                                  <ListGroupItem
                                    onClick={() =>
                                      this.handleChatWith(userEmail, name)
                                    }
                                    key={index}
                                    style={{
                                      borderColor: "#eee",
                                      borderWidth: 1,
                                      borderStyle: "solid",
                                      fontSize: 13,
                                    }}
                                  >
                                    {userEmail}
                                  </ListGroupItem>
                                </a>
                              );
                            }
                          )}
                        </ListGroup>
                      )
                    ) : (
                      <span style={{ fontSize: 10 }}>
                        loading chat...{" "}
                        <i className="fa fa-spinner fa-spin"></i>
                      </span>
                    )}
                  </CardBody>
                </Card>
              </Col>

              <Col xl="8">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <div className="col">
                        <h3 className="mb-0">{this.state.userName}</h3>
                      </div>
                      <div className="col text-right">
                        <Button
                          color="warning"
                          href="#stow"
                          onClick={(e) => {
                            e.preventDefault();
                            //this.props.history.push("/admin/subscribers");
                          }}
                          size="sm"
                        >
                          <i className="ni ni-send" />
                        </Button>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="container_chat">
                      <div className="main-veiw">
                        <div className="chat">
                          {parseInt(
                            JSON.stringify(this.state.setMessages.length)
                          ) <= 0 ? (
                            this.state.userName === null ? null : (
                              <div>
                                <span style={{ fontSize: 10 }}>
                                  loading {this.state.userName} chat...{" "}
                                  <i className="fa fa-spinner fa-spin"></i>
                                </span>
                              </div>
                            )
                          ) : (
                            this.state.setMessages
                              .reverse()
                              .map((item, index) => {
                                return item.user._id == "admin@octo5.co" ? (
                                  item.text == "" ? null : (
                                    <div
                                      className="message-container"
                                      key={index}
                                    >
                                      <div className="comment me">
                                        <div
                                          className="img icon icon-shape text-white rounded-circle shadow"
                                          style={{
                                            backgroundColor: "rgb(218 223 232)",
                                            color: "black",
                                            width: "35px",
                                            height: "35px",
                                          }}
                                        >
                                          <i
                                            className="ni ni-circle-08"
                                            style={{
                                              color: "black",
                                              fontSize: 13,
                                            }}
                                          ></i>
                                        </div>

                                        <div
                                          className="bubble"
                                          style={{ backgroundColor: "#28a745" }}
                                        >
                                          {item.text}
                                          <div
                                            style={{
                                              color: "white",
                                              textAlign: "right",
                                              fontSize: 9,
                                            }}
                                          >
                                            {this.formatAMPM(item.createdAt)}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                ) : item.text == "" ? null : (
                                  <div className="message-container">
                                    <div className="comment other">
                                      <div
                                        className="icon icon-shape text-white rounded-circle shadow"
                                        style={{
                                          backgroundColor: "rgb(218 223 232)",
                                          color: "black",
                                          width: "35px",
                                          height: "35px",
                                        }}
                                      >
                                        <i
                                          className="ni ni-single-02"
                                          style={{
                                            color: "black",
                                            fontSize: 13,
                                          }}
                                        ></i>
                                      </div>
                                      <div
                                        className="bubble"
                                        style={{
                                          backgroundColor: "#f4f5f7",
                                          color: "black",
                                        }}
                                      >
                                        {item.text}
                                        <div
                                          style={{
                                            color: "grey",
                                            textAlign: "right",
                                            fontSize: 9,
                                          }}
                                        >
                                          {this.formatAMPM(item.createdAt)}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                          )}

                          {JSON.stringify(this.state.setMessages.length) <=
                          0 ? null : (
                            <div className="input-container">
                              <button
                                className="attach"
                                onClick={() => {
                                  this.handleChatDelete(
                                    this.state.userEmail,
                                    this.state.userName
                                  );
                                }}
                              >
                                <a href="#">
                                  <i
                                    className="fa fa-trash"
                                    style={{ color: "white" }}
                                  />
                                </a>{" "}
                              </button>
                              <div className="textarea-container">
                                <textarea
                                  type="text"
                                  id="chatMessage"
                                  placeholder="start typing ..."
                                ></textarea>
                              </div>
                              <button
                                className="send"
                                onClick={async () => {
                                  if (
                                    !isEmpty(
                                      document.getElementById("chatMessage")
                                        .value
                                    )
                                  ) {
                                    let home = this;

                                    await firebase
                                      .firestore()
                                      .collection("chats")

                                      .add({
                                        _id: this.state.userEmail,
                                        text: document.getElementById(
                                          "chatMessage"
                                        ).value,
                                        createdAt: new Date(),
                                        user: {
                                          _id: this.state.userEmail,
                                          name: this.state.userName,
                                          userEmail: this.state.userEmail,
                                          sent: true,
                                        },
                                      })
                                      .then(async () => {
                                        this.state.setPushMessages.forEach(
                                          async (doc) => {
                                            const message = {
                                              token: doc.token,

                                              title: "New message from STOW support",
                                              text: document.getElementById(
                                                "chatMessage"
                                              ).value,
                                            };

                                            await axios({
                                              method: "post",
                                              headers: {
                                                Accept: "application/json",
                                                "Content-Type":
                                                  "application/json",
                                              },
                                              url: "https://us-central1-stow-62251.cloudfunctions.net/apiSendToDevice",
                                              
                                              data: JSON.stringify(message),
                                            }).then(async (response) => {

                                              console.log(response.data);

                                            });

                                            //console.log(message);
                                          }
                                        );

                                        await firebase
                                          .firestore()
                                          .collection("chatsArchive")
                                          .add({
                                            _id: this.state.userEmail,
                                            text: document.getElementById(
                                              "chatMessage"
                                            ).value,
                                            createdAt: new Date(),
                                            user: {
                                              _id: this.state.userEmail,
                                              name: this.state.userName,
                                              userEmail: this.state.userEmail,
                                              sent: true,
                                            },
                                          });

                                        document.getElementById(
                                          "chatMessage"
                                        ).value = "";
                                      });

                                    //document.getElementById("chatMessage").value ="";
                                  }

                                  /*
                                   */
                                }}
                              >
                                <a href="#">
                                  <i
                                    className="ni ni-send"
                                    style={{ color: "white" }}
                                  />
                                </a>
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </TabPane>

          <TabPane
            tabId="2"
            className={this.state.activeTab == 2 ? "active" : null}
          >
            <Row className="mt-10" style={{ marginTop: 20 }}>
              <Col className="mb-5 mb-xl-0" xl="4">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <div className="col">
                        <h3 className="mb-0">Archive Chat History</h3>
                      </div>
                      <div className="col text-right">
                        <Button
                          color="primary"
                          href="#stow"
                          onClick={(e) => {
                            e.preventDefault();

                            // this.props.history.push("/admin/transaction");
                          }}
                          size="sm"
                        >
                          <i className="ni ni-chat-round" />
                        </Button>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {this.props.ChatReducer.hasOwnProperty(
                      "ChatArchiveactionsData"
                    ) ? (
                      parseInt(
                        JSON.stringify(
                          this.props.ChatReducer.ChatArchiveactionsData.length
                        )
                      ) <= 0 ? (
                        <span style={{ fontSize: 10 }}>
                          loading chat...{" "}
                          <i className="fa fa-spinner fa-spin"></i>
                        </span>
                      ) : (
                        <ListGroup>
                          {isArray(
                            this.props.ChatReducer.ChatArchiveactionsData
                          ) &&
                            this.props.ChatReducer.ChatArchiveactionsData.map(
                              (item, index) => {
                                const { userEmail, name } = item.user;
                                return isEmpty(userEmail) ? null : (
                                  <a href="#" className="btn-block ">
                                    <ListGroupItem
                                      onClick={() =>
                                        this.handleChatWith2(userEmail, name)
                                      }
                                      key={index}
                                      style={{
                                        borderColor: "#eee",
                                        borderWidth: 1,
                                        borderStyle: "solid",
                                        fontSize: 13,
                                      }}
                                    >
                                      {userEmail}
                                    </ListGroupItem>
                                  </a>
                                );
                              }
                            )}
                        </ListGroup>
                      )
                    ) : (
                      <span style={{ fontSize: 10 }}>
                        loading chat...{" "}
                        <i className="fa fa-spinner fa-spin"></i>
                      </span>
                    )}
                  </CardBody>
                </Card>
              </Col>

              <Col xl="8">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <div className="col">
                        <h3 className="mb-0">{this.state.userName2}</h3>
                      </div>
                      <div className="col text-right">
                        <Button
                          color="warning"
                          href="#stow"
                          onClick={(e) => {
                            e.preventDefault();
                            //this.props.history.push("/admin/subscribers");
                          }}
                          size="sm"
                        >
                          <i className="ni ni-send" />
                        </Button>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="container_chat">
                      <div className="main-veiw">
                        <div className="chat">
                          {parseInt(
                            JSON.stringify(this.state.setMessages2.length)
                          ) <= 0 ? (
                            this.state.userName2 === null ? null : (
                              <div>
                                <span style={{ fontSize: 10 }}>
                                  loading {this.state.userName2} chat...{" "}
                                  <i className="fa fa-spinner fa-spin"></i>
                                </span>
                              </div>
                            )
                          ) : (
                            this.state.setMessages2
                              .reverse()
                              .map((item, index) => {
                                return item.user._id == "admin@octo5.co" ? (
                                  item.text == "" ? null : (
                                    <div
                                      className="message-container"
                                      key={index}
                                    >
                                      <div className="comment me">
                                        <div
                                          className="img icon icon-shape text-white rounded-circle shadow"
                                          style={{
                                            backgroundColor: "rgb(218 223 232)",
                                            color: "black",
                                            width: "35px",
                                            height: "35px",
                                          }}
                                        >
                                          <i
                                            className="ni ni-circle-08"
                                            style={{
                                              color: "black",
                                              fontSize: 13,
                                            }}
                                          ></i>
                                        </div>

                                        <div
                                          className="bubble"
                                          style={{ backgroundColor: "#28a745" }}
                                        >
                                          {item.text}
                                          <div
                                            style={{
                                              color: "white",
                                              textAlign: "right",
                                              fontSize: 9,
                                            }}
                                          >
                                            {this.formatAMPM(item.createdAt)}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                ) : item.text == "" ? null : (
                                  <div className="message-container">
                                    <div className="comment other">
                                      <div
                                        className="icon icon-shape text-white rounded-circle shadow"
                                        style={{
                                          backgroundColor: "rgb(218 223 232)",
                                          color: "black",
                                          width: "35px",
                                          height: "35px",
                                        }}
                                      >
                                        <i
                                          className="ni ni-single-02"
                                          style={{
                                            color: "black",
                                            fontSize: 13,
                                          }}
                                        ></i>
                                      </div>
                                      <div
                                        className="bubble"
                                        style={{
                                          backgroundColor: "#f4f5f7",
                                          color: "black",
                                        }}
                                      >
                                        {item.text}
                                        <div
                                          style={{
                                            color: "grey",
                                            textAlign: "right",
                                            fontSize: 9,
                                          }}
                                        >
                                          {this.formatAMPM(item.createdAt)}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                          )}

                          {JSON.stringify(this.state.setMessages2.length) <=
                          0 ? null : (
                            <div
                              className="input-container"
                              style={{ display: "none" }}
                            >
                              <button
                                className="attach"
                                onClick={() => {
                                  this.handleChatDelete(
                                    this.state.userEmail2,
                                    this.state.userName2
                                  );
                                }}
                              >
                                <a href="#">
                                  <i
                                    className="fa fa-trash"
                                    style={{ color: "white" }}
                                  />
                                </a>{" "}
                              </button>
                              <div className="textarea-container">
                                <textarea
                                  type="text"
                                  id="chatMessage"
                                  placeholder="start typing ..."
                                ></textarea>
                              </div>
                              <button className="send">
                                <a href="#">
                                  <i
                                    className="ni ni-send"
                                    style={{ color: "white" }}
                                  />
                                </a>
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ChatActions: (param) => dispatch(ChatActions(param)),
    ChatArchiveActions: (param) => dispatch(ChatArchiveActions(param)),
    //UserPushActions: (param) => dispatch(UserPushActions(param)),
  };
};

const mapStateToProps = (state) => ({
  ChatReducer: state.ChatReducer,
  //UserReducer: state.UserReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(Chat);

import React from "react";
// reactstrap components

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  Modal,
  Form,
  FormGroup,
  Input,
  Badge,
} from "reactstrap";

// core components
import Header from "components/Headers/Header";
import moment from "moment";
import SelectSearch from "react-select-search";

import ReactDOM from "react-dom";
import MaterialTable, { MTableToolbar, MTableCell } from "material-table";

import firebase from "firebase/app";

import { connect } from "react-redux";
import CountUp from "react-countup";
import { isEmpty } from "lodash";
import swal from "sweetalert";
import { createThis } from "typescript";
import { DialogContent } from "@material-ui/core";

import { isObject } from "lodash";
import { dateFormater } from "utils/functions";

import Styles from "./FormData.module.css";

class FormData extends React.Component {
  state = {
    showRegisterModal: false,
    coopRegData1: this.props.coopRegData1,
    coopRegData2: this.props.coopRegData2,
    coopRegData3: this.props.coopRegData3,
  };
  constructor(props) {
    super(props);
  }

  printdiv(printpage) {
    var headstr =
      "<html><head><link rel='styleshee' type='text/css' media='print' href='/src/views/coop/registration/FormData.module.css' /><title>STOW COOP REGISTRATION FORM</title></head><body>";
    var footstr = "</body>";
    var newstr = document.all.item(printpage).innerHTML;
    var oldstr = document.body.innerHTML;
    document.body.innerHTML = headstr + newstr + footstr;
    window.focus();
    window.print();
    document.body.innerHTML = oldstr;
    window.location.reload();
    return false;
  }

  render() {
    return (
      <>
        <div id="modal_body_print">
          <div style={{ right: 37, position: "absolute", top: 3 }}>
            <img
              src={require("assets/img/brand/image1.png")}
              style={{ width: 97, height: 77 }}
              alt="Logo"
            />
          </div>
          <div className={Styles.form_header_main}>PERSONAL INFORMATION</div>
          <br /> <br />
          <div className={Styles.form_container}>
            <Row style={{ marginBottom: "28px" }}>
              <div className="col-4">
                <div className={Styles.form_label}>First Name</div>
                <div
                  className={Styles.form_controller}
                  style={{
                    fontSize: 14,
                    background: "#f2f3f3",
                    borderRadius: 7,
                    padding: 10,
                    fontWeight: 600,
                  }}
                >
                  {this.state.coopRegData1.firstName}
                </div>
              </div>

              <div className="col-4">
                <div className={Styles.form_label}>Last Name</div>
                <div className={Styles.form_controller}>
                  {this.state.coopRegData1.lastName}
                </div>
              </div>

              <div className="col-4">
                <div className={Styles.form_label}>Email</div>
                <div className={Styles.form_controller}>
                  {this.state.coopRegData1.email}
                </div>
              </div>
            </Row>

            <Row style={{ marginBottom: "28px" }}>
              <div className="col-4">
                <div className={Styles.form_label}>PHONE NUMBER</div>
                <div className={Styles.form_controller}>
                  {this.state.coopRegData1.div + this.state.coopRegData1.phone}
                </div>
              </div>

              <div className="col-4">
                <div className={Styles.form_label}>Date of Birth</div>
                <div className={Styles.form_controller}>
                  {dateFormater(this.state.coopRegData1.dateOfBirth)}
                </div>
              </div>

              <div className="col-4">
                <div className={Styles.form_label}>Nationality</div>
                <div className={Styles.form_controller}>
                  {this.state.coopRegData1.country}
                </div>
              </div>
            </Row>

            <Row style={{ marginBottom: "28px" }}>
              <div className="col-8">
                <div className={Styles.form_label}>Contact Address</div>
                <div className={Styles.form_controller}>
                  {this.state.coopRegData1.contactAddress}
                </div>
              </div>

              <div className="col-4">
                <div className={Styles.form_label}>State of Origin</div>
                <div className={Styles.form_controller}>
                  {this.state.coopRegData1.state}
                </div>
              </div>
            </Row>

            <div className={Styles.form_header_inner}>
              MEAN OF IDENTIFICATION
            </div>
            <div className={Styles.form_header_label}>
              Acceptable means of identification is any of the following:
              International passport, National ID Card, Permanent Voters Card or
              Driver’s License
            </div>

            <Row style={{ marginBottom: "38px", marginTop: 30 }}>
              <div className="col-4">
                <div className={Styles.form_label}>Type of ID Card</div>
                <div className={Styles.form_controller}>
                  {this.state.coopRegData1.idCard}
                </div>
              </div>

              <div className="col-4">
                <div className={Styles.form_label}>Card Number</div>
                <div className={Styles.form_controller}>
                  {this.state.coopRegData1.cardNumber}
                </div>
              </div>

              <div className="col-4">
                <div className={Styles.form_label}>Exp. Date</div>
                <div className={Styles.form_controller}>
                  {dateFormater(this.state.coopRegData1.expDate)}
                </div>
              </div>
            </Row>

            <div className={Styles.form_hr_line} />
          </div>
          <br />
          <div className={Styles.form_header_main}>
            particulars of next of kin
          </div>
          <br />
          <div className={Styles.form_container}>
            <Row style={{ marginBottom: "28px" }}>
              <div className="col-3">
                <div className={Styles.form_label}>First Name</div>
                <div className={Styles.form_controller}>
                  {this.state.coopRegData2.firstName}
                </div>
              </div>

              <div className="col-3">
                <div className={Styles.form_label}>Last Name</div>
                <div className={Styles.form_controller}>
                  {this.state.coopRegData2.lastName}
                </div>
              </div>

              <div className="col-3">
                <div className={Styles.form_label}>EMAIL ADDRESS</div>
                <div className={Styles.form_controller}>
                  {this.state.coopRegData2.email}
                </div>
              </div>

              <div className="col-3">
                <div className={Styles.form_label}>PHONE NUMBER</div>
                <div className={Styles.form_controller}>
                  {(this.state.coopRegData2.div === undefined
                    ? ""
                    : this.state.coopRegData2.div) +
                    this.state.coopRegData2.phone}
                </div>
              </div>
            </Row>

            <Row style={{ marginBottom: "28px" }}>
              <div className="col-3">
                <div className={Styles.form_label}>
                  Relationship of Next of Kin
                </div>
                <div className={Styles.form_controller}>
                  {this.state.coopRegData2.relationshipNextOfKin}
                </div>
              </div>

              <div className="col-3">
                <div className={Styles.form_label}>Date of Birth</div>
                <div className={Styles.form_controller}>
                  {dateFormater(this.state.coopRegData2.dateOfBirth)}
                </div>
              </div>

              <div className="col-6">
                <div className={Styles.form_label}>Contact Address</div>
                <div className={Styles.form_controller}>
                  {this.state.coopRegData2.contactAddress}
                </div>
              </div>
            </Row>

            <div className={Styles.form_hr_line} />
          </div>
          <br />
          <div className={Styles.form_header_main}>
            DETAILS ON CURRENT WORK PLACE
          </div>
          <br />
          <div className={Styles.form_container}>
            <Row style={{ marginBottom: "28px" }}>
              <div className="col-4">
                <div className={Styles.form_label}>
                  Employer/Business Details
                </div>
                <div className={Styles.form_controller}>
                  {this.state.coopRegData2.businessName}
                </div>
              </div>

              <div className="col-4">
                <div className={Styles.form_label}>Position/Designation</div>
                <div className={Styles.form_controller}>
                  {this.state.coopRegData2.positionDesignation}
                </div>
              </div>

              <div className="col-4">
                <div className={Styles.form_label}>Department</div>
                <div className={Styles.form_controller}>
                  {this.state.coopRegData2.department}
                </div>
              </div>
            </Row>

            <Row style={{ marginBottom: "28px" }}>
              <div className="col-4">
                <div className={Styles.form_label}>Bank Name</div>
                <div className={Styles.form_controller}>
                  {this.state.coopRegData2.bankName}
                </div>
              </div>

              <div className="col-4">
                <div className={Styles.form_label}>Account Number</div>
                <div className={Styles.form_controller}>
                  {this.state.coopRegData2.accountNo}
                </div>
              </div>

              <div className="col-4">
                <div className={Styles.form_label}>
                  Tax Identification Number
                </div>
                <div className={Styles.form_controller}>
                  {this.state.coopRegData2.tin}
                </div>
              </div>
            </Row>

            <div className={Styles.form_hr_line} />
          </div>
          <br />
          <div className={Styles.form_header_main}>COOPERATIVE DETAILS</div>
          <br />
          <div className={Styles.form_container}>
            <Row style={{ marginBottom: "28px" }}>
              <div className="col-4">
                <div className={Styles.form_label}>Selected Cooperative</div>
                <div className={Styles.form_controller}>
                  {this.state.coopRegData3.selectCoop}
                </div>
              </div>

              <div className="col-4">
                <div className={Styles.form_label}>Scheme</div>
                <div className={Styles.form_controller}>
                  {this.state.coopRegData3.scheme}
                </div>
              </div>

              <div className="col-4"></div>
            </Row>

            <Row style={{ marginBottom: "28px" }}>
              <div className="col-4">
                <div className={Styles.form_label}>Period of Investment</div>
                <div className={Styles.form_controller}>
                  {this.state.coopRegData3.periodOfPayment}
                </div>
              </div>

              <div className="col-4">
                <div className={Styles.form_label}>Mode of Payment</div>
                <div className={Styles.form_controller}>
                  {this.state.coopRegData3.modeOfPayment}
                </div>
              </div>

              <div className="col-2">
                <div className={Styles.form_label_yes_no}>
                  Do you have an existing Loan?
                </div>
                <div style={{ padding: 10, paddingLeft: 0 }}>
                  <div style={{ display: "flex" }}>
                    <div
                      className={
                        this.state.coopRegData3.question === "Yes"
                          ? Styles.form_radio_active
                          : Styles.form_radio_default
                      }
                    ></div>
                    <div className={Styles.form_radio_label}>{"Yes"}</div>
                  </div>

                  <div style={{ display: "flex", marginTop: 10 }}>
                    <div
                      className={
                        this.state.coopRegData3.question === "No"
                          ? Styles.form_radio_active
                          : Styles.form_radio_default
                      }
                    ></div>
                    <div className={Styles.form_radio_label}>{"No"}</div>
                  </div>
                </div>
              </div>

              <div className="col-2">
                <div className={Styles.form_label}>If yes give details</div>
                <div className={Styles.form_controller}>
                  {this.state.coopRegData3.giveDetailsYes}
                </div>
              </div>
            </Row>

            <div className={Styles.form_header_inner}>BENEFICIARY</div>
            <div className={Styles.form_header_label}>
              Named below shall be my nominee/nominees under the terms of
              Bye-laws of the above mentioned society, namely the person/persons
              to whom, in the event of my death, all sums due to my by the
              Society shall be transferred
            </div>

            <Row style={{ marginBottom: "28px", marginTop: "28px" }}>
              <div className="col-4">
                <div className={Styles.form_label}>Beneficiary name</div>
                <div className={Styles.form_controller}>
                  {this.state.coopRegData3.insertNominee}
                </div>
              </div>

              <div className="col-4">
                <div className={Styles.form_label}>Position/Designation</div>
                <div className={Styles.form_controller}>
                  {this.state.coopRegData3.positionDesignation}
                </div>
              </div>
              <div className="col-4"></div>
            </Row>

            <Row style={{ marginBottom: "28px" }}>
              <div className="col-4">
                <div className={Styles.form_label}>Relationship</div>
                <div className={Styles.form_controller}>
                  {this.state.coopRegData3.relationshipToDeclarer}
                </div>
              </div>

              <div className="col-4">
                <div className={Styles.form_label}>Distribution Ratio</div>
                <div className={Styles.form_controller}>
                  {this.state.coopRegData3.ratioOfDistribution}
                </div>
              </div>
              <div className="col-4"></div>
            </Row>

            <div className={Styles.form_hr_line} />
          </div>
        </div>

        <Row style={{ marginBottom: "0px" }}>
          <div className="col-6"></div>

          <div className="col-6">
            <Button
              className="float-right"
              style={{
                marginRight: 15,
              }}
              //disabled={this.state.startSendingMessageLoader}
              color="warning"
              href="#stow"
              type="button"
              onClick={() => {
                this.printdiv("modal_body_print");
              }}
            >
              Print Form
            </Button>
          </div>
        </Row>
      </>
    );
  }
}

export default connect(null, null)(FormData);

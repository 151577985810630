import { isObject } from "lodash";
import isEmpty from "lodash/isEmpty";

const iniState = {
  propLoading: true,
  propError: false,
  propErrorMsg: "",
  propFI_2Y: [],
  propFI_4Y: [],
  propFI_TR: [],
};

const FractionalnvestmentReducer = (state = iniState, action) => {
  switch (action.type) {
    case "FI_TR_LOADING":
      return {
        ...state,
        propLoading: true,
        propError: false,
        propErrorMsg: "",
      };

    case "FI_2Y_LOADING":
      return {
        ...state,
        propLoading: true,
        propError: false,
        propErrorMsg: "",
      };

    case "FI_4Y_LOADING":
      return {
        ...state,
        propLoading: true,
        propError: false,
        propErrorMsg: "",
      };

    case "FI_4Y_SUCCESS":
      return {
        ...state,
        propFI_4Y: action.payload,
        propError: false,
        propLoading: false,
        propErrorMsg: "",
      };

    case "FI_TR_SUCCESS":
      return {
        ...state,
        propFI_TR: action.payload,
        propError: false,
        propLoading: false,
        propErrorMsg: "",
      };

    case "FI_2Y_SUCCESS":
      return {
        ...state,
        propFI_2Y: action.payload,
        propError: false,
        propLoading: false,
        propErrorMsg: "",
      };
    case "FT_2Y_ERROR":
      return {
        ...state,
        propLoading: false,
        propError: true,
        propErrorMsg: action.payload,
      };

    case "FT_4Y_ERROR":
      return {
        ...state,
        propLoading: false,
        propError: true,
        propErrorMsg: action.payload,
      };

    case "FI_TR_ERROR":
      return {
        ...state,
        propLoading: false,
        propError: true,
        propErrorMsg: action.payload,
      };

    default:
      return state;
  }
};

export default FractionalnvestmentReducer;



const iniState = {
  //propertysData: {},
  groupDepLoading: true,
  groupDepError: true,
  groupDepErrorMsg: "",
  groupDepLoadingCompleted: false,
  groupDepCount: 0,
};

const GroupDepositReducer = (state = iniState, action) => {
  switch (action.type) {
    case "GROUP_DEP_LOADING":
      return {
        ...state,
        groupDepLoading: action.propLoading,
        groupDepError: false,
        groupDepLoadingCompleted: false,
        //propErrorMsg:''
      };

    case "GROUP_DEP_SUCCESS":
      return {
        ...state,
        groupDepData: action.payload,
        groupDepError: false,
        groupDepLoadingCompleted: true,
        groupDepLoading: false,
      };

    case "GROUP_DEP_ERROR":
      return {
        ...state,
        groupDepError: true,
        groupDepLoadingCompleted: false,
        groupDepErrorMsg: action.payload,
        groupDepLoaded: false,
      };

    default:
      return state;
  }
};

export default GroupDepositReducer;


import React from "react";
// reactstrap components

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  Modal,
  Form,
  FormGroup,
  Input,
  Badge 
} from "reactstrap";

// core components
import Header from "components/Headers/Header.js";
import moment from 'moment';
import SelectSearch from 'react-select-search';


import ReactDOM from 'react-dom'
import MaterialTable,  {MTableToolbar,MTableCell} from 'material-table'
import { currencyFormat, dateFormater,UserRolePermission} from '../../utils/functions';


import   firebase from "firebase/app";

//TransUserPayment
import TransUserPayment from './functions/TransUserPayment'
import TransPaymentsMini from './functions/TransPaymentsMini'

import { PaymentActions, PaymentCount } from "./../../store/actions/paymentActions";
//
//import { CopyToClipboard } from "react-copy-to-clipboard";
import { connect } from "react-redux";
import CountUp from 'react-countup';
import { isEmpty } from "lodash";
import swal from "sweetalert";
import { createThis } from "typescript";


class Payments extends React.Component {

 

  constructor(props)
  {
     super(props);
     this.getSelectedTrans = this.getSelectedTrans.bind(this)

      this.state ={
    copiedText:null,
    showPaymentPage:false,
    showPaymentEditPage:false,
    transactionRecord:[],
    TransID:null,
    lastPaid:0,
    Updatemsg:'Saving...',
    customerName:null,
    customerUid:null,

    customerPhone:null,
    customerEmail:null,
    dataLoading:true,
    startPaymentLoader:false,
    startUpdatePaymentLoader:false,
    ProductId:null,


    edit_customer_name:null,
    edit_ref_id:null,
    edit_trans_id:null,
    edit_customer_name:null,
    edit_customer_phone:null,
    edit_status:null,

  }
  }

  componentDidMount()
  {

     this.props.PaymentActions()
   
    //alert(Object.keys(this.props.transactionReducer.transactionsData).length)

     if(Object.keys(this.props.transactionReducer.transactionsData).length >0)
      {

        var transactionRecord =[]
        this.props.transactionReducer.transactionsData.forEach( doc => {
          transactionRecord.push({
            /* id:doc.id,
            unm:doc.unm,
            uid:doc.uid,
            paid:doc.paid,
            //uid:(doc.hasOwnProperty('uid')?<TransUser uid={doc.uid}/>:null),
            pnm:doc.pnm,
            pending:doc.pending,
            price:doc.price,
            plan: (doc.hasOwnProperty("pplan")?(doc.pplan.hasOwnProperty("title")?doc.pplan.title:null):null),
            */ 
           price:  (doc.hasOwnProperty('price')?doc.price:'₦ 0.00'),value: doc.id,photo :(doc.hasOwnProperty('property')?((doc.property.hasOwnProperty('logo')?doc.property.logo.src:'https://www.flaticon.com/svg/static/icons/svg/2611/2611678.svg')):'https://www.flaticon.com/svg/static/icons/svg/2611/2611678.svg'), name:doc.id, pnm:(doc.hasOwnProperty('pnm')?doc.pnm:'')
          })


        })
        


         this.setState({transactionRecord:transactionRecord})


  }

}

 renderFriend(props, option, snapshot, className) {
    const imgStyle = {
        borderRadius: '50%',
        border: '1px solid #8e8585',
        verticalAlign: 'middle',
        marginRight: 10,
    };

    return (
        <button {...props} className={className} type="button">
           
            
            <Row>
              <Col className="col-2"><img alt="" style={imgStyle} width="37" height="37" src={option.photo} /></Col>
              <Col className="col-10" style={{fontSize:11}} >
              <span style={{fontWeight:'bolder',color:'#ccc'}}>{option.pnm} - {option.price}</span>
              <div>{option.name}</div>
              </Col>
            </Row>
            
            
        </button>
    );
}



    formatJSON(data){
        var result = []
        if(!Array.isArray(data)){//if is not an array
            result.push(data) // push it as the first item of an array
        }else{
            result=data
        }

        return result
    }

    getSelectedTrans(data)
    {
       
       
      

      if(isEmpty(data))
      {
        return null
      }

      this.setState({TransID:null,dataLoading:true},()=>{
       // alert('loading')

        // console.log('LOADING')

         if(this.state.dataLoading)
          {
            this.setState({customerName:'loading...'});
            
            
          }

       });

       

      this.setState({TransID:data})
       var home=this

      try 
                {

                          firebase
                        .firestore()
                        .collection("transactions")
                        //.where('id','==',transactionID)
                         .doc(data)
                        .onSnapshot( snapshot => {

                           var transData = [];
                           var uid =null;

                           if (snapshot.empty) {
                              this.setState({dataLoading:false})

                              //home.setState({dataLoading:false})
                              this.setState({customerName:'NONE'});
                                return;
                            }
                           
                            
                               
                            transData.push(snapshot.data());
                              

                           if(transData[0])
                            {

                              if(transData[0].hasOwnProperty('paid'))
                              {

                                this.setState({lastPaid:transData[0].paid});

                              }

                              if(transData[0].hasOwnProperty('phone'))
                              {

                                this.setState({  customerPhone:transData[0].phone});

                              }


                              if(transData[0].hasOwnProperty('email'))
                              {

                                this.setState({customerEmail:transData[0].email});

                              }

 


                              if(transData[0].hasOwnProperty('pid'))
                              {

                                this.setState({ProductId:transData[0].pid});

                              }

                              if(transData[0].hasOwnProperty('unm'))
                              {

                                this.setState({customerName:transData[0].unm});
                                this.setState({customerUid:transData[0].uid});
                                //home.setState({customerName:PropertyUser[0].lastName +' '+PropertyUser[0].firstName }) 
                                //alert(transData[0].paid)

                              }
                              else{

                                home.setState({customerName:'NONE'});

                              }

                              home.setState({dataLoading:false})

                              
                              

                            
                            
                            }
                            else{

                               home.setState({dataLoading:false})
                              home.setState({customerName:'NONE'});


                            }






                           


                                             
                      })


                }
                catch(error)
                {
                      this.setState({dataLoading:false})

                }
                

     
  
                if(!(this.state.dataLoading))
                {
                 
                   

                }
                else{

                  this.setState({customerName:'loading...'})

                }

    }

  render() {


    const PaymentReducer = this.props.PaymentReducer

    if(this.props.PaymentReducer.PaymentactionCount <=0)
    {

          if(PaymentReducer.hasOwnProperty('PaymentactionsData'))
            {
              //this.setState({paymentRecord:items});
              this.props.PaymentCount(Object.keys(PaymentReducer.PaymentactionsData).length);
              //alert(Object.keys(UserReducer.UseractionsData).length);
            }  

    }
  
    return (
      <>
        <Header />
        {/* Page content */}

        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow border-0">
                <div style={{ maxWidth: "100%", borderRadius: 20 }}>
                  <MaterialTable
                    columns={[
                      {
                        title: "Tran-ID",
                        field: "tid",
                        /* defaultGroupOrder: 0 , */ render: (rowData) => {
                          return rowData.tid;
                        },
                      },
                      {
                        title: "Amount",
                        field: "c_amt",
                        render: (rowData) => {
                          if (rowData.c_amt) {
                            return (
                              <div>
                                {currencyFormat(parseInt(rowData.c_amt))}
                              </div>
                            );
                          } else {
                            return "₦ 0.00";
                          }
                        },
                      },
                      {
                        title: "Date",
                        field: "c_date",
                        render: (rowData) => {
                          if (rowData.c_date) {
                            //return moment(rowData.c_date,"Do MMM YYY, LL")
                            return dateFormater(rowData.c_date);
                          } else {
                            return null;
                          }
                        },
                      },
                      //{ title: 'Product', field: 'productid',  },
                      { title: "Ref-ID", field: "r_txnref" },
                      {
                        title: "Status",
                        field: "paymentValid",
                        render: (rowData) => {
                          return rowData.paymentValid ? (
                            <Badge
                              color=""
                              style={{ fontSize: 10, color: "#000" }}
                              className="badge-dot"
                            >
                              <i className="bg-success" />
                              Completed
                            </Badge>
                          ) : (
                            <Badge
                              color=""
                              style={{ fontSize: 10, color: "#ccc" }}
                              className="badge-dot"
                            >
                              <i className="bg-danger" />
                              Pending
                            </Badge>
                          );
                        },
                      },

                      {
                        title: "Customer",
                        field: "name",
                        render: (rowData) => {
                          return rowData.name ? (
                            rowData.name
                          ) : (
                            <TransUserPayment transactionID={rowData.tid} />
                          );
                        },
                      },
                      //
                    ]}
                    actions={[
                      /*  {
                        icon: ()=>
                        <Button color="primary"
                        href="#"
                        style={{fontSize:20,paddingBottom:0}}
                        size="sm">
                            <i className='ni ni-fat-add'></i>
                        </Button>,
                        tooltip: 'Add Payment',
                        isFreeAction: true,
                        onClick: (event,rowData) => {

                         // alert(JSON.stringify(rows))
                          this.setState({showPaymentPage:true,customerName:null,TransID:null,dataLoading:true})


                        }
                      }, */
                      {
                        icon: (row) => (
                          <Button
                            color="primary"
                            href="#"
                            disabled={UserRolePermission(
                              this.props.currentUser.UserData.role,
                              "edit_pay"
                            )}
                            style={{
                              fontSize: 9,
                              display: UserRolePermission(
                                this.props.currentUser.UserData.role,
                                "edit_pay"
                              )
                                ? "none"
                                : "block",
                            }}
                            size="sm"
                          >
                            Edit
                          </Button>
                        ),

                        tooltip: "Edit Payment",

                        onClick: async (event, row) => {
                          // if(!(UserRolePermission(this.props.currentUser.UserData.role,'edit_pay')))
                          //{

                          this.setState({
                            edit_customer_name: row.name,
                            edit_trans_id: row.tid,
                            edit_customer_phone: row.phone,
                            edit_ref_id: row.r_txnref,
                            edit_status: row.paymentValid,
                           // edit_branchPaid: row.branchPaid,
                          });


                          

                          let home = this;

                          await firebase
                            .firestore()
                            .collection("transactions")
                            //.where('id','==',transactionID)
                            .doc(row.tid)
                            .onSnapshot((snapshot) => {
                              var transData = [];
                              var uid = null;

                              if (snapshot.empty) {
                                return;
                              }

                              transData = snapshot.data();

                              transData = snapshot.data();
                              console.log(transData);
                              console.log(row.tid);

                              if (transData) {
                                if (transData.hasOwnProperty("paid")) {
                                  home.setState({ lastPaid: transData.paid });

                                  //alert(row.tid+' = '+transData.paid);

                                  home.setState(
                                    { showPaymentEditPage: true },
                                    () => {
                                      var tt = setTimeout(function () {
                                        document.getElementById(
                                          "edit_amount"
                                        ).value = row.amount;

                                        document.getElementById(
                                          "edit_amount_old"
                                        ).value = row.amount;

                                        /*  alert(row.branchPaid);

                                     document
                                        .getElementById("branchPaid")
                                        .html(row.branchPaid);  */

                                        //edit_amount_old

                                        clearTimeout(tt);
                                      }, 800);
                                    }
                                  );
                                }

                              }
                              else {
                                alert('Kindly confirm your internet connections')
                              }


                            });

                          //}
                        },
                      },
                    ]}
                    data={this.props.PaymentReducer.PaymentactionsData}
                    options={{
                      //grouping: true,
                      exportButton: true,
                      exportFileName: "Payment_Data_" + new Date(),
                      pageSize: 10,
                      pageSizeOptions: [
                        5,
                        10,
                        20,
                        30,
                        50,
                        75,
                        100,
                        200,
                        500,
                        1000,
                        2000,
                        3000,
                        5000,
                        8000,
                        10000,
                      ],
                      toolbar: true,
                      // paging: true,

                      rowStyle: {
                        paddingLeft: "1.5rem",
                        paddingRight: "1.5rem",
                        verticalAlign: "middle",

                        fontSize: "0.8125rem",
                        whiteSpace: "nowrap",
                        padding: "1rem",
                        borderTop: "1px solid #e9ecef",
                        fontFamily: "Open Sans, sans-serif",
                      },

                      headerStyle: {
                        paddingTop: "0.75rem",
                        paddingBottom: "0.75rem",
                        fontSize: "0.65rem",
                        textTransform: "uppercase",
                        letterPpacing: "1px",
                        borderBottom: "1px solid #e9ecef",
                        fontWeight: "bolder",
                        paddingLeft: "1.5rem",
                        paddingRight: "1.5rem",
                        backgroundColor: "#f6f9fc",
                        color: "#8898aa",
                        verticalAlign: "middle",
                        borderColor: "#e9ecef",
                        fontFamily: "Open Sans, sans-serif",
                      },
                    }}
                    title={" "}
                    components={{
                      Toolbar: (props) => (
                        <p className="h3" style={{ padding: 10 }}>
                          <MTableToolbar {...props} />
                          <div style={{ marginTop: -40 }}>
                            {"Total"}{" "}
                            <CountUp
                              end={
                                Object.keys(
                                  this.props.PaymentReducer.PaymentactionsData
                                ).length
                              }
                            />
                          </div>
                        </p>
                      ),

                      Cell: (props) => {
                        if (props.columnDef.field == "tid") {
                          //return <MTableCell {...props} id={'cell_'+(props.rowData.hasOwnProperty('r_txnref')?props.rowData.r_txnref:props.rowData.tid)} />
                          return <MTableCell {...props} />;
                        } else {
                          return <MTableCell {...props} />;
                        }
                      },
                    }}
                  />
                </div>
              </Card>
            </div>
          </Row>
        </Container>

        <Modal
          id="model_payment_edit"
          className="modal-dialog-centered"
          isOpen={this.state.showPaymentEditPage}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Edit Payment
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ showPaymentEditPage: false });
                document.location.reload();
                document.getElementById(
                  "model_payment_edit"
                ).parentElement.parentElement.parentElement.style.display =
                  "none";
              }}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Form>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                      >
                        Ref-ID
                      </label>
                      <Input
                        readonly
                        className="form-control-alternative"
                        id="edit_customer_name"
                        value={this.state.edit_ref_id}
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-last-name"
                      >
                        Transaction-ID
                      </label>
                      <Input
                        className="form-control-alternative"
                        readonly
                        id="edit_trans_id"
                        value={this.state.edit_trans_id}
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Customer Name
                      </label>
                      <Input
                        readonly
                        className="form-control-alternative"
                        id="edit_customer_name"
                        value={this.state.edit_customer_name}
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-email"
                      >
                        Customer Phone
                      </label>
                      <Input
                        readonly
                        className="form-control-alternative"
                        id="edit_customer_phone"
                        value={this.state.edit_customer_phone}
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Amount
                      </label>

                     {/*  <div id="branchPaid" style={{ fontSize: 10, paddingRight: 40, float: 'right' }}></div> */}
                      
                      <Input
                        style={{ border: "1px solid #9393f3" }}
                        className="form-control-alternative"
                        id="edit_amount"
                        type="number"
                      />

                      <Input
                        style={{ border: "1px solid #9393f3" }}
                        className="form-control-alternative"
                        id="edit_amount_old"
                        type="hidden"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-email"
                      >
                        Payment Status
                      </label>

                      <select
                        style={{ border: "1px solid #9393f3" }}
                        className="form-control-alternative form-control"
                        id="edit_status"
                      >
                        <option
                          value="1"
                          selected={this.state.edit_status ? "selected" : null}
                        >
                          True
                        </option>
                        <option
                          value="0"
                          selected={!this.state.edit_status ? "selected" : null}
                        >
                          False
                        </option>
                      </select>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Button
                      block
                      color="primary"
                      size="lg"
                      type="button"
                      disabled={this.state.startUpdatePaymentLoader}
                      onClick={async () => {
                        var now = new Date();
                        var formattedToday =
                          now
                            .toLocaleDateString("en-GB", {
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                            })
                            .split(" ")
                            .join("-") + ""; //+ now.toLocaleTimeString();

                        const paymData = {
                          updated_date: formattedToday,

                          amt: parseInt(
                            document.getElementById("edit_amount").value
                          ),
                          c_amt: parseInt(
                            document.getElementById("edit_amount").value
                          ),
                          amount: parseInt(
                            document.getElementById("edit_amount").value
                          ),

                          paymentValid:
                            document.getElementById("edit_status").value == 1
                              ? true
                              : false,
                        };
                        let home = this;
                        this.setState({ startUpdatePaymentLoader: true });

                        //alert(JSON.stringify(paymData))

                        await firebase
                          .firestore()
                          .collection("payments")
                          .doc(this.state.edit_ref_id)
                          .update(paymData);

                        await firebase
                          .firestore()
                          .collection("transactions")
                          .doc(home.state.edit_trans_id)
                          .update({
                            paid:
                              parseInt(home.state.lastPaid) -
                              parseInt(
                                document.getElementById("edit_amount_old").value
                              ) +
                              parseInt(
                                document.getElementById("edit_amount").value
                              ),
                          })
                          .then(function () {
                            // swal('Payment Added','Payment of '+document.getElementById('payment_amount').value+' was made successfully\n for Transaction-ID - '+this.state.TransID,'success')
                            alert(
                              "Payment of " +
                                parseInt(
                                  document.getElementById("edit_amount").value
                                ) +
                                " was made successfully\n for Transaction-ID - " +
                                home.state.edit_trans_id
                            );
                            home.setState({
                              startUpdatePaymentLoader: false,
                              showPaymentEditPage: false,
                            });
                            window.location.reload();
                          });

                        // alert('Payment Record with Ref-ID: '+this.state.edit_ref_id+' updated')
                        //document.location.reload()
                      }}
                    >
                      {this.state.startUpdatePaymentLoader ? (
                        // <img src={require("assets/img/loader.gif")} />
                        <>
                          Updating... <i className="fa fa-spinner fa-spin"></i>
                        </>
                      ) : (
                        "Update"
                      )}
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </Modal>

        <Modal
          id="model_payment"
          className="modal-dialog-centered"
          isOpen={this.state.showPaymentPage}
          // toggle={() => this.toggleModal("exampleModal")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Add Payment
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ showPaymentPage: false });
                document.location.reload();

                //document.getElementById('model_payment').parentElement.style.display='none'
                document.getElementById(
                  "model_payment"
                ).parentElement.parentElement.parentElement.style.display =
                  "none";
                //document.getElementById('model_payment').parentElement.parentElement.parentElement.innerHTML='hahahah'
                // alert(JSON.stringify(document.getElementById('model_payment').parentElement.parentElement.parentElement.style))
              }}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Form>
              <div className="pl-lg-4">
                <Row>
                  <Col>
                    <FormGroup>
                      <div style={{ fontSize: 9 }}>
                        {/* JSON.stringify(this.state.transactionRecord) */}
                      </div>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Transaction
                      </label>

                      <SelectSearch
                        search
                        placeholder="Select/Search for a Transaction-ID"
                        renderOption={this.renderFriend}
                        emptyMessage={() => (
                          <div
                            style={{ textAlign: "center", fontSize: "0.8em" }}
                          >
                            Trans-ID Not Found
                          </div>
                        )}
                        //printOptions="always"
                        options={this.state.transactionRecord}
                        onChange={this.getSelectedTrans}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row
                  style={{
                    display: isEmpty(this.state.TransID) ? "none" : "flex",
                  }}
                >
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="customer_name"
                      >
                        Customer
                      </label>
                      <Input
                        className="form-control-alternative"
                        //defaultValue="Lucky"
                        readonly
                        value={
                          isEmpty(this.state.TransID)
                            ? null
                            : this.state.customerName
                        }
                        id="customer_name"
                        placeholder="Customer Name"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row
                  style={{
                    display: isEmpty(this.state.TransID) ? "none" : "flex",
                  }}
                >
                  <Col>
                    <div style={{ fontSize: 10, color: "red", padding: 10 }}>
                      Total amount paid:{" "}
                      <b>{currencyFormat(parseInt(this.state.lastPaid))}</b>
                    </div>

                    <FormGroup>
                      <TransPaymentsMini transaction={this.state.TransID} />
                    </FormGroup>
                  </Col>
                </Row>

                <Row
                  style={{
                    display:
                      this.state.customerName == null ||
                      this.state.customerName == "loading..." ||
                      this.state.customerName == "NONE"
                        ? "none"
                        : "flex",
                  }}
                >
                  <Col md="8">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="payment_amount"
                      >
                        Amount
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="payment_amount"
                        type="number"
                        placeholder="Payment Amount"
                      />
                    </FormGroup>
                  </Col>

                  <Col md="4">
                    <FormGroup>
                      <Button
                        disabled={this.state.startPaymentLoader}
                        onClick={async (e) => {
                          e.preventDefault();

                          if (
                            isEmpty(
                              document.getElementById("payment_amount").value
                            )
                          ) {
                            alert(
                              "Payment amount can not be empty\nKindly provide one to continue"
                            );
                          } else {
                            const paymentValid = true;
                            //const dateTrans = dateTr.toDateString();
                            //var dateTr = new Date();

                            var now = new Date();
                            var formattedToday = now
                              .toLocaleDateString("en-GB", {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                              })
                              .split(" ")
                              .join("-"); //+ now.toLocaleTimeString();

                            this.setState({ startPaymentLoader: true });

                            var trf = "BRA-";
                            for (var i = 0; i < 6; i++) {
                              trf += Math.floor(
                                Math.random() * (9 - 0) + 0
                              ).toString();
                            }
                            //console.log(trf)

                            const paymData = {
                              c_date: formattedToday,
                              tid: this.state.TransID,
                              amt: parseInt(
                                document.getElementById("payment_amount").value
                              ),
                              c_amt: parseInt(
                                document.getElementById("payment_amount").value
                              ),
                              r_amt: parseInt(
                                document.getElementById("payment_amount").value
                              ),
                              amount: parseInt(
                                document.getElementById("payment_amount").value
                              ),

                              paymentValid: paymentValid,
                              pid: trf,
                              //productid:this.state.ProductId,
                              c_transRef: trf,
                              r_txnref: trf,

                              name: this.state.customerName,
                              uid: this.state.customerUid,
                              email: this.state.customerEmail,
                              phone: this.state.customerPhone,
                            };

                            //alert(JSON.stringify(paymData))

                            const home = this;

                            firebase
                              .firestore()
                              .collection("payments")
                              .doc(trf)
                              .set(paymData)
                              .then(async function () {
                                home.setState({
                                  Updatemsg: "Updating...",
                                });

                                firebase
                                  .firestore()
                                  .collection("transactions")
                                  .doc(home.state.TransID)
                                  .update({
                                    paid:
                                      parseInt(home.state.lastPaid) +
                                      parseInt(
                                        document.getElementById(
                                          "payment_amount"
                                        ).value
                                      ),
                                  })
                                  .then(function () {
                                    home.setState({
                                      Updatemsg: "Completed...",
                                    });

                                    home.setState({
                                      showPaymentPage: false,
                                      startPaymentLoader: false,
                                    });

                                    // swal('Payment Added','Payment of '+document.getElementById('payment_amount').value+' was made successfully\n for Transaction-ID - '+this.state.TransID,'success')
                                    alert(
                                      "Payment of " +
                                        document.getElementById(
                                          "payment_amount"
                                        ).value +
                                        " was made successfully\n for Transaction-ID - " +
                                        home.state.TransID
                                    );
                                    window.location.reload();
                                  });
                              });
                          }
                        }}
                        style={{ marginTop: 35 }}
                        color="primary"
                        type="button"
                      >
                        {this.state.startPaymentLoader ? (
                          // <img src={require("assets/img/loader.gif")} />
                          <>
                            {this.state.Updatemsg}{" "}
                            <i className="fa fa-spinner fa-spin"></i>
                          </>
                        ) : (
                          "Save"
                        )}
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
          <div className="modal-footer" style={{ display: "none" }}>
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ showPaymentPage: false });
              }}
            >
              Close
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}



const mapDispatchToProps = (dispatch) => {
  return { 
    PaymentActions: (param) => dispatch(PaymentActions(param)),
    PaymentCount: (param) => dispatch(PaymentCount(param)),
    
   };
};

const mapStateToProps = (state) => ({
  
   UserReducer: state.UserReducer,
   PaymentReducer: state.PaymentReducer,
   transactionReducer: state.transactionReducer,
   currentUser: state.authReducer,
  
});

export default connect(mapStateToProps, mapDispatchToProps)(Payments);

import React from "react";
// node.js library that concatenates classes (strings)



// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  Modal,
  Form,
  FormGroup,
  Input,
  Badge 
} from "reactstrap";


import   firebase from "firebase/app";

import { useCollectionData } from "react-firebase-hooks/firestore";
import   ContentLoader,{BulletList,Code  } from 'react-content-loader'



import api from "../../../utils/config";
import { currencyFormat } from '../../../utils/functions';

//import "firebase/auth";
import { isEmpty, isObject } from "lodash";
//require("firebase/firestore");




const TransUserPayment = (props) => {

    const [ PaymentData, setPaymentData ] = React.useState([]);
    const [ UidData, setUidData ] = React.useState(null);
    const [ dataLoading, setdataLoading ] = React.useState(true);

    if (!firebase.apps.length)
    {
    

        firebase.initializeApp({
            apiKey: api.REACT_APP_FIREBASE_APP_ID,
            authDomain: api.REACT_APP_FIREBASE_DOMAIN,
            databaseURL: api.REACT_APP_FIREBASE_DATABASE,
            projectId: api.REACT_APP_FIREBASE_PROJECT_ID,
            storageBucket: api.REACT_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: api.REACT_APP_FIREBASE_SENDER_ID,
            appId: api.REACT_APP_FIREBASE_APP_ID,
            measurementId: api.REACT_APP_FIREBASE_MEASUREMENT_ID,
          });

         

    }

const { transactionID } = props;
  //const { tid, paid, pplan } = transaction;

                try 
                {

                          firebase
                        .firestore()
                        .collection("transactions")
                        //.where('id','==',transactionID)
                         .doc(transactionID)
                        .onSnapshot( snapshot => {

                           var transData = [];
                           var uid =null;

                           if (snapshot.empty) {
                              setdataLoading(false)
                        //console.log('AT ALL AT ALL')
                                return;
                            }
                           
                            
                               transData.push(snapshot.data());
                               //console.log('VALID')
                                //setdataLoading(false)

                           if(transData[0])
                            {
                              if(transData[0].hasOwnProperty('uid'))
                              {
                                uid = transData[0].uid;
                                setUidData(uid)
                                //setPaymentData(PropertyUser[0].lastName +' '+PropertyUser[0].firstName)  



                                firebase.firestore()
                                        .collection("users")
                                        .doc(uid)   
                                        .get()
                                        .then(function(snapshot)
                                        {
                                            var PropertyUser = [];
                                            PropertyUser.push(snapshot.data());

                                            if(PropertyUser[0])
                                            {
                                              if(PropertyUser[0].hasOwnProperty('lastName'))
                                              {
                                                setPaymentData(PropertyUser[0].lastName +' '+PropertyUser[0].firstName)  

                                              }

                                            }
                                            
                                                  

                                    setdataLoading(false)
                                    
                                      
                                })
                       


                              }

                            
                            
                            }
                            else{

                              setdataLoading(false)

                            }






                           


                                             
                      })


                }
                catch(error)
                {
                     setdataLoading(false)

                }
                

     
  
                if(!(dataLoading))
                {
                  //console.log(JSON.stringify(PaymentData)+' - '+UidData)
                    return (<div>{PaymentData}</div>)

                }
                else{
                    return (<div style={{fontSize:9}}>loading...</div>)

                }


   

  // return ('')


}

export default TransUserPayment;

import React from "react";
// reactstrap components
import {
  Card,
  Container,
  Row,
  Modal,
  Form,
  FormGroup,
  Input,
  Badge,
  Button,
  Col,
  Alert,
  Table,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  UncontrolledTooltip,
} from "reactstrap";

import ReactDatetime from "react-datetime";

// core components
import Header from "components/Headers/Header.js";

import ReactDOM from "react-dom";
//import {MaterialTable,MTableBodyRow,MTableCell} from 'material-table'
import MaterialTable, {
  MTableBodyRow,
  MTableCell,
  MTableToolbar,
} from "material-table";
import { connect } from "react-redux";
import CountUp from "react-countup";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import firebase from "firebase/app";
import api from "../../utils/config";
import {
  currencyFormat,
  dateFormater,
  UserRolePermission,
} from "../../utils/functions";
import { PropertySingle } from "./../../store/actions/propertyActions";
import {
  transactionActions,
  transactionDeleteActions,
  TransactionCount,
} from "./../../store/actions/transactionActions";
import {
  PaymentActions,
  PaymentCount,
} from "./../../store/actions/paymentActions";
import FilterNumericBetween from "./functions/FilterNumericBetween";
import filterDatePicker from "./functions/filterDatePicker";

import TransUser from "./functions/TransUser";
import TransPayments from "./functions/TransPayments";

import "firebase/auth";
import swal from "sweetalert";
import { isEmpty } from "lodash";
import Label from "reactstrap/lib/Label";
require("firebase/firestore");

class Transaction extends React.Component {
  state = {
    transactionRecord: [],
    showTransDetails: false,
    showPaymentPage: false,
    selectedUser: [],
    selectedTransaction: [],
    selectedProperty: [],
    startPaymentLoader: false,
    Updatemsg: "Saving...",
    startDate: null,
    endDate: null,
    Date1: "",
    Date2: "",
    filtering: false,
  };

  componentDidMount() {
    this.props.transactionActions();
    this.props.PaymentActions();

    if (!firebase.apps.length) {
      firebase.initializeApp({
        apiKey: api.REACT_APP_FIREBASE_APP_ID,
        authDomain: api.REACT_APP_FIREBASE_DOMAIN,
        databaseURL: api.REACT_APP_FIREBASE_DATABASE,
        projectId: api.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: api.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: api.REACT_APP_FIREBASE_SENDER_ID,
        appId: api.REACT_APP_FIREBASE_APP_ID,
        measurementId: api.REACT_APP_FIREBASE_MEASUREMENT_ID,
      });
    }

    //alert(Object.keys(this.props.transactionReducer.transactionsData).length)
    if (
      Object.keys(this.props.transactionReducer.transactionsData).length > 0
    ) {
      var transactionRecord = [];
      this.props.transactionReducer.transactionsData.forEach((doc) => {
        /*  console.log(
          doc.createdDate + "  -  "+this.fromISODate(doc.createdDate)
        ); */
        transactionRecord.push({
          id: doc.id,
          unm: doc.unm,
          uid: doc.uid,
          paid: doc.paid,
          unm: doc.hasOwnProperty("unm") ? doc.unm : null,
          //uid:(doc.hasOwnProperty('uid')?<TransUser uid={doc.uid}/>:null),
          pnm: doc.pnm,
          status: doc.status,
          price: doc.price,
          plan: doc.hasOwnProperty("pplan")
            ? doc.pplan.hasOwnProperty("title")
              ? doc.pplan.title
              : null
            : null,
          createdDate: doc.hasOwnProperty("createdDate")
            ? this.fromISODate(doc.createdDate)
            : null,
          ct: 0,
        });
      });

      this.setState({ transactionRecord: transactionRecord });
    }

    //alert(JSON.stringify(transactionRecord))
  }

  fromISODate(d) {
    var date = new Date(d);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var dt = date.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }

    return year + "-" + month + "-" + dt;
  }

  getPaymentCount(tid) {
    if (isEmpty(tid)) {
      return "null";
    } else {
      var transactionsData = [];
      firebase
        .firestore()
        .collection("payments")
        .where("tid", "==", tid)
        .onSnapshot((snapshot) => {
          snapshot.forEach((doc) => {
            transactionsData.push({
              ...doc.data(),
            });
          });

          var element = document.getElementById("cell_ct_" + tid);

          if (typeof element != "undefined" && element != null) {
            document.getElementById("cell_ct_" + tid).innerHTML =
              Object.keys(transactionsData).length > 0
                ? Object.keys(transactionsData).length
                : 0;
          } else {
            // return 0
          }

          //console.log('cell_ct_'+tid+' - '+Object.keys(transactionsData).length+'-'+tid)
        });

      //console.log(transactionsData)
    }
  }

  getUserFromUid(uid) {
    if (isEmpty(uid)) {
      return null;
    } else {
      //document.getElementById('cell_'+uid).innerHTML='<img  src={require("../../assets/img/brand/loader.gif")} />'
      firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .get()
        .then(function (snapshot) {
          var PropertyUser = [];
          PropertyUser.push(snapshot.data());

          var element = document.getElementById("cell_" + uid);

          if (typeof element != "undefined" && element != null) {
            document.getElementById("cell_" + uid).innerHTML =
              PropertyUser[0].lastName + " " + PropertyUser[0].firstName;
          }
        });
    }
  }

  setDate1(data) {
    return this.setState({ Date1: data });
  }

  setDate2(data) {
    return this.setState({ Date2: data });
  }

  setFiltering(data) {
    return this.setState({ filtering: !data });
  }

  myData = () => {
    /* function to determine the data to be rendered to the table */
    let myArr = [];
    if (this.state.Date1 && this.state.Date2) {
      (this.state.transactionRecord || []).map((item) =>
        item.createdDate >= this.state.Date1 &&
        item.createdDate <= this.state.Date2
          ? myArr?.push(item)
          : null
      );
    } else {
      myArr =
        this.state.transactionRecord ||
        []; /* YourData is the array you want to display and filter */
    }

    return myArr;
  };

  FilterByDateRange = () => {
    /* function for adding 2 textfields for date range */
    return (
      <>
        <Grid
          alignItems="center"
          container
          justify="center"
          style={{ display: this.state.filtering ? "flex" : "none" }}
        >
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Paper
              elevation={3}
              style={{
                margin: "10px auto",
                textAlign: "center",
                padding: "10px",
              }}
            >
              <Typography> Search by Date Range </Typography>
              <br />
              <br />
              <TextField
                value={this.state.Date1}
                onChange={(e) => this.setDate1(e.target.value)}
                type="date"
                id="date"
                label="Start Date"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ margin: "10px" }}
              />
              <TextField
                value={this.state.Date2}
                label="End Date"
                onChange={(e) => this.setDate2(e.target.value)}
                type="date"
                id="date"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ margin: "10px" }}
              />
              <div>
                <Button
                  onClick={() => {
                    this.setDate1("");
                    this.setDate2("");
                  }}
                  variant="contained"
                  color="warning"
                >
                  Clear
                </Button>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  };

  render() {
    const transactionReducer = this.props.transactionReducer;

    if (document.getElementById("empty_row_id")) {
      document.getElementById("empty_row_id").style.display = "none";
      //console.log("hide_rows");
    }

    /* if(this.props.transactionReducer.transactionCount <=0)
        {

           if(transactionReducer.hasOwnProperty('transactionsData'))
            {
              
             

              if(this.props.transactionReducer.transactionCount <= 0)
              {

                this.props.transactionReducer.TransactionCount(Object.keys(transactionReducer.transactionsData).length);
              }

            }


        } */

    return (
      <>
        <Header />

        <Modal
          id="model_payment"
          className="modal-dialog-centered"
          isOpen={this.state.showPaymentPage}
          // toggle={() => this.toggleModal("exampleModal")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Add Payment
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ showPaymentPage: false });
              }}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-username"
                  >
                    Transaction
                  </label>
                  <Input
                    className="form-control-alternative"
                    readonly
                    value={this.state.selectedTransaction.id}
                    //value={( isEmpty(this.state.TransID)?null:this.state.customerName)}
                    //id="customer_name"
                    //placeholder="Customer Name"
                    type="text"
                  />
                </FormGroup>
              </Col>

              <Col lg="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-username"
                  >
                    Customer
                  </label>

                  <Input
                    className="form-control-alternative"
                    readonly
                    value={this.state.selectedTransaction.unm}
                    //value={( isEmpty(this.state.TransID)?null:this.state.customerName)}
                    //id="customer_name"
                    //placeholder="Customer Name"
                    type="text"
                  />
                </FormGroup>
              </Col>

              <Col lg="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-username"
                  >
                    Amount Paid
                  </label>

                  <Input
                    className="form-control-alternative"
                    readonly
                    value={currencyFormat(
                      parseInt(this.state.selectedTransaction.paid)
                    )}
                    //value={( isEmpty(this.state.TransID)?null:this.state.customerName)}
                    //id="customer_name"
                    //placeholder="Customer Name"
                    type="text"
                  />
                </FormGroup>
              </Col>

              <Col lg="12">
                <label className="form-control-label" htmlFor="input-username">
                  Customer Payment Date
                </label>

                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-calendar-grid-58" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <ReactDatetime
                      inputProps={{
                        placeholder: "Date Picker Here",
                      }}
                      timeFormat={false}
                      renderDay={(props, currentDate, selectedDate) => {
                        let classes = props.className;
                        if (
                          this.state.startDate &&
                          this.state.endDate &&
                          this.state.startDate._d + "" === currentDate._d + ""
                        ) {
                          classes += " start-date";
                        } else if (
                          this.state.startDate &&
                          this.state.endDate &&
                          new Date(this.state.startDate._d + "") <
                            new Date(currentDate._d + "") &&
                          new Date(this.state.endDate._d + "") >
                            new Date(currentDate._d + "")
                        ) {
                          classes += " middle-date";
                        } else if (
                          this.state.endDate &&
                          this.state.endDate._d + "" === currentDate._d + ""
                        ) {
                          classes += " end-date";
                        }
                        return (
                          <td {...props} className={classes}>
                            {currentDate.date()}
                          </td>
                        );
                      }}
                      onChange={(e) => {
                        this.setState({ startDate: e });

                        //console.log(this.state.startDate);
                      }}
                    />
                  </InputGroup>
                </FormGroup>
                {
                  //JSON.stringify(this.state.startDate)
                }
              </Col>

              <Col lg="8">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-username"
                  >
                    Customer New Payment
                  </label>

                  <Input
                    className="form-control-alternative"
                    readonly
                    id="payment_amount"
                    type="number"
                  />
                </FormGroup>
              </Col>

              <Col lg="4">
                <Button
                  disabled={this.state.startPaymentLoader}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (
                      isEmpty(document.getElementById("payment_amount").value)
                    ) {
                      alert(
                        "Payment amount can not be empty\nKindly provide one to continue"
                      );
                    } else {
                      const paymentValid = true;
                      //const dateTrans = dateTr.toDateString();
                      //var dateTr = new Date();

                      var now = new Date();
                      var formattedToday = now
                        .toLocaleDateString("en-GB", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        })
                        .split(" ")
                        .join("-"); //+ now.toLocaleTimeString();

                      this.setState({ startPaymentLoader: true });

                      var trf = "BRA-";
                      for (var i = 0; i < 6; i++) {
                        trf += Math.floor(
                          Math.random() * (9 - 0) + 0
                        ).toString();
                      }
                      //console.log(trf)

                      const paymData = {
                        c_date: formattedToday,
                        dateRef: JSON.stringify(this.state.startDate),
                        tid: this.state.selectedTransaction.id,
                        amt: parseInt(
                          document.getElementById("payment_amount").value
                        ),
                        c_amt: parseInt(
                          document.getElementById("payment_amount").value
                        ),
                        r_amt: parseInt(
                          document.getElementById("payment_amount").value
                        ),
                        amount: parseInt(
                          document.getElementById("payment_amount").value
                        ),

                        paymentValid: paymentValid,
                        pid: trf,
                        //productid:this.state.ProductId,
                        c_transRef: trf,
                        r_txnref: trf,
                        name: this.state.selectedTransaction.unm,
                        uid: this.state.selectedTransaction.uid,
                        email: this.state.selectedTransaction.email,
                        phone: this.state.selectedTransaction.phone,
                      };

                      //alert(JSON.stringify(paymData))

                      const home = this;

                      await firebase
                        .firestore()
                        .collection("payments")
                        .doc(trf)
                        .set(paymData)
                        .then(async function () {
                          home.setState({
                            Updatemsg: "Updating...",
                          });

                          await firebase
                            .firestore()
                            .collection("transactions")
                            .doc(home.state.selectedTransaction.id)
                            .update({
                              paid:
                                parseInt(home.state.selectedTransaction.paid) +
                                parseInt(
                                  document.getElementById("payment_amount")
                                    .value
                                ),
                            })
                            .then(function () {
                              home.setState({
                                Updatemsg: "Completed...",
                              });

                              home.setState({
                                showPaymentPage: false,
                                startPaymentLoader: false,
                              });

                              // swal('Payment Added','Payment of '+document.getElementById('payment_amount').value+' was made successfully\n for Transaction-ID - '+this.state.TransID,'success')
                              alert(
                                "Payment of " +
                                  document.getElementById("payment_amount")
                                    .value +
                                  " was made successfully\n for Transaction-ID - " +
                                  home.state.selectedTransaction.id
                              );
                              window.location.reload();
                            });
                        });
                    }
                  }}
                  style={{ marginTop: 35 }}
                  color="primary"
                  type="button"
                >
                  {this.state.startPaymentLoader ? (
                    // <img src={require("assets/img/loader.gif")} />
                    <>
                      {this.state.Updatemsg}{" "}
                      <i className="fa fa-spinner fa-spin"></i>
                    </>
                  ) : (
                    "Save"
                  )}
                </Button>
              </Col>
            </Row>
          </div>
        </Modal>

        <Modal
          id="model_property_new"
          className="modal-dialog-centered"
          isOpen={this.state.showTransDetails}
          style={{ maxWidth: 80 + "%" }}
          // toggle={() => this.toggleModal("exampleModal")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              TRANSACTION DETAILS
            </h5>

            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => {
                e.preventDefault();

                this.setState({ showTransDetails: false });
                // document.location.reload()
              }}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>

          <div className="modal-body">
            <Row>
              <Col>
                <div
                  style={{
                    backgroundColor: "rgb(245, 247, 249)",
                    padding: "1.25rem",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: "rgb(230, 236, 241)",
                    borderImage: "initial",
                    borderRadius: "0.25rem",
                  }}
                >
                  <Alert
                    color="secondary"
                    style={{ backgroundColor: "#e5eaec" }}
                  >
                    TRANSCTION DATA
                  </Alert>

                  <div
                    class="form-group"
                    style={{ marginBottom: "0.3rem", marginLeft: 20 }}
                  >
                    <Label
                      style={{ fontSize: 10, marginBottom: -10, marginLeft: 5 }}
                    >
                      Transaction ID
                    </Label>
                    <div>{this.state.selectedTransaction.id}</div>
                  </div>

                  <div
                    class="form-group"
                    style={{ marginBottom: "0.3rem", marginLeft: 20 }}
                  >
                    <Label
                      style={{ fontSize: 10, marginBottom: -10, marginLeft: 5 }}
                    >
                      Property ID
                    </Label>
                    <div>{this.state.selectedTransaction.pid}</div>
                  </div>

                  <div
                    class="form-group"
                    style={{ marginBottom: "0.3rem", marginLeft: 20 }}
                  >
                    <Label
                      style={{ fontSize: 10, marginBottom: -10, marginLeft: 5 }}
                    >
                      Property Name
                    </Label>
                    <div>{this.state.selectedTransaction.pnm}</div>
                  </div>

                  <div
                    class="form-group"
                    style={{ marginBottom: "0.3rem", marginLeft: 20 }}
                  >
                    <Label
                      style={{ fontSize: 10, marginBottom: -10, marginLeft: 5 }}
                    >
                      {" "}
                      Property Amount
                    </Label>
                    <div>
                      {currencyFormat(
                        parseInt(this.state.selectedTransaction.price)
                      )}
                    </div>
                  </div>

                  <div
                    class="form-group"
                    style={{ marginBottom: "0.3rem", marginLeft: 20 }}
                  >
                    <Label
                      style={{ fontSize: 10, marginBottom: -10, marginLeft: 5 }}
                    >
                      {" "}
                      Transaction Status{" "}
                    </Label>
                    <div>
                      {this.state.selectedTransaction.paid <= 0 ? (
                        <Badge
                          color=""
                          style={{ fontSize: 10, color: "#000" }}
                          className="badge-dot"
                        >
                          <i className="bg-danger" />
                          Pending
                        </Badge>
                      ) : this.state.selectedTransaction.paid > 0 ? (
                        <Badge
                          color=""
                          style={{ fontSize: 10, color: "#ccc" }}
                          className="badge-dot"
                        >
                          <i className="bg-info" />
                          On-going
                        </Badge>
                      ) : (
                        <Badge
                          color=""
                          style={{ fontSize: 10, color: "#ccc" }}
                          className="badge-dot"
                        >
                          <i className="bg-success" />
                          Completed
                        </Badge>
                      )}
                    </div>
                  </div>

                  <div
                    class="form-group"
                    style={{ marginBottom: "0.3rem", marginLeft: 20 }}
                  >
                    <Label
                      style={{ fontSize: 10, marginBottom: -10, marginLeft: 5 }}
                    >
                      {" "}
                      Created Date
                    </Label>
                    <div>
                      {dateFormater(this.state.selectedTransaction.createdDate)}
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    marginTop: 20,
                    backgroundColor: "rgb(245, 247, 249)",
                    padding: "1.25rem",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: "rgb(230, 236, 241)",
                    borderImage: "initial",
                    borderRadius: "0.25rem",
                  }}
                >
                  <Alert
                    color="secondary"
                    style={{ backgroundColor: "#e5eaec" }}
                  >
                    CUSTOMER DATA
                  </Alert>

                  <div
                    class="form-group"
                    style={{ marginBottom: "0.3rem", marginLeft: 20 }}
                  >
                    <Label
                      style={{ fontSize: 10, marginBottom: -10, marginLeft: 5 }}
                    >
                      Customer ID
                    </Label>
                    <div>{this.state.selectedTransaction.uid}</div>
                  </div>

                  <div
                    class="form-group"
                    style={{ marginBottom: "0.3rem", marginLeft: 20 }}
                  >
                    <Label
                      style={{ fontSize: 10, marginBottom: -10, marginLeft: 5 }}
                    >
                      Customer Name
                    </Label>
                    <div>{this.state.selectedTransaction.unm}</div>
                  </div>

                  <div
                    class="form-group"
                    style={{ marginBottom: "0.3rem", marginLeft: 20 }}
                  >
                    <Label
                      style={{ fontSize: 10, marginBottom: -10, marginLeft: 5 }}
                    >
                      Customer Email
                    </Label>
                    <div>
                      <a
                        href={"mailto:" + this.state.selectedTransaction.email}
                      >
                        {this.state.selectedTransaction.email}
                      </a>{" "}
                    </div>
                  </div>

                  <div
                    class="form-group"
                    style={{ marginBottom: "0.3rem", marginLeft: 20 }}
                  >
                    <Label
                      style={{ fontSize: 10, marginBottom: -10, marginLeft: 5 }}
                    >
                      Customer Phone
                    </Label>
                    <div>
                      <a href={"tel:" + this.state.selectedTransaction.phone}>
                        {this.state.selectedTransaction.phone}
                      </a>
                    </div>
                  </div>

                  <div
                    class="form-group"
                    style={{ marginBottom: "0.3rem", marginLeft: 20 }}
                  >
                    <Label
                      style={{ fontSize: 10, marginBottom: -10, marginLeft: 5 }}
                    >
                      Gender
                    </Label>
                    <div>{this.state.selectedUser.gender}</div>
                  </div>

                  <div
                    class="form-group"
                    style={{ marginBottom: "0.3rem", marginLeft: 20 }}
                  >
                    <Label
                      style={{ fontSize: 10, marginBottom: -10, marginLeft: 5 }}
                    >
                      {" "}
                      Referal Code
                    </Label>
                    <div>{this.state.selectedUser.referalCode}</div>
                  </div>

                  <div
                    class="form-group"
                    style={{ marginBottom: "0.3rem", marginLeft: 20 }}
                  >
                    <Label
                      style={{ fontSize: 10, marginBottom: -10, marginLeft: 5 }}
                    >
                      {" "}
                      Total Amount Paid
                    </Label>
                    <div>
                      {currencyFormat(
                        parseInt(this.state.selectedTransaction.paid)
                      )}
                    </div>
                  </div>
                </div>
              </Col>

              <Col>
                {
                  //selectedProperty
                }

                <Alert
                  color="secondary"
                  style={{ backgroundColor: "#e5eaec", marginTop: 25 }}
                >
                  CUSTOMER PAYMENTS
                  <Button
                    color="primary"
                    className="float-right"
                    href="#"
                    style={{
                      fontSize: 15,
                      marginBottom: 10,
                      marginTop: -5,
                      display: UserRolePermission(
                        this.props.currentUser.UserData.role,
                        "add_trans"
                      )
                        ? "none"
                        : "block",
                    }}
                    disabled={UserRolePermission(
                      this.props.currentUser.UserData.role,
                      "add_trans"
                    )}
                    size="sm"
                    onClick={() => {
                      //if(!(UserRolePermission(this.props.currentUser.UserData.role,'add_trans')))
                      //{

                      this.setState({
                        showPaymentPage: true,
                      });

                      //}
                    }}
                  >
                    <i className="ni ni-fat-add"></i>
                  </Button>
                </Alert>

                <Table
                  className="align-items-center table-flush"
                  style={{ margin: 10, marginTop: 10, width: 90 + "%" }}
                  responsive
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Amount</th>
                      <th scope="col">Refrence</th>
                      <th scope="col">Status</th>
                      <th scope="col">Date</th>
                      <th scope="col">Ref-Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(this.state.selectedProperty).length <= 0 ? (
                      <tr>
                        <td colSpan={5} style={{ textAlign: "center" }}>
                          <Alert
                            color="warning"
                            style={{
                              marginLeft: 5 + "%",
                              marginRight: 5 + "%",
                            }}
                          >
                            <strong>
                              {this.state.selectedTransaction.unm}{" "}
                            </strong>{" "}
                            have not made any payments yet
                          </Alert>
                        </td>
                      </tr>
                    ) : null}

                    {Object.keys(this.state.selectedProperty).length > 0 &&
                      this.state.selectedProperty.map((data, i) => {
                        return (
                          <tr>
                            <td>{currencyFormat(parseInt(data.amount))}</td>
                            <td>
                              {data.hasOwnProperty("c_transRef")
                                ? data.c_transRef
                                : data.r_txnref}
                            </td>
                            <td>
                              {data.paymentValid ? (
                                <Badge
                                  color=""
                                  style={{ fontSize: 10, color: "#000" }}
                                  className="badge-dot"
                                >
                                  <i className="bg-success" />
                                  Completed
                                </Badge>
                              ) : (
                                <Badge
                                  color=""
                                  style={{ fontSize: 10, color: "#ccc" }}
                                  className="badge-dot"
                                >
                                  <i className="bg-danger" />
                                  Pending
                                </Badge>
                              )}
                            </td>
                            <td>{dateFormater(data.c_date)}</td>

                            <td>
                              {data.hasOwnProperty("dateRef")
                                ? dateFormater(data.dateRef.replace(/"/g, ""))
                                : "Null"}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </Modal>

        {/* Page content */}
        <Container className="mt--7" fluid>
          <div id="empty_row_id"></div>

          <Row>
            <div className="col">
              <Card className="shadow border-0">
                <div style={{ maxWidth: "100%", borderRadius: 20 }}>
                  {Object.keys(this.props.transactionReducer.transactionsData)
                    .length > 0 ? (
                    <MaterialTable
                      columns={[
                        {
                          title: "Action",
                          //width: "100px",
                          export: false,
                          filtering: false,
                          field: "id",
                          render: (rowData) => {
                            return (
                              <>
                                <Button
                                  color="primary"
                                  href="#stow"
                                  style={{
                                    fontSize: 9,
                                    width: 40,
                                    float: "left",
                                    display: UserRolePermission(
                                      this.props.currentUser.UserData.role,
                                      "view_trans"
                                    )
                                      ? "none"
                                      : "block",
                                  }}
                                  disabled={UserRolePermission(
                                    this.props.currentUser.UserData.role,
                                    "view_trans"
                                  )}
                                  // style={{}}
                                  onClick={(e) => {
                                    e.preventDefault();

                                    // if(!(UserRolePermission(this.props.currentUser.UserData.role,'view_trans')))
                                    //{

                                    var userInfo =
                                      this.props.UserReducer.UseractionsData.find(
                                        (x) => x.id === rowData.uid
                                      );
                                    var TransactionInfo =
                                      this.props.transactionReducer.transactionsData.find(
                                        (x) => x.id === rowData.id
                                      );
                                    var PaymentData =
                                      this.props.PaymentReducer.PaymentactionsData.filter(
                                        function (x) {
                                          return x.tid == rowData.id;
                                        }
                                      );

                                    this.setState({
                                      showTransDetails: true,
                                      selectedUser: userInfo,
                                      selectedTransaction: TransactionInfo,
                                      selectedProperty: PaymentData,
                                    });

                                    //}

                                    //this.props.history.push("/admin/transaction");
                                  }}
                                  size="sm"
                                >
                                  View
                                </Button>
                                {rowData.paid <= 0 ? (
                                  <Button
                                    color="danger"
                                    href="#stow"
                                    size="sm"
                                    style={{
                                      fontSize: 9,
                                      //width: 40,
                                      float: "left",
                                      display: UserRolePermission(
                                        this.props.currentUser.UserData.role,
                                        "delete_trans"
                                      )
                                        ? "none"
                                        : "block",
                                    }}
                                    disabled={UserRolePermission(
                                      this.props.currentUser.UserData.role,
                                      "delete_trans"
                                    )}
                                    onClick={(e) => {
                                      var msg = document.createElement("span");
                                      msg.innerHTML =
                                        "<div style='text-align:left'>Are you sure you want to delete this transaction ?<br/><br/>" +
                                        "<div style='color:grey;font-size:13px'>Customer Name: <b>" +
                                        rowData.unm +
                                        "</b></div>" +
                                        "<div style='color:grey;font-size:13px'>Customer ID: <b>" +
                                        rowData.uid +
                                        "</b></div>" +
                                        "<div style='color:grey;font-size:13px'>Property: <b>" +
                                        rowData.pnm +
                                        "</b></div><br/>" +
                                        "<div style='color:grey;font-size:13px'>Transaction ID: <b>" +
                                        rowData.id +
                                        "</b></div>" +
                                        "<div style='color:grey;font-size:13px'>Amount: <b>" +
                                        (rowData.paid
                                          ? currencyFormat(
                                              parseInt(rowData.paid)
                                            )
                                          : "₦0.00") +
                                        "</b></div>" +
                                        "<div style='color:grey;font-size:13px'>Date <b>" +
                                        (isEmpty(rowData.createdDate)
                                          ? null
                                          : dateFormater(rowData.createdDate)) +
                                        "</b></div><div>";

                                      // console.log(rowData);

                                      //
                                      document.getElementById(
                                        "trans_loader"
                                      ).style.display = "flex";

                                      swal({
                                        title: "Delete Transaction",
                                        content: msg,

                                        icon: "warning",
                                        buttons: ["Cancel", "Delete"],
                                        dangerMode: true,
                                      }).then((willDelete) => {
                                        if (willDelete) {
                                          /*  document.getElementById(
                                            "trans_row_" + rowData.id
                                          ).style.display = "none"; */

                                          document.getElementById(
                                            "trans_loader"
                                          ).innerHTML =
                                            '<i className="fa fa-spinner fa-spin"></i>';

                                          this.props.transactionDeleteActions(
                                            rowData.id,
                                            msg
                                          );
                                          /*  swal("Transaction Deleted", {
                                            icon: "success",
                                          }); */
                                        }
                                      });
                                    }}
                                  >
                                    <i
                                      className="fa fa-trash"
                                      style={{ fontSize: 10 }}
                                    ></i>
                                  </Button>
                                ) : null}
                              </>
                            );
                          },
                        },
                        {
                          title: "Customer",
                          field: "unm",
                          render: (rowData) => {
                            return isEmpty(rowData.unm) ? (
                              <TransUser uid={rowData.uid} />
                            ) : (
                              rowData.unm
                            );
                          },
                        },
                        {
                          title: "Property",
                          field: "pnm",
                          render: (rowData) => {
                            return (
                              <a
                                href="#"
                                onClick={(e) => {
                                  let array_ =
                                    this.props.transactionReducer
                                      .transactionsData;
                                  let id = rowData.id;

                                  for (
                                    var i = 0, len = array_.length;
                                    i < len;
                                    i++
                                  ) {
                                    if (array_[i].id === id) {
                                      //console.log(array_[i].property)

                                      if (array_[i].property) {
                                        this.props.PropertySingle(
                                          array_[i].property
                                        );
                                        this.props.history.push(
                                          "/admin/single/property"
                                        );
                                      } else {
                                        alert(
                                          "PROPRTY DOES NOT EXIST OR HAVE BEEN DELETED"
                                        );
                                      }
                                    }
                                  }
                                }}
                              >
                                {rowData.pnm}
                              </a>
                            );
                          },
                        },
                        /*  { title: "Payment Plan", field: "plan" }, */
                        {
                          title: "Amount",
                          field: "price",
                          render: (rowData) => {
                            return rowData.price
                              ? currencyFormat(parseInt(rowData.price))
                              : "₦0";
                          },
                        },
                        {
                          title: "Paid Amount",
                          field: "paid",
                          render: (rowData) => {
                            return rowData.paid
                              ? currencyFormat(parseInt(rowData.paid))
                              : "₦0";
                          },
                        },

                        {
                          title: "Status",
                          field: "status",

                          //lookup: { true: 'Pending', false: 'On-going' },
                          filtering: false,

                          export: false,
                          render: (rowData) => {
                            return rowData.paid <= 0 ? (
                              <Badge
                                color=""
                                style={{ fontSize: 10, color: "#000" }}
                                className="badge-dot"
                              >
                                <i className="bg-danger" />
                                Pending
                              </Badge>
                            ) : rowData.paid > 0 ? (
                              <Badge
                                color=""
                                style={{ fontSize: 10, color: "#ccc" }}
                                className="badge-dot"
                              >
                                <i className="bg-info" />
                                On-going
                              </Badge>
                            ) : (
                              <Badge
                                color=""
                                style={{ fontSize: 10, color: "#ccc" }}
                                className="badge-dot"
                              >
                                <i className="bg-success" />
                                Completed
                              </Badge>
                            );
                          },
                        },

                        // { title: 'Customer', field: 'uid', filtering: false, render: rowData => this.getUserFromUid(rowData.uid)},
                        //
                        /*  {title: 'Count', field:'ct', render: rowData => {
                     return (this.getPaymentCount(rowData.id))
                   }}, */

                        //2021-11-18

                        {
                          title: "Date",
                          filterComponent: (props) => (
                            <Button
                              color="warning"
                              href="#stow"
                              style={{
                                fontSize: 9,
                                // width: 40,
                              }}
                              onClick={() =>
                                this.setFiltering(this.state.filtering)
                              }
                              size="sm"
                            >
                              {this.state.filtering
                                ? "Hide Filter"
                                : "Show Filter"}
                            </Button>
                          ),
                          // filterComponent: (props) => <filterDatePicker {...props}/>,
                          field: "createdDate",
                          defaultSort: "desc",
                          render: (rowData) => {
                            return isEmpty(rowData.createdDate)
                              ? null
                              : dateFormater(rowData.createdDate);
                          },
                        },
                      ]}
                      components={{
                        Toolbar: (props) => (
                          <p className="h3" style={{ padding: 10 }}>
                            <MTableToolbar {...props} />
                            <div style={{ marginTop: -50 }}>
                              {"Total"}{" "}
                              <CountUp
                                end={
                                  Object.keys(
                                    this.props.transactionReducer
                                      .transactionsData
                                  ).length
                                }
                              />
                              <div
                                id="trans_loader"
                                style={{ display: "none" }}
                              >
                                <span style={{ fontSize: 10 }}>
                                  Deleting transaction...
                                </span>{" "}
                                <i className="fa fa-spinner fa-spin"></i>
                              </div>
                            </div>
                            {this.FilterByDateRange()}
                          </p>
                        ),

                        Row: (props) => {
                          if (!isEmpty(props.data.pnm)) {
                            return (
                              <MTableBodyRow
                                id={"trans_row_" + props.data.id}
                                {...props}
                              />
                            );
                          } else {
                            return (
                              <MTableBodyRow
                                id={"empty_row_id"}
                                style={{ display: "none" }}
                                {...props}
                              />
                            );
                          }
                        },
                        Cell: (props) => {
                          if (props.columnDef.field == "uid") {
                            return (
                              <MTableCell
                                {...props}
                                id={"cell_" + props.rowData.uid}
                              />
                            );
                          } else if (props.columnDef.field == "ct") {
                            return (
                              <MTableCell
                                {...props}
                                id={"cell_ct_" + props.rowData.id}
                              />
                            );
                          } else {
                            return <MTableCell {...props} />;
                          }
                        },
                      }}
                      data={this.myData()}
                      /* detailPanel={rowData => 
                    {
                       return (<TransPayments transaction={rowData.id} />)
                    }} */

                      //onRowClick={(event, rowData, togglePanel) => togglePanel()}

                      options={{
                        filtering: true,
                        exportButton: true,
                        exportFileName: "Transaction_Data_" + new Date(),

                        pageSize: 20,
                        pageSizeOptions: [
                          5, 10, 20, 30, 50, 75, 100, 200, 500, 1000, 2000,
                          3000, 5000, 8000, 10000,
                        ],
                        toolbar: true,
                        // paging: true,

                        rowStyle: {
                          paddingLeft: "1.5rem",
                          paddingRight: "1.5rem",
                          verticalAlign: "middle",

                          fontSize: "0.8125rem",
                          whiteSpace: "nowrap",
                          padding: "1rem",
                          borderTop: "1px solid #e9ecef",
                          fontFamily: "Open Sans, sans-serif",
                        },

                        headerStyle: {
                          paddingTop: "0.75rem",
                          paddingBottom: "0.75rem",
                          fontSize: "0.65rem",
                          textTransform: "uppercase",
                          letterPpacing: "1px",
                          borderBottom: "1px solid #e9ecef",
                          fontWeight: "bolder",
                          paddingLeft: "1.5rem",
                          paddingRight: "1.5rem",
                          backgroundColor: "#f6f9fc",
                          color: "#8898aa",
                          verticalAlign: "middle",
                          borderColor: "#e9ecef",
                          fontFamily: "Open Sans, sans-serif",
                        },
                      }}
                      title={" "}
                    />
                  ) : null}
                </div>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    PropertySingle: (param) => dispatch(PropertySingle(param)),
    PaymentActions: (param) => dispatch(PaymentActions(param)),
    transactionActions: (param) => dispatch(transactionActions(param)),
    transactionDeleteActions: (param) =>
      dispatch(transactionDeleteActions(param)),

    //
  };
};

const mapStateToProps = (state) => ({
  transactionReducer: state.transactionReducer,
  UserReducer: state.UserReducer,
  PaymentReducer: state.PaymentReducer,
  currentUser: state.authReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(Transaction);

import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Badge,
  Form,
  Input,
  Container,
  Row,
  Col,
  CardImg,
  CardTitle,
  Modal,
  Alert,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  UncontrolledAlert,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import CurrencyFormat from "react-currency-format";
import swal from "sweetalert";
import { connect } from "react-redux";
import firebase from "firebase/app";
import Downshift from "downshift";

class AddTransaction extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startSavingLoader: false,
      subscriberRecord: [],
      formData: {
        pid: this.props.propertyid,
        pnm: this.props.propertyName,
        investmentDetails: "",
        phone: "",
        email: "",
        paid: 0,
        price: this.props.PropertyReducer.propertySingle?.price,
        property: this.props.PropertyReducer.propertySingle,
        discountedPrice: 0,
        availableUnits: 0,
        unitPaidFor: 0,
        investmentLength: "",
        billingName: "",
        unm: "",
        ref: "",
        payment_type: "",
        createdDate: firebase.firestore.FieldValue.serverTimestamp(),
      },
      defaultValue: {
        pid: this.props.propertyid,
        returnPerAnnualPercentage: 0,
        discountedPrice: 0,
        name: this.props.propertyName,
        projectedValue: 0,
        guaranteedCapitalGain: 0,
        projectedValueCapitalGain: 0,
        availableUnits: 0,
        numberOfInvestors: 0,
        totalEarning: 0,
        createdate: firebase.firestore.FieldValue.serverTimestamp(),
        createdby: this.props.currentUser.currentUserEmail,
      },
      SelectedYear: {
        pid: this.props.propertyid,
        returnPerAnnualPercentage: 0,
        discountedPrice: 0,
        name: this.props.propertyName,
        projectedValue: 0,
        guaranteedCapitalGain: 0,
        projectedValueCapitalGain: 0,
        availableUnits: 0,
        numberOfInvestors: 0,
        totalEarning: 0,
      },
    };
  }

  componentDidMount() {
    if (Object.keys(this.props.UserReducer.UseractionsData).length > 0) {
      var subscriberRecord = [];
      this.props.UserReducer.UseractionsData.forEach((doc) => {
        subscriberRecord.push({
          id: doc.id,
          email: doc.email,
          name: doc.firstName + " " + doc.lastName,
          firstName: doc.firstName,
          lastName: doc.lastName,
          phone: doc.phone,
        });
      });
      this.updateFormData("ref", this.randomString(10, "#aA"));
      this.setState({ subscriberRecord: subscriberRecord });
    }
  }

  randomString(length, chars) {
    var mask = "";
    if (chars.indexOf("a") > -1) mask += "abcdefghijklmnopqrstuvwxyz";
    if (chars.indexOf("A") > -1) mask += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    if (chars.indexOf("#") > -1) mask += "0123456789";
    if (chars.indexOf("!") > -1) mask += "~`!@#$%^&*()_+-={}[]:\";'<>?,./|\\";
    var result = "";
    for (var i = length; i > 0; --i)
      result += mask[Math.round(Math.random() * (mask.length - 1))];
    return result.toUpperCase();
  }

  toggleFI_By_YEAR_AND_ID = (pid, year) => {
    // console.log(year + "-" + pid);

    /* 
     let data = this.state.formData;
    let field = "investmentLength";
    let value = year;
    data[field] = parseInt(value);
    this.setState({ formData: data });
     */
    this.updateFormData("investmentLength", year);

    if (year == 2) {
      let data = this.props?.FI_Reducer?.propFI_2Y.find(
        (data, i) => data.pid === pid
      );
      //console.log(data);

      if (data === undefined) {
        this.setState({ SelectedYear: this.state.defaultValue, isEmpty: true });
      } else {
        this.updateFormData("discountedPrice", data.discountedPrice);
        this.updateFormData("availableUnits", data.availableUnits);

        this.setState({ SelectedYear: data, isEmpty: false });
      }
    } else if (year == 4) {
      let data = this.props?.FI_Reducer?.propFI_4Y.find(
        (data, i) => data.pid === pid
      );

      if (data === undefined) {
        this.setState({ SelectedYear: this.state.defaultValue, isEmpty: true });
      } else {
        this.updateFormData("discountedPrice", data.discountedPrice);
        this.updateFormData("availableUnits", data.availableUnits);
        this.setState({ SelectedYear: data, isEmpty: false });
      }
    }
  };

  updateFormData = (field, value) => {
    let data = this.state.formData;
    data[field] =
      field == "paid" ||
      field == "price" ||
      field == "discountedPrice" ||
      field == "phone"
        ? parseInt(value)
        : value;
    // console.log(value);
    this.setState({ formData: data });
  };

  render() {
    // console.log(this.state.subscriberRecord);
    return (
      <Modal
        id="model_FI_edit"
        // size="lg"
        style={{ minWidth: 60 + "%" }}
        className="modal-dialog-centered"
        isOpen={this.props.modalState}
      >
        <div className="modal-header">
          <h5
            className="modal-title"
            id="_"
            style={{ textTransform: "uppercase" }}
          >
            ADD TRANSACTION TO {this.props.propertyName} FRACTIONAL INVESTMENT
          </h5>

          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              this.props.toggleModal();
            }}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>

        <div className="modal-body">
          <Row>
            <Col lg="12">
              <div className="pl-lg-4 px-10">
                <Form>
                  <UncontrolledAlert
                    id="validate_form_msg2"
                    className=" alert-warning"
                    style={{ display: "none", marginTop: 15 }}
                    fade={false}
                  >
                    <span className="alert-inner--icon">
                      <i className="ni ni-like-2" />
                    </span>{" "}
                    <span className="alert-inner--text">
                      <strong>Validation!</strong> Some important fields are not
                      filled out properly
                    </span>
                  </UncontrolledAlert>
                  <Row>
                    <Col lg="6">
                      <FormGroup id="refD">
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          Payment Reference
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="ref"
                          name="ref"
                          disabled
                          placeholder="HYIU87F0R"
                          onChange={(e) =>
                            this.updateFormData(e.target.name, e.target.value)
                          }
                          value={this.state.formData?.ref}
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup id="billingNameD">
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          Customer Name
                        </label>

                        <Downshift
                          class="form-control-alternative"
                          onChange={(selection) => {
                            this.updateFormData("email", selection.email);
                            this.updateFormData("phone", selection.phone);
                            this.updateFormData("billingName", selection.name);
                            this.updateFormData("unm", selection.name);

                            //

                            // console.log(selection);
                          }}
                          itemToString={(item) => (item ? item.name : "")}
                        >
                          {({
                            getInputProps,
                            getItemProps,
                            getLabelProps,
                            getMenuProps,
                            isOpen,
                            inputValue,
                            highlightedIndex,
                            selectedItem,
                            getRootProps,
                          }) => (
                            <div>
                              <div
                                style={{ display: "inline-block" }}
                                {...getRootProps(
                                  {},
                                  { suppressRefError: true }
                                )}
                              >
                                <input {...getInputProps()} />
                              </div>
                              <ul
                                className="dropdown-content"
                                {...getMenuProps()}
                              >
                                {isOpen
                                  ? this.state.subscriberRecord
                                      .filter(
                                        (item) =>
                                          !inputValue ||
                                          item.name.includes(inputValue)
                                      )
                                      .map((item, index) => (
                                        <li
                                          {...getItemProps({
                                            key: item.id,
                                            index,
                                            item,
                                            style: {
                                              backgroundColor:
                                                highlightedIndex === index
                                                  ? "lightgray"
                                                  : "white",
                                              fontWeight:
                                                selectedItem === item
                                                  ? "bold"
                                                  : "normal",
                                            },
                                          })}
                                        >
                                          {item.name}
                                        </li>
                                      ))
                                  : null}
                              </ul>
                            </div>
                          )}
                        </Downshift>

                        {/*  <Input
                          className="form-control-alternative"
                          id="billingName"
                          name="billingName"
                          onChange={(e) =>
                            this.updateFormData(e.target.name, e.target.value)
                          }
                          placeholder="Customer Name"
                          value={this.state.formData?.billingName}
                          type="text"
                        /> */}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <FormGroup id="emailD">
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          Customer Email
                        </label>

                        <Input
                          className="form-control-alternative"
                          id="email"
                          disabled
                          name="email"
                          onChange={(e) =>
                            this.updateFormData(e.target.name, e.target.value)
                          }
                          placeholder="Customer Email"
                          value={this.state.formData?.email}
                          type="email"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup id="phoneD">
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          Customer Phone
                        </label>

                        <Input
                          className="form-control-alternative"
                          id="phone"
                          disabled
                          name="phone"
                          onChange={(e) =>
                            this.updateFormData(e.target.name, e.target.value)
                          }
                          placeholder="Customer Phone"
                          value={this.state.formData?.phone}
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <FormGroup id="investmentLengthD">
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          Investment Length
                        </label>

                        <select
                          onChange={(e) => {
                            // this.updateFormData(e.target.name, e.target.value);

                            this.toggleFI_By_YEAR_AND_ID(
                              this.props.propertyid,
                              e.target.value
                            );
                          }}
                          className="form-control-alternative form-control"
                          id="investmentLength"
                          name="investmentLength"
                        >
                          <option value="">-- Select number of years --</option>
                          <option value={2}>2 Years</option>
                          <option value={4}>4 Years</option>
                        </select>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup id="availableUnitsD">
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          Available Unit(s)
                        </label>

                        <Input
                          className="form-control-alternative"
                          id="availableUnits"
                          name="availableUnits"
                          /*  onChange={(e) =>
                            this.updateFormData(e.target.name, e.target.value)
                          } */
                          disabled
                          placeholder=""
                          value={this.state.formData?.availableUnits}
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <FormGroup id="propertyNameD">
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          Property
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="propertyName"
                          name="propertyName"
                          disabled
                          placeholder="0"
                          value={this.props.propertyName}
                          type="text"
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="3">
                      <FormGroup id="paidD">
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          Paid
                        </label>

                        <CurrencyFormat
                          id="paid"
                          name="paid"
                          thousandSeparator={true}
                          prefix={"₦"}
                          className="form-control-alternative form-control"
                          value={this.state.formData?.paid}
                          onValueChange={(values) =>
                            this.updateFormData("paid", values.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="3">
                      <FormGroup id="payment_typeD">
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          Payment Type
                        </label>

                        <select
                          onChange={(e) => {
                            this.updateFormData("payment_type", e.target.value);
                          }}
                          className="form-control-alternative form-control"
                          id="payment_type"
                          name="payment_type"
                        >
                          <option value="">-- Select Type --</option>
                          <option value="part payment">Part Payment</option>
                          <option value="full payment">Full Payment</option>
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <FormGroup id="unitPaidForD">
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          Unit paid for
                        </label>

                        <Input
                          className="form-control-alternative"
                          id="unitPaidFor"
                          name="unitPaidFor"
                          placeholder="0"
                          value={this.state?.formData?.unitPaidFor}
                          onChange={(e) =>
                            this.updateFormData(e.target.name, e.target.value)
                          }
                          type="number"
                        />
                        <code
                          id="unitPaidForD_error"
                          style={{ fontSize: 10, display: "none" }}
                        >
                          Can not be greater than{" "}
                          {this.state.formData?.availableUnits}
                        </code>

                        {/*  <CurrencyFormat
                          id="price"
                          name="price"
                          thousandSeparator={true}
                          prefix={"₦"}
                          className="form-control-alternative form-control"
                          value={this.state.formData?.price}
                          onValueChange={(values) =>
                            this.updateFormData("price", values.value)
                          }
                        /> */}
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup id="discountedPriceD">
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          Discounted Price
                        </label>

                        <CurrencyFormat
                          id="discountedPrice"
                          name="discountedPrice"
                          thousandSeparator={true}
                          disabled={true}
                          prefix={"₦"}
                          className="form-control-alternative form-control"
                          value={this.state.formData?.discountedPrice}
                          onValueChange={(values) =>
                            this.updateFormData("discountedPrice", values.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <FormGroup id="investmentDetailsD">
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          Information of note
                        </label>

                        <Input
                          className="form-control-alternative"
                          id="investmentDetails"
                          name="investmentDetails"
                          onChange={(e) =>
                            this.updateFormData(e.target.name, e.target.value)
                          }
                          placeholder=""
                          value={this.state.formData?.investmentDetails}
                          type="textarea"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <Button
                        className="float-left"
                        style={{ marginLeft: 20 }}
                        //color="default"
                        href="#stow"
                        onClick={(e) => {
                          e.preventDefault();

                          this.props.toggleModal();
                        }}
                      >
                        Cancel
                      </Button>
                    </Col>

                    <Col>
                      <Button
                        className="float-right"
                        style={{ marginRight: 5 }}
                        color="success"
                        href="#stow"
                        type="submit"
                        onClick={async (e) => {
                          e.preventDefault();

                          document
                            .getElementById("investmentDetailsD")
                            .classList.remove("has-danger");
                          document
                            .getElementById("phoneD")
                            .classList.remove("has-danger");
                          document
                            .getElementById("emailD")
                            .classList.remove("has-danger");
                          document
                            .getElementById("paidD")
                            .classList.remove("has-danger");
                          /*  document
                            .getElementById("priceD")
                            .classList.remove("has-danger"); */
                          document
                            .getElementById("discountedPriceD")
                            .classList.remove("has-danger");
                          document
                            .getElementById("investmentLengthD")
                            .classList.remove("has-danger");
                          document
                            .getElementById("billingNameD")
                            .classList.remove("has-danger");
                          document
                            .getElementById("refD")
                            .classList.remove("has-danger");

                          document
                            .getElementById("unitPaidForD")
                            .classList.remove("has-danger");

                          document
                            .getElementById("availableUnitsD")
                            .classList.remove("has-danger");
                          document
                            .getElementById("payment_typeD")
                            .classList.remove("has-danger");
                          document.getElementById(
                            "unitPaidForD_error"
                          ).style.display = "none";

                          //

                          //

                          //////////////////////////////////////////////////

                          if (document.getElementById("ref").value === "") {
                            document
                              .getElementById("refD")
                              .classList.add("has-danger");
                            document.getElementById("ref").focus();
                            document.getElementById(
                              "validate_form_msg2"
                            ).style.display = "block";
                          } else if (this.state.formData?.billingName === "") {
                            document
                              .getElementById("billingNameD")
                              .classList.add("has-danger");
                            //  document.getElementById("billingName").focus();
                            document.getElementById(
                              "validate_form_msg2"
                            ).style.display = "block";
                          } else if (
                            document.getElementById("email").value === ""
                          ) {
                            document
                              .getElementById("emailD")
                              .classList.add("has-danger");
                            document.getElementById("email").focus();
                            document.getElementById(
                              "validate_form_msg2"
                            ).style.display = "block";
                          } else if (
                            document.getElementById("phone").value === ""
                          ) {
                            document
                              .getElementById("phoneD")
                              .classList.add("has-danger");
                            document.getElementById("phone").focus();
                            document.getElementById(
                              "validate_form_msg2"
                            ).style.display = "block";
                          } else if (
                            document.getElementById("investmentLength")
                              .value === ""
                          ) {
                            document
                              .getElementById("investmentLengthD")
                              .classList.add("has-danger");
                            document.getElementById("investmentLength").focus();
                            document.getElementById(
                              "validate_form_msg2"
                            ).style.display = "block";
                          } else if (
                            this.state.formData?.paid === "" ||
                            this.state.formData?.paid == 0
                          ) {
                            document
                              .getElementById("paidD")
                              .classList.add("has-danger");
                            document.getElementById("paid").focus();
                            document.getElementById(
                              "validate_form_msg2"
                            ).style.display = "block";
                          } else if (this.state.formData?.payment_type === "") {
                            document
                              .getElementById("payment_typeD")
                              .classList.add("has-danger");
                            document.getElementById("payment_type").focus();
                            document.getElementById(
                              "validate_form_msg2"
                            ).style.display = "block";
                          } else if (
                            this.state.formData?.discountedPrice === "" ||
                            this.state.formData?.discountedPrice == 0
                          ) {
                            document
                              .getElementById("discountedPriceD")
                              .classList.add("has-danger");
                            // document.getElementById("discountedPrice").focus();
                            document.getElementById(
                              "validate_form_msg2"
                            ).style.display = "block";
                          } else if (
                            this.state.formData?.availableUnits === "" ||
                            this.state.formData?.availableUnits == 0
                          ) {
                            document
                              .getElementById("availableUnitsD")
                              .classList.add("has-danger");
                            // document.getElementById("availableUnits").focus();
                            document.getElementById(
                              "validate_form_msg2"
                            ).style.display = "block";
                          } else if (
                            this.state.formData?.unitPaidFor === "" ||
                            this.state.formData?.unitPaidFor == 0
                          ) {
                            document
                              .getElementById("unitPaidForD")
                              .classList.add("has-danger");
                            // document.getElementById("unitPaidFor").focus();
                            document.getElementById(
                              "validate_form_msg2"
                            ).style.display = "block";
                          } else if (
                            parseInt(this.state.formData?.unitPaidFor) >
                            parseInt(this.state.formData?.availableUnits)
                          ) {
                            document.getElementById(
                              "unitPaidForD_error"
                            ).style.display = "block";
                            document
                              .getElementById("unitPaidForD")
                              .classList.add("has-danger");
                            // document.getElementById("unitPaidFor").focus();

                            let error =
                              document.getElementById("validate_form_msg2");
                            if (error) {
                              error.style.display = "block";
                            }
                            /*  document.getElementById(
                                "validate_form_msg2"
                              ).style.display = "block"; */
                          } else if (
                            document.getElementById("investmentDetails")
                              .value === ""
                          ) {
                            document
                              .getElementById("investmentDetailsD")
                              .classList.add("has-danger");
                            document
                              .getElementById("investmentDetails")
                              .focus();
                            document.getElementById(
                              "validate_form_msg2"
                            ).style.display = "block";
                          }

                          //availableUnitsD
                          else {
                            const errorBox =
                              document.getElementById("validate_form_msg2");

                            if (errorBox) {
                              errorBox.style.display = "none";
                            }

                            //  console.log(this.state.formData);
                            this.setState({
                              startSavingLoader: true,
                            });

                            var home = this;

                            await firebase
                              .firestore()
                              .collection("fractionalTransactions")
                              .add(this.state.formData)
                              .then((data) => {
                                console.log(data);
                                home.setState({
                                  startSavingLoader: false,
                                });

                                home.props.toggleModal();

                                swal({
                                  title: "Manual Payment Successful",
                                  text:
                                    "You have successfully added a payment for " +
                                    this.state.formData.billingName +
                                    ", for a " +
                                    this.state.formData.investmentLength +
                                    " years Fractional Investment (" +
                                    this.props.propertyName +
                                    ")",
                                  icon: "success",
                                  //buttons: false,
                                })
                                  .then(() => {
                                    document.location.reload();
                                  })
                                  .catch((error) => {
                                    console.log(error);
                                    home.setState({
                                      startSavingLoader: false,
                                    });
                                  });

                                //home.props.history.push("/admin/property");
                              });
                          }
                        }}
                      >
                        {this.state.startSavingLoader ? (
                          // <img src={require("assets/img/loader.gif")} />
                          <>
                            Saving... <i className="fa fa-spinner fa-spin"></i>
                          </>
                        ) : (
                          "Save Transaction"
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => ({
  UserReducer: state.UserReducer,
  currentUser: state.authReducer,
  PropertyReducer: state.PropertyReducer,
  FI_Reducer: state.FI_Reducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddTransaction);

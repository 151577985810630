import { isObject } from "lodash";
import isEmpty from "lodash/isEmpty";

const iniState = {
  //propertysData: {},
  groupRegLoading: true,
  groupRegError: true,
  groupRegErrorMsg: "",
  groupRegLoadingCompleted: false,
  groupRegCount: 0,
};

const GroupRegisterReducer = (state = iniState, action) => {
  switch (action.type) {
    case "GROUP_LOADING":
      return {
        ...state,
        groupRegLoading: action.propLoading,
        groupRegError: false,
        groupRegLoadingCompleted: false,
        //propErrorMsg:''
      };

    case "GROUP_SUCCESS":
      return {
        ...state,
        groupRegData: action.payload,
        groupRegError: false,
        groupRegLoadingCompleted: true,
        groupRegLoading: false,
      };

    case "GROUP_ERROR":
      return {
        ...state,
        groupRegError: true,
        groupRegLoadingCompleted: false,
        groupRegErrorMsg: action.payload,
        groupRegLoaded: false,
      };

    default:
      return state;
  }
};

export default GroupRegisterReducer;

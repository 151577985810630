
import React from "react";
// reactstrap components

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  Modal,
  Form,
  FormGroup,
  Input,
  Badge,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.js";
import moment from "moment";
import SelectSearch from "react-select-search";

import ReactDOM from "react-dom";
import MaterialTable, { MTableToolbar, MTableCell } from "material-table";
import {
  currencyFormat,
  dateFormater,
  UserRolePermission,
} from "../../utils/functions";

import firebase from "firebase/app";

//TransUserPayment
import TransUserPayment from "./functions/TransUserPayment";
import TransPaymentsMini from "./functions/TransPaymentsMini";

import {
  PropertyEdit,
  PropertySingle,
  propertyActions,
} from "./../../store/actions/propertyActions";
import {
  FI_2Y_Actions,
  FI_4Y_Actions,
  FI_TR_Actions,
} from "store/actions/fractionalnvestmentAction";

import AddNewProperty from "./functions/AddNewProperty";

import { connect } from "react-redux";
import CountUp from "react-countup";
import { isEmpty } from "lodash";
import swal from "sweetalert";
import { createThis } from "typescript";
import { DialogContent } from "@material-ui/core";

class Property extends React.Component {
  constructor(props) {
    super(props);
    //this.getSelectedTrans = this.getSelectedTrans.bind(this)

    this.state = {
      propertyRecord: [],
      showAddNewPropertyModal: false,
    };
  }

  GetProperty(e, ID) {
    let array_ = this.props.PropertyReducer.propertyData;
    //let id =  e.target.getAttribute('data-id')
    let id = ID;
    //alert(id)

    for (var i = 0, len = array_.length; i < len; i++) {
      if (array_[i].id === id) {
       // console.log(array_[i]);

        if (array_[i]) {
          this.props.PropertySingle(array_[i]);
          this.props.PropertyEdit(false);
          this.props.history.push("/admin/single/property");
        } else {
          alert("PROPRTY DOES NOT EXIST OR HAVE BEEN DELETED");
        }
      }
    }
  }

  componentDidMount() {
    this.props.propertyActions();

    this.props.FI_2Y_Actions();
    this.props.FI_4Y_Actions();
    this.props.FI_TR_Actions();

    //alert(Object.keys(this.props.PropertyReducer.propertyData).length)

    if (Object.keys(this.props.PropertyReducer.propertyData).length > 0) {
      var propertyRecord = [];
      this.props.PropertyReducer.propertyData.forEach((doc) => {
        propertyRecord.push({
          //...doc
          id: doc.id,
          name: doc.name,
          title: doc.title,
          price: doc.price,
          installmentPrice: doc.installmentPrice,
          location: doc.location,
          city: doc.city,
          logo: doc.logo,
          active: doc.active,
          beds: doc.beds,
          type: doc.type,
          createdate: doc.createdate,
          featured: doc.featured,
          completion_date: doc.hasOwnProperty("doc.completion_date")
            ? doc.completion_date
            : null,
        });
      });

      this.setState({ propertyRecord: propertyRecord });
      //console.log(JSON.stringify(propertyRecord))
    }
  }

  formatJSON(data) {
    var result = [];
    if (!Array.isArray(data)) {
      //if is not an array
      result.push(data); // push it as the first item of an array
    } else {
      result = data;
    }

    return result;
  }

  render() {
    const PropertyReducer = this.props.PropertyReducer;

    return (
      <>
        <Header />
        {/* Page content */}

        <Modal
          id="model_property_new"
          className="modal-dialog-centered"
          isOpen={this.state.showAddNewPropertyModal}
          style={{ maxWidth: 80 + "%" }}
          // toggle={() => this.toggleModal("exampleModal")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              ADD NEW PROPERTY
            </h5>

            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ showAddNewPropertyModal: false });
                // document.location.reload()
              }}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>

          <div className="modal-body">
            <div class="container">
              <AddNewProperty />
            </div>
          </div>
        </Modal>
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow border-0">
                <div style={{ maxWidth: "100%", borderRadius: 20 }}>
                  <MaterialTable
                    columns={[
                      {
                        title: "Logo",
                        field: "logo",
                        export: false,
                        render: (rowData) => {
                          return rowData.hasOwnProperty("logo") ? (
                            <img
                              style={{ width: 40 }}
                              className="rounded-circle"
                              /* src={require("assets/img/theme/team-4-800x800.jpg")} */
                              src={rowData.logo.src}
                            />
                          ) : null;
                        },
                      },
                      {
                        title: "Name",
                        field: "name",
                        /* defaultGroupOrder: 0 , */ render: (rowData) => {
                          return rowData.name;
                        },
                      },
                      {
                        title: "Price",
                        field: "price",
                        render: (rowData) => {
                          if (rowData.price) {
                            return (
                              <div>
                                {currencyFormat(parseInt(rowData.price))}
                              </div>
                            );
                          } else {
                            return "₦ 0.00";
                          }
                        },
                      },
                      {
                        title: "Installmental Price",
                        field: "installmentPrice",
                        render: (rowData) => {
                          if (rowData.installmentPrice) {
                            return (
                              <div>
                                {currencyFormat(parseInt(rowData.installmentPrice))}
                              </div>
                            );
                          } else {
                            return "₦ 0.00";
                          }
                        },
                      },
                      //{ title: 'Product', field: 'productid',  },
                      { title: "Beds", field: "beds" },
                      { title: "City", field: "city" },

                      { title: "Location", field: "location" },
                      { title: "Type", field: "type" },
                      {
                        title: "Status",
                        field: "paymentValid",
                        export: false,
                        render: (rowData) => {
                          return rowData.active ? (
                            <Badge
                              color=""
                              style={{ fontSize: 10, color: "#000" }}
                              className="badge-dot"
                            >
                              <i className="bg-success" />
                              Active
                            </Badge>
                          ) : (
                            <Badge
                              color=""
                              style={{ fontSize: 10, color: "#ccc" }}
                              className="badge-dot"
                            >
                              <i className="bg-danger" />
                              Disabled
                            </Badge>
                          );
                        },
                      },

                      {
                        title: "featured",
                        field: "featured",
                        render: (rowData) => {
                          return (
                            <Button
                              onClick={async (e) => {
                                let data = e.target.getAttribute("data-value");
                                let id = e.target.getAttribute("data-id");
                                let name = e.target.getAttribute("data-name");
                                // alert(data)

                                const featured = {
                                  featured: data == "warning" ? false : true,
                                };

                                if (data == "warning") {
                                  e.target.classList.remove("btn-warning");
                                  e.target.classList.add("btn-secondary");
                                } else {
                                  e.target.classList.remove("btn-secondary");
                                  e.target.classList.add("btn-warning");
                                }

                                e.target.setAttribute(
                                  "data-value",
                                  data == "warning" ? "secondary" : "warning"
                                );

                                await firebase
                                  .firestore()
                                  .collection("property")
                                  .doc(id)
                                  .update(featured)
                                  .then(async () => {
                                    swal(
                                      "Updated",
                                      "Property " + name + " updated ",
                                      "success"
                                    );
                                    document.location.reload();
                                  }).catch((error) => {
                                    console.log(error)
                                  })
                              }}
                              className="btn-icon "
                              data-id={rowData.id}
                              data-name={rowData.name}
                              data-value={
                                rowData.featured ? "warning" : "secondary"
                              }
                              size="sm"
                              color={rowData.featured ? "warning" : "secondary"}
                              type="button"
                            >
                              &nbsp;&nbsp;&nbsp;
                            </Button>
                          );
                        },
                      },

                      /* { title: 'Created Date', field: 'createdate', render: rowData=>{
                     return dateFormater(rowData.createdate)+JSON.stringify(rowData.createdate)
                   }}, */

                      /* { title: 'P-ID', field: 'id', render: rowData=>{
                    return rowData.id//dateFormater(rowData.createdate)
                  }}, */
                      //
                    ]}
                    actions={[
                      {
                        icon: () => (
                          <Button
                            color="primary"
                            onClick={() => {
                              //if(!(UserRolePermission(this.props.currentUser.UserData.role,'add_prop')))
                              //{
                              this.setState({
                                showAddNewPropertyModal: true,
                              });
                              //}
                            }}
                            disabled={UserRolePermission(
                              this.props.currentUser.UserData.role,
                              "add_prop"
                            )}
                            href="#"
                            style={{
                              fontSize: 20,
                              paddingBottom: 0,
                              display: UserRolePermission(
                                this.props.currentUser.UserData.role,
                                "add_prop"
                              )
                                ? "none"
                                : "block",
                            }}
                            size="sm"
                          >
                            <i className="ni ni-fat-add"></i>
                          </Button>
                        ),
                        tooltip: "Add Property",
                        isFreeAction: true,
                        //onClick: (event) => this.setState({showPaymentPage:true,customerName:null,TransID:null,dataLoading:true})
                      },
                      {
                        icon: (row) => (
                          <Button
                            color="primary"
                            href="#"
                            style={{ fontSize: 9 }}
                            size="sm"
                          >
                            <i className="fa fa-eye" />
                          </Button>
                        ),

                        tooltip: "View Property",

                        onClick: (event, row) => {
                          //alert(JSON.stringify(row))
                          this.GetProperty(event, row.id);
                        },
                      },
                    ]}
                    data={this.state.propertyRecord}
                    options={{
                      exportButton: true,
                      exportFileName: "Property_Data_" + new Date(),
                      //grouping: true,
                      pageSize: 10,
                      pageSizeOptions: [
                        5, 10, 20, 30, 50, 75, 100, 200, 500, 1000, 2000, 3000,
                        5000, 8000, 10000,
                      ],
                      toolbar: true,
                      // paging: true,

                      rowStyle: {
                        paddingLeft: "1.5rem",
                        paddingRight: "1.5rem",
                        verticalAlign: "middle",

                        fontSize: "0.8125rem",
                        whiteSpace: "nowrap",
                        padding: "1rem",
                        borderTop: "1px solid #e9ecef",
                        fontFamily: "Open Sans, sans-serif",
                      },

                      headerStyle: {
                        paddingTop: "0.75rem",
                        paddingBottom: "0.75rem",
                        fontSize: "0.65rem",
                        textTransform: "uppercase",
                        letterPpacing: "1px",
                        borderBottom: "1px solid #e9ecef",
                        fontWeight: "bolder",
                        paddingLeft: "1.5rem",
                        paddingRight: "1.5rem",
                        backgroundColor: "#f6f9fc",
                        color: "#8898aa",
                        verticalAlign: "middle",
                        borderColor: "#e9ecef",
                        fontFamily: "Open Sans, sans-serif",
                      },
                    }}
                    title={" "}
                    components={{
                      Toolbar: (props) => (
                        <p className="h3" style={{ padding: 10 }}>
                          <MTableToolbar {...props} />
                          <div style={{ marginTop: -40 }}>
                            {"Total"}{" "}
                            <CountUp
                              end={
                                Object.keys(
                                  this.props.PropertyReducer.propertyData
                                ).length
                              }
                            />
                          </div>
                        </p>
                      ),

                      Cell: (props) => {
                        if (props.columnDef.field == "tid") {
                          //return <MTableCell {...props} id={'cell_'+(props.rowData.hasOwnProperty('r_txnref')?props.rowData.r_txnref:props.rowData.tid)} />
                          return <MTableCell {...props} />;
                        } else {
                          return (
                            <MTableCell
                              {...props}
                              data={JSON.stringify(props.columnDef)}
                            />
                          );
                        }
                      },
                    }}
                  />
                </div>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    PropertySingle: (param) => dispatch(PropertySingle(param)),
    PropertyEdit: (param) => dispatch(PropertyEdit(param)),
    propertyActions: (param) => dispatch(propertyActions(param)),

    FI_2Y_Actions: (param) => dispatch(FI_2Y_Actions(param)),
    FI_4Y_Actions: (param) => dispatch(FI_4Y_Actions(param)),
    FI_TR_Actions: (param) => dispatch(FI_TR_Actions(param)),

    //
  };
};

const mapStateToProps = (state) => ({
  UserReducer: state.UserReducer,
  PropertyReducer: state.PropertyReducer,
  currentUser: state.authReducer,
  FI_Reducer: state.FI_Reducer,
  //PropertyReducer: state.PropertyReducer,
  //PropertyReducer: state.PropertyReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(Property);

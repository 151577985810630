import React from "react";
// reactstrap components

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  Modal,
  Form,
  FormGroup,
  Input,
  Badge,
} from "reactstrap";

// core components
import Header from "components/Headers/Header";
import moment from "moment";
import SelectSearch from "react-select-search";

import ReactDOM from "react-dom";
import MaterialTable, { MTableToolbar, MTableCell } from "material-table";

import firebase from "firebase/app";

import { connect } from "react-redux";
import CountUp from "react-countup";
import { isEmpty } from "lodash";
import swal from "sweetalert";
import { createThis } from "typescript";
import { DialogContent } from "@material-ui/core";

import { isObject } from "lodash";
import { dateFormater, currencyFormat } from "utils/functions";

import { ActivationAction } from "store/actions/coopAction";

class Activation extends React.Component {
  state = {
    //showRegisterModal: false,

    activationReducer: [],
  };
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.ActivationAction();
  }

  render() {
    const coopActData = this.props.CoopOtherReducers?.coopActData;
    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow border-0">
                <div
                  style={{ maxWidth: "100%", borderRadius: 20, minHeight: 550 }}
                >
                  <MaterialTable
                    columns={[
                      {
                        title: "AccountID",
                        field: "account_id",
                        render: (rowData) => {
                          return (
                            <>
                              <div> {rowData.account_id}</div>
                              <code style={{ fontSize: 9 }}>
                                {rowData.uid}
                              </code>
                            </>
                          );
                        },
                      },

                      {
                        title: "Email",
                        field: "email",
                        /* defaultGroupOrder: 0 , */ render: (rowData) => {
                          return rowData.email;
                        },
                      },

                      {
                        title: "amount",
                        field: "amount",
                        render: (rowData) => {
                          return currencyFormat(rowData.amount);
                        },
                      },

                      {
                        title: "Card Type",
                        field: "card_type",
                        /* defaultGroupOrder: 0 , */ render: (rowData) => {
                          return rowData.card_type;
                        },
                      },
                      {
                        title: "Last4 Digit",
                        field: "last4",
                        /* defaultGroupOrder: 0 , */ render: (rowData) => {
                          return rowData.last4;
                        },
                      },

                      {
                        title: "Payment Ref",
                        field: "reference",
                        /* defaultGroupOrder: 0 , */ render: (rowData) => {
                          return rowData.reference;
                        },
                      },

                      {
                        title: "Date",
                        field: "date_time",
                        defaultSort: "desc",
                        render: (rowData) => {
                          return isEmpty(rowData.date_time)
                            ? null
                            : dateFormater(rowData.date_time);
                        },
                      },

                      //
                    ]}
                    options={{
                      exportButton: true,
                      exportFileName: "Activation_Data_" + new Date(),
                      //grouping: true,
                      pageSize: 10,
                      pageSizeOptions: [
                        5, 10, 20, 30, 50, 75, 100, 200, 500, 1000, 2000, 3000,
                        5000, 8000, 10000,
                      ],
                      toolbar: true,
                      // paging: true,

                      rowStyle: {
                        paddingLeft: "1.5rem",
                        paddingRight: "1.5rem",
                        verticalAlign: "middle",

                        fontSize: "0.8125rem",
                        whiteSpace: "nowrap",
                        padding: "1rem",
                        borderTop: "1px solid #e9ecef",
                        fontFamily: "Open Sans, sans-serif",
                      },

                      headerStyle: {
                        paddingTop: "0.75rem",
                        paddingBottom: "0.75rem",
                        fontSize: "0.65rem",
                        textTransform: "uppercase",
                        letterPpacing: "1px",
                        borderBottom: "1px solid #e9ecef",
                        fontWeight: "bolder",
                        paddingLeft: "1.5rem",
                        paddingRight: "1.5rem",
                        backgroundColor: "#f6f9fc",
                        color: "#8898aa",
                        verticalAlign: "middle",
                        borderColor: "#e9ecef",
                        fontFamily: "Open Sans, sans-serif",
                      },
                    }}
                    actions={
                      [
                        /*  {
                        icon: (row) => (
                          <Button
                            color="warning"
                            href="#"
                            style={{ fontSize: 9 }}
                            size="sm"
                            onClick={(e) => {
                              this.setState({
                                showRegisterModal: true,
                              });
                            }}
                          >
                            <i className="fa fa-eye" />
                          </Button>
                        ),

                        tooltip: "View User",

                        onClick: (event, row) => {
                          //  alert(JSON.stringify(row.uid));
                          // this.GetRegisterationData(event, row.uid);
                        },
                      }, */
                      ]
                    }
                    data={coopActData}
                    title={" "}
                    components={{
                      Toolbar: (props) => (
                        <p className="h3" style={{ padding: 10 }}>
                          <MTableToolbar {...props} />
                          <div style={{ marginTop: -40 }}>
                            {"Total"}{" "}
                            <CountUp
                              end={Object.keys(coopActData || []).length}
                            />
                          </div>
                        </p>
                      ),
                    }}
                  />
                </div>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ActivationAction: (param) => dispatch(ActivationAction(param)),
  };
};

const mapStateToProps = (state) => ({
  CoopOtherReducers: state.CoopOtherReducers,
  currentUser: state.authReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(Activation);

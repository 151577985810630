import React from "react";
// node.js library that concatenates classes (strings)



// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  Modal,
  Form,
  FormGroup,
  Input,
  Badge,
  CardImg,
  Alert 
} from "reactstrap";

import Slider from "nouislider";


//import FileUploader from "react-firebase-file-uploader";

import   firebase from "firebase/app";
import  'firebase/storage'; 

import   ContentLoader,{BulletList,Code  } from 'react-content-loader'

import api from "../../../utils/config";
import { connect } from "react-redux";
import { currencyFormat,dateFormater } from '../../../utils/functions';
import { PropertySingle } from "./../../../store/actions/propertyActions";


import { isEmpty } from "lodash";
import swal from "sweetalert";
import Compress from "react-image-file-resizer";



class UploadPhoto extends React.Component {

constructor(props)
{
  super(props);

  const { data,pid } = props;

  
  //alert(JSON.stringify(data))

  this.extractFileExtension = this.extractFileExtension.bind(this)
  this.uuidv4 = this.uuidv4.bind(this)

  this.state = {
    filenames: null,
    downloadURLs: null,
    isUploading: false,
    isUploadingError:false,
    uploadProgress: 0,
    photoObject:(data),
    pid :pid,
    serverMessage:null,
    mobileUri:null,
    mobileFinalUrl:null,
  };

}
   
componentDidMount  (props)
{

    



        if (!firebase.apps.length)
        {


            firebase.initializeApp({
                apiKey: api.REACT_APP_FIREBASE_APP_ID,
                authDomain: api.REACT_APP_FIREBASE_DOMAIN,
                databaseURL: api.REACT_APP_FIREBASE_DATABASE,
                projectId: api.REACT_APP_FIREBASE_PROJECT_ID,
                storageBucket: api.REACT_APP_FIREBASE_STORAGE_BUCKET,
                messagingSenderId: api.REACT_APP_FIREBASE_SENDER_ID,
                appId: api.REACT_APP_FIREBASE_APP_ID,
                measurementId: api.REACT_APP_FIREBASE_MEASUREMENT_ID,
            });

            

        }


        

}




  

 extractFileExtension =(filename)=>{
   //alert(filename)
  var ext = /(?:\.([^.]+))?$/.exec(filename);
  if (ext != null && ext[0] != null) {
    return ext[0];
  } else {
    return '';
  } 
}


   uuidv4 =()=> {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }


 handleUpload =(e)=>{

  //console.log(e.target.files[0])
  const image = e.target.files[0]
  //console.log(JSON.stringify(this.state.photoObject))

  
  Compress.imageFileResizer(
    image, // the file from input
    500, // width
    'auto', // height
    "JPEG", // compress format WEBP, JPEG, PNG
    100, // quality
    0, // rotation
    (uri) => {
      //console.log(uri);
      this.setState({
        mobileUri:uri
      },()=>{

        //document.location.href=this.state.mobileUri
        var strImage0 = this.state.mobileUri.split(',')[0];
        var strImage1 = this.state.mobileUri.split(',')[1];
        var homeMobile = this
        
        //alert(strImage)
       // console.log(this.state.mobileUri)
        //console.log(strImage0)
        //console.log(strImage1)


          var message = strImage1;
         

          const fileName = this.uuidv4()+this.extractFileExtension(image.name); 
          const storage = firebase.storage();
          //const Base64Photo = storage.ref('photos_mobile/').child(fileName).putString(message, 'base64',{contentType:'image/jpg'});
          const Base64Photo = storage.ref('photosMobile/'+fileName).putString(message, 'base64',{contentType:'image/jpg'});



          this.setState({
            isUploading:true,
            serverMessage:'Updating Mobile Photo...'
          })
          Base64Photo.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'

            
            function(snapshot){

              var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

              homeMobile.setState({
                        serverMessage:'Updating Mobile Photo '+ parseInt(progress)+'% ...'
                      });

                       //console.log('Upload is ' + progress + '% done');
                       
                      switch (snapshot.state) {
                        case firebase.storage.TaskState.PAUSED: // or 'paused'
                        // console.log('Upload is paused');

                        homeMobile.setState({
                            serverMessage:'Updating Web Photo Paused'
                          })

                          break;
                        case firebase.storage.TaskState.RUNNING: // or 'running'
                          //console.log('Upload is running :: '+progress);
                          

                          break;
                      }

            },
            error=>{
        
              swal("Error Updating Mobile Photo", 'Unable to Update Mobile Photo\n with id :: '+homeMobile.state.pid+' \n'+JSON.stringify(error), "danger")

            },
            ()=>{
              storage
              .ref("photosMobile")
              .child(fileName)
              .getDownloadURL()
              .then(url=>{

                //console.log(url)

                homeMobile.setState({
                  mobileFinalUrl:url
                })



  const fileName = this.uuidv4()+this.extractFileExtension(image.name); 
  const storage = firebase.storage();
  const uploadPhotoTask = storage.ref('photos/'+fileName).put(image);
  homeMobile.setState({
    filenames:fileName,
    isUploading:true,
    serverMessage:'Uploading Web Photo...'
  }) 




  uploadPhotoTask.on(
    "state_changed",
    
    function(snapshot){

      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

      homeMobile.setState({
                serverMessage:'Updating Web Photo '+ parseInt(progress)+'% ...'
              });

               //console.log('Upload is ' + progress + '% done');
               
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                // console.log('Upload is paused');

                homeMobile.setState({
                    serverMessage:'Updating Web Photo Paused'
                  })

                  break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                  console.log('Upload is running :: '+progress);
                  

                  break;
              }

    },
    error=>{

      homeMobile.setState({
        isUploadingError:true,
      })


      swal("Error Uploading Web Photo", 'Unable to upload Web Photo \n'+JSON.stringify(error), "danger")
    },
    ()=>{
      storage
      .ref("photos")
      .child(fileName)
      .getDownloadURL()
      .then(url=>{
    
        //console.log(url)
       // var home= this



        var newPhoto =
                      {
                        "src":url,
                        "mobile":homeMobile.state.mobileFinalUrl,
                        "photos":image.name
                      }
            
                      

                    
                      homeMobile.setState(prevState => ({
                      photoObject: [...prevState.photoObject, newPhoto],
                      serverMessage:'Web Photo Uploaded'
                      
                    }), async()=>
                    {
                      

                      const photo ={
                        photos:homeMobile.state.photoObject
                      }

                      homeMobile.setState({
                        serverMessage:'Updating Photo Data...'
                      })
                      //console.log(JSON.stringify(homeMobile.state.photoObject))
                               // console.log(photo)

                      await firebase.firestore()
                                  .collection("property")
                                  .doc(this.state.pid)
                                  .update(photo).then( async ()=>
                                    {
                                      const Property = await  firebase.firestore()
                                                              .collection("property")
                                                              .doc(homeMobile.state.pid).get()
                                                              

                                                              homeMobile.setState({
                                                                serverMessage:'Data Updated...',
                                                                isUploading:false,
                                                              })

                                                              var propertyData = [];
                                                              var projectId =[];
                                                              
                                                              projectId.push({
                                                                id: Property.id,
                                                                
                                                              }); 

                                                              propertyData.push(Property.data())

                                                              Object.assign(propertyData[0], {id: Property.id});
                                                              
                                                             

                                                              

                                                              homeMobile.props.PropertySingle(propertyData[0])


                                    }
                                  ).catch((error)=>{

                                   // console.log(JSON.stringify(error))
                                    swal("Error Updating", 'Unable to Update Photo\n with id :: '+homeMobile.state.pid+' \n'+JSON.stringify(error), "danger")

                                  })

                    swal("Photo Added", 'You have successfully upload '+image.name +' to this property', "success")
                    .then((value) => {
                      
                      document.location.reload()
                    });
                  

                    })

            




        


        this.setState({
          
          downloadURLs:url

        })
      })



    }
    ) 



  ////////////////////////////
                


              })
            })


         
                        })
     // alert(uri)
      // You upload logic goes here
    },
    "base64" // blob or base64 default base64
  );

  

  
}
   
render()
{
    return(<div> 
        <Alert color="default">
                        <span className="alert-inner--icon">
                          <i className="ni ni-image" />
                        </span>{" "} {" "}
                        <span className="alert-inner--text">
                          <strong>Upload Photo</strong> 
                        </span>



                          {
                            (this.state.isUploading)?
                            (<div className='mt-5'>{this.state.serverMessage} <i className="fa fa-spinner fa-spin"></i></div>)
                            :(
                              <Input 
                                style={{
                                  "color": "#212529",
                                  "backgroundColor": "#f7fafc",
                                  "borderColor": "#f7fafc",
                                  "boxShadow": "0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
                                  "display": "inline-block",
                                  "fontWeight": "600",
                                  
                                  "border": "1px solid transparent",
                                  "padding": "0.625rem 1.25rem",
                                  "fontSize": "0.5rem",
                                  "lineHeight": "1.5",
                                  "borderRadius": "0.375rem",
                                  "transition": "color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out"

                                }} 
                              
                                accept="image/*"
                                className="mt-2"
                                type="file"
                                id="photo_upload"
                                onChange={

                                  this.handleUpload


                                }
                             />
                            )
                          }
                        


                       
                    

                      </Alert>


         <div style={{fontSize:9}}>
           

          { 

          (!(isEmpty(this.state.downloadURLs)?(<img src={this.state.photoObject} />):null))

          }
        </div>
    </div>)

}
    



}

// PropertySingle: (param) => dispatch(PropertySingle(param)),




const mapDispatchToProps = (dispatch) => {
  return { 

    PropertySingle: (param) => dispatch(PropertySingle(param)),
    
   };
};

const mapStateToProps = (state) => ({
    
   PropertyReducer: state.PropertyReducer,
   
  
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadPhoto);

import { isObject } from "lodash";
import isEmpty from "lodash/isEmpty";

const iniState = {
 
  //PromoactionsData: {},
  PromoLoading: true,
  PromoError: true,
  PromoErrorMsg:'',
  PromoLoadingCompleted:false,
  PromoactionCount:0
};

const PromoReducer = (state = iniState, action) => {
  switch (action.type) {

    case "PROMO_COUNT":
     // alert(action.payload)
      return {
        ...state,
        PromoactionCount: action.payload,
      }
    case "PROMO_LOADING":

    return {
        ...state,
        PromoLoading: action.PromoLoading,
        PromoError: false,
        PromoLoadingCompleted:false,
        //PromoErrorMsg:''
      };

    case "PROMO_SUCCESS":
     
    //alert(JSON.stringify(action.payload))
      return {
        ...state,
        PromoactionsData: action.payload,
        //PromoactionCount: (isObject(action.payload)?(Object.keys(action.payload).length):0),
        PromoError: false,
        PromoLoadingCompleted:true,
        PromoLoading: false,
      };
    case "PROMO_ERROR":
     
    //alert('error')
      return {
        ...state,
        PromoError: true,
        PromoLoadingCompleted: false,
         PromoErrorMsg:action.payload,
         PromoLoaded:false,
      };

    
    default:
      return state;
  }
};

export default PromoReducer;

import authReducer from "./authReducer";
import transactionReducer from "./transactionReducer";
import UserReducer from "./UserReducer";
import PaymentReducer from "./PaymentReducer";
import PropertyReducer from "./PropertyReducer";
import PromoReducer from "./PromoReducer";
import AgreementReducer from "./AgreementReducer";

import FeaturedListReducer from "./FeaturedListReducer";
import ChatReducer from "./ChatReducer";
import CouponReducer from "./CouponReducer";
import CoopRegisterReducer from "./CoopRegisterReducer";
import CoopOtherReducers from "./CoopOtherReducers";
import GroupRegisterReducer from "./GroupRegisterReducer";
import GroupDepositReducer from "./GroupDepositReducer";
import GroupInviteReducer from "./GroupInviteReducer";
import FractionalnvestmentReducer from "./FractionalnvestmentReducer";

//
//

//
//import sponsorsReducer from "./sponsorsReducer";
import { combineReducers } from "redux";
//import PaymentReducer from "./PaymentReducer";

const rootReducer = combineReducers({
  authReducer: authReducer,
  transactionReducer: transactionReducer,
  UserReducer: UserReducer,
  PaymentReducer: PaymentReducer,
  PropertyReducer: PropertyReducer,
  PromoReducer: PromoReducer,
  FeaturedListReducer: FeaturedListReducer,
  AgreementReducer: AgreementReducer,
  ChatReducer: ChatReducer,
  CouponReducer: CouponReducer,
  CoopRegisterReducer: CoopRegisterReducer,
  CoopOtherReducers: CoopOtherReducers,
  GroupRegisterReducer: GroupRegisterReducer,
  GroupDepositReducer: GroupDepositReducer,
  GroupInviteReducer: GroupInviteReducer,
  FI_Reducer: FractionalnvestmentReducer,
});

export default rootReducer;

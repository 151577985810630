import React from "react";
// reactstrap components

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  Modal,
  Form,
  FormGroup,
  Input,
  Badge,
} from "reactstrap";

// core components
import Header from "components/Headers/Header";
import moment from "moment";
import SelectSearch from "react-select-search";

import ReactDOM from "react-dom";
import MaterialTable, { MTableToolbar, MTableCell } from "material-table";
import { dateFormater, currencyFormat } from "utils/functions";

import firebase from "firebase/app";

import { connect } from "react-redux";
import CountUp from "react-countup";
import { isEmpty } from "lodash";

import { Group_Dep_Actions } from "store/actions/groupDepAction";
import { Group_Inv_Actions } from "store/actions/groupInviteAction";


//DepositData

class PaymentGroup extends React.Component {
  state = {};
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.Group_Dep_Actions();
    this.props.Group_Inv_Actions();

  }

  render() {
    const groupDepData = this.props.GroupDepositReducer?.groupDepData;
    const groupInviteData = this.props.GroupInviteReducer?.groupInviteData;

    //alert(JSON.stringify(groupDepData));

     //console.log(JSON.stringify(groupInviteData));

    return (
      <>
        <Header />

        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow border-0">
                <div style={{ maxWidth: "100%", borderRadius: 20 }}>
                  <MaterialTable
                    columns={[
                      {
                        title: "Fullname",
                        field: "name",
                        /* defaultGroupOrder: 0 , */ render: (rowData) => {
                          return rowData.name;
                        },
                      },

                      {
                        title: "Property",
                        field: "p_name",
                        /* defaultGroupOrder: 0 , */ render: (rowData) => {
                          return rowData.p_name;
                        },
                      },

                      {
                        title: "Amount",
                        field: "amount",
                        render: (rowData) => {
                          return currencyFormat(parseInt(rowData.amount));
                          // return rowData.p_amount;
                        },
                      },

                      {
                        title: "Reference",
                        field: "reference",
                        render: (rowData) => {
                          return rowData.reference;
                        },
                      },

                      {
                        title: "Date",
                        field: "date_time",
                        defaultSort: "desc",
                        render: (rowData) => {
                          return isEmpty(rowData.date_time)
                            ? null
                            : dateFormater(rowData.date_time);
                        },
                      },
                    ]}
                    options={{
                      exportButton: true,
                      exportFileName: "Payment_Data_" + new Date(),
                      //grouping: true,
                      pageSize: 10,
                      pageSizeOptions: [
                        5, 10, 20, 30, 50, 75, 100, 200, 500, 1000, 2000, 3000,
                        5000, 8000, 10000,
                      ],
                      toolbar: true,
                      // paging: true,

                      rowStyle: {
                        paddingLeft: "1.5rem",
                        paddingRight: "1.5rem",
                        verticalAlign: "middle",

                        fontSize: "0.8125rem",
                        whiteSpace: "nowrap",
                        padding: "1rem",
                        borderTop: "1px solid #e9ecef",
                        fontFamily: "Open Sans, sans-serif",
                      },

                      headerStyle: {
                        paddingTop: "0.75rem",
                        paddingBottom: "0.75rem",
                        fontSize: "0.65rem",
                        textTransform: "uppercase",
                        letterPpacing: "1px",
                        borderBottom: "1px solid #e9ecef",
                        fontWeight: "bolder",
                        paddingLeft: "1.5rem",
                        paddingRight: "1.5rem",
                        backgroundColor: "#f6f9fc",
                        color: "#8898aa",
                        verticalAlign: "middle",
                        borderColor: "#e9ecef",
                        fontFamily: "Open Sans, sans-serif",
                      },
                    }}
                    /* actions={[
                      {
                        icon: (row) => (
                          <Button
                            color="info"
                            href="#"
                            style={{ fontSize: 9 }}
                            size="sm"
                            onClick={(e) => {}}
                          >
                            <i className="fa fa-eye" />
                          </Button>
                        ),

                        tooltip: "View User",

                        onClick: (event, row) => {
                          //  alert(JSON.stringify(row.uid));
                          // this.GetRegisterationData(event, row.uid);
                        },
                      },
                    ]} */
                    data={groupDepData}
                    title={" "}
                    components={{
                      Toolbar: (props) => (
                        <p className="h3" style={{ padding: 10 }}>
                          <MTableToolbar {...props} />
                          <div style={{ marginTop: -40 }}>
                            {"Total"}{" "}
                            <CountUp
                              end={Object.keys(groupDepData || []).length}
                            />
                          </div>
                        </p>
                      ),
                    }}
                  />
                </div>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    Group_Dep_Actions: (param) => dispatch(Group_Dep_Actions(param)),
    Group_Inv_Actions: (param) => dispatch(Group_Inv_Actions(param)),
    
  };
};

const mapStateToProps = (state) => ({
  GroupDepositReducer: state.GroupDepositReducer,
  GroupInviteReducer: state.GroupInviteReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentGroup);

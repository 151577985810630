import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

import firebase from "firebase/app";
import * as admin from "firebase-admin";
import api from "../../utils/config";

class EmailVerify extends React.Component {
  constructor(props) {
    super(props);

    //alert(JSON.stringify(props))
    //console.log(props.match.params.uid)
    this.state = {
      userID: props.match.params.uid,
      userEmail: props.match.params.email,
      VerificationLink: "",
    };
  }
    
    
    

    VerificationLink = async (email) => {
     // alert(email);

      const actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for
        // this URL must be whitelisted in the Firebase Console.
        url: "https://admin-portal.stow.ng",
        // This must be true for email link sign-in.
        handleCodeInApp: false,
        iOS: {
          bundleId: "com.example.ios",
        },
        android: {
          packageName: "com.example.android",
          installApp: true,
          minimumVersion: "12",
        },
        // FDL custom domain.
        dynamicLinkDomain: "coolapp.page.link",
      };

        const link = await admin.auth().generateEmailVerificationLink('eogie@octo5.co');
        console.log(link)

      //alert(link);

      this.setState({
        VerificationLink: link,
      });
      //return
    };

    componentDidMount(props) {
      
        alert(admin.apps.length);
    if (admin.apps.length  <= 0) {
      admin.initializeApp({
        credential: admin.credential.cert({
          client_email: api.FIREBASE_ADMIN_client_email,
          private_key: api.FIREBASE_ADMIN_private_key,
          project_id: api.REACT_APP_FIREBASE_PROJECT_ID,
        }),
        databaseURL: api.REACT_APP_FIREBASE_DATABASE,
      });

      alert('connected')

      //this.VerificationLink(this.state.userEmail);
    }
  }

  render() {
    return (
      <h1>
        {this.state.userID}-- {this.state.userEmail}---
        {this.state.VerificationLink}
      </h1>
    );
  }
}

export default EmailVerify;

import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import CountUp from "react-countup";
import axios from "axios";
import firebase from "firebase/app";
import swal from "sweetalert";

import {
  Button,
  Card,
  CardImg,
  CardHeader,
  Modal,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledAlert,
} from "reactstrap";

import MaterialTable, {
  MTableToolbar,
  MTableBodyRow,
  MTableCell,
} from "material-table";
import { connect } from "react-redux";

import { dateFormater, UserRolePermission } from "utils/functions";

import { SendNotificationToSingleDevices } from "utils/functions";

import { UserSegmentPushActions } from "store/actions/userActions";

class SendPushToGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      savingGrouPush: false,
      setTitle: "",
      setEmail: "",
      setModal: false,
      inputValues: {
        segment_name: "",
        field: "tag",
        relation: "=",
        key: "",
        value: "",
        createdAt: new Date(),
      },
      inputValues2: {
        title: "",
        message: "",
      },
    };
  }

  componentDidMount(props) {
    this.props.UserSegmentPushActions();
  }

  formatAMPM = (timeStamp) => {
    if (timeStamp == "now") {
      const fireBaseTime = new Date();

      const mine = fireBaseTime.toLocaleString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        //second: "numeric",
      });

      return mine;
    } else {
      const fireBaseTime = new Date(
        timeStamp.seconds * 1000 + timeStamp.nanoseconds / 1000000
      );

      const mine = fireBaseTime.toLocaleString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
        // hour: "numeric",
        //minute: "numeric",
        //second: "numeric",
      });

      return mine;
    }
  };

  handleOnChange = (event) => {
    const { name, value } = event.target;
    // console.log(name + " - " + value);

    this.setState({
      inputValues: {
        ...this.state.inputValues,
        [name]: value,
      },
    });
  };

  handleGroupOnChange = (event) => {
    const { name, value } = event.target;
    //console.log(name + " - " + value);

    this.setState({
      inputValues2: {
        ...this.state.inputValues2,
        [name]: value,
      },
    });
  };

  SubmitFormEvent = async (e) => {
    e.preventDefault();

    /* const data = {
      push: {
        name: this.state.inputValues.segment_name,
        filters: [
          {
            field: this.state.inputValues.field,
            relation: this.state.inputValues.relation,
            key: this.state.inputValues.key,
            value: this.state.inputValues.value,
          },
        ],
      },
      doc: `${JSON.stringify(this.state.inputValues)}`,
    }; */

    this.setState({ savingGrouPush: true });

    let state = this;

    await firebase
      .firestore()
      .collection("pushSegment")
      .add(this.state.inputValues)
      .then(async (data) => {
        console.log(data);
        state.setState({ savingGrouPush: false });

        //alert("New Group/Segment addedd successfully");
        swal(
          `${this.state.inputValues.segment_name} created`,
          `New Group/Segment addedd successfully`,
          "success"
        );
        // document.location.reload();
      })
      .catch((error) => {
        console.log(error);
        swal(
          "Error Detected",
          `An Unexpected error occured. Kindly confirm your network and try again`,
          "error"
        );
      });
  };

  SubmitSendToGroupFormEvent = async (e) => {
    e.preventDefault();

    var message = {
      app_id: process.env.REACT_APP_ONNESIGNAL_APP_ID,
      contents: this.state.inputValues2.message,
      headings: this.state.inputValues2.title,
      channel: "segment",
      segment: this.state.setEmail,
    };

    await SendNotificationToSingleDevices(message);

    // console.log(message);
    this.setState({ setModal: false });
  };

  render() {
    const User = this.props.UserReducer;

    return (
      <div className="row">
        <div className="col-md-4">
          <div className="card">
            <header className="card-header">Add Group/Segment</header>
            <div className="card-body">
              <form
                action=""
                method="post"
                className=""
                onSubmit={this.SubmitFormEvent}
              >
                <div className="form-group">
                  <label
                    className=""
                    style={{
                      marginBottom: " -10px",
                      color: "grey",
                      fontSize: "12px",
                    }}
                  >
                    Name
                  </label>
                  <div className="input-group">
                    <input
                      className="form-control"
                      onChange={this.handleOnChange}
                      type="text"
                      required="required"
                      id="segment_name"
                      name="segment_name"
                      placeholder="Segment Name"
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label
                    className=""
                    style={{
                      marginBottom: "-10px",
                      color: "grey",
                      fontSize: "12px",
                    }}
                  >
                    Field
                  </label>
                  <div className="input-group">
                    <select
                      className="form-control"
                      required="required"
                      name="field"
                      id="field"
                      onChange={this.handleOnChange}
                      placeholder="Select field"
                    >
                      <option value="">Select Segment Field</option>
                      <option value="last_session">last_session</option>
                      <option value="first_session">first_session</option>
                      <option value="session_count">session_count</option>
                      <option value="session_time">session_time</option>
                      <option value="amount_spent">amount_spent</option>
                      <option value="bought_sku">bought_sku</option>
                      <option value="tag">tag</option>
                      <option value="language">language</option>
                      <option value="app_version">app_version</option>
                      <option value="location">location</option>
                      <option value="country">country</option>
                      <option value="email">email</option>
                    </select>
                  </div>
                </div>

                <div className="form-group">
                  <label
                    className=""
                    style={{
                      marginBottom: "-10px",
                      color: "grey",
                      fontSize: "12px",
                    }}
                  >
                    Relation
                  </label>
                  <div className="input-group">
                    <select
                      className="form-control"
                      onChange={this.handleOnChange}
                      required="required"
                      name="relation"
                      id="relation"
                      placeholder="Select relation"
                    >
                      <option value="">Select Relation </option>
                      <option value=">">&gt;</option>
                      <option value="<">&lt;</option>
                      <option value="=">=</option>
                      <option value="!=">!=</option>
                      <option value="exists">exists</option>
                      <option value="not_exists">not_exists</option>
                      <option value="time_elapsed_gt">time_elapsed_gt</option>
                      <option value="time_elapsed_lt">time_elapsed_lt</option>
                    </select>
                  </div>
                </div>
                <br />
                <hr style={{ margin: "10px" }} />
                <br></br>

                <div className="form-group">
                  <label
                    className=""
                    style={{
                      marginBottom: "-10px",
                      color: "grey",
                      fontSize: "12px",
                    }}
                  >
                    Key
                  </label>
                  <div className="input-group">
                    <input
                      className="form-control"
                      onChange={this.handleOnChange}
                      type="text"
                      required="required"
                      id="key"
                      name="key"
                      placeholder="Segment Key "
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label
                    className=""
                    style={{
                      marginBottom: "-10px",
                      color: "grey",
                      fontSize: "12px",
                    }}
                  >
                    Value
                  </label>
                  <div className="input-group">
                    <input
                      className="form-control"
                      onChange={this.handleOnChange}
                      type="text"
                      required="required"
                      id="value"
                      name="value"
                      placeholder="Segment Value "
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div
                  className="form-group form-actions"
                  style={{ float: "right" }}
                >
                  {this.state.savingGrouPush ? (
                    <div className="mt-5">
                      saving...
                      <i className="fa fa-spinner fa-spin"></i>
                    </div>
                  ) : (
                    <button
                      className="btn-pill btn btn-primary btn-lg"
                      type="submit"
                      style={{ backgroundColor: "rgb(60, 75, 100)" }}
                    >
                      Submit
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <div className="card">
            <header className="card-header">List Group/Segment</header>
            <div className="card-body">
              <Modal
                id="model_payment"
                className="modal-dialog-centered"
                isOpen={this.state.setModal}
                // style={{ minWidth: 90 + "%" }}
                // toggle={() => this.toggleModal("exampleModal")}
              >
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {this.state.setTitle}
                  </h5>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ setModal: false });
                    }}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <div class="modal-body" style={{ padding: "40px" }}>
                  <form
                    action=""
                    method="post"
                    class=""
                    onSubmit={this.SubmitSendToGroupFormEvent}
                  >
                    <div class="form-group">
                      <div class="input-group">
                        <input
                          onChange={this.handleGroupOnChange}
                          class="form-control"
                          type="text"
                          required="required"
                          id="title"
                          name="title"
                          placeholder="Enter Title of Message"
                          autoComplete="off"
                        />
                      </div>
                    </div>

                    <div class="form-group">
                      <div class="input-group">
                        <textarea
                          class="form-control"
                          required="required"
                          onChange={this.handleGroupOnChange}
                          id="message"
                          name="message"
                          placeholder="Enter Body of Message"
                          rows="4"
                        ></textarea>
                      </div>
                    </div>
                    <div
                      class="form-group form-actions"
                      style={{ float: "right" }}
                    >
                      <button
                        class="btn-pill btn btn-primary btn-lg"
                        type="submit"
                        style={{ backgroundColor: "rgb(60, 75, 100)" }}
                      >
                        Send Message
                      </button>
                    </div>
                  </form>
                  <br />
                </div>
              </Modal>

              {Object.keys(User.UserGroupPushActionsData || []).length > 0 ? (
                <MaterialTable
                  columns={[
                    {
                      title: "Name",
                      field: "segment_name",
                      // filtering: false,
                      render: (rowData) => {
                        return rowData.segment_name;
                      },
                    },
                    {
                      title: "Field",
                      filtering: true,
                      //defaultGroupOrder: 0,
                      field: "field",
                      render: (rowData) => {
                        return rowData.field;
                      },
                    },
                    {
                      title: "Relation",
                      field: "relation",
                      filtering: false,
                      render: (rowData) => {
                        return rowData.relation;
                      },
                    },
                    {
                      title: "Key",
                      field: "key",
                      //lookup: { looker },

                      render: (rowData) => {
                        return rowData.key;
                      },
                    },

                    {
                      title: "Value",
                      field: "value",
                      //lookup: { looker },

                      render: (rowData) => {
                        return rowData.value;
                      },
                    },
                    {
                      title: "Date",
                      field: "createdAt",
                      defaultSort: "desc",
                      render: (rowData) => {
                        return this.formatAMPM(rowData?.createdAt);
                      },
                    },
                  ]}
                  actions={[
                    {
                      icon: () => (
                        <Button
                          color="warning"
                          href="#"
                          style={{
                            fontSize: 9,
                            display: UserRolePermission(
                              this.props.currentUser.UserData.role,
                              "send_cloud_message"
                            )
                              ? "none"
                              : "block",
                          }}
                          size="sm"
                        >
                          Send Message
                        </Button>
                      ),
                      tooltip: "Send Push",
                      //isFreeAction: true,
                      onClick: (event, rowData) => {
                        //console.log(rowData);
                        if (rowData.external_user_id == "") {
                          alert("Can not send message to an empty User ID");
                        } else {
                          this.setState({
                            setTitle:
                              "Send message to " + " | " + rowData.segment_name,
                          });

                          this.setState({ setEmail: rowData.segment_name });
                          this.setState({ setModal: true });
                        }
                      },
                    },
                  ]}
                  data={User.UserGroupPushActionsData || []}
                  options={{
                    // grouping: true,
                    //filtering: true,
                    //filtering: true,
                    pageSize: 5,
                    pageSizeOptions: [5, 10, 20, 30, 50, 75, 100],
                    toolbar: true,
                    // paging: true,

                    // rowStyle: rowStyle,

                    //headerStyle: headerStyle,
                  }}
                  title={" "}
                  components={{
                    Toolbar: (props) => (
                      <p className="h3" style={{ padding: 10 }}>
                        <MTableToolbar {...props} />
                        <div style={{ marginTop: -40, fontSize: 15 }}>
                          {"Total"}{" "}
                          <CountUp
                            end={
                              Object.keys(User.UserGroupPushActionsData).length
                            }
                          />
                        </div>
                      </p>
                    ),
                  }}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    UserSegmentPushActions: (param) => dispatch(UserSegmentPushActions(param)),
  };
};

const mapStateToProps = (state) => ({
  UserReducer: state.UserReducer,
  currentUser: state.authReducer,
  CoopRegisterReducer: state.CoopRegisterReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(SendPushToGroup);

import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import CountUp from 'react-countup';
import axios from 'axios';




// reactstrap components
import {
  Button,
  Card,
  CardImg,
  CardHeader,
  Modal,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledAlert,
} from "reactstrap";

import swal from "sweetalert";
import firebase from "firebase/app";
import { connect } from "react-redux";
import MaterialTable, {
  MTableToolbar,
  MTableBodyRow,MTableCell,
} from "material-table";
import { array } from "js-sha512";

import { dateFormater, UserRolePermission } from "utils/functions";




class CloudMessaging extends React.Component {
  state = {
    //propertyBed:this.props.PropertyReducer.propertyOptions[0].beds,
    startSendingMessageLoader: false,
    showPushModal: false,
    title_msg: null,
    body_msg: null,
    showOldPush:true
  };

  constructor(props) {
    super(props);
    //this.activeRoute.bind(this);
  }

  componentDidMount(props) {
    //this.props.propertyOptionsAction()
  }

  render() {
    return (
      <div class="container" style={{ border: "0px solid red" }}>
        <Modal
          id="model_payment"
          className="modal-dialog-centered"
          isOpen={this.state.showPushModal}
          style={{ maxWidth: 80 + "%" }}
          // toggle={() => this.toggleModal("exampleModal")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              <span id="user_data"></span>
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ showPushModal: false });
              }}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Form>
              <div
                className="edit_form"
                style={{
                  width: 100 + "%",
                }}
              >
                <Row>
                  <Col>
                    <UncontrolledAlert
                      id="validate_form_msg"
                      className=" alert-warning"
                      style={{ display: "none" }}
                      fade={false}
                    >
                      <span className="alert-inner--icon">
                        <i className="ni ni-like-2" />
                      </span>{" "}
                      <span className="alert-inner--text">
                        <strong>Validation!</strong> All fields are mandatory
                      </span>
                    </UncontrolledAlert>
                  </Col>
                </Row>

                <Row>
                  <Col lg="12">
                    <FormGroup id="proTitleD">
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Title
                      </label>
                      <Input
                        className="form-control-alternative"
                        // defaultValue="lucky.jesse"
                        required
                        id="proTitle"
                        placeholder="Title of Message"
                        onChange={(data) => {
                          // console.log(data.target.value)
                          this.setState({ title_msg: data.target.value });
                        }}
                        type="text"
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="12">
                    <FormGroup id="proBodyD">
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Body
                      </label>
                      <Input
                        className="form-control-alternative"
                        // defaultValue="lucky.jesse"
                        required
                        id="proBody"
                        placeholder="Body of Message"
                        onChange={(data) => {
                          // console.log(data.target.value)
                          this.setState({ body_msg: data.target.value });
                        }}
                        type="textarea"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg="6">
                    <Input
                      className="form-control-alternative"
                      id="token"
                      required
                      type="hidden"
                    />

                    <Input
                      className="form-control-alternative"
                      id="uname"
                      required
                      type="hidden"
                    />

                    <Input
                      className="form-control-alternative"
                      id="uid"
                      required
                      type="hidden"
                    />
                  </Col>

                  <Col>
                    <Button
                      className="float-right"
                      style={{
                        marginRight: 15,
                        display: UserRolePermission(
                          this.props.currentUser.UserData.role,
                          "send_cloud_message"
                        )
                          ? "none"
                          : "block",
                      }}
                      disabled={this.state.startSendingMessageLoader}
                      color="success"
                      href="#stow"
                      type="submit"
                      onClick={async (e) => {
                        e.preventDefault();

                        document
                          .getElementById("proBodyD")
                          .classList.remove("has-danger");
                        document
                          .getElementById("proTitleD")
                          .classList.remove("has-danger");

                        if (document.getElementById("proTitle").value == "") {
                          document
                            .getElementById("proTitleD")
                            .classList.add("has-danger");
                          document.getElementById("proTitle").focus();

                          if (document.getElementById("validate_form_msg")) {
                            document.getElementById(
                              "validate_form_msg"
                            ).style.display = "block";
                          }
                        } else if (
                          document.getElementById("proBody").value == ""
                        ) {
                          document
                            .getElementById("proBodyD")
                            .classList.add("has-danger");
                          document.getElementById("proBody").focus();

                          if (document.getElementById("validate_form_msg")) {
                            document.getElementById(
                              "validate_form_msg"
                            ).style.display = "block";
                          }
                        } else {
                          this.setState({
                            startSendingMessageLoader: true,
                          });

                          if (document.getElementById("token").value == "all") {
                            var now = new Date();
                            var formattedToday =
                              now
                                .toLocaleDateString("en-GB", {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                })
                                .split(" ")
                                .join("-") + "";

                            var nowIOS = new Date().getTime();

                            var pushMessage = {
                              title: this.state.title_msg,
                              body: this.state.body_msg, // document.getElementById('proBody').value,
                              dateTimeStamp: nowIOS,
                              createdDate: formattedToday,
                            };

                            var home = this;

                            await firebase
                              .firestore()
                              .collection("pushMessage")
                              .add(pushMessage);

                            const message = {
                              topic: "ALL_STOW_USERS",
                              //sound: "default",
                              title: this.state.title_msg,
                              text: this.state.body_msg,
                              //data: { data: "goes here" },
                            };

                            //console.log(JSON.stringify(message))

                            await axios({
                              mode: "no-cors",
                              method: "post",
                              headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                              },
                              //url:'http://localhost:1402/stow-62251/us-central1/apiSendToTopic',
                              url: "https://us-central1-stow-62251.cloudfunctions.net/apiSendToTopic",
                              data: JSON.stringify(message),
                            })
                              .then(async (response) => {
                                swal(
                                  "Message Sent",
                                  " You have successfully sent  message to all customers",
                                  {
                                    icon: "success",
                                  }
                                ).then((value) => {
                                  home.setState({
                                    showPushModal: false,
                                    startSendingMessageLoader: false,
                                  });
                                });
                              })
                              .catch((error) => {
                                swal(
                                  "Server Error",
                                  "There has been a problem sending your message  \n\n" +
                                    JSON.stringify(error),
                                  "error"
                                );
                              });
                          } else {
                            /**/
                            const message = {
                              token: document.getElementById("token").value,
                              //sound: "default",
                              title: this.state.title_msg,
                              text: this.state.body_msg,
                              //data: { data: "goes here" },
                            }; /* */

                            /* */

                            await axios({
                              //mode: "no-cors",
                              method: "post",

                              headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                              },
                              url: "https://us-central1-stow-62251.cloudfunctions.net/apiSendToDevice",
                              //url: "http://localhost:1402/stow-62251/us-central1/apiSendToDevice",
                              data: JSON.stringify(message),
                            })
                              .then(async (response) => {
                                
                                if (response.data.failureCount == 1) {
                                  swal(
                                    "Error Sending Message",
                                    "SERVER RESPONSE\n\n" +
                                      response.data.results[0].error.code +
                                      "\n\n" +
                                      response.data.results[0].error.message,
                                    {
                                      icon: "error",
                                    }
                                  ).then((value) => {
                                    home.setState({
                                      showPushModal: false,
                                    });
                                  });
                                } else {
                                  swal(
                                    "Message Sent",
                                    " You have successfully sent your message to " +
                                      document.getElementById("uname").value +
                                      " ",
                                    {
                                      icon: "success",
                                    }
                                  ).then((value) => {
                                    home.setState({
                                      showPushModal: false,
                                    });
                                  });
                                }

                                var home = this;

                                home.setState({
                                  startSendingMessageLoader: false,
                                });

                                /*
                                         var  pushMessage ={
                                            title: document.getElementById('proTitle').value,
                                            body: document.getElementById('proBody').value,
                                            token: document.getElementById('token').value,
                                            uid: document.getElementById('uid').value,
                                            createdDate: formattedToday,
                                            
                                          }
                                         
                                         await firebase
                                            .firestore()
                                            .collection("pushMessage")
                                            
                                            .add(pushMessage)
                                            .then(function () { 

                                            }) */
                              })
                              .catch((error) => {
                                this.setState({
                                  startSendingMessageLoader: false,
                                  //showPushModal:false,
                                });

                                swal(
                                  "Server Error",
                                  "There has been a problem sending your message  \n\n" +
                                    JSON.stringify(error),
                                  "error"
                                );
                              });

                            /* */
                          }
                        }
                      }}
                    >
                      {this.state.startSendingMessageLoader ? (
                        <>
                          Sending... <i className="fa fa-spinner fa-spin"></i>
                        </>
                      ) : (
                        "Send Message to User"
                      )}
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </Modal>

        <Row>
          <div className="col">
            <MaterialTable
              columns={[
                {
                  title: "First Name",
                  field: "firstName",
                  render: (rowData) => {
                    return rowData.firstName;
                  },
                },
                {
                  title: "Last Name",
                  field: "lastName",
                  render: (rowData) => {
                    return rowData.lastName;
                  },
                },
                {
                  title: "Email",
                  field: "email",
                  render: (rowData) => {
                    return (
                      <div>
                        {rowData.email}

                        {rowData.token.indexOf("ExponentPush") != 0 ? (
                          <div
                            color=""
                            style={{
                              fontSize: 8,
                              color: "#000",
                              // width: '60%',
                              whiteSpace: "break-spaces",
                            }}
                            className="badge-dot"
                          >
                            <i className="bg-success" />
                            {rowData.token}
                          </div>
                        ) : (
                          <div
                            color=""
                            style={{ fontSize: 8, color: "#000" }}
                            className="badge-dot"
                          >
                            <i className="bg-danger" />
                            {rowData.token}
                          </div>
                        )}
                      </div>
                    );
                  },
                },
                {
                  title: "Phone",
                  field: "phone",
                  render: (rowData) => {
                    return rowData.phone;
                  },
                },
              ]}
              actions={[
                {
                  icon: () => (
                    <Button
                      color="primary"
                      href="#"
                      style={{
                        fontSize: 11,
                        paddingBottom: 6,
                        display: UserRolePermission(
                          this.props.currentUser.UserData.role,
                          "send_cloud_message"
                        )
                          ? "none"
                          : "block",
                      }}
                      size="sm"
                    >
                      Send to All
                    </Button>
                  ),
                  tooltip: "Send to All",
                  isFreeAction: true,
                  onClick: (event, rowData) => {
                    this.setState(
                      {
                        showPushModal: true,
                      },
                      () => {
                        var tim = setTimeout(function () {
                          document.getElementById("user_data").innerHTML =
                            "Send Message to All";
                          document.getElementById("token").value = "all";
                          document.getElementById("uid").value = "all";
                          document.getElementById("uname").value = "all";
                        }, 600);
                      }
                    );
                  },
                },
                {
                  icon: (row) => (
                    <Button
                      color="primary"
                      href="#"
                      style={{
                        fontSize: 9,
                        display: UserRolePermission(
                          this.props.currentUser.UserData.role,
                          "send_cloud_message"
                        )
                          ? "none"
                          : "block",
                      }}
                      size="sm"
                    >
                      Send Message
                    </Button>
                  ),

                  tooltip: "Send Message",

                  onClick: (event, rowData) => {
                    this.setState(
                      {
                        showPushModal: true,
                      },
                      () => {
                        var tim = setTimeout(function () {
                          document.getElementById("user_data").innerHTML =
                            "Send Message to " +
                            rowData.firstName +
                            " " +
                            rowData.lastName;
                          document.getElementById("token").value = rowData.id;
                          document.getElementById("uid").value = rowData.uid;
                          document.getElementById("uname").value =
                            rowData.firstName + " " + rowData.lastName;
                        }, 600);
                      }
                    );
                  },
                },
              ]}
              data={this.props.UserReducer.UserPushActionsData}
              options={{
                //  grouping: true,
                pageSize: 10,
                pageSizeOptions: [5, 10, 20, 30, 50, 75, 100],
                toolbar: true,
                // paging: true,

                rowStyle: {
                  paddingLeft: "1.5rem",
                  paddingRight: "1.5rem",
                  verticalAlign: "middle",

                  fontSize: "0.8125rem",
                  whiteSpace: "nowrap",
                  padding: "1rem",
                  borderTop: "1px solid #e9ecef",
                  fontFamily: "Open Sans, sans-serif",
                },

                headerStyle: {
                  paddingTop: "0.75rem",
                  paddingBottom: "0.75rem",
                  fontSize: "0.65rem",
                  textTransform: "uppercase",
                  letterPpacing: "1px",
                  borderBottom: "1px solid #e9ecef",
                  fontWeight: "bolder",
                  paddingLeft: "1.5rem",
                  paddingRight: "1.5rem",
                  backgroundColor: "#f6f9fc",
                  color: "#8898aa",
                  verticalAlign: "middle",
                  borderColor: "#e9ecef",
                  fontFamily: "Open Sans, sans-serif",
                },
              }}
              title={" "}
              components={{
                Toolbar: (props) => (
                  <p className="h3" style={{ padding: 10 }}>
                    <MTableToolbar {...props} />
                    <div style={{ marginTop: -40, fontSize: 15 }}>
                      {"Total"}{" "}
                      <CountUp
                        end={
                          Object.keys(
                            this.props.UserReducer.UserPushActionsData
                          ).length
                        }
                      />
                    </div>
                  </p>
                ),

                /* Row: (props) => {
                  if (this.state.showOldPush) {
                    return props.data.token.indexOf("ExponentPush") != 0 ? (
                      <MTableBodyRow {...props} />
                    ) : (
                      <MTableBodyRow style={{ display: "none" }} {...props} />
                    );
                  } else {
                    return <MTableBodyRow {...props} />;
                  }
                }, */
              }}
            ></MaterialTable>

            {/* {JSON.stringify(this.props.UserReducer.UserPushActionsData)} */}
          </div>
        </Row>
      </div>
    );
  }
}




const mapDispatchToProps = (dispatch) => {
    return {
     
    };
  };
  
  const mapStateToProps = (state) => ({
    UserReducer: state.UserReducer,
    currentUser: state.authReducer,
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(CloudMessaging);

    
import React from "react";
// node.js library that concatenates classes (strings)

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  Modal,
  Form,
  FormGroup,
  Input,
  Badge,
  Alert,
  UncontrolledAlert,
} from "reactstrap";

import moment from "moment";

import firebase from "firebase/app";
import "firebase/storage";
import { connect } from "react-redux";

import ContentLoader, { BulletList, Code } from "react-content-loader";

import api from "../../../utils/config";
import { currencyFormat, dateFormater } from "../../../utils/functions";

//import "firebase/auth";
import { isEmpty } from "lodash";
import swal from "sweetalert";

class AddNewProperty extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startSavingLoader: false,
    };
  }

  componentDidMount(props) {
    if (!firebase.apps.length) {
      firebase.initializeApp({
        apiKey: api.REACT_APP_FIREBASE_APP_ID,
        authDomain: api.REACT_APP_FIREBASE_DOMAIN,
        databaseURL: api.REACT_APP_FIREBASE_DATABASE,
        projectId: api.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: api.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: api.REACT_APP_FIREBASE_SENDER_ID,
        appId: api.REACT_APP_FIREBASE_APP_ID,
        measurementId: api.REACT_APP_FIREBASE_MEASUREMENT_ID,
      });
    }
  }

  render() {
    return (
      <Card className='bg-secondary shadow'>
        <CardHeader>
          <UncontrolledAlert
            id='validate_form_msg'
            className=' alert-warning'
            style={{ display: "none" }}
            fade={false}>
            <span className='alert-inner--icon'>
              <i className='ni ni-like-2' />
            </span>{" "}
            <span className='alert-inner--text'>
              <strong>Validation!</strong> Some important fields are not filled
              out properly
            </span>
          </UncontrolledAlert>
        </CardHeader>
        <CardBody>
          <Form>
            <div
              className='edit_form'
              style={{
                width: 100 + "%",
              }}>
              <Row>
                <Col lg='12'>
                  <FormGroup id='proNameD'>
                    <label
                      className='form-control-label'
                      htmlFor='input-username'>
                      Name
                    </label>
                    <Input
                      className='form-control-alternative'
                      // defaultValue="lucky.jesse"
                      required
                      id='proName'
                      placeholder='Property Name'
                      type='text'
                    />
                  </FormGroup>
                </Col>
              </Row>
              <hr className='my-4' />
              {/* Address */}

              <h6 className='heading-small text-muted mb-4'>
                Contact Information
              </h6>
              <div className='pl-lg-4'>
                <Row>
                  <Col lg='6'>
                    <FormGroup id='proStateD'>
                      <label
                        className='form-control-label'
                        htmlFor='input-username'>
                        State
                      </label>
                      <Input
                        id='proState'
                        className='form-control-alternative'
                        // defaultValue="lucky.jesse"
                        required
                        placeholder='State'
                        type='text'
                      />
                    </FormGroup>
                  </Col>
                  <Col lg='6'>
                    <FormGroup id='proCityD'>
                      <label
                        className='form-control-label'
                        htmlFor='input-email'>
                        City
                      </label>
                      <Input
                        className='form-control-alternative'
                        id='proCity'
                        placeholder='City'
                        type='text'
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg='6'>
                    <FormGroup id='proLocationD'>
                      <label
                        className='form-control-label'
                        htmlFor='input-first-name'>
                        Location
                      </label>

                      <select
                        style={{ border: "1px solid #9393f3" }}
                        className='form-control-alternative form-control'
                        id='proLocation'>
                        {this.props.PropertyReducer.hasOwnProperty(
                          "propertyOptions"
                        )
                          ? this.props.PropertyReducer.propertyOptions[0].locations.map(
                              (data, i) => {
                                return <option value={data}>{data}</option>;
                              }
                            )
                          : null}
                      </select>
                    </FormGroup>
                  </Col>
                  <Col lg='6'>
                    <FormGroup id='proCountryD'>
                      <label
                        className='form-control-label'
                        htmlFor='input-last-name'>
                        Country
                      </label>
                      <Input
                        className='form-control-alternative'
                        //defaultValue="Jesse"
                        id='proCountry'
                        defaultValue={"Nigeria"}
                        placeholder='Country'
                        type='text'
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md='12'>
                    <FormGroup id='proAddressD'>
                      <label
                        className='form-control-label'
                        htmlFor='input-address'>
                        Address
                      </label>
                      <Input
                        className='form-control-alternative'
                        //defaultValue="Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09"
                        id='proAddress'
                        placeholder='Address'
                        rows='4'
                        type='textarea'
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <hr className='my-4' />
              {/* Address */}
              <h6 className='heading-small text-muted mb-4'>
                Other information
              </h6>
              <div className='pl-lg-4'>
                <Row>
                  <Col lg='4'>
                    <FormGroup id='proBedsD'>
                      <label
                        className='form-control-label'
                        htmlFor='input-city'>
                        Beds
                      </label>

                      <select
                        style={{ border: "1px solid #9393f3" }}
                        className='form-control-alternative form-control'
                        id='proBeds'>
                        {this.props.PropertyReducer.hasOwnProperty(
                          "propertyOptions"
                        )
                          ? this.props.PropertyReducer.propertyOptions[0].beds.map(
                              (data, i) => {
                                return <option value={data}>{data}</option>;
                              }
                            )
                          : null}
                      </select>
                    </FormGroup>
                  </Col>
                  <Col lg='4'>
                    <FormGroup id='proUnitsD'>
                      <label
                        className='form-control-label'
                        htmlFor='input-country'>
                        Total Units
                      </label>
                      <Input
                        className='form-control-alternative'
                        id='proUnits'
                        placeholder='Units'
                        type='text'
                      />
                    </FormGroup>
                  </Col>
                  <Col lg='4'>
                    <FormGroup id='proAvailableD'>
                      <label
                        className='form-control-label'
                        htmlFor='input-country'>
                        Available
                      </label>
                      <Input
                        className='form-control-alternative'
                        id='proAvailable'
                        placeholder='Number of Units Available'
                        type='text'
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg='4'>
                    <FormGroup id='proSeriesD'>
                      <label
                        className='form-control-label'
                        htmlFor='input-city'>
                        Series
                      </label>

                      <select
                        style={{ border: "1px solid #9393f3" }}
                        className='form-control-alternative form-control'
                        id='proSeries'>
                        {this.props.PropertyReducer.hasOwnProperty(
                          "propertyFeatures"
                        )
                          ? this.props.PropertyReducer.propertyFeatures.map(
                              (data, i) => {
                                return (
                                  <option value={data.id}>{data.id}</option>
                                );
                              }
                            )
                          : null}
                      </select>
                    </FormGroup>
                  </Col>
                  <Col lg='4'>
                    <FormGroup id='proTypeD'>
                      <label
                        className='form-control-label'
                        htmlFor='input-country'>
                        Type
                      </label>

                      <select
                        style={{ border: "1px solid #9393f3" }}
                        className='form-control-alternative form-control'
                        id='proType'>
                        {this.props.PropertyReducer.hasOwnProperty(
                          "propertyOptions"
                        )
                          ? this.props.PropertyReducer.propertyOptions[0].type.map(
                              (data, i) => {
                                return <option value={data}>{data}</option>;
                              }
                            )
                          : null}
                      </select>
                    </FormGroup>
                  </Col>

                  <Col lg='4'>
                    <FormGroup id='proTitleD'>
                      <label
                        className='form-control-label'
                        htmlFor='input-country'>
                        Title
                      </label>
                      <Input
                        className='form-control-alternative'
                        id='proTitle'
                        placeholder='Title'
                        type='text'
                      />
                    </FormGroup>
                  </Col>

                  <Col lg='4'>
                    <FormGroup id='proActiveD'>
                      <label
                        className='form-control-label'
                        htmlFor='input-country'>
                        Active
                      </label>

                      <select
                        style={{ border: "1px solid #9393f3" }}
                        className='form-control-alternative form-control'
                        id='proActive'>
                        <option value={false}>False</option>
                        {/* <option
                            value={true}
                            
                          >
                            True
                          </option> */}
                      </select>
                    </FormGroup>
                  </Col>

                  <Col lg='4'>
                    <FormGroup id='proPromoD'>
                      <label
                        className='form-control-label'
                        htmlFor='input-country'>
                        Promo
                      </label>

                      <select
                        style={{ border: "1px solid #9393f3" }}
                        className='form-control-alternative form-control'
                        id='proPromo'>
                        <option> -- No Promo --</option>

                        {this.props.PromoReducer.PromoactionsData &&
                          this.props.PromoReducer.PromoactionsData.map(
                            (data, i) => {
                              return <option value={data.id}>{data.id}</option>;
                            }
                          )}
                      </select>
                    </FormGroup>
                  </Col>

                  <Col lg='4'>
                    <FormGroup id='proPriceD'>
                      <label
                        className='form-control-label'
                        htmlFor='input-country'>
                        Outright Price
                      </label>
                      <Input
                        className='form-control-alternative'
                        id='proPrice'
                        placeholder='Price'
                        type='text'
                      />
                    </FormGroup>
                  </Col>

                  <Col lg='4'>
                    <FormGroup id='proInstallmentalPriceD'>
                      <label
                        className='form-control-label'
                        htmlFor='input-country'>
                        Installmental Price
                      </label>
                      <Input
                        className='form-control-alternative'
                        id='proInstallmentalPrice'
                        placeholder='Installmental Price'
                        type='text'
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <hr className='my-4' />
              {/* Description */}

              <div className='pl-lg-4'>
                <FormGroup id='proSummaryD'>
                  <label>Summary</label>
                  <Input
                    className='form-control-alternative'
                    placeholder='Property Summary'
                    rows='4'
                    id='proSummary'
                    type='textarea'
                  />
                </FormGroup>
              </div>
              <Row>
                <Col lg='6'></Col>

                <Col>
                  <Button
                    className='float-right'
                    style={{ marginRight: 15 }}
                    disabled={this.state.startSavingLoader}
                    color='success'
                    href='#stow'
                    type='submit'
                    onClick={async (e) => {
                      e.preventDefault();

                      document
                        .getElementById("proNameD")
                        .classList.remove("has-danger");
                      document
                        .getElementById("proStateD")
                        .classList.remove("has-danger");
                      document
                        .getElementById("proCityD")
                        .classList.remove("has-danger");
                      document
                        .getElementById("proLocationD")
                        .classList.remove("has-danger");
                      document
                        .getElementById("proCountryD")
                        .classList.remove("has-danger");
                      document
                        .getElementById("proAddressD")
                        .classList.remove("has-danger");
                      document
                        .getElementById("proBedsD")
                        .classList.remove("has-danger");
                      document
                        .getElementById("proUnitsD")
                        .classList.remove("has-danger");
                      document
                        .getElementById("proAvailableD")
                        .classList.remove("has-danger");

                      document
                        .getElementById("proSeriesD")
                        .classList.remove("has-danger");
                      document
                        .getElementById("proTypeD")
                        .classList.remove("has-danger");

                      document
                        .getElementById("proTitleD")
                        .classList.remove("has-danger");
                      document
                        .getElementById("proActiveD")
                        .classList.remove("has-danger");
                      document
                        .getElementById("proPriceD")
                        .classList.remove("has-danger");
                      document
                        .getElementById("proInstallmentalPriceD")
                        .classList.remove("has-danger");

                      if (document.getElementById("proName").value == "") {
                        document
                          .getElementById("proNameD")
                          .classList.add("has-danger");
                        document.getElementById("proName").focus();
                        document.getElementById(
                          "validate_form_msg"
                        ).style.display = "block";
                      } else if (
                        document.getElementById("proState").value == ""
                      ) {
                        document
                          .getElementById("proStateD")
                          .classList.add("has-danger");
                        document.getElementById("proState").focus();
                        document.getElementById(
                          "validate_form_msg"
                        ).style.display = "block";
                      } else if (
                        document.getElementById("proCity").value == ""
                      ) {
                        document
                          .getElementById("proCityD")
                          .classList.add("has-danger");
                        document.getElementById("proCity").focus();
                        document.getElementById(
                          "validate_form_msg"
                        ).style.display = "block";
                      } else if (
                        document.getElementById("proLocation").value == ""
                      ) {
                        document
                          .getElementById("proLocationD")
                          .classList.add("has-danger");
                        document.getElementById("proLocation").focus();
                        document.getElementById(
                          "validate_form_msg"
                        ).style.display = "block";
                      } else if (
                        document.getElementById("proCountry").value == ""
                      ) {
                        document
                          .getElementById("proCountryD")
                          .classList.add("has-danger");
                        document.getElementById("proCountry").focus();
                        document.getElementById(
                          "validate_form_msg"
                        ).style.display = "block";
                      } else if (
                        document.getElementById("proAddress").value == ""
                      ) {
                        document
                          .getElementById("proAddressD")
                          .classList.add("has-danger");
                        document.getElementById("proAddress").focus();
                        document.getElementById(
                          "validate_form_msg"
                        ).style.display = "block";
                      } else if (
                        document.getElementById("proBeds").value == ""
                      ) {
                        document
                          .getElementById("proBedsD")
                          .classList.add("has-danger");
                        document.getElementById("proBeds").focus();
                        document.getElementById(
                          "validate_form_msg"
                        ).style.display = "block";
                      } else if (
                        document.getElementById("proUnits").value == ""
                      ) {
                        document
                          .getElementById("proUnitsD")
                          .classList.add("has-danger");
                        document.getElementById("proUnits").focus();
                        document.getElementById(
                          "validate_form_msg"
                        ).style.display = "block";
                      } else if (
                        document.getElementById("proAvailable").value == ""
                      ) {
                        document
                          .getElementById("proAvailableD")
                          .classList.add("has-danger");
                        document.getElementById("proAvailable").focus();
                        document.getElementById(
                          "validate_form_msg"
                        ).style.display = "block";
                      } else if (
                        document.getElementById("proSeries").value == ""
                      ) {
                        document
                          .getElementById("proSeriesD")
                          .classList.add("has-danger");
                        document.getElementById("proSeries").focus();
                        document.getElementById(
                          "validate_form_msg"
                        ).style.display = "block";
                      } else if (
                        document.getElementById("proType").value == ""
                      ) {
                        document
                          .getElementById("proTypeD")
                          .classList.add("has-danger");
                        document.getElementById("proType").focus();
                        document.getElementById(
                          "validate_form_msg"
                        ).style.display = "block";
                      } else if (
                        document.getElementById("proTitle").value == ""
                      ) {
                        document
                          .getElementById("proTitleD")
                          .classList.add("has-danger");
                        document.getElementById("proTitle").focus();
                        document.getElementById(
                          "validate_form_msg"
                        ).style.display = "block";
                      } else if (
                        document.getElementById("proActive").value == ""
                      ) {
                        document
                          .getElementById("proActiveD")
                          .classList.add("has-danger");
                        document.getElementById("proActive").focus();
                        document.getElementById(
                          "validate_form_msg"
                        ).style.display = "block";
                      } else if (
                        document.getElementById("proPrice").value == ""
                      ) {
                        document
                          .getElementById("proPriceD")
                          .classList.add("has-danger");
                        document.getElementById("proPrice").focus();
                        document.getElementById(
                          "validate_form_msg"
                        ).style.display = "block";
                      } else if (
                        document.getElementById("proInstallmentalPrice")
                          .value == ""
                      ) {
                        document
                          .getElementById("proInstallmentalPriceD")
                          .classList.add("has-danger");
                        document
                          .getElementById("proInstallmentalPrice")
                          .focus();
                        document.getElementById(
                          "validate_form_msg"
                        ).style.display = "block";
                      } else {
                        this.setState({
                          startSavingLoader: true,
                        });

                        const property = {
                          name: document.getElementById("proName").value,
                          state: document.getElementById("proState").value,
                          city: document.getElementById("proCity").value,
                          location:
                            document.getElementById("proLocation").value,
                          country: document.getElementById("proCountry").value,
                          address: document.getElementById("proAddress").value,

                          beds: document.getElementById("proBeds").value,
                          totalUnits: parseInt(
                            document.getElementById("proUnits").value
                          ),
                          availableUnits: parseInt(
                            document.getElementById("proAvailable").value
                          ),

                          series: document.getElementById("proSeries").value,

                          type: document.getElementById("proType").value,
                          title: document.getElementById("proTitle").value,
                          active:
                            document.getElementById("proActive").value == "true"
                              ? true
                              : false,

                          //promo: document.getElementById('proPromo').value,

                          price: parseInt(
                            document.getElementById("proPrice").value
                          ),
                          installmentPrice: parseInt(
                            document.getElementById("proInstallmentalPrice")
                              .value
                          ),
                          summary: document.getElementById("proSummary").value,

                          featureList: [],
                          primaryPhoto: [
                            {
                              primaryPhoto: null,
                            },
                            {
                              src: null,
                            },
                          ],
                          logo: [
                            {
                              logo: null,
                            },
                            {
                              src: null,
                            },
                          ],
                          floorPlan: [],
                          photos: [],
                          pplan: [],
                          createdate:
                            firebase.firestore.FieldValue.serverTimestamp(),
                          createdby: this.props.currentUser.currentUserEmail,
                        };

                        const PropertyAll = {};

                        var home = this;

                        await firebase
                          .firestore()
                          .collection("property")

                          .add(property)
                          .then(function () {
                            home.setState({
                              startSavingLoader: false,
                            });

                            swal({
                              title: "Property Added!",
                              text:
                                "You have successfully Added " +
                                document.getElementById("proName").value +
                                " ",
                              icon: "success",
                              //buttons: false,
                            }).then(() => {
                              document.location.reload();
                            });

                            //home.props.history.push("/admin/property");
                          });
                      }
                    }}>
                    {this.state.startSavingLoader ? (
                      // <img src={require("assets/img/loader.gif")} />
                      <>
                        Adding... <i className='fa fa-spinner fa-spin'></i>
                      </>
                    ) : (
                      "Add Property"
                    )}
                  </Button>
                </Col>
              </Row>
            </div>{" "}
          </Form>
        </CardBody>
      </Card>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    //PropertySingle: (param) => dispatch(PropertySingle(param)),
  };
};

const mapStateToProps = (state) => ({
  PropertyReducer: state.PropertyReducer,
  PromoReducer: state.PromoReducer,
  currentUser: state.authReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddNewProperty);

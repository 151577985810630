import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  Badge,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";

import { connect } from "react-redux";
import { signOut } from "store/actions/authActions";
import { isEmpty } from "lodash";
import app from "./../../variables/base";
import api from "../../utils/config";

import firebase from "firebase/app";
import * as firebaseA from "firebase/app";
import "firebase/auth";
require("firebase/firestore");

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: api.REACT_APP_FIREBASE_APP_ID,
    authDomain: api.REACT_APP_FIREBASE_DOMAIN,
    databaseURL: api.REACT_APP_FIREBASE_DATABASE,
    projectId: api.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: api.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: api.REACT_APP_FIREBASE_SENDER_ID,
    appId: api.REACT_APP_FIREBASE_APP_ID,
    measurementId: api.REACT_APP_FIREBASE_MEASUREMENT_ID,
  });
}

//alert(JSON.stringify(firebase.default.auth))

class AdminNavbar extends React.Component {
  formatJSON(data) {
    var result = [];
    if (!Array.isArray(data)) {
      //if is not an array
      result.push(data); // push it as the first item of an array
    } else {
      result = data;
    }

    return result;
  }

  componentDidMount() {
    //firebaseA.firestore()
    //alert((this.formatJSON(this.props.currentUser)))
    //console.log(((this.props.currentUser.uid)))
    // firebaseA.firestore().collection("users").doc(uid)
  }

  render() {
    // alert( JSON.stringify((this.props.currentUser)))

    if (isEmpty(this.props.currentUser.currentUser)) {
      //firebase.auth.signOut()
      this.props.history.push("/auth/login");
    }

    return (
      <>
        <Navbar className='navbar-top navbar-dark' expand='md' id='navbar-main'>
          <Container fluid>
            <Link
              className='h4 mb-0 text-white text-uppercase d-none d-lg-inline-block'
              to='/'>
              {this.props.brandText}
            </Link>
            <Form className='navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto'>
              <FormGroup className='mb-0'>
                <InputGroup className='input-group-alternative'>
                  <InputGroupAddon addonType='prepend'>
                    <InputGroupText>
                      <i className='fas fa-search' />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder='Search' type='text' />
                </InputGroup>
              </FormGroup>
            </Form>
            <Nav className='align-items-center d-none d-md-flex' navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className='pr-0' nav>
                  <Media className='align-items-center'>
                    <span className='avatar avatar-sm rounded-circle'>
                      <img
                        alt='...'
                        src={require("assets/img/theme/team-4-800x800.jpg")}
                      />
                    </span>
                    <Media className='ml-2 d-none d-lg-block'>
                      <span className='mb-0 text-sm font-weight-bold'>
                        {this.props.currentUser.hasOwnProperty("UserData")
                          ? this.props.currentUser.UserData.hasOwnProperty(
                              "firstName"
                            )
                            ? this.props.currentUser.UserData.firstName +
                              " " +
                              this.props.currentUser.UserData.lastName
                            : null
                          : null}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className='dropdown-menu-arrow' right>
                  <DropdownItem className='noti-title' header tag='div'>
                    <h6 className='text-overflow m-0'>Welcome!</h6>
                  </DropdownItem>
                  <DropdownItem to='/admin/user-profile' tag={Link}>
                    <i className='ni ni-single-02' />
                    <span>My profile</span>
                  </DropdownItem>
                  <DropdownItem to='/admin/user-profile' tag={Link}>
                    <i className='ni ni-settings-gear-65' />
                    <span>Settings</span>
                  </DropdownItem>
                  <DropdownItem to='/admin/user-profile' tag={Link}>
                    <i className='ni ni-calendar-grid-58' />
                    <span>Activity</span>
                  </DropdownItem>

                  <DropdownItem to='/admin/user-profile' tag={Link}>
                    <i className='ni ni-support-16' />
                    <span>Support</span>
                  </DropdownItem>

                  {this.props.currentUser.hasOwnProperty("UserData") ? (
                    /*  (
                            this.props.currentUser.hasOwnProperty('UserData')?
                               ((this.props.currentUser.UserData.hasOwnProperty('role')
                                        ?this.props.currentUser.UserData.role :null)):null
                          ) */

                    <DropdownItem
                      className='bg-default'
                      to='/admin/user-profile'
                      tag={Link}>
                      <i
                        className='ni ni-sound-wave'
                        style={{ color: "white" }}
                      />
                      <span style={{ color: "white" }}>
                        Role{" "}
                        <Badge
                          color='success'
                          href='#pablo'
                          style={{ color: "white" }}
                          onClick={(e) => e.preventDefault()}>
                          {
                            //this.props.currentUser.UserData.role
                            this.props.currentUser.hasOwnProperty("UserData")
                              ? this.props.currentUser.UserData.hasOwnProperty(
                                  "role"
                                )
                                ? this.props.currentUser.UserData.role
                                : null
                              : null
                          }
                        </Badge>
                      </span>
                    </DropdownItem>
                  ) : null}

                  <DropdownItem divider />
                  <DropdownItem
                    href='#stow'
                    onClick={(e) => this.props.signout()}>
                    <i className='ni ni-user-run' />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signout: () => dispatch(signOut()),
  };
};

const mapStateToProps = (state) => ({
  currentUser: state.authReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbar);
//export default AdminNavbar;

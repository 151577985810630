import axios from "axios";

import api from "../../utils/config";

import  firebase from "firebase/app";
import "firebase/auth";
import app from './../../variables/base'
import swal from "sweetalert";
import { isEmpty } from "lodash";


  
  if (!firebase.apps.length)
    {
    

        firebase.initializeApp({
            apiKey: api.REACT_APP_FIREBASE_APP_ID,
            authDomain: api.REACT_APP_FIREBASE_DOMAIN,
            databaseURL: api.REACT_APP_FIREBASE_DATABASE,
            projectId: api.REACT_APP_FIREBASE_PROJECT_ID,
            storageBucket: api.REACT_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: api.REACT_APP_FIREBASE_SENDER_ID,
            appId: api.REACT_APP_FIREBASE_APP_ID,
            measurementId: api.REACT_APP_FIREBASE_MEASUREMENT_ID,
          });

    }


    
    export const FListCount = (count) =>{

      //alert(count+'====>')
      return (dispatch, getState) => {
         dispatch({ type: "FLIST_COUNT", payload: count });
      }

    }

export const setFListData = (FListCountData) => {

        return {
             type: "FLIST_SUCCESS",
             payload: FListCountData,

        };
};


export const FListActions =  (credentials) => {
  return async (dispatch, getState)  => {

    dispatch({ type: "FLIST_LOADING", loading: true });

    try {

            await firebase
                .firestore()
                .collection("featureList")
                 //.orderBy("order","asc")
                .onSnapshot( snapshot => {

                     if (snapshot.empty) {
                        return;
                    }
                    
                      var FListCountData = [];
                      //var FList ={}
                        snapshot.forEach(async doc => {
                            
                                    //console.log(FList.data())

                            FListCountData.push({
                              id: doc.id,
                            //FList: (isEmpty(FList)?FList:FList.data()),
                            ...doc.data()
                            });
                        });

                       // alert(JSON.stringify(FListCountData.slice(0,2)))

                dispatch(setFListData((FListCountData)))
                  
              })


    }
    catch(error)
    {

        //alert('error')
         dispatch({ type: "FLIST_ERROR",  payload: error, });

    }





  }


}
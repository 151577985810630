import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// reactstrap components
import {
  Button,
  Card,
  CardImg,
  CardHeader,
  Modal,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";

import swal from "sweetalert";
import firebase from "firebase/app";
import { connect } from "react-redux";
import {propertyOptionsAction } from "./../../../store/actions/propertyActions";



class TypeData extends React.Component {

    state = {   
        propertyType:this.props.PropertyReducer.propertyOptions[0].type,
        startSavingLoader:false,
    };

    constructor(props) {
      super(props);
      //this.activeRoute.bind(this);
    }


  removeByValue = (array, value) => {
    return array.filter(function (elem, _index) {
      return value != elem;
    });
  };
  
    componentDidMount(props)
    {
      this.props.propertyOptionsAction()
    }

    render ()
    {
        return(             
            <div class="container">


                      <div className="bg-dark"
                          style={{
                            background: '#fff',
                            padding: '10px',
                            borderRadius: '5px',
                            boxShadow: '0 0 12px #e2e7ef',
                            marginBottom: '10px',
                            fontSize:11,
                            height: '65px',

                          }}>

                            <Row>
                              <Col lg='7'>
                              
                              <FormGroup>
                                
                                <Input
                                  className="form-control-alternative"
                                  id="proType"
                                  placeholder="Enter Name of Type"
                                  
                                  type="text"
                                />
                              </FormGroup>
                              
                              </Col>

                              <Col lg='5'>
                                <span id='save_type'></span>
                                 <Button                          
                                  className="mr-2"
                                  style={{ float: "right"}}
                                  title={'Add Type'}
                                  color="info"
                                  href="#stow"
                                  onClick={async (e) => {

                                    if(document.getElementById('proType').value==='')
                                    {

                                      alert('You must provide a name for the new Property Type to continue')

                                    }
                                    else{


                                      this.setState((prevState) => ({
                                        propertyType: [...prevState.propertyType, document.getElementById('proType').value],
                                        startSavingLoader:true,
                                        
                                      }),async ()=>{
                                        



                                        var home = this;

                                      let data ={
                                        type : this.state.propertyType
                                      }

                                      

                                      await firebase
                                        .firestore()
                                        .collection("data")
                                        .doc('options')
                                        .update(data)
                                        .then(function () {
                                          
                                          home.setState({
                                            startSavingLoader: false,
                                          });

                                         
              
                                          swal({
                                            title: "Type Added!",
                                            text:"You have successfully Added " + document.getElementById('proType').value + " ",
                                            icon: "success",
                                            
                                          }).then(()=>{
                                                //document.location.reload()
                                                document.getElementById('proType').value =''
                                            });
              
                                        }); 
                                      });

                                       

                                    }
                                  }}
                                  >
                                   {this.state.startSavingLoader ? (
                                    // <img src={require("assets/img/loader.gif")} />
                                    <>
                                      Saving...{" "}
                                      <i className="fa fa-spinner fa-spin"></i>
                                    </>
                                  ) : (
                                    "Save Type"
                                  )}
                                  </Button>

                              </Col>

                            </Row>



                            </div>
            


              <div class="row">
              {this.state.propertyType &&
                  this.state.propertyType.map((data, i) =>
              { return (<div class="col-12" 
                          style={{
                            background: '#fff',
                            padding: '10px',
                            borderRadius: '5px',
                            boxShadow: '0 0 12px #e2e7ef',
                            marginBottom: '10px',
                            fontSize:11,

                          }}>
                <div>
                  
                  
                  
                    &nbsp;&nbsp;&nbsp;<span style={{fontSize:14}}>{data} </span>
                  
                </div>            
                
                <span id={"delete_type_"+i} className='float-right' style={{float:'right'}}></span>

                <Button
               
                className="mr-2"
                style={{ float: "right",marginTop:-25,fontSize:9 }}
                title={'Delete  '+data}
                color="info"
                href="#stow"
                onClick={(e) => {

                  swal({
                    title: "Delete Type ?",
                    // html:true,
                    text:
                      "Delete " +data + " from List",
                    icon: "warning",
                    buttons: true,
                    buttons: {
                      cancel: "Cancel",
                      catch: {
                        text: "Delete",
                        //value: "catch",
                      },
                      //defeat: true,
                    },
                    dangerMode: true,
                  }).then((willDelete) => {
                    if (willDelete) {

                     e.preventDefault();  
                     let arr =    this.state.propertyType;

                     
                     document.getElementById('delete_type_'+i).innerHTML= '<div style="font-size:9px">Deleting Type... <i class="fa fa-spinner fa-spin"></i></div>';
                     
                     this.setState(
                      {
                        propertyType: this.removeByValue(
                          arr,
                          data
                        ),
                      },
                      async () => {


                       let dataType ={ type: this.state.propertyType  }

                       await firebase
                       .firestore()
                       .collection("data")
                       .doc('options')
                       .update(dataType)
                       .then(async () => {


                        //document.getElementById('delete_type_'+i).innerHTML= '';

                        swal(
                          "Type Deleted",
                          "You have successfully Deleted the Type - " +  data,
                          {
                            icon: "success",
                          }
                        ).then((value) => {
                          //document.location.reload()
                        });


                       })


                      });


                    }

                  })

                  

                                             
                
                }}
                size="sm"
              >
               Delete
              </Button>

             
                
                </div>) } 
                   
                   )
                     
                    }

              </div>
            </div>
)
    }

}




const mapDispatchToProps = (dispatch) => {
    return {
     // PropertyEdit: (param) => dispatch(PropertyEdit(param)),
     // PropertySingle: (param) => dispatch(PropertySingle(param)),
      propertyOptionsAction: (param) => dispatch(propertyOptionsAction(param)),
      //promoActions: (param) => dispatch(promoActions(param)),
    };
  };
  
  const mapStateToProps = (state) => ({
    //currentUser: state.authReducer,
    PropertyReducer: state.PropertyReducer,
    PromoReducer: state.PromoReducer,
    FeaturedListReducer: state.FeaturedListReducer,
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(TypeData);

    
import React from "react";
// import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
// import { PropTypes } from "prop-types";

// reactstrap components
import {
  Button,
  // Card,
  // CardImg,
  // CardHeader,
  // Modal,
  // CardBody,
  // CardTitle,
  // Collapse,
  // DropdownMenu,
  // DropdownItem,
  // UncontrolledDropdown,
  // DropdownToggle,
  FormGroup,
  // Form,
  Input,
  // InputGroupAddon,
  // InputGroupText,
  // InputGroup,
  // Media,
  // NavbarBrand,
  // Navbar,
  // NavItem,
  // NavLink,
  // Nav,
  // Progress,
  // Table,
  // Container,
  Row,
  Col,
} from "reactstrap";

import swal from "sweetalert";
import firebase from "firebase/app";
import { connect } from "react-redux";
import { propertyOptionsAction } from "./../../../store/actions/propertyActions";

class PlanData extends React.Component {
  state = {
    propertyPlan: this.props.PropertyReducer.propertyOptions[0].Plan,
    startSavingLoader: false,
  };

  constructor(props) {
    super(props);
    //this.activeRoute.bind(this);
  }

  removeByValue = (array, value) => {
    return array.filter(function (elem, _index) {
      return value != elem;
    });
  };

  componentDidMount(props) {
    this.props.propertyOptionsAction();
  }

  render() {
    console.log("this.props.PropertyReducer.propertyOptions =>",this.props.PropertyReducer.propertyOptions)
    return (
      <div class='container'>
          <div
            className='bg-dark'
            style={{
              background: "#fff",
              padding: "10px",
              borderRadius: "5px",
              boxShadow: "0 0 12px #e2e7ef",
              marginBottom: "10px",
              fontSize: 11,
              height: "65px",
            }}>
            <Row>
              <Col xs='8'>
                <FormGroup>
                  <Input
                    className='form-control-alternative'
                    id='proPlan_edit'
                    placeholder='Enter Name of Plan'
                    type='text'
                  />
                </FormGroup>
              </Col>

              <Col xs='4'>
                <span id='save_plan'></span>
                <Button
                  className='mr-2'
                  style={{ float: "right" }}
                  title={"Add  Plan"}
                  color='info'
                  href='#stow'
                  onClick={async (e) => {
                    if (document.getElementById("proPlan_edit").value === "") {
                      alert(
                        "You must provide a name for the new plan to continue"
                      );
                    } else {
                      this.setState(
                        (prevState) => ({
                          propertyPlan: [
                            ...prevState.propertyPlan,
                            parseInt(
                              document.getElementById("proPlan_edit").value
                            ),
                          ],
                          startSavingLoader: true,
                        }),
                        async () => {
                          var home = this;

                          let data = {
                            Plan: this.state.propertyPlan,
                          };

                          await firebase
                            .firestore()
                            .collection("data")
                            .doc("options")
                            .update(data)
                            .then(function () {
                              home.setState({
                                startSavingLoader: false,
                              });

                              swal({
                                title: "Plan Added!",
                                text:
                                  "You have successfully Added " +
                                  document.getElementById("proPlan_edit")
                                    .value +
                                  " ",
                                icon: "success",
                              }).then(() => {
                                //document.location.reload()
                                document.getElementById("proPlan_edit").value =
                                  "";
                              });
                            });
                        }
                      );
                    }
                  }}>
                  {this.state.startSavingLoader ? (
                    // <img src={require("assets/img/loader.gif")} />
                    <>
                      Saving... <i className='fa fa-spinner fa-spin'></i>
                    </>
                  ) : (
                    "Save Plan"
                  )}
                </Button>
              </Col>
            </Row>
          </div>
      

        <div class='row'>
          {this.state.propertyPlan &&
            this.state.propertyPlan.map((data, i) => {
              return (
                <div
                  class='col-12'
                  style={{
                    background: "#fff",
                    padding: "10px",
                    borderRadius: "5px",
                    boxShadow: "0 0 12px #e2e7ef",
                    marginBottom: "10px",
                    fontSize: 11,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "end",
                  }}>
                  <div>
                    &nbsp;&nbsp;&nbsp;
                    <span style={{ fontSize: 14 }}>{data} </span>
                  </div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                      <span
                        id={"delete_plan_" + i}
                        className='float-right'
                        style={{ float: "right" }}></span>
                      <Button
                        className='mr-2'
                        style={{ float: "right", marginTop: -25, fontSize: 9 }}
                        title={"Delete  " + data}
                        color='info'
                        href='#stow'
                        onClick={(e) => {
                          swal({
                            title: "Delete Plan ?",
                            // html:true,
                            text: "Delete " + data + " from List",
                            icon: "warning",
                            buttons: true,
                            buttons: {
                              cancel: "Cancel",
                              catch: {
                                text: "Delete",
                                //value: "catch",
                              },
                              //defeat: true,
                            },
                            dangerMode: true,
                          }).then((willDelete) => {
                            if (willDelete) {
                              e.preventDefault();
                              let arr = this.state.propertyPlan;

                              document.getElementById(
                                "delete_plan_" + i
                              ).innerHTML =
                                '<div style="font-size:9px">Deleting Plan... <i class="fa fa-spinner fa-spin"></i></div>';

                              this.setState(
                                {
                                  propertyPlan: this.removeByValue(arr, data),
                                },
                                async () => {
                                  let dataPlan = {
                                    Plan: this.state.propertyPlan,
                                  };

                                  await firebase
                                    .firestore()
                                    .collection("data")
                                    .doc("options")
                                    .update(dataPlan)
                                    .then(async () => {
                                      //document.getElementById('delete_plan_'+i).innerHTML= '';

                                      swal(
                                        "Plan Plan",
                                        "You have successfully Deleted the Plan - " +
                                          data,
                                        {
                                          icon: "success",
                                        }
                                      ).then((value) => {
                                        //document.location.reload()
                                      });
                                    });
                                }
                              );
                            }
                          });
                        }}
                        size='sm'>
                        Delete
                      </Button>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // PropertyEdit: (param) => dispatch(PropertyEdit(param)),
    // PropertySingle: (param) => dispatch(PropertySingle(param)),
    propertyOptionsAction: (param) => dispatch(propertyOptionsAction(param)),
    //promoActions: (param) => dispatch(promoActions(param)),
  };
};

const mapStateToProps = (state) => ({
  //currentUser: state.authReducer,
  PropertyReducer: state.PropertyReducer,
  PromoReducer: state.PromoReducer,
  FeaturedListReducer: state.FeaturedListReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanData);

import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// reactstrap components
import {
  Button,
  Card,
  CardImg,
  CardHeader,
  Modal,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";

import swal from "sweetalert";
import firebase from "firebase/app";
import  'firebase/storage'; 
import { connect } from "react-redux";
import {propertyOptionsAction } from "./../../../store/actions/propertyActions";



class FeatureData extends React.Component {

    state = {   
        propertyFeature:this.props.FeaturedListReducer.FListactionsData,
        startSavingLoader:false,
        startEditSavingLoader:false,
        showEditFeatureModal:false,
        editName:null,
        editId:null,
        editImg:null
    };

    constructor(props) {
      super(props);
      //this.activeRoute.bind(this);
    }


  removeByValue = (array, value) => {
    return array.filter(function (elem, _index) {
      return value != elem;
    });
  };


  uuidv4 =()=> {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }



 extractFileExtension =(filename)=>{
  //alert(filename)
 var ext = /(?:\.([^.]+))?$/.exec(filename);
 if (ext != null && ext[0] != null) {
   return ext[0];
 } else {
   return '';
 } 
}

  
    componentDidMount(props)
    {
      this.props.propertyOptionsAction()
    }

    render ()
    {
        return(             
            <div className="container">


<Modal id="model_featured_edit"
                          className="modal-dialog-centered"
                          isOpen={this.state.showEditFeatureModal}
                        // toggle={() => this.toggleModal("exampleModal")}
                        >

                        <div className="modal-header">
                            <h5 className="modal-title" id="">
                              EDIT <span id="featuerd_edit_title"></span>
                            </h5>
                            <button
                              aria-label="Close"
                              className="close"
                              data-dismiss="modal"
                              type="button"
                              onClick={(e) => {
                                e.preventDefault(); 
                                this.setState({showEditFeatureModal:false});
                                
                                }}
                            
                            >
                              <span aria-hidden={true}>×</span>
                              
                            </button>
                          </div>
                          <div className="modal-body">

                          <Row>
                              <Col lg='12'>
                              
                              <FormGroup>
                                
                                <Input
                                  className="form-control-alternative"
                                  id="proFeatureEdit"
                                  placeholder="Enter Name of Feature"
                                  
                                  type="text"
                                />

                                
                                </FormGroup>
                              
                              </Col>

                              <Col lg='8'>

                              <FormGroup>

                              <Input
                                  className="form-control alternative"
                                  id="proFeaturePhotoEdit"
                                  style={{marginTop:15}}
                                  accept="image/*"
                                  type="file"
                                />
                              </FormGroup>

                              </Col>

                              <Col>
                              <CardImg
                                    className="text-center"
                                    style={{ height: 60, width:60,marginRight:10}}
                                      src={this.state.editImg} 
                                    top
                                  />
                              
                              </Col>

                              <Col lg='12' style={{marginTop:-15}}>
                                <span id='save_edit_feature'></span>


                                <Button    block                       
                                  className="mr-0"
                                  style={{ float: "right",cursor:'pointer'}}
                                  disabled={this.state.startEditSavingLoader}
                                  title={'Add  Feature'}
                                  color="info"
                                  href="#stow"
                                  onClick={async (e) => {

                                    var proFeaturePhotoEdit = document.getElementById("proFeaturePhotoEdit"); 
                                    var proFeaturePhotoDataEdit = document.getElementById("proFeaturePhotoEdit").files[0];



                                    if(document.getElementById('proFeatureEdit').value==='')
                                    {

                                      //alert('You must provide a name for the new feature to continue')

                                      swal({
                                        title:"Feature List name is Empty",
                                        text:"You must provide a name for the new feature to continue",
                                        icon: "error",
                                        
                                      })

                                    }
                                    else if (proFeaturePhotoEdit.files.length == 0)
                                    {

                                      swal({
                                        title:"Feature List image is Empty",
                                        text:"You must provide an image file for the new feature to continue",
                                        icon: "error",
                                        
                                      })

                                    }
                                    else{

                                      


                                      this.setState({startEditSavingLoader:true});

                                      var home = this

                                      //delete old image
                                      const delImage = firebase.storage().ref();
                                      delImage.child(this.state.editImg).delete();


                                      const image = proFeaturePhotoDataEdit;
                                      const fileName = this.uuidv4()+this.extractFileExtension(image.name); 
                                      const storage = firebase.storage();
                                      const uploadPhotoTask = storage.ref('featureList/'+fileName).put(image);


                                      uploadPhotoTask.on(
                                        "state_changed",
                                        snapshot=>{},
                                        error=>{
                                    

                                          swal({
                                            title:"Feature Image Upload Error",
                                            text:"An unexpected error occured while uploading your feature image.\n Kindly confirm your internet connection or contact an admin",
                                            icon: "error",
                                            
                                          })
                                          
                                          
                                          //console.log(error)
                                        },
                                        async ()=>{
                                          storage
                                          .ref("featureList")
                                          .child(fileName)
                                          .getDownloadURL()
                                          .then( async (url)=>{


                                            var now = new Date()
                                                var formattedToday = now.toLocaleDateString('en-GB', {
                                                  day : 'numeric',
                                                  month : 'short',
                                                  year : 'numeric'
                                                }).split(' ').join('-')+ '' 

                                          var FeatureListJson ={
                                                createdate:formattedToday,
                                                createdby: this.props.currentUser.UserData.email,
                                                icon:{
                                                      icon:image.name,
                                                      src:url,
                                                  },
                                                lastupdate:formattedToday,
                                                text: document.getElementById('proFeatureEdit').value,
                                                updatedby:this.props.currentUser.UserData.email
                                            }



                                           

                                            await firebase
                                            .firestore()
                                            .collection("featureList")
                                            .doc(this.state.editId)
                                            .update(FeatureListJson)
                                            .then(function () {

                                              home.setState({

                                                startEditSavingLoader:false,
                                                showEditFeatureModal:false,

                                              })


                                              swal("Feature Updated", 'You have  updated  :: '+home.state.editName+' :: successfully', "success")
                                              .then((value) => {
                                                
                                                document.location.reload()
                                              });


                                            })




                                        })
                                      }
                                        )


                                    }
                                  }} >
                                    
                                    {this.state.startEditSavingLoader? (
                                    // <img src={require("assets/img/loader.gif")} />
                                    <>
                                      Updating... <i className="fa fa-spinner fa-spin"></i>
                                    </>
                                  ) : (
                                    "Update"
                                  )} 

                                    </Button>



                              </Col>

                              </Row>

                          </div>
                  </Modal>



                      <div className="bg-dark"
                          style={{
                            background: '#fff',
                            padding: '10px',
                            borderRadius: '5px',
                            boxShadow: '0 0 12px #e2e7ef',
                            marginBottom: '10px',
                            fontSize:11,
                            height: 'auto',

                          }}>

                            <Row>
                              <Col lg='12'>
                              
                              <FormGroup>
                                
                                <Input
                                  className="form-control-alternative"
                                  id="proFeature"
                                  placeholder="Enter Name of Feature"
                                  
                                  type="text"
                                />

                                
                                <Input
                                  className="form-control alternative"
                                  id="proFeaturePhoto"
                                  style={{marginTop:15}}
                                  accept="image/*"
                                  type="file"
                                />
                              </FormGroup>
                              
                              </Col>

                              <Col lg='12' style={{marginTop:-15}}>
                                <span id='save_feature'></span>
                                 <Button    block                       
                                  className="mr-0"
                                  style={{ float: "right",cursor:'pointer'}}
                                  disabled={this.state.startSavingLoader}
                                  title={'Add  Feature'}
                                  color="info"
                                  href="#stow"
                                  onClick={async (e) => {

                                    var proFeaturePhoto = document.getElementById("proFeaturePhoto"); 
                                    var proFeaturePhotoData = document.getElementById("proFeaturePhoto").files[0];



                                    if(document.getElementById('proFeature').value==='')
                                    {

                                      //alert('You must provide a name for the new feature to continue')

                                      swal({
                                        title:"Feature List name is Empty",
                                        text:"You must provide a name for the new feature to continue",
                                        icon: "error",
                                        
                                      })

                                    }
                                    else if (proFeaturePhoto.files.length == 0)
                                    {

                                      swal({
                                        title:"Feature List image is Empty",
                                        text:"You must provide an image file for the new feature to continue",
                                        icon: "error",
                                        
                                      })

                                    }
                                    else{

                                      this.setState({startSavingLoader:true});

                                      var home = this

                                      const image = proFeaturePhotoData;
                                      const fileName = this.uuidv4()+this.extractFileExtension(image.name); 
                                      const storage = firebase.storage();
                                      const uploadPhotoTask = storage.ref('featureList/'+fileName).put(image);


                                      uploadPhotoTask.on(
                                        "state_changed",
                                        snapshot=>{},
                                        error=>{
                                    

                                          swal({
                                            title:"Feature Image Upload Error",
                                            text:"An unexpected error occured while uploading your feature image.\n Kindly confirm your internet connection or contact an admin",
                                            icon: "error",
                                            
                                          })
                                          
                                          
                                          //console.log(error)
                                        },

                                         async ()=>{
                                            storage
                                            .ref("featureList")
                                            .child(fileName)
                                            .getDownloadURL()
                                            .then( async (url)=>{


                                              var now = new Date()
                                                  var formattedToday = now.toLocaleDateString('en-GB', {
                                                    day : 'numeric',
                                                    month : 'short',
                                                    year : 'numeric'
                                                  }).split(' ').join('-')+ '' 

                                            var FeatureListJson ={
                                                  createdate:formattedToday,
                                                  createdby: this.props.currentUser.UserData.email,
                                                  icon:{
                                                        icon:image.name,
                                                        src:url,
                                                    },
                                                  lastupdate:formattedToday,
                                                  text: document.getElementById('proFeature').value,
                                                  updatedby:this.props.currentUser.UserData.email
                                              }


                                              await firebase
                                              .firestore()
                                              .collection("featureList")
                                              
                                              .add(FeatureListJson)
                                              .then(function () {

                                                home.setState({

                                                  startSavingLoader:false,

                                                })


                                                swal("Feature added to list", 'You have successfully added '
                                                      + document.getElementById('proFeature').value+' to the featured list', "success")
                                                .then((value) => {
                                                  
                                                  document.location.reload()
                                                });


                                              })




                                          })
                                        }


                                          


                                       
                                        )
                                        



                                      

                                       

                                    }
                                  }}
                                  >
                                   {this.state.startSavingLoader ? (
                                    // <img src={require("assets/img/loader.gif")} />
                                    <>
                                      Saving...{" "}
                                      <i className="fa fa-spinner fa-spin"></i>
                                    </>
                                  ) : (
                                    "Save Feature"
                                  )}
                                  </Button>

                              </Col>

                            </Row>



                            </div>
            


              <div className="row">
              {this.state.propertyFeature &&
                  this.state.propertyFeature.map((data, i) =>
              { return (<div className="col-12" 
                          style={{
                            background: '#fff',
                            padding: '10px',
                            borderRadius: '5px',
                            boxShadow: '0 0 12px #e2e7ef',
                            marginBottom: '10px',
                            fontSize:11,

                          }}>
                <div>
                                  {/* <i className=" ni ni-active-40" /> */}
                                  <CardImg
                                    className="text-center"
                                    style={{ height: 25, width: 25,marginRight:10}}
                                      src={data.icon.src} 
                                    top
                                  />
                                  <span style={{ fontSize: 8 }}>
                                    {data.text} 
                                  </span>
                                </div>          
                
                <span id={"delete_feature_"+i} className='float-right' style={{float:'right'}}></span>


                <div><Button 
                 //className="mr-2"
                 data-name={data.text}
                 data-id={data.id}
                 data-img={data.icon.src}
                 style={{ marginTop:-15,fontSize:9 ,height:15,left:300,paddingBottom:20}}
                 title={'Edit  '+data}
                 color="primary"
                 href="#stow"
                 onClick={(e)=>{

                  let button = e
                  let home = this

                  this.setState({
                    editName :button.target.getAttribute('data-name'),
                    editImg: button.target.getAttribute('data-img'),
                    editId:button.target.getAttribute('data-id')
                  })
                 
                   this.setState({showEditFeatureModal:true},()=>{

                     
                    var tt = setTimeout(function(){
                      document.getElementById('featuerd_edit_title').innerHTML=home.state.editName.toUpperCase()
                      document.getElementById('proFeatureEdit').value=home.state.editName

                      //

                      clearTimeout(tt);

                    },800)
                   })
                 }}
                >Edit</Button></div>

                <Button
               
                className="mr-2"
                
                style={{ float: "right",marginTop:-25,fontSize:9 }}
                title={'Delete  '+data}
                color="info"
                href="#stow"
                onClick={(e) => {

                  swal({
                    title: "Delete Feature ?",
                    // html:true,
                    text:
                      "Delete " +data + " from List",
                    icon: "warning",
                    buttons: true,
                    buttons: {
                      cancel: "Cancel",
                      catch: {
                        text: "Delete",
                        //value: "catch",
                      },
                      //defeat: true,
                    },
                    dangerMode: true,
                  }).then((willDelete) => {
                    if (willDelete) {

                     e.preventDefault();  
                     let arr =    this.state.propertyFeature;

                     
                     document.getElementById('delete_feature_'+i).innerHTML= '<div style="font-size:9px">Deleting Feature... <i class="fa fa-spinner fa-spin"></i></div>';
                     
                     this.setState(
                      {
                        propertyFeature: this.removeByValue(
                          arr,
                          data
                        ),
                      },
                      async () => {


                       let dataFeature ={ feature: this.state.propertyFeature  }

                       await firebase
                       .firestore()
                       .collection("data")
                       .doc('options')
                       .update(dataFeature)
                       .then(async () => {


                        //document.getElementById('delete_feature_'+i).innerHTML= '';

                        swal(
                          "Feature Deleted",
                          "You have successfully Deleted the Feature - " +  data,
                          {
                            icon: "success",
                          }
                        ).then((value) => {
                          //document.location.reload()
                        });


                       })


                      });


                    }

                  })

                  

                                             
                
                }}
                size="sm"
              >
               Delete
              </Button>

             
                
                </div>) } 
                   
                   )
                     
                    }

              </div>
            </div>
)
    }

}




const mapDispatchToProps = (dispatch) => {
    return {
     // PropertyEdit: (param) => dispatch(PropertyEdit(param)),
     // PropertySingle: (param) => dispatch(PropertySingle(param)),
      propertyOptionsAction: (param) => dispatch(propertyOptionsAction(param)),
      //promoActions: (param) => dispatch(promoActions(param)),
    };
  };
  
  const mapStateToProps = (state) => ({
    currentUser: state.authReducer,
    PropertyReducer: state.PropertyReducer,
    PromoReducer: state.PromoReducer,
    FeaturedListReducer: state.FeaturedListReducer,
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(FeatureData);

    
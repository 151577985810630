import React from "react";
// node.js library that concatenates classes (strings)

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  Modal,
  Form,
  FormGroup,
  Input,
  Badge,
  Alert,
  UncontrolledAlert,
} from "reactstrap";

import axios from "axios";


import moment from "moment";

import firebase from "firebase/app";
import "firebase/storage";
import { connect } from "react-redux";

import ContentLoader, { BulletList, Code } from "react-content-loader";

import api from "../../../utils/config";
import {
  currencyFormat,
  dateFormater,
  UserRolePermission,
} from "../../../utils/functions";

//import "firebase/auth";
import { isEmpty } from "lodash";
import swal from "sweetalert";

class AddNewCustomer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startSavingLoader: false,
    };
  }

  componentDidMount(props) {
    if (!firebase.apps.length) {
      firebase.initializeApp({
        apiKey: api.REACT_APP_FIREBASE_APP_ID,
        authDomain: api.REACT_APP_FIREBASE_DOMAIN,
        databaseURL: api.REACT_APP_FIREBASE_DATABASE,
        projectId: api.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: api.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: api.REACT_APP_FIREBASE_SENDER_ID,
        appId: api.REACT_APP_FIREBASE_APP_ID,
        measurementId: api.REACT_APP_FIREBASE_MEASUREMENT_ID,
      });
    }
  }

  render() {
    const passwordRand = Math.random().toString(36).slice(-8);
    return (
      <Card className="bg-secondary shadow">
        <CardHeader>
          <UncontrolledAlert
            id="validate_form_msg"
            className=" alert-warning"
            style={{ display: "none" }}
            fade={false}
          >
            <span className="alert-inner--icon">
              <i className="ni ni-like-2" />
            </span>{" "}
            <span className="alert-inner--text">
              <strong>Validation!</strong> Some important fields are not filled
              out properly
            </span>
          </UncontrolledAlert>
        </CardHeader>
        <CardBody>
          <Form>
            <div
              className="edit_form"
              style={{
                width: 100 + "%",
              }}
            >
              <Row>
                <Col lg="6">
                  <FormGroup id="firstNameD">
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Firstname
                    </label>
                    <Input
                      className="form-control-alternative"
                      // defaultValue="lucky.jesse"
                      required
                      id="firstName"
                      placeholder="First Name"
                      type="text"
                    />
                  </FormGroup>
                </Col>

                <Col lg="6">
                  <FormGroup id="lastNameD">
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Lastname
                    </label>
                    <Input
                      className="form-control-alternative"
                      // defaultValue="lucky.jesse"
                      required
                      id="lastName"
                      placeholder="Last Name"
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col lg="4">
                  <FormGroup id="countryFlagD">
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Country
                    </label>
                    <Input
                      className="form-control-alternative"
                      defaultValue="Nigeria"
                      required
                      id="countryFlag"
                      placeholder="Country"
                      type="text"
                    />
                  </FormGroup>
                </Col>

                <Col lg="4">
                  <FormGroup id="countryCodeD">
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Code
                    </label>
                    <Input
                      className="form-control-alternative"
                      defaultValue="+234"
                      required
                      id="countryCode"
                      placeholder="C-Code"
                      type="text"
                    />
                  </FormGroup>
                </Col>

                <Col lg="4">
                  <FormGroup id="regPhoneD">
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Phone
                    </label>
                    <Input
                      className="form-control-alternative"
                      // defaultValue="lucky.jesse"
                      required
                      id="regPhone"
                      placeholder="Phone Number"
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col lg="4">
                  <FormGroup id="regEmailD">
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Email
                    </label>
                    <Input
                      className="form-control-alternative"
                      // defaultValue="lucky.jesse"
                      required
                      id="regEmail"
                      placeholder="Email"
                      type="text"
                    />
                  </FormGroup>
                </Col>

                <Col lg="4">
                  <FormGroup id="regPasswordD">
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Password
                    </label>
                    <Input
                      className="form-control-alternative"
                      // defaultValue="lucky.jesse"
                      required
                      id="regPassword"
                      placeholder="Password"
                      type="text"
                      value={passwordRand}
                    />
                  </FormGroup>
                </Col>

                <Col lg="4">
                  <FormGroup id="regConfirmPasswordD">
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Confirm Password
                    </label>
                    <Input
                      className="form-control-alternative"
                      // defaultValue="lucky.jesse"
                      required
                      id="regConfirmPassword"
                      placeholder="Confirm Password"
                      type="text"
                      value={passwordRand}
                    />
                  </FormGroup>
                </Col>

                <Col lg="12">
                  <Button
                    color="primary btn-block"
                    href="#"
                    style={{ padding: 12 }}
                    size="md"
                    disabled={UserRolePermission(
                      this.props.currentUser.UserData.role,
                      "add_developer"
                    )}
                    onClick={async (e) => {
                      e.preventDefault();

                      document
                        .getElementById("firstNameD")
                        .classList.remove("has-danger");
                      document
                        .getElementById("lastNameD")
                        .classList.remove("has-danger");
                      document
                        .getElementById("countryFlagD")
                        .classList.remove("has-danger");
                      document
                        .getElementById("countryCodeD")
                        .classList.remove("has-danger");
                      document
                        .getElementById("regPhoneD")
                        .classList.remove("has-danger");
                      document
                        .getElementById("regEmailD")
                        .classList.remove("has-danger");
                      document
                        .getElementById("regPasswordD")
                        .classList.remove("has-danger");
                      document
                        .getElementById("regConfirmPasswordD")
                        .classList.remove("has-danger");

                      if (document.getElementById("firstName").value == "") {
                        document
                          .getElementById("firstNameD")
                          .classList.add("has-danger");
                        document.getElementById("firstName").focus();
                        document.getElementById(
                          "validate_form_msg"
                        ).style.display = "block";
                      } else if (
                        document.getElementById("lastName").value == ""
                      ) {
                        document
                          .getElementById("lastNameD")
                          .classList.add("has-danger");
                        document.getElementById("lastName").focus();
                        document.getElementById(
                          "validate_form_msg"
                        ).style.display = "block";
                      } else if (
                        document.getElementById("countryFlag").value == ""
                      ) {
                        document
                          .getElementById("countryFlagD")
                          .classList.add("has-danger");
                        document.getElementById("countryFlag").focus();
                        document.getElementById(
                          "validate_form_msg"
                        ).style.display = "block";
                      } else if (
                        document.getElementById("countryCode").value == ""
                      ) {
                        document
                          .getElementById("countryCodeD")
                          .classList.add("has-danger");
                        document.getElementById("countryCode").focus();
                        document.getElementById(
                          "validate_form_msg"
                        ).style.display = "block";
                      } else if (
                        document.getElementById("regPhone").value == ""
                      ) {
                        document
                          .getElementById("regPhoneD")
                          .classList.add("has-danger");
                        document.getElementById("regPhone").focus();
                        document.getElementById(
                          "validate_form_msg"
                        ).style.display = "block";
                      } else if (
                        document.getElementById("regEmailD").value == ""
                      ) {
                        document
                          .getElementById("regEmailD")
                          .classList.add("has-danger");
                        document.getElementById("regEmail").focus();
                        document.getElementById(
                          "validate_form_msg"
                        ).style.display = "block";
                      } else if (
                        document.getElementById("regPasswordD").value == ""
                      ) {
                        document
                          .getElementById("regPasswordD")
                          .classList.add("has-danger");
                        document.getElementById("regPassword").focus();
                        document.getElementById(
                          "validate_form_msg"
                        ).style.display = "block";
                      } else if (
                        document.getElementById("regPasswordD").value !=
                        document.getElementById("regConfirmPasswordD").value
                      ) {
                        document
                          .getElementById("regConfirmPasswordD")
                          .classList.add("has-danger");
                        document.getElementById("regConfirmPassword").focus();
                        document.getElementById(
                          "validate_form_msg"
                        ).style.display = "block";
                      } else {
                        this.setState({
                          startSavingLoader: true,
                        });

                        let accuratePhone = document
                          .getElementById("regPhone")
                          .value.substring(1);

                        var credentials = {
                          firstName: document.getElementById("firstName").value,
                          lastName: document.getElementById("lastName").value,
                          email: document.getElementById("regEmail").value,
                          country: document.getElementById("countryFlag").value,
                          countryCode:
                            document.getElementById("countryCode").value,
                          phone: document.getElementById("regPhone").value,
                          phoneS:
                            document.getElementById("countryCode").value +
                            accuratePhone,
                          pass: document.getElementById("regPasswordD").value,
                          referalCode: Math.floor(
                            Math.random() *
                              Math.floor(Math.random() * Date.now())
                          ),

                          phoneVerify: false,
                          dateCreated: new Date()
                            .toJSON()
                            .slice(0, 10)
                            .replace(/-/g, "/"),
                          notificationToken: "",
                          regRef: null,
                        };

                        //console.log(credentials);

                        let URL =
                          "https://us-central1-stow-62251.cloudfunctions.net/CreateNewSTOWUsers?firstName=" +
                          credentials.firstName +
                          "&lastName=" +
                          credentials.lastName +
                          "&phone=" +
                          credentials.phone +
                          "&pass=" +
                          credentials.pass +
                          "&email=" +
                          credentials.email +
                          "&referalCode=" +
                          credentials.referalCode +
                          "&countryCode=" +
                          credentials.countryCode +
                          "&country=" +
                          credentials.country +
                          "&phoneS=" +
                          credentials.phoneS;

                        await axios({
                          mode: "no-cors",
                          method: "get",
                          headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                          },
                          url: URL,
                         // data: JSON.stringify(credentials),
                        })
                          .then(async (response) => {
                            this.setState({
                              startSavingLoader: false,
                            });

                            //console.log(response);

                            //alert(response.status+'====')

                            if (response.status == 200) {
                              swal(
                                "New user added created",
                                "You have successfully added the user " +
                                  credentials.firstName +
                                  " " +
                                  credentials.lastName +
                                  " to STOW",
                                "success"
                              ).then((value) => {
                                document.location.reload();
                              });
                            }
                          })
                          .catch((error) => {
                            this.setState({
                              startSavingLoader: false,
                            });

                            console.log(error);

                            swal(
                              "Server Error",
                              "There has been a problem creating your new user  \n\n" +
                                JSON.stringify(error),
                              "error"
                            );
                          });
                        
                        
                        

                        var home = this;
                      }
                    }}
                    style={{
                      fontSize: 12,
                      display: UserRolePermission(
                        this.props.currentUser.UserData.role,
                        "add_user"
                      )
                        ? "none"
                        : "block",
                    }}
                  >
                    {this.state.startSavingLoader ? (
                      // <img src={require("assets/img/loader.gif")} />
                      <>
                        Adding user... <i className="fa fa-spinner fa-spin"></i>
                      </>
                    ) : (
                      "Add User"
                    )}
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </CardBody>
      </Card>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    //PropertySingle: (param) => dispatch(PropertySingle(param)),
  };
};

const mapStateToProps = (state) => ({
  PropertyReducer: state.PropertyReducer,
  PromoReducer: state.PromoReducer,
  currentUser: state.authReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddNewCustomer);

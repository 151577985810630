import { isObject } from "lodash";
import isEmpty from "lodash/isEmpty";

const iniState = {
  //AgreementactionsData: {},
  CouponLoading: true,
  CouponError: true,
  CouponErrorMsg: "",
  CouponLoadingCompleted: false,
  CouponactionCount: 0,
};

const CouponReducer = (state = iniState, action) => {
  switch (action.type) {
    case "COUPON_COUNT":
      // alert(action.payload)
      return {
        ...state,
        CouponactionCount: action.payload,
      };
    case "COUPON_LOADING":
      return {
        ...state,
        CouponLoading: action.CouponLoading,
        CouponError: false,
        CouponLoadingCompleted: false,
        //CouponErrorMsg:''
      };

    case "COUPON_SUCCESS":
      //alert(JSON.stringify(action.payload))
      return {
        ...state,
        CouponactionsData: action.payload,
        //CouponactionCount: (isObject(action.payload)?(Object.keys(action.payload).length):0),
        CouponError: false,
        CouponLoadingCompleted: true,
        CouponLoading: false,
      };
    case "COUPON_ERROR":
      //alert('error')
      return {
        ...state,
        CouponError: true,
        CouponLoadingCompleted: false,
        CouponErrorMsg: action.payload,
        CouponLoaded: false,
      };

    default:
      return state;
  }
};

export default CouponReducer;

import swal from "sweetalert";
export function currencyFormat(num) {
  if (typeof num === "number") {
    return "₦ " + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
}

export function amountFormat(num) {
  return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}


export function dateFormater(date) {
  var now = new Date(date);
  var formattedToday =
    now
      .toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .split(" ")
      .join("-") + " "; //+ now.toLocaleTimeString();

  //var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  //var formattedDate = now.getDate() + "-" + months[now.getMonth()] + "-" + now.getFullYear()+ " " +now.getHours() + ":" + now.getMinutes();
  return formattedToday;
}

export function UserRolePermission(UserRole, ButtonClick) {
  let button_disabled = true;

  //IF USER IS FINANCE
  if (UserRole == "finance" && ButtonClick == "view_trans") {
    button_disabled = false;
  } else if (UserRole == "finance" && ButtonClick == "add_trans") {
    button_disabled = false;
  } else if (UserRole == "finance" && ButtonClick == "edit_pay") {
    button_disabled = false;
  } else if (UserRole == "finance" && ButtonClick == "add_prop") {
    button_disabled = true;
  } else if (UserRole == "finance" && ButtonClick == "edit_prop") {
    button_disabled = true;
  } else if (UserRole == "finance" && ButtonClick == "edit_promo") {
    button_disabled = true;
  } else if (UserRole == "finance" && ButtonClick == "add_agree") {
    button_disabled = true;
  } else if (UserRole == "finance" && ButtonClick == "add_developer") {
    button_disabled = true;
  } else if (UserRole == "finance" && ButtonClick == "chat") {
    button_disabled = true;
  } else if (UserRole == "finance" && ButtonClick == "add_progress") {
    button_disabled = true;
  } else if (UserRole == "finance" && ButtonClick == "send_cloud_message") {
    button_disabled = true;
  } else if (UserRole == "finance" && ButtonClick == "edit_progress") {
    button_disabled = true;
  } else if (UserRole == "finance" && ButtonClick == "delete_progress") {
    button_disabled = true;
  } else if (UserRole == "finance" && ButtonClick == "add_user") {
    button_disabled = true;
  } else if (UserRole == "finance" && ButtonClick == "edit_developer") {
    button_disabled = true;
  } else if (UserRole == "finance" && ButtonClick == "delete_trans") {
    button_disabled = true;
  } else if (UserRole == "finance" && ButtonClick == "add_payment_plan") {
    button_disabled = false;
  } else if (UserRole == "finance" && ButtonClick == "edit_payment_plan") {
    button_disabled = false;
  }
  //

  //send_cloud_message

  //

  //IF USER IS ADMIN
  if (UserRole == "admin" && ButtonClick == "view_trans") {
    button_disabled = false;
  } else if (UserRole == "admin" && ButtonClick == "delete_trans") {
    button_disabled = false;
  } else if (UserRole == "admin" && ButtonClick == "add_trans") {
    button_disabled = false;
  } else if (UserRole == "admin" && ButtonClick == "edit_pay") {
    button_disabled = false;
  } else if (UserRole == "admin" && ButtonClick == "add_prop") {
    button_disabled = false;
  } else if (UserRole == "admin" && ButtonClick == "edit_prop") {
    button_disabled = false;
  } else if (UserRole == "admin" && ButtonClick == "edit_promo") {
    button_disabled = false;
  } else if (UserRole == "admin" && ButtonClick == "add_agree") {
    button_disabled = false;
  } else if (UserRole == "admin" && ButtonClick == "edit_agree") {
    button_disabled = false;
  } else if (UserRole == "admin" && ButtonClick == "edit_agree") {
    button_disabled = false;
  } else if (UserRole == "admin" && ButtonClick == "add_developer") {
    button_disabled = false;
  } else if (UserRole == "admin" && ButtonClick == "chat") {
    button_disabled = false;
  } else if (UserRole == "admin" && ButtonClick == "add_progress") {
    button_disabled = false;
  } else if (UserRole == "admin" && ButtonClick == "send_cloud_message") {
    button_disabled = false;
  } else if (UserRole == "admin" && ButtonClick == "edit_progress") {
    button_disabled = false;
  } else if (UserRole == "admin" && ButtonClick == "delete_progress") {
    button_disabled = false;
  } else if (UserRole == "admin" && ButtonClick == "add_user") {
    button_disabled = false;
  } else if (UserRole == "admin" && ButtonClick == "edit_developer") {
    button_disabled = false;
  } else if (UserRole == "admin" && ButtonClick == "add_payment_plan") {
    button_disabled = false;
  } else if (UserRole == "admin" && ButtonClick == "edit_payment_plan") {
    button_disabled = false;
  }
  //add_progress

  //IF USER IS HELP DESK
  else if (UserRole == "help desk" && ButtonClick == "view_trans") {
    button_disabled = false;
  } else if (UserRole == "help desk" && ButtonClick == "add_trans") {
    button_disabled = true;
  } else if (UserRole == "help desk" && ButtonClick == "edit_pay") {
    button_disabled = true;
  } else if (UserRole == "help desk" && ButtonClick == "add_prop") {
    button_disabled = true;
  } else if (UserRole == "help desk" && ButtonClick == "edit_prop") {
    button_disabled = true;
  } else if (UserRole == "help desk" && ButtonClick == "edit_promo") {
    button_disabled = true;
  } else if (UserRole == "help desk" && ButtonClick == "add_agree") {
    button_disabled = true;
  } else if (UserRole == "help desk" && ButtonClick == "edit_agree") {
    button_disabled = true;
  } else if (UserRole == "help desk" && ButtonClick == "add_developer") {
    button_disabled = true;
  } else if (UserRole == "help desk" && ButtonClick == "chat") {
    button_disabled = false;
  } else if (UserRole == "help desk" && ButtonClick == "add_progress") {
    button_disabled = false;
  } else if (UserRole == "help desk" && ButtonClick == "send_cloud_message") {
    button_disabled = true;
  } else if (UserRole == "help desk" && ButtonClick == "edit_progress") {
    button_disabled = true;
  } else if (UserRole == "help desk" && ButtonClick == "delete_progress") {
    button_disabled = true;
  } else if (UserRole == "help desk" && ButtonClick == "add_user") {
    button_disabled = true;
  } else if (UserRole == "help desk" && ButtonClick == "edit_developer") {
    button_disabled = false;
  } else if (UserRole == "help desk" && ButtonClick == "delete_trans") {
    button_disabled = true;
  } else if (UserRole == "help desk" && ButtonClick == "add_payment_plan") {
    button_disabled = false;
  } else if (UserRole == "help desk" && ButtonClick == "edit_payment_plan") {
    button_disabled = false;
  }
  //

  return button_disabled;
}

export const SendNotificationToSingleDevices = (data) => {
  var body = {};
  if (data.channel === "push") {
    body = {
      app_id: data.app_id,
      contents: { en: data.contents },
      headings: { en: data.headings },
      channel_for_external_user_ids: data.channel,
      include_external_user_ids: [data.email],
    };
  } else {
    body = {
      app_id: data.app_id,
      contents: { en: data.contents },
      headings: { en: data.headings },
      //channel_for_external_user_ids: data.channel,
      included_segments: [data.segment],
    };
  }

  console.log(body);

  fetch("https://onesignal.com/api/v1/notifications", {
    method: "POST",
    headers: {
      Authorization: `Basic ${process.env.REACT_APP_ONNESIGNAL_REST_API_KEY}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then(async (response) => {
      if (response.hasOwnProperty("id")) {
        if (response.id === "") {
          if (response.errors.hasOwnProperty("invalid_external_user_ids")) {
            swal(
              "Push Server Error",
              `Unable to send your message. \n\nEmail ${data.email} not registered for push yet`,
              "error"
            );
          } else {
            swal(
              "Push Server Error",
              `Unable to send your message. \n\nError: ${response.errors}`,
              "error"
            );
          }
        } else {
          //Success
          swal(
            "Message Sent",
            `You have successfully send your push notification message to ${
              data.channel === "push" ? data.email : data?.segment
            }`,
            "success"
          );
          // return `You have successfully send your push notification message to ${data?.email}`;
        }
      } else {
        //Error
        swal(
          "Push Server Error",
          `Unable to send your message. \n\nAn Unexpected error detected. Kindly try again`,
          "error"
        );
      }
    })
    .catch((error) => {
      //
      swal(
        "Server Error",
        "Kindly confirm your network connection \n\n" + error,
        "error"
      );
    });
};

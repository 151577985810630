/*eslint-disable*/
import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// reactstrap components
import {
  Button,
  Card,
  CardImg,
  CardHeader,
  Modal,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import firebase from "firebase/app";
import { connect } from "react-redux";
import { UserPushActions } from "./../../store/actions/userActions";

import { propertyOptionsAction } from "./../../store/actions/propertyActions";
import { FListActions } from "./../../store/actions/featuredListActions";

import BedData from "./../../views/examples/functions/BedData";
import PlanData from "./../../views/examples/functions/PlanData";

import LocationData from "./../../views/examples/functions/LocationData";
import TypeData from "./../../views/examples/functions/TypeData";
import FeatureData from "./../../views/examples/functions/FeatureData";
import AgreementData from "./../../views/examples/functions/AgreementData";
import DeveloperData from "./../../views/examples/functions/DeveloperData";

import CloudMessaging from "./../../views/examples/functions/CloudMessaging";
import Chat from "./../../views/examples/functions/Chat";

import PromoManager from "../../views/examples/functions/PromoManager";

import { dateFormater, UserRolePermission } from "../../utils/functions";
import routesCoop from "views/coop/routes";
import routesGroup from "views/group/routes";
import CoopCloudMessaging from "views/coop/CoopCloudMessaging";
import SendPushToGroup from "views/coop/SendPushToGroup";


class Sidebar extends React.Component {
  state = {
    collapseOpen: false,
    showBedModal: false,
    showPlanModal: false,
    showPaymentPlanModal: false,
    showLocationModal: false,
    showTypeModal: false,
    showAgreementModal: false,
    showOwnerModal: false,
    propertyBed: this.props.PropertyReducer.hasOwnProperty("propertyOptions")
      ? this.props.PropertyReducer.propertyOptions[0].hasOwnProperty("beds")
        ? this.props.PropertyReducer.propertyOptions[0].beds
        : []
      : [],
    startSavingLoader: false,
    showFeatureModal: false,
    showPushMessageModal: false,
    showPromoModal: false,

    showChatModal: false,

    showCloudMsgModal: false,
    showGroupMsgModal: false,
  };
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }

  componentDidMount(props) {
    this.props.UserPushActions();
    this.props.propertyOptionsAction();
    this.props.FListActions();
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false,
    });
  };
  // creates the links that appear in the left menu / Sidebar
  createLinks = (routes) => {
    return routes.map((prop, key) => {
      return (
        <NavItem
          key={key}
          style={{
            display:
              prop.path == "/login" || prop.path == "/single/property"
                ? "none"
                : "block",
          }}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={this.closeCollapse}
            activeClassName='active'>
            <i className={prop.icon} />
            {prop.name}
          </NavLink>
        </NavItem>
      );
    });
  };

  createCoopLinks = (routes) => {
    return routes.map((prop, key) => {
      return (
        <NavItem
          key={key}
          style={{
            display:
              prop.path == "/login" || prop.path == "/single/property"
                ? "none"
                : "block",
          }}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={(e) => {
              this.closeCollapse;

              if (prop.hasOwnProperty("function")) {
                if (prop.name == "Cloud Messaging") {
                  // alert('yes')
                  //showCloudMsgModal: false,
                  this.setState({ showCloudMsgModal: true });

                  //alert(document.getElementById("headerContainer").className);
                }

                e.preventDefault();
              }
              //function
            }}
            activeClassName='active'
            className={prop.hasOwnProperty("class") ? prop.class : ""}>
            <i className={prop.icon} />
            {prop.name}
          </NavLink>
        </NavItem>
      );
    });
  };

  createGroupPlan = (routes) => {
    return routes.map((prop, key) => {
      return (
        <NavItem
          key={key}
          style={{
            display:
              prop.path == "/login" || prop.path == "/single/property"
                ? "none"
                : "block",
          }}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={(e) => {
              this.closeCollapse;

              if (prop.hasOwnProperty("function")) {
                if (prop.name == "Cloud Messaging") {
                  // alert('yes')
                  //showCloudMsgModal: false,
                  this.setState({ showCloudMsgModal: true });

                  //alert(document.getElementById("headerContainer").className);
                }

                e.preventDefault();
              }
              //function
            }}
            activeClassName='active'
            className={prop.hasOwnProperty("class") ? prop.class : ""}>
            <i className={prop.icon} />
            {prop.name}
          </NavLink>
        </NavItem>
      );
    });
  };
  render() {
    const { bgColor, routes, logo } = this.props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link,
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: "_blank",
      };
    }
    return (
      <Navbar
        className='navbar-vertical fixed-left navbar-light bg-white'
        expand='md'
        id='sidenav-main'>
        <Container fluid>
          {/* Toggler */}
          <button
            className='navbar-toggler'
            type='button'
            onClick={this.toggleCollapse}>
            <span className='navbar-toggler-icon' />
          </button>
          {/* Brand */}
          {logo ? (
            <NavbarBrand className='pt-0' {...navbarBrandProps}>
              <img
                alt={logo.imgAlt}
                className='navbar-brand-img'
                src={logo.imgSrc}
              />
            </NavbarBrand>
          ) : null}
          {/* User */}
          <Nav className='align-items-center d-md-none'>
            <UncontrolledDropdown nav>
              <DropdownToggle nav className='nav-link-icon'>
                <i className='ni ni-bell-55' />
              </DropdownToggle>
              <DropdownMenu
                aria-labelledby='navbar-default_dropdown_1'
                className='dropdown-menu-arrow'
                right>
                <DropdownItem>Action</DropdownItem>
                <DropdownItem>Another action</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Something else here</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav>
              <DropdownToggle nav>
                <Media className='align-items-center'>
                  <span className='avatar avatar-sm rounded-circle'>
                    <img
                      alt='...'
                      src={require("assets/img/theme/team-1-800x800.jpg")}
                    />
                  </span>
                </Media>
              </DropdownToggle>
              <DropdownMenu className='dropdown-menu-arrow' right>
                <DropdownItem className='noti-title' header tag='div'>
                  <h6 className='text-overflow m-0'>Welcome!</h6>
                </DropdownItem>
                <DropdownItem to='/admin/user-profile' tag={Link}>
                  <i className='ni ni-single-02' />
                  <span>My profile</span>
                </DropdownItem>
                <DropdownItem to='/admin/user-profile' tag={Link}>
                  <i className='ni ni-settings-gear-65' />
                  <span>Settings</span>
                </DropdownItem>
                <DropdownItem to='/admin/user-profile' tag={Link}>
                  <i className='ni ni-calendar-grid-58' />
                  <span>Activity</span>
                </DropdownItem>
                <DropdownItem to='/admin/user-profile' tag={Link}>
                  <i className='ni ni-support-16' />
                  <span>Support</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem href='#stow' onClick={(e) => e.preventDefault()}>
                  <i className='ni ni-user-run' />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
          {/* Collapse */}
          <Collapse navbar isOpen={this.state.collapseOpen}>
            {/* Collapse header */}
            <div className='navbar-collapse-header d-md-none'>
              <Row>
                {logo ? (
                  <Col className='collapse-brand' xs='6'>
                    {logo.innerLink ? (
                      <Link to={logo.innerLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </Link>
                    ) : (
                      <a href={logo.outterLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </a>
                    )}
                  </Col>
                ) : null}
                <Col className='collapse-close' xs='6'>
                  <button
                    className='navbar-toggler'
                    type='button'
                    onClick={this.toggleCollapse}>
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            {/* Form */}
            <Form className='mt-4 mb-3 d-md-none'>
              <InputGroup className='input-group-rounded input-group-merge'>
                <Input
                  aria-label='Search'
                  className='form-control-rounded form-control-prepended'
                  placeholder='Search'
                  type='search'
                />
                <InputGroupAddon addonType='prepend'>
                  <InputGroupText>
                    <span className='fa fa-search' />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Form>
            {/* Navigation */}
            <Nav navbar>{this.createLinks(routes)}</Nav>
            {/* Divider */}
            <hr className='my-3' />
            {/* Heading */}
            <h6 className='navbar-heading text-muted'>Others</h6>
            {/* Navigation */}
            <Nav className='mb-md-3' navbar>
              <NavItem style={{ display: "block" }}>
                <NavLink
                  href='#'
                  onClick={() => {
                    this.setState({
                      showFeatureModal: true,
                    });
                  }}>
                  <i className='ni ni-spaceship' />
                  Property Features
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink href='#'>
                  <i className='ni ni-planet' />

                  <UncontrolledDropdown>
                    <DropdownToggle
                      className='btn-icon-only text-dark-'
                      href='#pablo'
                      role='button'
                      size='sm'
                      color=''
                      style={{ color: "rgba(0, 0, 0, 0.5)" }}
                      onClick={(e) => e.preventDefault()}>
                      Cloud Messaging <i className='ni ni-bold-down' />
                    </DropdownToggle>
                    <DropdownMenu className='dropdown-menu-arrow' right>
                      <Modal
                        id='model_cloud_message'
                        style={{ maxWidth: 60 + "%" }}
                        className='modal-dialog-centered'
                        isOpen={this.state.showCloudMsgModal}
                        // toggle={() => this.toggleModal("exampleModal")}
                      >
                        <div className='modal-header'>
                          <h5 className='modal-title' id='exampleModalLabel'>
                            Single Cloud Messaging
                          </h5>
                          <button
                            aria-label='Close'
                            className='close'
                            data-dismiss='modal'
                            type='button'
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ showCloudMsgModal: false });
                            }}>
                            <span aria-hidden={true}>×</span>
                          </button>
                        </div>
                        <div className='modal-body'>
                          <CoopCloudMessaging />
                        </div>
                      </Modal>

                      <DropdownItem
                        style={{ textAlign: "right" }}
                        href='#pablo'
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            showCloudMsgModal: true,
                          });
                        }}>
                        Single Push
                      </DropdownItem>

                      <DropdownItem
                        style={{ textAlign: "right" }}
                        href='#pablo'
                        onClick={(e) => {
                          e.preventDefault();
                          //showGroupMsgModal
                          this.setState({
                            showGroupMsgModal: true,
                          });
                        }}>
                        Group/Segment Push
                      </DropdownItem>

                      <Modal
                        id='model_cloud_message'
                        style={{ maxWidth: 90 + "%" }}
                        className='modal-dialog-centered'
                        isOpen={this.state.showGroupMsgModal}
                        // toggle={() => this.toggleModal("exampleModal")}
                      >
                        <div className='modal-header'>
                          <h5 className='modal-title' id='exampleModalLabel'>
                            Group/Segment Cloud Messaging
                          </h5>
                          <button
                            aria-label='Close'
                            className='close'
                            data-dismiss='modal'
                            type='button'
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ showGroupMsgModal: false });
                            }}>
                            <span aria-hidden={true}>×</span>
                          </button>
                        </div>
                        <div className='modal-body'>
                          <SendPushToGroup />
                        </div>
                      </Modal>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href='#'>
                  <i className='ni ni-palette' />

                  <Modal
                    id='model_bed'
                    className='modal-dialog-centered'
                    isOpen={this.state.showBedModal}
                    // toggle={() => this.toggleModal("exampleModal")}
                  >
                    <div className='modal-header'>
                      <h5 className='modal-title' id='exampleModalLabel'>
                        BED
                      </h5>
                      <button
                        aria-label='Close'
                        className='close'
                        data-dismiss='modal'
                        type='button'
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({ showBedModal: false });
                        }}>
                        <span aria-hidden={true}>×</span>
                      </button>
                    </div>
                    <div className='modal-body'>
                      <BedData />
                    </div>
                  </Modal>

                  <Modal
                    id='model_plan'
                    className='modal-dialog-centered'
                    isOpen={this.state.showPlanModal}
                    // toggle={() => this.toggleModal("exampleModal")}
                  >
                    <div className='modal-header'>
                      <h5 className='modal-title' id='exampleModalLabel'>
                        PROPERTY PLAN
                      </h5>
                      <button
                        aria-label='Close'
                        className='close'
                        data-dismiss='modal'
                        type='button'
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({ showPlanModal: false });
                        }}>
                        <span aria-hidden={true}>×</span>
                      </button>
                    </div>
                    <div className='modal-body'>
                      <PlanData />
                    </div>
                  </Modal>
 

                  <Modal
                    id='model_location'
                    className='modal-dialog-centered'
                    isOpen={this.state.showLocationModal}
                    // toggle={() => this.toggleModal("exampleModal")}
                  >
                    <div className='modal-header'>
                      <h5 className='modal-title' id='exampleModalLabel'>
                        LOCATION
                      </h5>
                      <button
                        aria-label='Close'
                        className='close'
                        data-dismiss='modal'
                        type='button'
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({ showLocationModal: false });
                        }}>
                        <span aria-hidden={true}>×</span>
                      </button>
                    </div>
                    <div className='modal-body'>
                      <LocationData />
                    </div>
                  </Modal>

                  <Modal
                    id='model_location'
                    className='modal-dialog-centered'
                    isOpen={this.state.showTypeModal}
                    // toggle={() => this.toggleModal("exampleModal")}
                  >
                    <div className='modal-header'>
                      <h5 className='modal-title' id='exampleModalLabel'>
                        PROPERTY TYPE
                      </h5>
                      <button
                        aria-label='Close'
                        className='close'
                        data-dismiss='modal'
                        type='button'
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({ showTypeModal: false });
                        }}>
                        <span aria-hidden={true}>×</span>
                      </button>
                    </div>
                    <div className='modal-body'>
                      <TypeData />
                    </div>
                  </Modal>

                  <Modal
                    id='model_featured'
                    className='modal-dialog-centered'
                    isOpen={this.state.showFeatureModal}
                    // toggle={() => this.toggleModal("exampleModal")}
                  >
                    <div className='modal-header'>
                      <h5 className='modal-title' id='exampleModalLabel'>
                        PROPERTY FEATURE LIST
                      </h5>
                      <button
                        aria-label='Close'
                        className='close'
                        data-dismiss='modal'
                        type='button'
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({ showFeatureModal: false });
                        }}>
                        <span aria-hidden={true}>×</span>
                      </button>
                    </div>
                    <div className='modal-body'>
                      <FeatureData />
                    </div>
                  </Modal>

                  <UncontrolledDropdown>
                    <DropdownToggle
                      className='btn-icon-only text-dark'
                      href='#pablo'
                      role='button'
                      size='sm'
                      color=''
                      onClick={(e) => e.preventDefault()}>
                      Property Options <i className='ni ni-bold-down' />
                    </DropdownToggle>
                    <DropdownMenu className='dropdown-menu-arrow' right>
                      <DropdownItem
                        style={{ textAlign: "right" }}
                        href='#pablo'
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            showBedModal: true,
                          });
                        }}>
                        Beds
                      </DropdownItem>
                      <DropdownItem
                        style={{ textAlign: "right" }}
                        href='#pablo'
                        onClick={(e) => {
                          this.setState({
                            showLocationModal: true,
                          });

                          e.preventDefault();
                        }}>
                        Location
                      </DropdownItem>
                      <DropdownItem
                        style={{ textAlign: "right" }}
                        href='#pablo'
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            showPlanModal: true,
                          });
                        }}>
                        Property Plan
                      </DropdownItem>
                      <DropdownItem
                        style={{ textAlign: "right" }}
                        href='#pablo'
                        onClick={(e) => {
                          this.setState({
                            showTypeModal: true,
                          });
                          e.preventDefault();
                        }}>
                        Property Type
                      </DropdownItem>

                      <DropdownItem
                        style={{ textAlign: "right" }}
                        href='#pablo'
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.history.push("/admin/property-payment-plans");
                        }}>
                        Payment Plans
                      </DropdownItem>

                      <Modal
                        id='model_location'
                        className='modal-dialog-centered'
                        //style={{ width: 70 + '%' }}
                        size='lg'
                        isOpen={this.state.showAgreementModal}
                        // toggle={() => this.toggleModal("exampleModal")}
                      >
                        <div className='modal-header'>
                          <h5 className='modal-title' id='exampleModalLabel'>
                            PROPERTY AGREEMENT
                          </h5>
                          <button
                            aria-label='Close'
                            className='close'
                            data-dismiss='modal'
                            type='button'
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ showAgreementModal: false });
                            }}>
                            <span aria-hidden={true}>×</span>
                          </button>
                        </div>
                        <div className='modal-body'>{<AgreementData />}</div>
                      </Modal>
                      <DropdownItem
                        style={{ textAlign: "right" }}
                        href='#pablo'
                        onClick={(e) => {
                          this.setState({
                            showAgreementModal: true,
                          });
                          e.preventDefault();
                        }}>
                        Agreements
                      </DropdownItem>

                      <Modal
                        id='model_owner'
                        className='modal-dialog-centered'
                        //style={{ width: 70 + '%' }}
                        size='lg'
                        isOpen={this.state.showOwnerModal}
                        // toggle={() => this.toggleModal("exampleModal")}
                      >
                        <div className='modal-header'>
                          <h5 className='modal-title' id='exampleModalLabel'>
                            PROPERTY DEVELOPER
                          </h5>
                          <button
                            aria-label='Close'
                            className='close'
                            data-dismiss='modal'
                            type='button'
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ showOwnerModal: false });
                            }}>
                            <span aria-hidden={true}>×</span>
                          </button>
                        </div>
                        <div className='modal-body'>{<DeveloperData />}</div>
                      </Modal>
                      <DropdownItem
                        style={{
                          textAlign: "right",
                          /*  display: UserRolePermission(
                            this.props.currentUser.UserData.role,
                            "add_developer"
                          )
                            ? "none"
                            : "block",*/
                        }}
                        href='#pablo'
                        onClick={(e) => {
                          this.setState({
                            showOwnerModal: true,
                          });
                          e.preventDefault();
                        }}>
                        Developer
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink href="#">
                  <i className="ni ni-ui-04" />
                  Feature List
                </NavLink>
              </NavItem> */}

              <Modal
                id='model_promo'
                style={{ maxWidth: 60 + "%" }}
                className='modal-dialog-centered'
                isOpen={this.state.showPromoModal}
                // toggle={() => this.toggleModal("exampleModal")}
              >
                <div className='modal-header'>
                  <h5 className='modal-title' id='exampleModalLabel'>
                    Promo Manager
                  </h5>
                  <button
                    aria-label='Close'
                    className='close'
                    data-dismiss='modal'
                    type='button'
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ showPromoModal: false });
                    }}>
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <div className='modal-body'>
                  <PromoManager />
                </div>
              </Modal>
              <NavItem>
                <NavLink
                  href='#'
                  onClick={(e) => {
                    this.setState({
                      showPromoModal: true,
                    });
                    e.preventDefault();
                  }}>
                  <i className='ni ni-diamond' />
                  Promo
                </NavLink>
              </NavItem>

              <Modal
                id='model_cloud_message'
                style={{ maxWidth: 60 + "%" }}
                className='modal-dialog-centered'
                isOpen={this.state.showPushMessageModal}
                // toggle={() => this.toggleModal("exampleModal")}
              >
                <div className='modal-header'>
                  <h5 className='modal-title' id='exampleModalLabel'>
                    Cloud Messaging
                  </h5>
                  <button
                    aria-label='Close'
                    className='close'
                    data-dismiss='modal'
                    type='button'
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ showPushMessageModal: false });
                    }}>
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <div className='modal-body'>
                  <CloudMessaging />
                </div>
              </Modal>
              {/*  <NavItem>
                <NavLink
                  href="#"
                  onClick={(e) => {
                    this.setState({
                      showPushMessageModal: true,
                    });
                    e.preventDefault();
                  }}
                >
                  <i className="ni ni-planet" />
                  Cloud Messaging
                </NavLink>
              </NavItem> */}

              <NavItem
                style={{
                  display: UserRolePermission(
                    this.props.currentUser.UserData?.role,
                    "chat"
                  )
                    ? "none"
                    : "block",
                }}>
                <NavLink
                  href='#'
                  onClick={(e) => {
                    this.setState({
                      showChatModal: true,
                    });
                    e.preventDefault();
                  }}>
                  <i className='ni ni-chat-round' />
                  Chat
                </NavLink>
              </NavItem>

              <Modal
                id='model_cloud_message'
                style={{ maxWidth: 75 + "%" }}
                className='modal-dialog-centered'
                isOpen={this.state.showChatModal}
                // toggle={() => this.toggleModal("exampleModal")}
              >
                <div className='modal-header'>
                  <h5 className='modal-title' id='exampleModalLabel'>
                    Chat Support
                  </h5>
                  <button
                    aria-label='Close'
                    className='close'
                    data-dismiss='modal'
                    type='button'
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ showChatModal: false });
                    }}>
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <div
                  className='modal-body'
                  style={{ border: "0px solid black" }}>
                  <Chat />
                </div>
              </Modal>

              <NavItem>
                <NavLink href='#'>
                  <i className='ni ni-books' />
                  FAQ
                </NavLink>
              </NavItem>
            </Nav>

            <hr className='my-3' />
            <h6 className='navbar-heading text-muted'> STOW COOP</h6>

            <Nav navbar>{this.createCoopLinks(routesCoop)}</Nav>

            <hr className='my-3' />
            <h6 className='navbar-heading text-muted'>STOW GROUP</h6>

            <Nav navbar>{this.createGroupPlan(routesGroup)}</Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}],
  routesCoop: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  routesCoop: PropTypes.arrayOf(PropTypes.object),

  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

//export default Sidebar;

const mapDispatchToProps = (dispatch) => {
  return {
    // PropertyEdit: (param) => dispatch(PropertyEdit(param)),
    //PropertySingle: (param) => dispatch(PropertySingle(param)),
    propertyOptionsAction: (param) => dispatch(propertyOptionsAction(param)),
    UserPushActions: (param) => dispatch(UserPushActions(param)),
    FListActions: (param) => dispatch(FListActions(param)),
  };
};

const mapStateToProps = (state) => ({
  currentUser: state.authReducer,
  UserReducer: state.UserReducer,
  PropertyReducer: state.PropertyReducer,
  PromoReducer: state.PromoReducer,
  FeaturedListReducer: state.FeaturedListReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);

//PropertyReducer: state.PropertyReducer,

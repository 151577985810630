import React from "react";
// node.js library that concatenates classes (strings)



// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  Modal,
  Form,
  FormGroup,
  Input,
  Badge,
  Alert 
} from "reactstrap";

import moment from 'moment';



import   firebase from "firebase/app";

import { useCollectionData } from "react-firebase-hooks/firestore";




import api from "../../../utils/config";
import { currencyFormat } from '../../../utils/functions';

//import "firebase/auth";
import { isEmpty } from "lodash";
//require("firebase/firestore");



const TransPaymentsMini = (props) => {

    const [ PaymentData, setPaymentData ] = React.useState([]);
    const [ dataLoading, setdataLoading ] = React.useState(true);

    if (!firebase.apps.length)
    {
    

        firebase.initializeApp({
            apiKey: api.REACT_APP_FIREBASE_APP_ID,
            authDomain: api.REACT_APP_FIREBASE_DOMAIN,
            databaseURL: api.REACT_APP_FIREBASE_DATABASE,
            projectId: api.REACT_APP_FIREBASE_PROJECT_ID,
            storageBucket: api.REACT_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: api.REACT_APP_FIREBASE_SENDER_ID,
            appId: api.REACT_APP_FIREBASE_APP_ID,
            measurementId: api.REACT_APP_FIREBASE_MEASUREMENT_ID,
          });

         

    }

const { transaction } = props;
  //const { tid, paid, pplan } = transaction;

  var transactionsData = [];

  

                try 
                {

                    firebase
                .firestore()
                .collection("payments")
                .where('tid','==',transaction)
                .onSnapshot( snapshot => {

                     snapshot.forEach( doc => {

                                    transactionsData.push({
                                            ...doc.data()
                                            });
                                            
                                        })

                    setPaymentData(transactionsData)
                    setdataLoading(false)
                    
                       
                })
                 

                }
                catch(error)
                {
                     setdataLoading(false)

                }
                

     
  
                if(!(dataLoading))
                {
                   // return (PaymentData)
                   return ((Object.keys(PaymentData).length >0)?(

                     PaymentData.map( (data,i)=>(
         
             <Row className=" icon-examples" style={{float:'left',marginLeft:10}}>


              <Col lg="12" md="12">
                      
                        <button
                          className="btn-icon-clipboard"
                         
                         
                          type="button"
                        >
                          <div>
                            <i className=" ni ni-collection" />
                            <div style={{paddingLeft:10}}>
                            { currencyFormat( (data.hasOwnProperty('amount')?parseInt(data.amount):
                  
                  (data.hasOwnProperty('amt')?parseInt(data.amt):0)
                  ))}
                            </div>

                            
                          </div>
                          <div style={{fontSize:9,color:'#ccc',marginLeft:35}}>{' '+(data.hasOwnProperty('id')?data.id:(data.hasOwnProperty('c_transRef')?data.c_transRef:(data.hasOwnProperty('r_txnref')?data.r_txnref:'INT-null')))}</div>
                        </button>
                      
                      
                    </Col>

               
              </Row>
           
        ))

                   ):(<div className="ct-example" style={{width:100+'%'}}><center>
                   <img alt="No Payment made" style={{width:100}}
                        src={require("../../../assets/img/brand/empty_payment.jpg")}
                      /></center></div>))

                }
                else{
                    return (<div style={{padding:50}}> {'loading...'}</div>)

                }


   

  // return ('')


}

export default TransPaymentsMini;
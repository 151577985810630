
import React from "react";
// reactstrap components
import {
  Card,
  Container,
  Row,
  Col,
  Table,
  Button,
  Badge,
  Alert,
  FormGroup,
  Modal,
  Form,
  Input,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.js";

import firebase from "firebase/app";

import ReactDOM from "react-dom";
import MaterialTable, { MTableToolbar, MTableCell } from "material-table";

import {
  currencyFormat,
  dateFormater,
  UserRolePermission,
} from "../../utils/functions";

import { isEmpty, isArray, isObject } from "lodash";

import { connect } from "react-redux";
import CountUp from "react-countup";




import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import { PropertySingle } from "./../../store/actions/propertyActions";
import TransPaymentsMini from "./functions/TransPaymentsMini";
import AddNewCustomer from "./functions/AddNewCustomer";
import swal from "sweetalert";

import { UserActions, UserCount } from "./../../store/actions/userActions";
import {
  transactionActions,
  TransactionCount,
} from "./../../store/actions/transactionActions";

class Users extends React.Component {
  state = {
    subscriberRecord: [],
    showPaymentPage: false,
    showPaymentAddPage: false,
    startPaymentLoader: false,

    TransID: null,
    customerEmail: null,
    customerPhone: null,
    customerUid: null,
    customerName: null,
    lastPaid: 0,
    Updatemsg: "Saving...",

    FinanceLoader: false,
    BrokerLoader: false,
    AdminLoader: false,

    HDLoader: false,
    NoneLoader: false,
    addNewModal: false,
    Date1: "",
    Date2: "",
    filtering: false,
  };

  componentDidMount() {
    this.props.UserActions();
    this.props.transactionActions();
    if (Object.keys(this.props.UserReducer.UseractionsData).length > 0) {
      var subscriberRecord = [];
      this.props.UserReducer.UseractionsData.forEach((doc) => {
       // console.log(doc.dateCreated.replace("/", "-").replace("/", "-"));
        
       // console.log(this.fromISODate(doc.dateCreated));
        subscriberRecord.push({
          id: doc.id,
          firstName: doc.firstName,
          lastName: doc.lastName,
          role: doc.role,
          emailVerified: doc.emailVerified,

          phone: doc.phone,
          email: doc.email,
          referalCode: doc.referalCode,
          dateCreated: doc.hasOwnProperty("dateCreated")
            ? this.fromISODate(doc.dateCreated)
                
            : null,
          // astName:doc.lastName,

          ct: 0,
        });
      });

      this.setState({ subscriberRecord: subscriberRecord });
    }
  }

  fromISODate(d) {
    var date = new Date(d);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var dt = date.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }

    return year + "-" + month + "-" + dt;
  }

  myData = () => {
    /* function to determine the data to be rendered to the table */
    let myArr = [];
    if (this.state.Date1 && this.state.Date2) {
      (this.state.subscriberRecord || []).map((item) =>
        item.dateCreated >= this.state.Date1 &&
        item.dateCreated <= this.state.Date2
          ? myArr?.push(item)
          : null
      );
    } else {
      myArr =
        this.state.subscriberRecord ||
        []; /* YourData is the array you want to display and filter */
    }

    return myArr;
  };

  FilterByDateRange = () => {
    /* function for adding 2 textfields for date range */
    return (
      <>
        <Grid
          alignItems="center"
          container
          justify="center"
          style={{ display: this.state.filtering ? "flex" : "none" }}
        >
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Paper
              elevation={3}
              style={{
                margin: "10px auto",
                textAlign: "center",
                padding: "10px",
              }}
            >
              <Typography> Search by Date Range </Typography>
              <br />
              <br />
              <TextField
                value={this.state.Date1}
                onChange={(e) => this.setDate1(e.target.value)}
                type="date"
                id="date"
                label="Start Date"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ margin: "10px" }}
              />
              <TextField
                value={this.state.Date2}
                label="End Date"
                onChange={(e) => this.setDate2(e.target.value)}
                type="date"
                id="date"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ margin: "10px" }}
              />
              <div>
                <Button
                  onClick={() => {
                    this.setDate1("");
                    this.setDate2("");
                  }}
                  variant="contained"
                  color="warning"
                >
                  Clear
                </Button>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  };

  getTransactionCount(uid) {
    if (isEmpty(uid)) {
      return "null";
    } else {
      var transactionsData = [];
      //console.log(uid)
      firebase
        .firestore()
        .collection("transactions")
        .where("uid", "==", uid)
        .onSnapshot((snapshot) => {
          snapshot.forEach((doc) => {
            transactionsData.push({
              ...doc.data(),
            });
          });

          // console.log(Object.keys(transactionsData).length)

          var element = document.getElementById("cell_ct_" + uid);

          if (typeof element != "undefined" && element != null) {
            document.getElementById("cell_ct_" + uid).innerHTML =
              Object.keys(transactionsData).length > 0
                ? Object.keys(transactionsData).length
                : "0-0";
          } else {
            //document.getElementById('cell_ct_'+uid).innerHTML='0000'
            // return 0
          }
        });
    }
  }

  GetProperty(e) {
    let array_ = this.props.transactionReducer.transactionsData;
    //let id =  e.target.getAttribute('data-id')
    let id = e.target.getAttribute("data-id");

    for (var i = 0, len = array_.length; i < len; i++) {
      if (array_[i].id === id) {
        //console.log(array_[i].property)

        if (array_[i].property) {
          this.props.PropertySingle(array_[i].property);
          this.props.history.push("/admin/single/property");
        } else {
          alert("PROPRTY DOES NOT EXIST OR HAVE BEEN DELETED");
        }
      }
    }
  }

  setDate1(data) {
    return this.setState({ Date1: data });
  }

  setDate2(data) {
    return this.setState({ Date2: data });
  }

  setFiltering(data) {
    return this.setState({ filtering: !data });
  }

  render() {
    const transactionReducer = this.props.transactionReducer;

    return (
      <>
        <Header />
        {/* Page content */}

        <Modal
          id="model_property_new"
          className="modal-dialog-centered"
          isOpen={this.state.addNewModal}
          style={{ maxWidth: 70 + "%" }}
          // toggle={() => this.toggleModal("exampleModal")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              ADD NEW CUSTOMER
            </h5>

            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ addNewModal: false });
                // document.location.reload()
              }}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>

          <div className="modal-body">
            <AddNewCustomer />
          </div>
        </Modal>

        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow border-0">
                <div style={{ maxWidth: "100%", borderRadius: 20 }}>
                  <MaterialTable
                    columns={[
                      { title: "Firstname", field: "firstName" },
                      { title: "LastName", field: "lastName" },
                      {
                        title: "Phone",
                        field: "phone",
                        phoneNumber: "numeric",
                      },
                      { title: "Email", field: "email" },
                      /*  {title: 'Count', field:'ct', render: rowData => {
                    return (this.getTransactionCount(rowData.id))
                  }}, */
                      { title: "Referal", field: "referalCode" },

                      {
                        title: "Created Date",
                        filterComponent: (props) => (
                          <Button
                            color="warning"
                            href="#stow"
                            style={{
                              fontSize: 9,
                              // width: 40,
                            }}
                            onClick={() =>
                              this.setFiltering(this.state.filtering)
                            }
                            size="sm"
                          >
                            {this.state.filtering
                              ? "Hide Filter"
                              : "Show Filter"}
                          </Button>
                        ),
                        field: "dateCreated",
                        defaultSort: "desc",

                        render: (rowData) => {
                          return isEmpty(rowData.dateCreated)
                            ? null
                            : dateFormater(rowData.dateCreated);
                        },
                      },
                      {
                        title: "Role",
                        field: "role",
                        rendeer: (rowData) => {
                          return rowData.hasOwnProperty("role")
                            ? isEmpty(rowData.role)
                              ? "None"
                              : rowData.role + "-"
                            : "None";
                        },
                      },
                      //
                    ]}
                    //data={this.props.UserReducer.UseractionsData}
                    actions={[
                      /* {
                        //icon: () => (<CIcon name="cil-filter"/>),
                        icon: "filter",
                        tooltip: "Date Filter",
                        isFreeAction: true,
                        onClick: (event) => {
                          this.setFiltering(this.state.filtering);
                        },
                      }, */
                      {
                        icon: () => (
                          <Button
                            color="primary"
                            href="#"
                            disabled={UserRolePermission(
                              this.props.currentUser.UserData.role,
                              "add_user"
                            )}
                            style={{
                              fontSize: 20,
                              paddingBottom: 0,

                              display: UserRolePermission(
                                this.props.currentUser.UserData.role,
                                "add_user"
                              )
                                ? "none"
                                : "block",
                            }}
                            size="sm"
                          >
                            <i className="ni ni-fat-add"></i>
                          </Button>
                        ),
                        tooltip: "Add User",
                        isFreeAction: true,
                        onClick: (event, rowData) => {
                          this.setState({ addNewModal: true });

                          // alert(JSON.stringify(rows))
                          //this.setState({showPaymentPage:true,customerName:null,TransID:null,dataLoading:true})
                        },
                      },
                    ]}
                    //data={this.state.subscriberRecord}
                    data={this.myData()}
                    components={{
                      Toolbar: (props) => (
                        <p className="h3" style={{ padding: 10 }}>
                          <MTableToolbar {...props} />
                          <div style={{ marginTop: -40 }}>
                            {"Total"}{" "}
                            <CountUp
                              end={
                                Object.keys(
                                  this.props.UserReducer.UseractionsData
                                ).length
                              }
                            />
                          </div>
                          {this.FilterByDateRange()}
                        </p>
                      ),
                      Cell: (props) => {
                        if (props.columnDef.field == "ct") {
                          return (
                            <MTableCell
                              {...props}
                              id={"cell_ct_" + props.rowData.id}
                            />
                          );
                        } else {
                          return <MTableCell {...props} />;
                        }

                        //return <MTableCell {...props} data-value={JSON.stringify(props.columnDef)} id={'cell_'+props.rowData.id} />
                      },
                    }}
                    detailPanel={[
                      {
                        tooltip: "Customer Detail",
                        render: (rowData) => {
                          let array_ =
                            this.props.transactionReducer.transactionsData;

                          let userTrans = [];

                          let id = rowData.id;
                          for (var i = 0, len = array_.length; i < len; i++) {
                            if (array_[i].uid === id) {
                              userTrans.push(array_[i]);
                            }
                          }

                          //console.log(userTrans)

                          return (
                            <div>
                              {this.props.currentUser.UserData.role ==
                              "admin" ? (
                                <div>
                                  <div
                                    style={{
                                      float: "left",
                                      padding: 20,
                                      paddingLeft: 80,
                                      fontSize: 10,
                                      color: "grey",
                                    }}
                                  >
                                    {rowData.id}
                                  </div>
                                  <Button
                                    id="others_role"
                                    style={{
                                      marginTop: 20,
                                      marginRight: 20,
                                      float: "right",
                                    }}
                                    size="sm"
                                    color={
                                      rowData.hasOwnProperty("role")
                                        ? rowData.role == "none" ||
                                          isEmpty(rowData.role)
                                          ? "info"
                                          : "default"
                                        : "info"
                                    }
                                    type="button"
                                    disabled={this.state.NoneLoader}
                                    onClick={async (e) => {
                                      e.preventDefault();

                                      const home = this;

                                      swal({
                                        title:
                                          "Remove " +
                                          rowData.firstName +
                                          " " +
                                          rowData.lastName +
                                          " account Role ",
                                        // html:true,
                                        text: "Are you sure you want to remove user account role",
                                        icon: "warning",
                                        buttons: true,
                                        buttons: {
                                          cancel: "Cancel",
                                          catch: {
                                            text: "Yes",
                                            //value: "catch",
                                          },
                                          //defeat: true,
                                        },
                                        dangerMode: true,
                                      }).then(async (willDelete) => {
                                        if (willDelete) {
                                          home.setState({
                                            NoneLoader: true,
                                          });

                                          await firebase
                                            .firestore()
                                            .collection("users")
                                            .doc(rowData.id)
                                            .update({
                                              role: "none",
                                              //paid: parseInt(home.state.lastPaid) +  parseInt(document.getElementById('payment_amount').value)
                                            })
                                            .then(function () {
                                              home.setState({
                                                NoneLoader: false,
                                              });

                                              home.props.UserActions();

                                              document
                                                .getElementById("admin_role")
                                                .classList.remove("btn-info");
                                              document
                                                .getElementById("admin_role")
                                                .classList.add("btn-default");

                                              document
                                                .getElementById("hd_role")
                                                .classList.remove("btn-info");
                                              document
                                                .getElementById("hd_role")
                                                .classList.add("btn-default");

                                              document
                                                .getElementById("others_role")
                                                .classList.add("btn-info");
                                              document
                                                .getElementById("others_role")
                                                .classList.remove(
                                                  "btn-default"
                                                );

                                              document
                                                .getElementById("finance_role")
                                                .classList.remove("btn-info");
                                              document
                                                .getElementById("finance_role")
                                                .classList.add("btn-default");

                                              swal(
                                                "Role Update ",
                                                rowData.firstName +
                                                  " " +
                                                  rowData.lastName +
                                                  " role now removed",
                                                {
                                                  icon: "success",
                                                }
                                              ).then((value) => {});
                                            });
                                        }
                                      });
                                    }}
                                  >
                                    {this.state.NoneLoader ? (
                                      <>
                                        Updating...{" "}
                                        <i className="fa fa-spinner fa-spin"></i>
                                      </>
                                    ) : (
                                      "No Role"
                                    )}
                                  </Button>

                                  <Button
                                    id="hd_role"
                                    style={{
                                      marginTop: 20,
                                      marginRight: 20,
                                      float: "right",
                                    }}
                                    size="sm"
                                    //color="default"

                                    color={
                                      rowData.hasOwnProperty("role")
                                        ? rowData.role == "help desk"
                                          ? "info"
                                          : "default"
                                        : "default"
                                    }
                                    onClick={async (e) => {
                                      e.preventDefault();

                                      const home = this;

                                      swal({
                                        title:
                                          "Make user " +
                                          rowData.firstName +
                                          " " +
                                          rowData.lastName +
                                          " account  Help Desk",
                                        // html:true,
                                        text: "Are you sure you want to make this user role Help Desk?",
                                        icon: "warning",
                                        buttons: true,
                                        buttons: {
                                          cancel: "Cancel",
                                          catch: {
                                            text: "Yes",
                                            //value: "catch",
                                          },
                                          //defeat: true,
                                        },
                                        dangerMode: true,
                                      }).then(async (willDelete) => {
                                        if (willDelete) {
                                          home.setState({
                                            HDLoader: true,
                                          });

                                          await firebase
                                            .firestore()
                                            .collection("users")
                                            .doc(rowData.id)
                                            .update({
                                              role: "help desk",
                                              //paid: parseInt(home.state.lastPaid) +  parseInt(document.getElementById('payment_amount').value)
                                            })
                                            .then(function () {
                                              home.setState({
                                                HDLoader: false,
                                              });

                                              home.props.UserActions();

                                              document
                                                .getElementById("admin_role")
                                                .classList.remove("btn-info");
                                              document
                                                .getElementById("admin_role")
                                                .classList.add("btn-default");

                                              document
                                                .getElementById("hd_role")
                                                .classList.add("btn-info");
                                              document
                                                .getElementById("hd_role")
                                                .classList.remove(
                                                  "btn-default"
                                                );

                                              document
                                                .getElementById("others_role")
                                                .classList.remove("btn-info");
                                              document
                                                .getElementById("others_role")
                                                .classList.add("btn-default");

                                              document
                                                .getElementById("finance_role")
                                                .classList.remove("btn-info");
                                              document
                                                .getElementById("finance_role")
                                                .classList.add("btn-default");

                                              swal(
                                                "Role Update ",
                                                rowData.firstName +
                                                  " " +
                                                  rowData.lastName +
                                                  " role now Help Desk ",
                                                {
                                                  icon: "success",
                                                }
                                              ).then((value) => {});
                                            });
                                        }
                                      });
                                    }}
                                    type="button"
                                    disabled={this.state.HDLoader}
                                  >
                                    {this.state.HDLoader ? (
                                      <>
                                        Updating...{" "}
                                        <i className="fa fa-spinner fa-spin"></i>
                                      </>
                                    ) : (
                                      "Help Desk Role"
                                    )}
                                  </Button>

                                  <Button
                                    id="finance_role"
                                    onClick={async (e) => {
                                      e.preventDefault();

                                      const home = this;

                                      swal({
                                        title:
                                          "Make user " +
                                          rowData.firstName +
                                          " " +
                                          rowData.lastName +
                                          " account Finance",
                                        // html:true,
                                        text: "Are you sure you want to make this user role Finance?",
                                        icon: "warning",
                                        buttons: true,
                                        buttons: {
                                          cancel: "Cancel",
                                          catch: {
                                            text: "Yes",
                                            //value: "catch",
                                          },
                                          //defeat: true,
                                        },
                                        dangerMode: true,
                                      }).then(async (willDelete) => {
                                        if (willDelete) {
                                          home.setState({
                                            FinanceLoader: true,
                                          });

                                          await firebase
                                            .firestore()
                                            .collection("users")
                                            .doc(rowData.id)
                                            .update({
                                              role: "finance",
                                              //paid: parseInt(home.state.lastPaid) +  parseInt(document.getElementById('payment_amount').value)
                                            })
                                            .then(function () {
                                              home.setState({
                                                FinanceLoader: false,
                                              });

                                              home.props.UserActions();

                                              //finance_role

                                              document
                                                .getElementById("finance_role")
                                                .classList.add("btn-info");
                                              document
                                                .getElementById("finance_role")
                                                .classList.remove(
                                                  "btn-default"
                                                );

                                              document
                                                .getElementById("admin_role")
                                                .classList.remove("btn-info");
                                              document
                                                .getElementById("admin_role")
                                                .classList.add("btn-default");

                                              document
                                                .getElementById("hd_role")
                                                .classList.remove("btn-info");
                                              document
                                                .getElementById("hd_role")
                                                .classList.add("btn-default");

                                              document
                                                .getElementById("others_role")
                                                .classList.remove("btn-info");
                                              document
                                                .getElementById("others_role")
                                                .classList.add("btn-default");

                                              swal(
                                                "Role Updated ",
                                                rowData.firstName +
                                                  " " +
                                                  rowData.lastName +
                                                  " role now Finance ",
                                                {
                                                  icon: "success",
                                                }
                                              ).then((value) => {});
                                            });
                                        }
                                      });
                                    }}
                                    disabled={this.state.FinanceLoader}
                                    style={{
                                      marginTop: 20,
                                      marginRight: 20,
                                      float: "right",
                                      marginBottom: 20,
                                    }}
                                    size="sm"
                                    //color="default"

                                    color={
                                      rowData.hasOwnProperty("role")
                                        ? rowData.role == "finance"
                                          ? "info"
                                          : "default"
                                        : "default"
                                    }
                                    type="button"
                                  >
                                    {this.state.FinanceLoader ? (
                                      <>
                                        Updating...{" "}
                                        <i className="fa fa-spinner fa-spin"></i>
                                      </>
                                    ) : (
                                      "Finance Role"
                                    )}
                                  </Button>

                                  <Button
                                    id="admin_role"
                                    onClick={async (e) => {
                                      e.preventDefault();

                                      const home = this;

                                      swal({
                                        title:
                                          "Make user " +
                                          rowData.firstName +
                                          " " +
                                          rowData.lastName +
                                          " account Admin",
                                        // html:true,
                                        text: "Are you sure you want to make this user role Admin?",
                                        icon: "warning",
                                        buttons: true,
                                        buttons: {
                                          cancel: "Cancel",
                                          catch: {
                                            text: "Yes",
                                            //value: "catch",
                                          },
                                          //defeat: true,
                                        },
                                        dangerMode: true,
                                      }).then(async (willDelete) => {
                                        if (willDelete) {
                                          home.setState({
                                            AdminLoader: true,
                                          });

                                          await firebase
                                            .firestore()
                                            .collection("users")
                                            .doc(rowData.id)
                                            .update({
                                              role: "admin",
                                              //paid: parseInt(home.state.lastPaid) +  parseInt(document.getElementById('payment_amount').value)
                                            })
                                            .then(function () {
                                              home.setState({
                                                AdminLoader: false,
                                              });

                                              home.props.UserActions();

                                              document
                                                .getElementById("admin_role")
                                                .classList.add("btn-info");
                                              document
                                                .getElementById("admin_role")
                                                .classList.remove(
                                                  "btn-default"
                                                );

                                              document
                                                .getElementById("hd_role")
                                                .classList.remove("btn-info");
                                              document
                                                .getElementById("hd_role")
                                                .classList.add("btn-default");

                                              document
                                                .getElementById("others_role")
                                                .classList.remove("btn-info");
                                              document
                                                .getElementById("others_role")
                                                .classList.add("btn-default");

                                              document
                                                .getElementById("finance_role")
                                                .classList.remove("btn-info");
                                              document
                                                .getElementById("finance_role")
                                                .classList.add("btn-default");

                                              swal(
                                                "Role Updated ",
                                                rowData.firstName +
                                                  " " +
                                                  rowData.lastName +
                                                  " role now Admin ",
                                                {
                                                  icon: "success",
                                                }
                                              ).then((value) => {});
                                            });
                                        }
                                      });
                                    }}
                                    disabled={this.state.AdminLoader}
                                    style={{
                                      marginTop: 20,
                                      marginRight: 20,
                                      float: "right",
                                      marginBottom: 20,
                                    }}
                                    size="sm"
                                    //color="default"

                                    color={
                                      rowData.hasOwnProperty("role")
                                        ? rowData.role == "admin"
                                          ? "info"
                                          : "default"
                                        : "default"
                                    }
                                    type="button"
                                  >
                                    {this.state.AdminLoader ? (
                                      <>
                                        Updating...{" "}
                                        <i className="fa fa-spinner fa-spin"></i>
                                      </>
                                    ) : (
                                      "Admin Role"
                                    )}
                                  </Button>
                                </div>
                              ) : null}

                              <Table
                                className="align-items-center table-flush"
                                style={{
                                  margin: 50,
                                  marginTop: 30,
                                  width: 90 + "%",
                                }}
                                responsive
                              >
                                <thead className="thead-light">
                                  <tr>
                                    <th scope="col">Property</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Pro-Price</th>
                                    <th scope="col">Paid</th>
                                    <th scope="col">Tran-ID</th>
                                    <th scope="col">Date</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.keys(userTrans).length <= 0 ? (
                                    <tr>
                                      <td
                                        colSpan={5}
                                        style={{ textAlign: "center" }}
                                      >
                                        <Alert
                                          color="warning"
                                          style={{
                                            marginLeft: 5 + "%",
                                            marginRight: 5 + "%",
                                          }}
                                        >
                                          <strong>
                                            {rowData.firstName +
                                              " " +
                                              rowData.lastName}{" "}
                                          </strong>{" "}
                                          have not made any transaction
                                        </Alert>
                                      </td>
                                    </tr>
                                  ) : null}

                                  {Object.keys(userTrans).length > 0 &&
                                    userTrans.map((data, i) => {
                                      //return (JSON.stringify(data))

                                      return !isEmpty(data.pnm) ? (
                                        <tr>
                                          <th>
                                            <Button
                                              title={"View Property"}
                                              style={{
                                                marginRight: 10,
                                                float: "left",
                                              }}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.GetProperty(e);
                                              }}
                                              //data-all={(data[i])}
                                              data-id={data.id}
                                              data-pid={data.pid}
                                              data-uid={data.uid}
                                              data-property={JSON.stringify(
                                                data.property
                                              )}
                                              data-pplan={JSON.stringify(
                                                data.pplan
                                              )}
                                              color="primary"
                                              href="#"
                                              size="sm"
                                            >
                                              <i
                                                className="fa fa-eye"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  //this.GetProperty(e)
                                                }}
                                                data-property={JSON.stringify(
                                                  data[i]
                                                )}
                                                data-pid={data.pid}
                                                data-id={data.id}
                                                data-uid={data.uid}
                                                data-property={JSON.stringify(
                                                  data.property
                                                )}
                                                data-pplan={JSON.stringify(
                                                  data.pplan
                                                )}
                                              />
                                            </Button>

                                            {data.pnm}
                                            <div
                                              style={{
                                                marginLeft: 40,
                                                color: "#ccc",
                                                fontSize: 12,
                                              }}
                                            >
                                              <i className="ni ni-shop text-success mr-1" />

                                              {data.hasOwnProperty("pplan")
                                                ? data.pplan.hasOwnProperty(
                                                    "title"
                                                  )
                                                  ? data.pplan.title
                                                  : null
                                                : null}
                                            </div>
                                          </th>
                                          <td>
                                            {data.paid <= 0 ? (
                                              <Badge
                                                color=""
                                                style={{
                                                  fontSize: 10,
                                                  color: "#000",
                                                }}
                                                className="badge-dot"
                                              >
                                                <i className="bg-danger" />
                                                Pending
                                              </Badge>
                                            ) : data.paid > 0 ? (
                                              <Badge
                                                color=""
                                                style={{
                                                  fontSize: 10,
                                                  color: "#ccc",
                                                }}
                                                className="badge-dot"
                                              >
                                                <i className="bg-info" />
                                                On-going
                                              </Badge>
                                            ) : (
                                              <Badge
                                                color=""
                                                style={{
                                                  fontSize: 10,
                                                  color: "#ccc",
                                                }}
                                                className="badge-dot"
                                              >
                                                <i className="bg-success" />
                                                Completed
                                              </Badge>
                                            )}
                                          </td>

                                          <td>
                                            {currencyFormat(
                                              parseInt(data.price)
                                            )}
                                          </td>
                                          <td>
                                            {currencyFormat(
                                              parseInt(data.paid)
                                            )}
                                          </td>
                                          <td>
                                            {data.id}

                                            <Button
                                              color="success"
                                              title={"Add Payment for Customer"}
                                              href="#"
                                              style={{
                                                fontSize: 20,
                                                paddingBottom: 0,
                                                float: "right",
                                                marginLeft: 10,
                                              }}
                                              size="sm"
                                              data-id={data.id}
                                              data-cid={data.uid}
                                              data-email={data.email}
                                              data-phone={data.phone}
                                              data-name={data.unm}
                                              data-paid={data.paid}
                                              data-lastPayment={
                                                data.lastPayment
                                              }
                                              onClick={(e) => {
                                                e.preventDefault();
                                                let id =
                                                  e.target.getAttribute(
                                                    "data-id"
                                                  );

                                                let email =
                                                  e.target.getAttribute(
                                                    "data-email"
                                                  );
                                                let cid =
                                                  e.target.getAttribute(
                                                    "data-cid"
                                                  );
                                                let phone =
                                                  e.target.getAttribute(
                                                    "data-phone"
                                                  );
                                                let name =
                                                  e.target.getAttribute(
                                                    "data-name"
                                                  );

                                                let paid =
                                                  e.target.getAttribute(
                                                    "data-paid"
                                                  );
                                                let lastPayment =
                                                  e.target.getAttribute(
                                                    "data-lastPayment"
                                                  );

                                                //alert(id)

                                                //alert(cid+' - '+phone+' - '+email)
                                                this.setState({
                                                  showPaymentAddPage: true,
                                                  TransID: id,
                                                  customerEmail: email,
                                                  customerPhone: phone,
                                                  customerUid: cid,
                                                  customerName: name,
                                                  lastPaid: paid,
                                                });
                                              }}
                                            >
                                              <i
                                                className="ni ni-fat-add"
                                                style={{ fontSize: 20 }}
                                                data-id={data.id}
                                                data-cid={data.uid}
                                                data-email={data.email}
                                                data-phone={data.phone}
                                                data-name={data.unm}
                                                data-paid={data.paid}
                                                data-lastPayment={
                                                  data.lastPayment
                                                }
                                                onClick={(e) => {
                                                  e.preventDefault();

                                                  e.target.getAttribute(
                                                    "data-id"
                                                  );
                                                  //this.GetProperty(e)
                                                }}
                                              ></i>
                                            </Button>

                                            <Button
                                              title={"View Customer Payments"}
                                              style={{
                                                marginLeft: 10,
                                                float: "right",
                                              }}
                                              onClick={(e) => {
                                                e.preventDefault();

                                                let id =
                                                  e.target.getAttribute(
                                                    "data-id"
                                                  );

                                                this.setState({
                                                  TransID: id,
                                                  showPaymentPage: true,
                                                });
                                              }}
                                              //data-all={(data[i])}
                                              data-id={data.id}
                                              color="info"
                                              href="#"
                                              size="sm"
                                            >
                                              <i
                                                className="fa fa-eye"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  //this.GetProperty(e)
                                                }}
                                                data-id={data.id}
                                              />
                                            </Button>
                                          </td>

                                          <td>
                                            {dateFormater(data.createdDate)}
                                          </td>
                                        </tr>
                                      ) : null;
                                    })}
                                </tbody>
                              </Table>
                            </div>
                          );
                        },
                      },
                    ]}
                    options={{
                      exportButton: true,
                      exportFileName: "Customer_Data_" + new Date(),
                      filtering: true,
                      pageSize: 10,
                      pageSizeOptions: [
                        5, 10, 20, 30, 50, 75, 100, 200, 500, 1000, 2000, 3000,
                        5000, 8000, 10000,
                      ],
                      toolbar: true,
                      // paging: true,

                      rowStyle: {
                        paddingLeft: "1.5rem",
                        paddingRight: "1.5rem",
                        verticalAlign: "middle",

                        fontSize: "0.8125rem",
                        whiteSpace: "nowrap",
                        padding: "1rem",
                        borderTop: "1px solid #e9ecef",
                        fontFamily: "Open Sans, sans-serif",
                      },

                      headerStyle: {
                        paddingTop: "0.75rem",
                        paddingBottom: "0.75rem",
                        fontSize: "0.65rem",
                        textTransform: "uppercase",
                        letterPpacing: "1px",
                        borderBottom: "1px solid #e9ecef",
                        fontWeight: "bolder",
                        paddingLeft: "1.5rem",
                        paddingRight: "1.5rem",
                        backgroundColor: "#f6f9fc",
                        color: "#8898aa",
                        verticalAlign: "middle",
                        borderColor: "#e9ecef",
                        fontFamily: "Open Sans, sans-serif",
                      },
                    }}
                    title={" "}
                  />
                </div>
              </Card>
            </div>
          </Row>
        </Container>

        <Modal
          id="model_payment"
          className="modal-dialog-centered"
          isOpen={this.state.showPaymentPage}
          // toggle={() => this.toggleModal("exampleModal")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Customer Payment Record
            </h5>

            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ showPaymentPage: false });
              }}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>

          <div className="modal-body">
            <Row
              style={{ display: isEmpty(this.state.TransID) ? "none" : "flex" }}
            >
              <Col>
                <FormGroup>
                  <TransPaymentsMini transaction={this.state.TransID} />
                </FormGroup>
              </Col>
            </Row>
          </div>
        </Modal>

        <Modal
          id="model_payment"
          className="modal-dialog-centered"
          isOpen={this.state.showPaymentAddPage}
          // toggle={() => this.toggleModal("exampleModal")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Add Payment for Customer
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ showPaymentAddPage: false });
                document.location.reload();
              }}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>

          <div className="modal-body"></div>

          <Form>
            <div className="pl-lg-4">
              <Row>
                <Col>
                  <FormGroup>
                    <div style={{ fontSize: 9 }}>
                      {/* JSON.stringify(this.state.transactionRecord) */}
                    </div>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Transaction
                    </label>
                    <Input
                      className="form-control-alternative"
                      //defaultValue="Lucky"
                      readonly
                      style={{ width: 90 + "%" }}
                      value={
                        isEmpty(this.state.TransID) ? null : this.state.TransID
                      }
                      id="trans_id"
                      placeholder=""
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row
                style={{
                  display: isEmpty(this.state.TransID) ? "none" : "flex",
                }}
              >
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="customer_name"
                    >
                      Customer
                    </label>
                    <Input
                      className="form-control-alternative"
                      //defaultValue="Lucky"
                      style={{ width: 90 + "%" }}
                      readonly
                      value={
                        isEmpty(this.state.TransID)
                          ? null
                          : this.state.customerName
                      }
                      id="customer_name"
                      placeholder="Customer Name"
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row
                style={{
                  display: isEmpty(this.state.TransID) ? "none" : "flex",
                }}
              >
                <Col>
                  <div style={{ fontSize: 10, color: "red", padding: 10 }}>
                    Total amount paid:{" "}
                    <b>{currencyFormat(parseInt(this.state.lastPaid))}</b>
                  </div>

                  <FormGroup>
                    <TransPaymentsMini transaction={this.state.TransID} />
                  </FormGroup>
                </Col>
              </Row>

              <Row
                style={{
                  display:
                    this.state.customerName == null ||
                    this.state.customerName == "loading..." ||
                    this.state.customerName == "NONE"
                      ? "none"
                      : "flex",
                }}
              >
                <br></br>

                <Col md="8">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="payment_amount"
                    >
                      Amount
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="payment_amount"
                      type="number"
                      placeholder="Payment Amount"
                    />
                  </FormGroup>
                </Col>

                <Col md="4">
                  <FormGroup>
                    <Button
                      disabled={this.state.startPaymentLoader}
                      onClick={async (e) => {
                        e.preventDefault();

                        if (
                          isEmpty(
                            document.getElementById("payment_amount").value
                          )
                        ) {
                          alert(
                            "Payment amount can not be empty\nKindly provide one to continue"
                          );
                        } else {
                          const paymentValid = true;
                          //const dateTrans = dateTr.toDateString();
                          //var dateTr = new Date();

                          var now = new Date();
                          var formattedToday = now
                            .toLocaleDateString("en-GB", {
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                            })
                            .split(" ")
                            .join("-"); //+ now.toLocaleTimeString();

                          this.setState({ startPaymentLoader: true });

                          var trf = "BRA-";
                          for (var i = 0; i < 6; i++) {
                            trf += Math.floor(
                              Math.random() * (9 - 0) + 0
                            ).toString();
                          }
                          //console.log(trf)

                          const paymData = {
                            c_date: formattedToday,
                            tid: this.state.TransID,
                            amt: parseInt(
                              document.getElementById("payment_amount").value
                            ),
                            c_amt: parseInt(
                              document.getElementById("payment_amount").value
                            ),
                            r_amt: parseInt(
                              document.getElementById("payment_amount").value
                            ),
                            amount: parseInt(
                              document.getElementById("payment_amount").value
                            ),

                            paymentValid: paymentValid,
                            pid: trf,
                            //productid:this.state.ProductId,
                            c_transRef: trf,
                            r_txnref: trf,

                            name: this.state.customerName,
                            uid: this.state.customerUid,
                            email: this.state.customerEmail,
                            phone: this.state.customerPhone,
                          };

                          //alert(JSON.stringify(paymData))

                          const home = this;

                          await firebase
                            .firestore()
                            .collection("payments")
                            .doc(trf)
                            .set(paymData)
                            .then(async function () {
                              home.setState({
                                Updatemsg: "Updating...",
                              });

                              await firebase
                                .firestore()
                                .collection("transactions")
                                .doc(home.state.TransID)
                                .update({
                                  paid:
                                    parseInt(home.state.lastPaid) +
                                    parseInt(
                                      document.getElementById("payment_amount")
                                        .value
                                    ),
                                })
                                .then(function () {
                                  home.setState({
                                    Updatemsg: "Completed...",
                                  });

                                  home.setState({
                                    showPaymentPage: false,
                                    startPaymentLoader: false,
                                  });

                                  // swal('Payment Added','Payment of '+document.getElementById('payment_amount').value+' was made successfully\n for Transaction-ID - '+this.state.TransID,'success')
                                  alert(
                                    "Payment of " +
                                      document.getElementById("payment_amount")
                                        .value +
                                      " was made successfully\n for Transaction-ID - " +
                                      home.state.TransID
                                  );
                                  window.location.reload();
                                });
                            });
                        }
                      }}
                      style={{ marginTop: 35 }}
                      color="primary"
                      type="button"
                    >
                      {this.state.startPaymentLoader ? (
                        // <img src={require("assets/img/loader.gif")} />
                        <>
                          {this.state.Updatemsg}{" "}
                          <i className="fa fa-spinner fa-spin"></i>
                        </>
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </FormGroup>
                </Col>
              </Row>

              <br></br>
            </div>
          </Form>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    PropertySingle: (param) => dispatch(PropertySingle(param)),
    UserActions: (param) => dispatch(UserActions(param)),
    transactionActions: (param) => dispatch(transactionActions(param)),
  };
};

const mapStateToProps = (state) => ({
  UserReducer: state.UserReducer,
  transactionReducer: state.transactionReducer,
  PaymentReducer: state.PaymentReducer,
  currentUser: state.authReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);

import { isObject } from "lodash";
import isEmpty from "lodash/isEmpty";

const iniState = {
  //propertysData: {},
  coopActLoading: true,
  coopActError: true,
  coopActErrorMsg: "",
  coopActLoadingCompleted: false,
  coopActCount: 0,

  ////SETTINGS STATE
  coopSetLoading: true,
  coopSetError: true,
  coopSetErrorMsg: "",
  coopSetLoadingCompleted: false,
  coopSetCount: 0,

  ///DEPOSIT STATE
  coopDepLoading: true,
  coopDepError: true,
  coopDepErrorMsg: "",
  coopDepLoadingCompleted: false,
  coopDepCount: 0,

  ///WALLET STATE
  coopWalLoading: true,
  coopWalError: true,
  coopWalErrorMsg: "",
  coopWalLoadingCompleted: false,
  coopWalCount: 0,
};

const CoopOtherReducers = (state = iniState, action) => {
  switch (action.type) {
    case "ACTIVATE_LOADING":
      return {
        ...state,
        coopActLoading: action.propLoading,
        coopActError: false,
        coopActLoadingCompleted: false,
        //propErrorMsg:''
      };

    case "ACTIVATE_SUCCESS":
      return {
        ...state,
        coopActData: action.payload,
        coopActError: false,
        coopActLoadingCompleted: true,
        coopActLoading: false,
      };

    case "ACTIVATE_ERROR":
      return {
        ...state,
        coopActError: true,
        coopActLoadingCompleted: false,
        coopActErrorMsg: action.payload,
        coopActLoaded: false,
      };

    ///////////////////

    case "SETTINGS_LOADING":
      return {
        ...state,
        coopSetLoading: action.propLoading,
        coopSetError: false,
        coopSetLoadingCompleted: false,
        //propErrorMsg:''
      };

    case "SETTINGS_SUCCESS":
      return {
        ...state,
        coopSetData: action.payload,
        coopSetError: false,
        coopSetLoadingCompleted: true,
        coopSetLoading: false,
      };

    case "SETTINGS_ERROR":
      return {
        ...state,
        coopSetError: true,
        coopSetLoadingCompleted: false,
        coopSetErrorMsg: action.payload,
        coopSetLoaded: false,
      };

    ///////////////////

    case "DEPOSIT_LOADING":
      return {
        ...state,
        coopDepLoading: action.propLoading,
        coopDepError: false,
        coopDepLoadingCompleted: false,
        //propErrorMsg:''
      };

    case "DEPOSIT_SUCCESS":
      return {
        ...state,
        coopDepData: action.payload,
        coopDepError: false,
        coopDepLoadingCompleted: true,
        coopDepLoading: false,
      };

    case "DEPOSIT_ERROR":
      return {
        ...state,
        coopDepError: true,
        coopDepLoadingCompleted: false,
        coopDepErrorMsg: action.payload,
        coopDepLoaded: false,
      };

    ///////////////////

    case "WALLET_LOADING":
      return {
        ...state,
        coopWalLoading: action.propLoading,
        coopWalError: false,
        coopWalLoadingCompleted: false,
        //propErrorMsg:''
      };

    case "WALLET_SUCCESS":
      return {
        ...state,
        coopWalData: action.payload,
        coopWalError: false,
        coopWalLoadingCompleted: true,
        coopWalLoading: false,
      };

    case "WALLET_ERROR":
      return {
        ...state,
        coopWalError: true,
        coopWalLoadingCompleted: false,
        coopWalErrorMsg: action.payload,
        coopWalLoaded: false,
      };

    default:
      return state;
  }
};

export default CoopOtherReducers;

import React from "react";
// reactstrap components

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  Modal,
  Form,
  FormGroup,
  Input,
  Badge,
} from "reactstrap";

// core components
import Header from "components/Headers/Header";
import moment from "moment";
import SelectSearch from "react-select-search";

import ReactDOM from "react-dom";
import MaterialTable, { MTableToolbar, MTableCell } from "material-table";

import firebase from "firebase/app";

import { connect } from "react-redux";
import CountUp from "react-countup";
import { isEmpty } from "lodash";
import swal from "sweetalert";
import { createThis } from "typescript";
import { DialogContent } from "@material-ui/core";

import { isObject } from "lodash";
import { dateFormater } from "utils/functions";

import Styles from "./FormData.module.css";

class SettingsData extends React.Component {
  state = {
    showRegisterModal: false,
  };
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <div id="modal_body_print">
          <div>
            <Row style={{ marginBottom: "28px" }}>
              <div className="col-4">
                <div className={Styles.form_label}>First Name</div>
                <div
                  className={Styles.form_controller}
                  style={{
                    fontSize: 14,
                    background: "#f2f3f3",
                    borderRadius: 7,
                    padding: 10,
                    fontWeight: 600,
                  }}
                >
                  {this.props.firstName}
                </div>
              </div>

              <div className="col-4">
                <div className={Styles.form_label}>Last Name</div>
                <div className={Styles.form_controller}>
                  {this.props.lastName}
                </div>
              </div>

              <div className="col-4">
                <div className={Styles.form_label}>Email</div>
                <div className={Styles.form_controller}>{this.props.email}</div>
              </div>
            </Row>
            <br />

            <Row style={{ marginBottom: "28px" }}>
              <div className="col-4">
                <div className={Styles.form_label}>Frequency</div>
                <div
                  className={Styles.form_controller}
                  style={{
                    fontSize: 14,
                    background: "#f2f3f3",
                    borderRadius: 7,
                    padding: 10,
                    fontWeight: 600,
                  }}
                >
                  {this.props.frequency}
                </div>
              </div>

              <div className="col-4">
                <div className={Styles.form_label}>Target</div>
                <div className={Styles.form_controller}>
                  {this.props.target}
                </div>
              </div>

              <div className="col-4">
                <div className={Styles.form_label}>Amount</div>
                <div className={Styles.form_controller}>
                  {this.props.amount}
                </div>
              </div>
            </Row>
            <br />

            <Row style={{ marginBottom: "28px" }}>
              <div className="col-8">
                <div className={Styles.form_label}>Name</div>
                <div
                  className={Styles.form_controller}
                  style={{
                    fontSize: 14,
                    background: "#f2f3f3",
                    borderRadius: 7,
                    padding: 10,
                    fontWeight: 600,
                  }}
                >
                  {this.props.PlanName}
                </div>
              </div>

              <div className="col-4">
                <div className={Styles.form_label}>Date</div>
                <div className={Styles.form_controller}>
                  {this.props.date_time}
                </div>
              </div>
            </Row>
          </div>
        </div>
      </>
    );
  }
}

export default connect(null, null)(SettingsData);

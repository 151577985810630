import React from "react";
import { withRouter } from "react-router-dom";
import {
  Card,
  Container,
  Row,
  Col,
  Table,
  Button,
  Badge,
  Alert,
  FormGroup,
  Modal,
  Form,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Header from "components/Headers/Header.js";
import firebase from "firebase/app";
import ReactDOM from "react-dom";
import MaterialTable, { MTableToolbar, MTableCell } from "material-table";
import {
  currencyFormat,
  dateFormater,
  UserRolePermission,
} from "utils/functions";
import { isEmpty, isArray, isObject } from "lodash";
import { connect } from "react-redux";
import CountUp from "react-countup";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { PropertySingle } from "store/actions/propertyActions";
import AddEditPaymentPlans from "./AddEditPaymentPlans";
import swal from "sweetalert";
import { UserActions, UserCount } from "store/actions/userActions";

import {
  propertyDeveloperAction,
  propertyOptionsAction,
  propertyPaymentPlanAction,
} from "./../../../store/actions/propertyActions";
import { allPropertyPaymentPlans } from "utils/constants";

class PaymentPlanTable extends React.Component {
  state = {
    subscriberRecord: [],
    showPaymentPage: false,
    showPaymentAddPage: false,
    startPaymentLoader: false,

    customerEmail: null,
    customerPhone: null,
    customerUid: null,
    customerName: null,
    lastPaid: 0,
    Updatemsg: "Saving...",

    editPaymentPlanLoader: false,

    addNewModal: false,
    editModal: false,
    Date1: "",
    Date2: "",
    filtering: false,
    //----------------------------------------------------------------
    tabstate: 0,
    propertyPaymentPlanName_edit: null,

    propertyPaymentPlanName_edit_title: "",
    propertyPaymentPlanName_edit_id: null,

    propertyDev: this.props.PropertyReducer.hasOwnProperty("propertyDeveloper")
      ? this.props.PropertyReducer.propertyDeveloper
      : null,
    propertyPaymentPlan: this.props.PropertyReducer.hasOwnProperty(
      "propertyPaymentPlan"
    )
      ? this.props.PropertyReducer.propertyPaymentPlan
      : null,
    // selectedPropertyData: [],

    startDevUpload: false,
    showEditPaymentPlanModal: false,
    showViewPaymentPlanModal: false,
    startDeveUpdate: false,
  };

  componentDidMount() {
    this.props.UserActions();
    this.props.propertyDeveloperAction();
    this.props.propertyPaymentPlanAction(allPropertyPaymentPlans);
    this.paymentPlanCheck();

    if (Object.keys(this.props.UserReducer.UseractionsData).length > 0) {
      var subscriberRecord = [];
      this.props.UserReducer.UseractionsData.forEach((doc) => {
        // console.log(doc.dateCreated.replace("/", "-").replace("/", "-"));

        // console.log(this.fromISODate(doc.dateCreated));
        subscriberRecord.push({
          id: doc.id,
          firstName: doc.firstName,
          lastName: doc.lastName,
          role: doc.role,
          emailVerified: doc.emailVerified,

          phone: doc.phone,
          email: doc.email,
          referalCode: doc.referalCode,
          dateCreated: doc.hasOwnProperty("dateCreated")
            ? this.fromISODate(doc.dateCreated)
            : null,
          // astName:doc.lastName,

          ct: 0,
        });
      });

      this.setState({ subscriberRecord: subscriberRecord });
    }
  }

  paymentPlanCheck() {
    const href = window.location.href;
    if (href.includes(allPropertyPaymentPlans[1])) {
      this.setState({ tabstate: 1 });
    } else if (href.includes(allPropertyPaymentPlans[2])) {
      this.setState({ tabstate: 2 });
    } else if (href.includes(allPropertyPaymentPlans[3])) {
      this.setState({ tabstate: 3 });
    } else if (href.includes(allPropertyPaymentPlans[4])) {
      this.setState({ tabstate: 4 });
    } else if (href.includes(allPropertyPaymentPlans[5])) {
      this.setState({ tabstate: 5 });
    } else {
      this.setState({ tabstate: 0 });
    }
  }

  fromISODate(d) {
    var date = new Date(d);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var dt = date.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }

    return year + "-" + month + "-" + dt;
  }

  myData = () => {
    /* function to determine the data to be rendered to the table */
    let myArr = [];
    if (this.state.Date1 && this.state.Date2) {
      (this.state.subscriberRecord || []).map((item) =>
        item.dateCreated >= this.state.Date1 &&
        item.dateCreated <= this.state.Date2
          ? myArr?.push(item)
          : null
      );
    } else {
      myArr =
        this.state.subscriberRecord ||
        []; /* YourData is the array you want to display and filter */
    }

    return myArr;
  };

  FilterByDateRange = () => {
    /* function for adding 2 textfields for date range */
    return (
      <>
        <Grid
          alignItems='center'
          container
          justify='center'
          style={{ display: this.state.filtering ? "flex" : "none" }}>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Paper
              elevation={3}
              style={{
                margin: "10px auto",
                textAlign: "center",
                padding: "10px",
              }}>
              <Typography> Search by Date Range </Typography>
              <br />
              <br />
              <TextField
                value={this.state.Date1}
                onChange={(e) => this.setDate1(e.target.value)}
                type='date'
                id='date'
                label='Start Date'
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ margin: "10px" }}
              />
              <TextField
                value={this.state.Date2}
                label='End Date'
                onChange={(e) => this.setDate2(e.target.value)}
                type='date'
                id='date'
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ margin: "10px" }}
              />
              <div>
                <Button
                  onClick={() => {
                    this.setDate1("");
                    this.setDate2("");
                  }}
                  variant='contained'
                  color='warning'>
                  Clear
                </Button>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  };

  setDate1(data) {
    return this.setState({ Date1: data });
  }

  setDate2(data) {
    return this.setState({ Date2: data });
  }

  setFiltering(data) {
    return this.setState({ filtering: !data });
  }

  handleChange = (event, newValue) => {
    this.setState({ tabstate: newValue });
    const href = window.location.href;
    const { history } = this.props;

    // Use history.push to navigate to a different route

    if (newValue === 1) {
      history.push(
        `/admin/property-payment-plans#${allPropertyPaymentPlans[1]}`
      );
    } else if (newValue === 2) {
      history.push(
        `/admin/property-payment-plans#${allPropertyPaymentPlans[2]}`
      );
    } else if (newValue === 3) {
      history.push(
        `/admin/property-payment-plans#${allPropertyPaymentPlans[3]}`
      );
    } else if (newValue === 4) {
      history.push(
        `/admin/property-payment-plans#${allPropertyPaymentPlans[4]}`
      );
    } else if (newValue === 5) {
      history.push(
        `/admin/property-payment-plans#${allPropertyPaymentPlans[5]}`
      );
    } else {
      history.push(
        `/admin/property-payment-plans#${allPropertyPaymentPlans[0]}`
      );
    }
  };

  render() {
    const transactionReducer = this.props.transactionReducer;
    const propertyPaymentPlan = this.state.propertyPaymentPlan;
    const propertyPaymentTitle = this.state.propertyPaymentPlanName_edit_title;
    const paymentPlanContentData = propertyPaymentTitle
      ? propertyPaymentPlan[propertyPaymentTitle]
      : {};
    // console.log({ propertyPaymentPlan });
    // console.log({ tabstate: this.state.tabstate });
    // console.log({href: window.location.href});

    const hashWithoutSymbol = window.location.hash.slice(1) || "";
    const dataToMap = propertyPaymentPlan
      ? propertyPaymentPlan[hashWithoutSymbol || allPropertyPaymentPlans[0]] ||
        []
      : [];
    //   this.setState({selectedPropertyData: dataToMap});
    //   const selectedPropertyData = Object.values(dataToMap);
    console.log({ dataToMap });
    // console.log({hashWithoutSymbol});
    // console.log(propertyPaymentPlan[hashWithoutSymbol])

    return (
      <>
        <Header />
        {/* Page content */}

        <Modal
          id='model_property_new'
          className='modal-dialog-centered'
          isOpen={this.state.addNewModal}
          style={{ maxWidth: 70 + "%" }}
          // toggle={() => this.toggleModal("exampleModal")}
        >
          <div className='modal-header'>
            <h5 className='modal-title' id='exampleModalLabel'>
              ADD NEW PAYMENT PLAN
            </h5>

            <button
              aria-label='Close'
              className='close'
              data-dismiss='modal'
              type='button'
              onClick={(e) => {
                e.preventDefault();
                this.setState({ addNewModal: false });
                // document.location.reload()
              }}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>

          <div className='modal-body'>
            <AddEditPaymentPlans
              modalType='add'
              selectedPropertyData={dataToMap}
            />
          </div>
        </Modal>
        <Modal
          id='model_property_edit'
          className='modal-dialog-centered'
          isOpen={this.state.editModal}
          style={{ maxWidth: 70 + "%" }}
          // toggle={() => this.toggleModal("exampleModal")}
        >
          <div className='modal-header'>
            <h5 className='modal-title' id='exampleModalLabel'>
              EDIT PAYMENT PLAN
            </h5>

            <button
              aria-label='Close'
              className='close'
              data-dismiss='modal'
              type='button'
              onClick={(e) => {
                e.preventDefault();
                this.setState({ editModal: false });
                // document.location.reload()
              }}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>

          <div className='modal-body'>
            <AddEditPaymentPlans
              modalType='edit'
              selectedPropertyData={dataToMap}
            />
          </div>
        </Modal>

        <Container className='mt--7' fluid>
          <Row>
            <Col>
              <AppBar
                position='static'
                style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }}>
                <Tabs
                  value={this.state.tabstate}
                  onChange={this.handleChange}
                  variant='scrollable'
                  scrollButtons='auto'
                  aria-label='scrollable auto tabs example'>
                  {(allPropertyPaymentPlans || []).map((property, index) => (
                    <Tab label={property} {...a11yProps(index)} />
                  ))}
                </Tabs>
              </AppBar>
            </Col>
          </Row>
          <Row>
            <div className='col'>
              <Card className='shadow border-0'>
                <div style={{ maxWidth: "100%", borderRadius: 20 }}>
                  <MaterialTable
                    style={{
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                    }}
                    columns={[
                      { title: "title", field: "title" },
                      {
                        title: "details",
                        field: "details",
                        render: (rowData) => {
                          return (
                            <ul>
                              {rowData.details.map((data, index) => (
                                <li key={index} className='list-style'>
                                  {data}
                                </li>
                              ))}
                            </ul>
                          );
                        },
                      },
                      {
                        title: "Initial Deposit %",
                        field: "initialDepositPercentage",
                        phoneNumber: "numeric",
                      },
                      { title: "multiplier", field: "multiplier" },
                      { title: "order", field: "order" },
                      { title: "surcharge", field: "surcharge" },
                      { title: "discount", field: "discount" },
                    ]}
                    actions={[
                      /* {
                        //icon: () => (<CIcon name="cil-filter"/>),
                        icon: "filter",
                        tooltip: "Date Filter",
                        isFreeAction: true,
                        onClick: (event) => {
                          this.setFiltering(this.state.filtering);
                        },
                      }, */
                      {
                        icon: () => (
                          <Button
                            color='primary'
                            href={`#${hashWithoutSymbol}`}
                            disabled={UserRolePermission(
                              this.props.currentUser.UserData.role,
                              "add_payment_plan"
                            )}
                            style={{
                              fontSize: 20,
                              paddingBottom: 0,

                              display: UserRolePermission(
                                this.props.currentUser.UserData.role,
                                "add_payment_plan"
                              )
                                ? "none"
                                : "block",
                            }}
                            size='sm'>
                            <i className='ni ni-fat-add'></i>
                          </Button>
                        ),
                        tooltip: "Add Payment Plan",
                        isFreeAction: true,
                        onClick: (event, rowData) => {
                          this.setState({ addNewModal: true });

                          // alert(JSON.stringify(rows))
                          //this.setState({showPaymentPage:true,customerName:null,TransID:null,dataLoading:true})
                        },
                      },
                    ]}
                    //data={this.state.subscriberRecord}
                    // data={this.myData()}
                    data={dataToMap}
                    components={{
                      Toolbar: (props) => (
                        <p className='h3' style={{ padding: 10 }}>
                          <MTableToolbar {...props} />
                          <div style={{ marginTop: -40 }}>
                            {"Total"}{" "}
                            <CountUp
                              end={
                                // Object.keys(
                                //   this.props.UserReducer.UseractionsData
                                // ).length
                                dataToMap.length
                              }
                            />
                          </div>
                          {this.FilterByDateRange()}
                        </p>
                      ),
                      Cell: (props) => {
                        if (props.columnDef.field == "ct") {
                          return (
                            <MTableCell
                              {...props}
                              id={"cell_ct_" + props.rowData.id}
                            />
                          );
                        } else {
                          return <MTableCell {...props} />;
                        }

                        //return <MTableCell {...props} data-value={JSON.stringify(props.columnDef)} id={'cell_'+props.rowData.id} />
                      },
                    }}
                    detailPanel={[
                      {
                        tooltip: "Threshold Detail",
                        render: (rowData) => {
                          const propertyTrans = Object.values(
                            rowData.threshold
                          );

                          return (
                            <div>
                              {this.props.currentUser.UserData.role ==
                              "admin" ? (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    minWidth: "100%",
                                  }}>
                                  <div
                                    style={{
                                      //   float: "left",
                                      padding: 20,
                                      paddingBottom: 0,
                                      paddingLeft: 80,
                                      fontSize: 10,
                                      color: "grey",
                                      textTransform: "uppercase",
                                    }}>
                                    {rowData.id}
                                  </div>
                                  <Button
                                    id='edit_payment_plan'
                                    onClick={(event) => {
                                      event.preventDefault();
                                      this.props.history.push(
                                        `/admin/property-payment-plans?id=${
                                          rowData.id
                                        }#${
                                          hashWithoutSymbol ||
                                          allPropertyPaymentPlans[0]
                                        }`
                                      );
                                      this.setState({ editModal: true });

                                      // alert(JSON.stringify(rows))
                                      //this.setState({showPaymentPage:true,customerName:null,TransID:null,dataLoading:true})
                                    }}
                                    disabled={this.state.editPaymentPlanLoader}
                                    style={{
                                      marginTop: 20,
                                      marginRight: 100,
                                      //   float: "right",
                                      marginBottom: 0,
                                    }}
                                    size='sm'
                                    color='default'
                                    type='button'>
                                    Edit Payment Plan
                                  </Button>
                                </div>
                              ) : null}

                              <Table
                                className='align-items-center table-flush'
                                style={{
                                  margin: 50,
                                  marginTop: 30,
                                  width: 90 + "%",
                                }}
                                responsive>
                                <thead className='thead-light'>
                                  <tr>
                                    <th scope='col'>Equity</th>
                                    <th scope='col'>Result</th>
                                    <th scope='col'>Timeline</th>
                                    {/* <th scope='col'>Pro-Price</th>
                                    <th scope='col'>Tran-ID</th>
                                    <th scope='col'>Date</th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.keys(propertyTrans).length <= 0 ? (
                                    <tr>
                                      <td
                                        colSpan={5}
                                        style={{ textAlign: "center" }}>
                                        <Alert
                                          color='warning'
                                          style={{
                                            marginLeft: 5 + "%",
                                            marginRight: 5 + "%",
                                          }}>
                                          No Data
                                        </Alert>
                                      </td>
                                    </tr>
                                  ) : null}

                                  {Object.keys(propertyTrans).length > 0 &&
                                    propertyTrans.map((data, i) => {
                                      //return (JSON.stringify(data))

                                      return (
                                        <tr>
                                          <td>{data.equity}</td>
                                          <td>{data.result}</td>
                                          <td>{data.timeline}</td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </Table>
                            </div>
                          );
                        },
                      },
                    ]}
                    options={{
                      exportButton: true,
                      exportFileName: "Customer_Data_" + new Date(),
                      filtering: true,
                      pageSize: 10,
                      pageSizeOptions: [
                        5, 10, 20, 30, 50, 75, 100, 200, 500, 1000, 2000, 3000,
                        5000, 8000, 10000,
                      ],
                      toolbar: true,
                      // paging: true,

                      rowStyle: {
                        paddingLeft: "1.5rem",
                        paddingRight: "1.5rem",
                        verticalAlign: "middle",

                        fontSize: "0.8125rem",
                        whiteSpace: "nowrap",
                        padding: "1rem",
                        borderTop: "1px solid #e9ecef",
                        fontFamily: "Open Sans, sans-serif",
                      },

                      headerStyle: {
                        paddingTop: "0.75rem",
                        paddingBottom: "0.75rem",
                        fontSize: "0.65rem",
                        textTransform: "uppercase",
                        letterPpacing: "1px",
                        borderBottom: "1px solid #e9ecef",
                        fontWeight: "bolder",
                        paddingLeft: "1.5rem",
                        paddingRight: "1.5rem",
                        backgroundColor: "#f6f9fc",
                        color: "#8898aa",
                        verticalAlign: "middle",
                        borderColor: "#e9ecef",
                        fontFamily: "Open Sans, sans-serif",
                      },
                    }}
                    title={" "}
                  />
                </div>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    PropertySingle: (param) => dispatch(PropertySingle(param)),
    UserActions: (param) => dispatch(UserActions(param)),
    propertyDeveloperAction: (param) =>
      dispatch(propertyDeveloperAction(param)),
    propertyPaymentPlanAction: (param) =>
      dispatch(propertyPaymentPlanAction(param)),
  };
};

const mapStateToProps = (state) => ({
  UserReducer: state.UserReducer,
  transactionReducer: state.transactionReducer,
  PaymentReducer: state.PaymentReducer,
  currentUser: state.authReducer,
  PropertyReducer: state.PropertyReducer,
});

const PrePaymentPlanTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentPlanTable);
const PaymentPlanTableWithRouter = withRouter(PrePaymentPlanTable);

export default PaymentPlanTableWithRouter;

import { isObject } from "lodash";
import isEmpty from "lodash/isEmpty";

const iniState = {
  //AgreementactionsData: {},
  AgreementLoading: true,
  AgreementError: true,
  AgreementErrorMsg: "",
  AgreementLoadingCompleted: false,
  AgreementactionCount: 0,
};

const AgreementReducer = (state = iniState, action) => {
  switch (action.type) {
    case "AGREEMENT_COUNT":
      // alert(action.payload)
      return {
        ...state,
        AgreementactionCount: action.payload,
      };
    case "AGREEMENT_LOADING":
      return {
        ...state,
        AgreementLoading: action.AgreementLoading,
        AgreementError: false,
        AgreementLoadingCompleted: false,
        //AgreementErrorMsg:''
      };

    case "AGREEMENT_SUCCESS":
      //alert(JSON.stringify(action.payload))
      return {
        ...state,
        AgreementactionsData: action.payload,
        //AgreementactionCount: (isObject(action.payload)?(Object.keys(action.payload).length):0),
        AgreementError: false,
        AgreementLoadingCompleted: true,
        AgreementLoading: false,
      };
    case "AGREEMENT_ERROR":
      //alert('error')
      return {
        ...state,
        AgreementError: true,
        AgreementLoadingCompleted: false,
        AgreementErrorMsg: action.payload,
        AgreementLoaded: false,
      };

    default:
      return state;
  }
};

export default AgreementReducer;

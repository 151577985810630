import axios from "axios";

import api from "../../utils/config";

import  firebase from "firebase/app";
import "firebase/auth";
import app from './../../variables/base'
import swal from "sweetalert";
import { isEmpty } from "lodash";


  
  if (!firebase.apps.length)
    {
    

        firebase.initializeApp({
            apiKey: api.REACT_APP_FIREBASE_APP_ID,
            authDomain: api.REACT_APP_FIREBASE_DOMAIN,
            databaseURL: api.REACT_APP_FIREBASE_DATABASE,
            projectId: api.REACT_APP_FIREBASE_PROJECT_ID,
            storageBucket: api.REACT_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: api.REACT_APP_FIREBASE_SENDER_ID,
            appId: api.REACT_APP_FIREBASE_APP_ID,
            measurementId: api.REACT_APP_FIREBASE_MEASUREMENT_ID,
          });

    }


    
    export const CouponCount = (count) =>{

      //alert(count+'====>')
      return (dispatch, getState) => {
         dispatch({ type: "COUPON_COUNT", payload: count });
      }

    }

export const setCouponCountData = (CouponCountData) => {

        return {
             type: "COUPON_SUCCESS",
             payload: CouponCountData,

        };
};


export const couponActions =  (credentials) => {
  return async (dispatch, getState)  => {

    dispatch({ type: "COUPON_LOADING", loading: true });
   

    try {

            await firebase
              .firestore()
              .collection("promoCoupon")
              //.orderBy("order","asc")
              .onSnapshot(
                (snapshot) => {

                  
                  if (snapshot.empty) {
                   // alert("empty");
                    return;
                  }

                  //alert("done");
                  

                  var CouponCountData = [];
                  //var Coupon ={}
                  snapshot.forEach(async (doc) => {
                    //console.log(Coupon.data())


                    CouponCountData.push({
                      id: doc.id,
                      //Coupon: (isEmpty(Coupon)?Coupon:Coupon.data()),
                      ...doc.data(),
                    });
                  });



                  //alert(JSON.stringify(CouponCountData))

                  dispatch(setCouponCountData(CouponCountData));
                },
                (error) => {
                  dispatch({ type: "COUPON_ERROR", payload: error });

                  //alert("ERROR :: " + error);
                }
              );


    }
    catch(error)
    {

        //alert('error')
         dispatch({ type: "COUPON_ERROR", payload: error });

    }





  }


}
import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// reactstrap components
import {
  Button,
  Card,
  CardImg,
  CardHeader,
  Modal,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import swal from "sweetalert";
import firebase from "firebase/app";
import "firebase/storage";
import { connect } from "react-redux";
 import { Editor } from "@tinymce/tinymce-react";




import { agreementActions } from "./../../../store/actions/agreementActions";
import { UserRolePermission } from "./../../../utils/functions";


class AgreementData extends React.Component {
  state = {
    propertyAgreement: this.props.AgreementReducer.AgreementactionsData,
    startSavingLoader: false,
    startEditSavingLoader: false,
    showEditAgreementModal: false,
    editName: null,
    editId: null,
    editImg: null,
    agreementEditor: null,
    agreementEditorEdit: null,
  };

  constructor(props) {
    super(props);
    //this.activeRoute.bind(this);
  }

  removeByValue = (array, value) => {
    return array.filter(function (elem, _index) {
      return value != elem;
    });
  };

  uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  extractFileExtension = (filename) => {
    //alert(filename)
    var ext = /(?:\.([^.]+))?$/.exec(filename);
    if (ext != null && ext[0] != null) {
      return ext[0];
    } else {
      return "";
    }
  };

  componentDidMount(props) {
    this.props.agreementActions();
  }

  handleEditorChange = (content, editor) => {
    this.setState({
      agreementEditor: content,
    });
    //console.log("Content was updated:", content);
  };

  handleEditorChangeEdit = (content, editor) => {
    this.setState({
      agreementEditorEdit: content,
    });
    //console.log("Content was updated:", content);
  };

  render() {
    return (
      <div className="container">
        <Modal
          size="lg"
          id="model_featured_edit"
          className="modal-dialog-centered"
          isOpen={this.state.showEditAgreementModal}
          // toggle={() => this.toggleModal("exampleModal")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="">
              EDIT <span id="featuerd_edit_title"></span>
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ showEditAgreementModal: false });
              }}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <Col lg="12">
                <FormGroup>
                  <Input
                    className="form-control-alternative"
                    id="proAgreementEdit"
                    placeholder="Enter Name of Agreement"
                    type="text"
                  />
                </FormGroup>
              </Col>

              <Col lg="12">
                <FormGroup>
                  <Editor
                    initialValue={this.state.editText}
                    apiKey="iswoe77jbas62javv1txe8fr8jnv3dvrrgmebp8ei4lm9wpx"
                    init={{
                      height: 500,
                      menubar: false,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo code redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
            fullscreen bullist | casechange checklist formatpainter pageembed permanentpen table | numlist outdent indent | removeformat |",
                    }}
                    onEditorChange={this.handleEditorChangeEdit}
                  />
                </FormGroup>
              </Col>

              <Col lg="12" style={{ marginTop: -15 }}>
                <span id="save_edit_feature"></span>

                <Button
                  block
                  className="mr-0"
                  style={{
                    float: "right",
                    cursor: "pointer",
                    display: this.state.editDisabled?'none':'false',
                  }}
                  disabled={this.state.startEditSavingLoader}
                  title={"Add  Agreement"}
                  color="info"
                  href="#stow"
                  onClick={async (e) => {
                    if (
                      document.getElementById("proAgreementEdit").value === ""
                    ) {
                      //alert('You must provide a name for the new feature to continue')

                      swal({
                        title: "Agreement name is Empty",
                        text: "You must provide a name for the new agreement to continue",
                        icon: "error",
                      });
                    } else if (this.state.agreementEditorEdit == null) {
                      swal({
                        title: "Agreement content is Empty",
                        text: "You must provide a content for the new agreement to continue",
                        icon: "error",
                      });
                    } else {
                      this.setState({ startEditSavingLoader: true });

                      var home = this;

                      var now = new Date();
                      var formattedToday =
                        now
                          .toLocaleDateString("en-GB", {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                          })
                          .split(" ")
                          .join("-") + "";

                      var AgreementListJson = {
                        date_time: formattedToday,
                        // date_time: this.props.currentUser.UserData.email,

                        name: document.getElementById("proAgreementEdit").value,
                        text: this.state.agreementEditorEdit,
                        updatedby: this.props.currentUser.UserData.email,
                      };

                      await firebase
                        .firestore()
                        .collection("agreement")
                        .doc(this.state.editId)
                        .update(AgreementListJson)
                        .then(function () {
                          home.setState({
                            startEditSavingLoader: false,
                            showEditAgreementModal: false,
                          });

                          swal(
                            "Agreement Updated",
                            "You have  updated  :: " +
                              home.state.editName +
                              " :: successfully",
                            "success"
                          ).then((value) => {
                            document.location.reload();
                          });
                        });
                    }
                  }}
                >
                  {this.state.startEditSavingLoader ? (
                    // <img src={require("assets/img/loader.gif")} />
                    <>
                      Updating... <i className="fa fa-spinner fa-spin"></i>
                    </>
                  ) : (
                    "Update"
                  )}
                </Button>
              </Col>
            </Row>
          </div>
        </Modal>

        <div
          className="bg-dark"
          style={{
            display: UserRolePermission(
              this.props.currentUser.UserData.role,
              "add_agree"
            )
              ? "none"
              : "block",
            background: "#fff",
            padding: "10px",
            borderRadius: "5px",
            boxShadow: "0 0 12px #e2e7ef",
            marginBottom: "10px",
            fontSize: 11,
            height: "auto",
          }}
        >
          <Row>
            <Col lg="12">
              <FormGroup>
                <Input
                  className="form-control-alternative"
                  id="proAgreement"
                  placeholder="Enter Name of Agreement"
                  type="text"
                />

                <br />

                <Editor
                  initialValue=""
                  apiKey="iswoe77jbas62javv1txe8fr8jnv3dvrrgmebp8ei4lm9wpx"
                  init={{
                    height: 250,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo code  redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify  | \
             fullscreen bullist  | casechange checklist formatpainter pageembed permanentpen table | numlist outdent indent | removeformat |",
                  }}
                  onEditorChange={this.handleEditorChange}
                />

                {/* <Input
                  className="form-control alternative"
                  id="proAgreementPhoto"
                  style={{ marginTop: 15 }}
                  accept=".pdf"
                  type="file"
                /> */}
              </FormGroup>
            </Col>

            <Col lg="12" style={{ marginTop: -15 }}>
              <span id="save_feature"></span>
              <Button
                block
                className="mr-0"
                style={{ float: "right", cursor: "pointer" }}
                disabled={this.state.startSavingLoader}
                title={"Add  Agreement"}
                color="info"
                href="#stow"
                onClick={async (e) => {
                  if (document.getElementById("proAgreement").value === "") {
                    //alert('You must provide a name for the new feature to continue')

                    swal({
                      title: "Agreement name is Empty",
                      text: "You must provide a name for the new agreement to continue",
                      icon: "error",
                    });
                  } else if (this.state.agreementEditor == null) {
                    swal({
                      title: "Agreement Content is Empty",
                      text: "You must provide a content for the new agreement to continue",
                      icon: "error",
                    });
                  } else {
                    this.setState({ startSavingLoader: true });

                    var now = new Date();
                    var formattedToday =
                      now
                        .toLocaleDateString("en-GB", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        })
                        .split(" ")
                        .join("-") + "";

                    var AgreementListJson = {
                      date_time: formattedToday,
                      name: document.getElementById("proAgreement").value,
                      text: this.state.agreementEditor,
                      updatedby: this.props.currentUser.UserData.email,
                    };

                    var home = this;

                    await firebase
                      .firestore()
                      .collection("agreement")

                      .add(AgreementListJson)
                      .then(function () {
                        home.setState({
                          startSavingLoader: false,
                        });

                        swal(
                          "Agreement added to list",
                          "You have successfully added " +
                            document.getElementById("proAgreement").value +
                            " to the agreement list",
                          "success"
                        ).then((value) => {
                          document.location.reload();
                        });
                      });
                  }
                }}
              >
                {this.state.startSavingLoader ? (
                  // <img src={require("assets/img/loader.gif")} />
                  <>
                    Saving... <i className="fa fa-spinner fa-spin"></i>
                  </>
                ) : (
                  "Save Agreement"
                )}
              </Button>
            </Col>
          </Row>
        </div>

        <div className="row" style={{ marginTop: 30 }}>
          {this.state.propertyAgreement &&
            this.state.propertyAgreement.map((data, i) => {
              return (
                <div
                  className="col-12"
                  style={{
                    background: "#fff",
                    padding: "10px",
                    borderRadius: "5px",
                    boxShadow: "0 0 12px #e2e7ef",
                    marginBottom: "10px",
                    fontSize: 11,
                  }}
                >
                  <div>
                    <i
                      class="ni ni-collection"
                      style={{ fontSize: 30, padding: 10, float: "left" }}
                    ></i>

                    <div style={{ fontSize: 10, marginTop: 15, float: "left" }}>
                      {data.name}
                    </div>
                  </div>

                  <span
                    id={"delete_feature_" + i}
                    className="float-right"
                    style={{ float: "right" }}
                  ></span>

                  <div style={{ float: "right", width: 30 + "%" }}>
                    <Button
                      //className="mr-2"
                      data-name={data.name}
                      data-id={data.id}
                      data-text={`${data.text}`}
                      style={{
                        //marginTop: -15,
                        float: "right",
                        fontSize: 9,
                        height: 15,
                        // left: 300,
                        paddingBottom: 20,
                      }}
                      title={
                        UserRolePermission(
                          this.props.currentUser.UserData.role,
                          "edit_agree"
                        )
                          ? "View " + data.name
                          : "Edit " + data.name
                      }
                      color="primary"
                      href="#stow"
                      onClick={(e) => {
                        let button = e;
                        let home = this;

                        this.setState({
                          editName: button.target.getAttribute("data-name"),
                          editText: button.target.getAttribute("data-text"),
                          editId: button.target.getAttribute("data-id"),
                          editDisabled: UserRolePermission(
                            this.props.currentUser.UserData.role,
                            "edit_agree"
                          ),
                        });

                        this.setState({ showEditAgreementModal: true }, () => {
                          var tt = setTimeout(function () {
                            document.getElementById(
                              "featuerd_edit_title"
                            ).innerHTML = home.state.editName.toUpperCase();
                            document.getElementById("proAgreementEdit").value =
                              home.state.editName;

                            //

                            clearTimeout(tt);
                          }, 800);
                        });
                      }}
                    >
                      {UserRolePermission(
                        this.props.currentUser.UserData.role,
                        "edit_agree"
                      )
                        ? "View"
                        : "Edit"}
                    </Button>

                    <Button
                      className="mr-2"
                      style={{
                        float: "right",
                        marginTop: 5,
                        fontSize: 9,
                        float: "right",
                        display: UserRolePermission(
                          this.props.currentUser.UserData.role,
                          "add_agree"
                        )?'none':'flex',
                      }}
                      title={"Delete  " + data.name}
                      color="info"
                      data-name={data.name}
                      data-id={data.id}
                      //data-img={data.text}
                      href="#stow"
                      onClick={(e) => {
                        let button = e;
                        let home = this;

                        this.setState({
                          editName: button.target.getAttribute("data-name"),
                          editImg: button.target.getAttribute("data-img"),
                          editId: button.target.getAttribute("data-id"),
                        });

                        swal({
                          title: "Delete Agreement ?",
                          // html:true,
                          text: "Delete " + data.name + " from List",
                          icon: "warning",
                          buttons: true,
                          buttons: {
                            cancel: "Cancel",
                            catch: {
                              text: "Delete",
                              //value: "catch",
                            },
                            //defeat: true,
                          },
                          dangerMode: true,
                        }).then(async (willDelete) => {
                          if (willDelete) {
                            e.preventDefault();

                            await firebase
                              .firestore()
                              .collection("agreement")
                              .doc(this.state.editId)
                              .delete()
                              .then(async () => {
                                //document.getElementById('delete_feature_'+i).innerHTML= '';

                                swal(
                                  "Agreement Deleted",
                                  "You have successfully Deleted the Agreement - " +
                                    data.name,
                                  {
                                    icon: "success",
                                  }
                                ).then((value) => {
                                  document.location.reload();
                                });
                              });
                          }
                        });
                      }}
                      size="sm"
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // PropertyEdit: (param) => dispatch(PropertyEdit(param)),
    // PropertySingle: (param) => dispatch(PropertySingle(param)),
    agreementActions: (param) => dispatch(agreementActions(param)),
    //promoActions: (param) => dispatch(promoActions(param)),
  };
};

const mapStateToProps = (state) => ({
  currentUser: state.authReducer,
  PropertyReducer: state.PropertyReducer,
  PromoReducer: state.PromoReducer,
  AgreementReducer: state.AgreementReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(AgreementData);

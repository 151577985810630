import axios from "axios";
//import setAuthorizationToken from "../../utils/TokenInterceptor";
//import jwt from "jsonwebtoken";
import api from "../../utils/config";

import firebase from "firebase/app";
import "firebase/auth";
import app from "./../../variables/base";
import swal from "sweetalert";

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: api.REACT_APP_FIREBASE_APP_ID,
    authDomain: api.REACT_APP_FIREBASE_DOMAIN,
    databaseURL: api.REACT_APP_FIREBASE_DATABASE,
    projectId: api.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: api.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: api.REACT_APP_FIREBASE_SENDER_ID,
    appId: api.REACT_APP_FIREBASE_APP_ID,
    measurementId: api.REACT_APP_FIREBASE_MEASUREMENT_ID,
  });
}

export const Reset_Login = () => {
  return (dispatch, getState) => {
    dispatch({ type: "LOGIN_RESET" });
  };
};

export const setUserData = (data) => {
  return {
    type: "USER_DATA",
    payload: data,
  };
};

export const signIn = (credentials, props) => {
  return (dispatch, getState) => {
    dispatch({ type: "AUTH_LOADING", loading: true });

    let email = credentials.email;
    let password = credentials.password;

    try {
      //alert(JSON.stringify(props))

      firebase
        .auth()
        //.createUserWithEmailAndPassword('eze@gmail.co', 'password')
        .signInWithEmailAndPassword(email, password)
        .then((user) => {
          //console.log(email + "-" + password);

          //alert(JSON.stringify(user))
          //CheckUserAuthState(props)

          firebase.auth().onAuthStateChanged((user) => {
            if (user) {
              user.getIdTokenResult().then(async function (getIdTokenResult) {
                handleToken(
                  getIdTokenResult.token,
                  getIdTokenResult.claims.exp,
                  props
                );

                await firebase
                  .firestore()
                  .collection("users")
                  .doc(user.uid)
                  .get()
                  .then(function (snapshot) {
                    if (snapshot.empty) {
                      return;
                    }

                    dispatch(setUserData(snapshot.data()));
                    // console.log("snapshot =>", snapshot);
                    // console.log("snapshot.data() =>", snapshot.data());
                    // console.log("snapshot.exists",snapshot.exists);

                    if (snapshot?.data()?.hasOwnProperty("role")) {
                      dispatch(setCurrentUser(user));
                      props.history.push("/admin/index");
                    } else {
                      var err = "Invalid User Role";
                      var code = 400;

                      dispatch({ type: "LOGIN_ERROR", err, code });
                      removeCurrentUser();

                      swal(
                        "Access not Granted!",
                        "You are not allowed to access access the STOW Admin yet\nKindly contact a STOW Administrator to continue",
                        "error"
                      );
                    }
                    // alert(snapshot.data().hasOwnProperty('role'))
                  });
              });
            } else {
              dispatch(removeCurrentUser());
            }
          });
        })
        .catch(function (error) {
          var err;

          console.log(JSON.stringify(error));

          if (error.response) {
            err = error.response.data;
            var code = error.response.status;
          } else {
            err =
              "Login Failed. Please check your network connection, and try again";
            //var code = 500;
          }

          if (error.code == "auth/user-not-found") {
            swal(
              "Access Denied!",
              "User does not exist in STOW account",
              "error"
            );
          } else if (error.code == "auth/wrong-password") {
            swal(
              "Access Denied!",
              "Invalid Email and Password provided",
              "error"
            );
          } else if (error.code == "auth/network-request-failed") {
            swal(
              "Access Denied!",
              "Unable to connect to the STOW ADMIN server\nKindly confirm your internet connection",
              "error"
            );
          } else {
            swal(
              "Access Denied!",
              "Your login credentials is not valid",
              "error"
            );
          }

          dispatch({ type: "LOGIN_ERROR", err, code });
          removeCurrentUser();
        });
    } catch (error) {
      dispatch({ type: "LOGIN_ERROR" });
      dispatch(removeCurrentUser());
      //alert(JSON.stringify(error+'ee'))
    }
  };
};

const handleToken = (token, tokenExpire, props) => {
  localStorage.setItem("token", token);
  localStorage.setItem("tokenExpire", tokenExpire);
  //alert(token+' - '+token;          Expire)
  //CheckUserAuthState(props)
  //setAuthorizationToken();
  // console.log(jwt.decode(token));
};

export const setCurrentUser = (user) => {
  return {
    type: "LOGIN_SUCCESS",
    payload: user,
  };
};

export const DispatchSetCurrentUser = (user) => {
  return (dispatch, getState) => {
    dispatch({ type: "LOGIN_SUCCESS", payload: user });
    //document.location.href= api.API_URL+'admin/index'
  };
};

export const DispatchRemoveCurrentUser = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("tokenExpire");

  return (dispatch) => {
    try {
      dispatch({ type: "LOGOUT_SUCCESS" });
    } catch (e) {
      //alert(JSON.stringify(e))
      // console.log('ERROR::-:: '+JSON.stringify(e))
    }
  };
};

export const removeCurrentUser = () => {
  //app.firestore.auth.signOut()
  localStorage.removeItem("token");
  localStorage.removeItem("tokenExpire");
  //alert('OUT')

  //
  return { type: "LOGOUT_SUCCESS" };
};

export const signOut = () => {
  return (dispatch, getState) => {
    //alert('OUT')
    // axios.post("/auth/logout").then(() => {
    dispatch(removeCurrentUser());
    // });
  };
};

export const signUp = (newUser) => {
  return (dispatch, getState) => {
    dispatch({ type: "AUTH_LOADING", loading: true });
    const promise = new Promise(function (resolve, reject) {
      axios
        .post(api.API_URL + "/auth/register", newUser)
        .then((user) => {
          dispatch({ type: "SIGNUP_SUCCESS" });

          resolve();
        })
        .catch((error) => {
          var err;
          if (error.response) {
            err = error.response.data.error;
          } else {
            err =
              "Registration Failed. Please check your network connection and try again";
          }

          dispatch({ type: "SIGNUP_ERROR", err });
          reject();
        });
    });

    return promise;
  };
};

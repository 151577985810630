//coopRegistrationAction.js

import axios from "axios";

import api from "../../utils/config";

import firebase from "firebase/app";
import "firebase/auth";
import app from "../../variables/base";
import swal from "sweetalert";
import { isEmpty } from "lodash";

//SinglePropertyFloorPlan

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: api.REACT_APP_FIREBASE_APP_ID,
    authDomain: api.REACT_APP_FIREBASE_DOMAIN,
    databaseURL: api.REACT_APP_FIREBASE_DATABASE,
    projectId: api.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: api.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: api.REACT_APP_FIREBASE_SENDER_ID,
    appId: api.REACT_APP_FIREBASE_APP_ID,
    measurementId: api.REACT_APP_FIREBASE_MEASUREMENT_ID,
  });
}

export const setRegister_1_Data = (propertyData) => {
  return {
    type: "REGISTER_1_SUCCESS",
    payload: propertyData,
  };
};

export const setActivationData = (propertyData) => {
  return {
    type: "ACTIVATE_SUCCESS",
    payload: propertyData,
  };
};

export const setSettingsData = (propertyData) => {
  return {
    type: "SETTINGS_SUCCESS",
    payload: propertyData,
  };
};

export const setDepositData = (propertyData) => {
  return {
    type: "DEPOSIT_SUCCESS",
    payload: propertyData,
  };
};

export const setWalletData = (propertyData) => {
  return {
    type: "WALLET_SUCCESS",
    payload: propertyData,
  };
};

export const setRegister_2_Data = (propertyData) => {
  return {
    type: "REGISTER_2_SUCCESS",
    payload: propertyData,
  };
};

export const setRegister_3_Data = (propertyData) => {
  return {
    type: "REGISTER_3_SUCCESS",
    payload: propertyData,
  };
};

export const Registration_1_Actions = (credentials) => {
  return async (dispatch, getState) => {
    dispatch({ type: "REGISTER_LOADING", loading: true });

    try {
      await firebase
        .firestore()
        .collection("stowPlusRegisterStep1")
        .onSnapshot((snapshot) => {
          if (snapshot.empty) {
            return;
          }

          var propertyData = [];
          // var User = {};
          snapshot.forEach(async (doc) => {
            propertyData.push({
              id: doc.id,
              ...doc.data(),
            });
          });

          //console.log(propertyData);

          // alert(JSON.stringify(propertyData.slice(0,2)))

          dispatch(setRegister_1_Data(propertyData));
        });
    } catch (error) {
      console.log("error :: ", error);
      dispatch({ type: "REGISTER_ERROR", payload: error });
    }
  };
};

export const Registration_2_Actions = (credentials) => {
  return async (dispatch, getState) => {
    dispatch({ type: "REGISTER_LOADING", loading: true });

    try {
      await firebase
        .firestore()
        .collection("stowPlusRegisterStep2")
        .onSnapshot((snapshot) => {
          if (snapshot.empty) {
            return;
          }

          var propertyData = [];
          // var User = {};
          snapshot.forEach(async (doc) => {
            propertyData.push({
              id: doc.id,
              ...doc.data(),
            });
          });

          // console.log(propertyData);

          // alert(JSON.stringify(propertyData.slice(0,2)))

          dispatch(setRegister_2_Data(propertyData));
        });
    } catch (error) {
      console.log("error :: ", error);
      dispatch({ type: "REGISTER_ERROR", payload: error });
    }
  };
};

export const Registration_3_Actions = (credentials) => {
  return async (dispatch, getState) => {
    dispatch({ type: "REGISTER_LOADING", loading: true });

    try {
      await firebase
        .firestore()
        .collection("stowPlusRegisterStep3")
        .onSnapshot((snapshot) => {
          if (snapshot.empty) {
            return;
          }

          var propertyData = [];
          // var User = {};
          snapshot.forEach(async (doc) => {
            propertyData.push({
              id: doc.id,
              ...doc.data(),
            });
          });

          //console.log(propertyData);

          // alert(JSON.stringify(propertyData.slice(0,2)))

          dispatch(setRegister_3_Data(propertyData));
        });
    } catch (error) {
      console.log("error :: ", error);
      dispatch({ type: "REGISTER_ERROR", payload: error });
    }
  };
};

export const ActivationAction = (credentials) => {
  return async (dispatch, getState) => {
    dispatch({ type: "ACTIVATE_LOADING", loading: true });

    try {
      await firebase
        .firestore()
        .collection("stowPlusUserAccount")
        .onSnapshot((snapshot) => {
          if (snapshot.empty) {
            return;
          }

          var propertyData = [];
          // var User = {};
          snapshot.forEach(async (doc) => {
            propertyData.push({
              id: doc.id,
              ...doc.data(),
            });
          });

          //console.log(propertyData);

          // alert(JSON.stringify(propertyData.slice(0,2)))

          dispatch(setActivationData(propertyData));
        });
    } catch (error) {
      console.log("error :: ", error);
      dispatch({ type: "ACTIVATE_ERROR", payload: error });
    }
  };
};

export const SettingsAction = (credentials) => {
  return async (dispatch, getState) => {
    dispatch({ type: "SETTINGS_LOADING", loading: true });

    try {
      await firebase
        .firestore()
        .collection("stowPlusSavingPlanSetting")
        .onSnapshot((snapshot) => {
          if (snapshot.empty) {
            return;
          }

          var propertyData = [];
          // var User = {};
          snapshot.forEach(async (doc) => {
            propertyData.push({
              id: doc.id,
              ...doc.data(),
            });
          });

          //console.log(propertyData);

          // alert(JSON.stringify(propertyData.slice(0,2)))

          dispatch(setSettingsData(propertyData));
        });
    } catch (error) {
      console.log("error :: ", error);
      dispatch({ type: "SETTINGS_ERROR", payload: error });
    }
  };
};

export const DepositAction = (credentials) => {
  return async (dispatch, getState) => {
    dispatch({ type: "DEPOSIT_LOADING", loading: true });

    try {
      await firebase
        .firestore()
        .collection("stowPlusRecuringRecord")
        .onSnapshot((snapshot) => {
          if (snapshot.empty) {
            return;
          }

          var propertyData = [];
          // var User = {};
          snapshot.forEach(async (doc) => {
            propertyData.push({
              id: doc.id,
              ...doc.data(),
            });
          });

          //console.log(propertyData);

          // alert(JSON.stringify(propertyData.slice(0,2)))

          dispatch(setDepositData(propertyData));
        });
    } catch (error) {
      console.log("error :: ", error);
      dispatch({ type: "DEPOSIT_ERROR", payload: error });
    }
  };
};

export const WalletAction = (credentials) => {
  return async (dispatch, getState) => {
    dispatch({ type: "WALLET_LOADING", loading: true });

    try {
      await firebase
        .firestore()
        .collection("stowPlusWallet")
        .onSnapshot((snapshot) => {
          if (snapshot.empty) {
            return;
          }

          var propertyData = [];
          // var User = {};
          snapshot.forEach(async (doc) => {
            propertyData.push({
              id: doc.id,
              ...doc.data(),
            });
          });

          dispatch(setWalletData(propertyData));
        });
    } catch (error) {
      console.log("error :: ", error);
      dispatch({ type: "WALLET_ERROR", payload: error });
    }
  };
};

export const PushSegmentData = (userData) => {
  //console.log("userData", userData);
  return async (dispatch, getState) => {};
};

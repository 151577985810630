import React from "react";
// reactstrap components

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  Modal,
  Form,
  FormGroup,
  Input,
  Badge,
} from "reactstrap";

// core components
import Header from "components/Headers/Header";
import moment from "moment";
import SelectSearch from "react-select-search";

import ReactDOM from "react-dom";
import MaterialTable, { MTableToolbar, MTableCell } from "material-table";

import firebase from "firebase/app";

import { connect } from "react-redux";
import CountUp from "react-countup";
import { isEmpty } from "lodash";
import swal from "sweetalert";
import { createThis } from "typescript";
import { DialogContent } from "@material-ui/core";
import {
  Registration_1_Actions,
  Registration_2_Actions,
  Registration_3_Actions,
  SettingsAction,
  ActivationAction,
  WalletAction,
  DepositAction,
} from "store/actions/coopAction";
import { isObject } from "lodash";
import { dateFormater, currencyFormat } from "utils/functions";
import FormData from "./FormData";
import SettingsData from "./SettingsData";
import DepositData from "./DepositData";

//DepositData

class Registrations extends React.Component {
  state = {
    showRegisterModal: false,
    showSettingModal: false,
    showDepositModal: false,
    showDepositData: [],

    settingTitle: "",
    DepositTitle: "",

    coopRegData1: [],
    coopRegData2: [],
    coopRegData3: [],
    firstName: "",
    lastName: "",
    email: "",
    frequency: "",
    amount: 0,
    target: 0,
    PlanName: "",
    date_time: "",
  };
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.Registration_1_Actions();
    this.props.Registration_2_Actions();
    this.props.Registration_3_Actions();

    this.props.SettingsAction();
    this.props.ActivationAction();
    this.props.WalletAction();
    this.props.DepositAction();
  }

  //coopMessageing

  coopMessageing() {
    //alert("ffff");
  }

  getActivationStatus(id) {
    if (this.props.CoopOtherReducers.hasOwnProperty("coopActData")) {
      let coopActData = this.props.CoopOtherReducers.coopActData;
      for (var i = 0, len = coopActData.length; i < len; i++) {
        if (coopActData[i].uid === id) {
          return true;
        }
      }
      return false;
    }
  }

  getSettingsStatus(id) {
    if (this.props.CoopOtherReducers.hasOwnProperty("coopSetData")) {
      let coopSetData = this.props.CoopOtherReducers.coopSetData;
      for (var i = 0, len = coopSetData.length; i < len; i++) {
        if (coopSetData[i].uid === id) {
          return true;
        }
      }
      return false;
    }
  }

  getSettingsData(id) {
    if (this.props.CoopOtherReducers.hasOwnProperty("coopSetData")) {
      let coopSetData = this.props.CoopOtherReducers.coopSetData;
      for (var i = 0, len = coopSetData.length; i < len; i++) {
        if (coopSetData[i].uid === id) {
          return [
            coopSetData[i].amount,
            coopSetData[i].planTarget,
            coopSetData[i].regularity,
            coopSetData[i].name,
            coopSetData[i].date_time,
          ];
        }
      }
      return false;
    }
  }

  /////////

  getDepositData(uid) {
    if (this.props.CoopOtherReducers.hasOwnProperty("coopDepData")) {
      //.find  - returns the first matching object
      //.filter  - return the all matching objects

      return this.props.CoopOtherReducers.coopDepData.filter(
        (item) => item.uid === uid
      );
    } else {
      return [];
    }
  }

  getWalletStatus(id) {
    if (this.props.CoopOtherReducers.hasOwnProperty("coopWalData")) {
      let coopWalData = this.props.CoopOtherReducers.coopWalData;
      for (var i = 0, len = coopWalData.length; i < len; i++) {
        if (coopWalData[i].uid === id) {
          return true;
        }
      }
      return false;
    }
  }

  getWalletData(id) {
    if (this.props.CoopOtherReducers.hasOwnProperty("coopWalData")) {
      let coopWalData = this.props.CoopOtherReducers.coopWalData;
      for (var i = 0, len = coopWalData.length; i < len; i++) {
        if (coopWalData[i].uid === id) {
          return coopWalData[i].balance;
        }
      }
      return false;
    }
  }

  GetRegisterationData(e, ID) {
    if (this.props.CoopRegisterReducer.hasOwnProperty("coopRegData1")) {
      let coopRegData1 = this.props.CoopRegisterReducer?.coopRegData1;
      let coopRegData2 = this.props.CoopRegisterReducer?.coopRegData2;
      let coopRegData3 = this.props.CoopRegisterReducer?.coopRegData3;

      //let id =  e.target.getAttribute('data-id')
      let id = ID;

      for (var i = 0, len = coopRegData1.length; i < len; i++) {
        if (coopRegData1[i].uid === id) {
          //console.log(coopRegData1[i]);

          if (coopRegData1[i]) {
            this.setState({ coopRegData1: coopRegData1[i] });
            // this.setState({ coopRegData2: coopRegData2[i] });
            //this.setState({ coopRegData3: coopRegData3[i] });
          } else {
            // alert("Step 1 records not found");
          }
        }
      }

      for (var i = 0, len = coopRegData2.length; i < len; i++) {
        if (coopRegData2[i].uid === id) {
          // console.log(coopRegData1[i]);

          if (coopRegData2[i]) {
            // this.setState({ coopRegData1: coopRegData1[i] });
            this.setState({ coopRegData2: coopRegData2[i] });
            //this.setState({ coopRegData3: coopRegData3[i] });
          } else {
            // alert("Registration ID not valid");
          }
        }
      }

      for (var i = 0, len = coopRegData3.length; i < len; i++) {
        if (coopRegData3[i].uid === id) {
          // console.log(coopRegData1[i]);

          if (coopRegData3[i]) {
            // this.setState({ coopRegData1: coopRegData1[i] });
            //this.setState({ coopRegData2: coopRegData2[i] });
            this.setState({ coopRegData3: coopRegData3[i] });
          } else {
            //alert("Registration ID not valid");
          }
        }
      }
    }
  }

  render() {
    const coopRegReducer1 = this.props.CoopRegisterReducer?.coopRegData1;
    const coopRegReducer2 = this.props.CoopRegisterReducer?.coopRegData2;
    const coopRegReducer3 = this.props.CoopRegisterReducer?.coopRegData3;

    // console.log(coopRegReducer1);
    return (
      <>
        <Header />

        <Modal
          id="model_coop_deposit"
          style={{ maxWidth: 80 + "%" }}
          className="modal-dialog-centered"
          isOpen={this.state.showDepositModal}
          // toggle={() => this.toggleModal("exampleModal")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {this.state.DepositTitle}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ showDepositModal: false });
              }}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <DepositData data={this.state.showDepositData} />
          </div>
        </Modal>
        <Modal
          id="model_coop_setting"
          style={{ maxWidth: 50 + "%" }}
          className="modal-dialog-centered"
          isOpen={this.state.showSettingModal}
          // toggle={() => this.toggleModal("exampleModal")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {this.state.settingTitle}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ showSettingModal: false });
              }}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <SettingsData
              firstName={this.state.firstName}
              lastName={this.state.lastName}
              email={this.state.email}
              frequency={this.state.frequency}
              target={this.state.target}
              amount={this.state.amount}
              PlanName={this.state.PlanName}
              date_time={this.state.date_time}
            />
          </div>
        </Modal>
        <Modal
          id="model_coop_register"
          style={{ maxWidth: 60 + "%" }}
          className="modal-dialog-centered"
          isOpen={this.state.showRegisterModal}
          // toggle={() => this.toggleModal("exampleModal")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel"></h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ showRegisterModal: false });
              }}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <FormData
              coopRegData1={this.state.coopRegData1}
              coopRegData2={this.state.coopRegData2}
              coopRegData3={this.state.coopRegData3}
            />
          </div>
        </Modal>
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow border-0">
                <div style={{ maxWidth: "100%", borderRadius: 20 }}>
                  <MaterialTable
                    columns={[
                      {
                        title: "Fullname",
                        field: "name",
                        /* defaultGroupOrder: 0 , */ render: (rowData) => {
                          return rowData.firstName + " " + rowData.lastName;
                        },
                      },

                      {
                        title: "Email",
                        field: "email",
                        /* defaultGroupOrder: 0 , */ render: (rowData) => {
                          return rowData.email;
                        },
                      },

                      {
                        title: "Phone",
                        field: "phone",
                        render: (rowData) => {
                          return rowData.code + "" + rowData.phone;
                        },
                      },

                      //getWalletStatus

                      {
                        title: "Account",
                        field: "uid",
                        render: (rowData) => {
                          return this.getActivationStatus(rowData.uid) ? (
                            <Badge
                              color=""
                              style={{ fontSize: 10, color: "#000" }}
                              className="badge-dot"
                            >
                              <i className="bg-success" />
                              Activated
                            </Badge>
                          ) : (
                            <Badge
                              color=""
                              style={{ fontSize: 10, color: "#ccc" }}
                              className="badge-dot"
                            >
                              <i className="bg-danger" />
                              Pending
                            </Badge>
                          );
                        },
                      },

                      {
                        title: "Setting",
                        field: "uid",
                        render: (rowData) => {
                          return this.getSettingsStatus(rowData.uid) ? (
                            <>
                              <Badge
                                color=""
                                style={{ fontSize: 10, color: "#000" }}
                                className="badge-dot"
                              >
                                {" "}
                                <i className="bg-success" />
                                Initialized
                              </Badge>

                              <Button
                                color="secondary"
                                href="#"
                                style={{ fontSize: 9, marginLeft: 15 }}
                                size="sm"
                                onClick={(e) => {
                                  this.setState({
                                    firstName: rowData.firstName,
                                    lastName: rowData.lastName,
                                    email: rowData.email,
                                    amount: currencyFormat(
                                      parseInt(
                                        this.getSettingsData(rowData.uid)[0]
                                      )
                                    ),
                                    frequency: this.getSettingsData(
                                      rowData.uid
                                    )[2],
                                    PlanName: this.getSettingsData(
                                      rowData.uid
                                    )[3],
                                    date_time: dateFormater(
                                      this.getSettingsData(rowData.uid)[4]
                                    ),
                                    target: currencyFormat(
                                      parseInt(
                                        this.getSettingsData(rowData.uid)[1]
                                      )
                                    ),

                                    settingTitle:
                                      rowData.firstName +
                                      " " +
                                      rowData.lastName +
                                      " - SETTINGS",
                                    showSettingModal: true,
                                  });
                                }}
                              >
                                <i className="fa fa-ellipsis-v" />
                              </Button>
                            </>
                          ) : (
                            <Badge
                              color=""
                              style={{ fontSize: 10, color: "#ccc" }}
                              className="badge-dot"
                            >
                              <i className="bg-danger" />
                              Pending
                            </Badge>
                          );
                        },
                      },

                      {
                        title: "Wallet",
                        field: "uid",
                        render: (rowData) => {
                          return this.getWalletStatus(rowData.uid) ? (
                            <>
                              <Badge
                                color=""
                                style={{ fontSize: 10, color: "#000" }}
                                className="badge-dot"
                              >
                                <div
                                  style={{
                                    fontSize: 13,
                                    textAlign: "left",
                                  }}
                                >
                                  {currencyFormat(
                                    parseInt(this.getWalletData(rowData.uid))
                                  )}
                                </div>
                              </Badge>

                              <Button
                                color="secondary"
                                href="#"
                                style={{ fontSize: 9, marginLeft: 15 }}
                                size="sm"
                                onClick={(e) => {
                                  this.setState({
                                    showDepositData: this.getDepositData(
                                      rowData.uid
                                    ),
                                    showDepositModal: true,
                                    DepositTitle:
                                      rowData.firstName +
                                      " " +
                                      rowData.lastName +
                                      " - DEPOSIT",
                                  });
                                }}
                              >
                                <i className="fa fa-ellipsis-v" />
                              </Button>
                            </>
                          ) : (
                            currencyFormat(0)
                          );
                        },
                      },

                      /*  {
                        title: "Country",
                        field: "country",
                         render: (rowData) => {
                          return rowData.country;
                        },
                      }, */

                      {
                        title: "Date",
                        field: "dateTime",
                        defaultSort: "desc",
                        render: (rowData) => {
                          return isEmpty(rowData.dateTime)
                            ? null
                            : dateFormater(rowData.dateTime);
                        },
                      },
                    ]}
                    options={{
                      exportButton: true,
                      exportFileName: "Property_Data_" + new Date(),
                      //grouping: true,
                      pageSize: 10,
                      pageSizeOptions: [
                        5, 10, 20, 30, 50, 75, 100, 200, 500, 1000, 2000, 3000,
                        5000, 8000, 10000,
                      ],
                      toolbar: true,
                      // paging: true,

                      rowStyle: {
                        paddingLeft: "1.5rem",
                        paddingRight: "1.5rem",
                        verticalAlign: "middle",

                        fontSize: "0.8125rem",
                        whiteSpace: "nowrap",
                        padding: "1rem",
                        borderTop: "1px solid #e9ecef",
                        fontFamily: "Open Sans, sans-serif",
                      },

                      headerStyle: {
                        paddingTop: "0.75rem",
                        paddingBottom: "0.75rem",
                        fontSize: "0.65rem",
                        textTransform: "uppercase",
                        letterPpacing: "1px",
                        borderBottom: "1px solid #e9ecef",
                        fontWeight: "bolder",
                        paddingLeft: "1.5rem",
                        paddingRight: "1.5rem",
                        backgroundColor: "#f6f9fc",
                        color: "#8898aa",
                        verticalAlign: "middle",
                        borderColor: "#e9ecef",
                        fontFamily: "Open Sans, sans-serif",
                      },
                    }}
                    actions={[
                      {
                        icon: (row) => (
                          <Button
                            color="warning"
                            href="#"
                            style={{ fontSize: 9 }}
                            size="sm"
                            onClick={(e) => {
                              this.setState({
                                showRegisterModal: true,
                              });
                            }}
                          >
                            <i className="fa fa-eye" />
                          </Button>
                        ),

                        tooltip: "View User",

                        onClick: (event, row) => {
                          //  alert(JSON.stringify(row.uid));
                          this.GetRegisterationData(event, row.uid);
                        },
                      },
                    ]}
                    data={coopRegReducer1}
                    title={" "}
                    components={{
                      Toolbar: (props) => (
                        <p className="h3" style={{ padding: 10 }}>
                          <MTableToolbar {...props} />
                          <div style={{ marginTop: -40 }}>
                            {"Total"}{" "}
                            <CountUp
                              end={Object.keys(coopRegReducer1 || []).length}
                            />
                          </div>
                        </p>
                      ),
                    }}
                  />
                </div>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    Registration_1_Actions: (param) => dispatch(Registration_1_Actions(param)),
    Registration_2_Actions: (param) => dispatch(Registration_2_Actions(param)),
    Registration_3_Actions: (param) => dispatch(Registration_3_Actions(param)),

    SettingsAction: (param) => dispatch(SettingsAction(param)),
    ActivationAction: (param) => dispatch(ActivationAction(param)),
    WalletAction: (param) => dispatch(WalletAction(param)),
    DepositAction: (param) => dispatch(DepositAction(param)),

    //
  };
};

const mapStateToProps = (state) => ({
  CoopRegisterReducer: state.CoopRegisterReducer,
  CoopOtherReducers: state.CoopOtherReducers,
  currentUser: state.authReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(Registrations);

import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";
import routesCoop from "views/coop/routes";
import routesGroup from "views/group/routes";
import PaymentPlanTable from "views/examples/functions/PaymentPlanTable";

class Admin extends React.Component {
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  getRoutesCoop = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  getBrandText = (path) => {
    // Check for the specific path "/admin/property-payment-plans"
    if (path === "/admin/property-payment-plans") {
      return "property payment plans";
    }

    // Continue with the existing logic for other routes
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }

    for (let i = 0; i < routesCoop.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routesCoop[i].layout + routesCoop[i].path
        ) !== -1
      ) {
        return routesCoop[i].name;
      }
    }

    for (let i = 0; i < routesGroup.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routesGroup[i].layout + routesGroup[i].path
        ) !== -1
      ) {
        return routesGroup[i].name;
      }
    }
    return "Brand";
  };
  render() {
    // console.log(
    //   "this.props.location.pathname =>",
    //   this.props.location.pathname
    // );
    return (
      <>
        <Sidebar
          {...this.props}
          routes={routes}
          routesCoop={routesCoop}
          logo={{
            innerLink: "/admin/index",
            imgSrc: require("assets/img/brand/argon-react.png"),
            imgAlt: "...",
          }}
        />
        <div className='main-content' ref='mainContent'>
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>
            {this.getRoutes(routes)}

            {this.getRoutesCoop(routesCoop)}
            {this.getRoutesCoop(routesGroup)}
            <Route
              path={`/admin/property-payment-plans`}
              component={PaymentPlanTable}
            />
            <Redirect from='*' to='/admin/index' />
          </Switch>
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}

export default Admin;

import axios from "axios";

import api from "../../utils/config";

import firebase from "firebase/app";
import "firebase/auth";
import app from "./../../variables/base";
import swal from "sweetalert";
import { isEmpty } from "lodash";

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: api.REACT_APP_FIREBASE_APP_ID,
    authDomain: api.REACT_APP_FIREBASE_DOMAIN,
    databaseURL: api.REACT_APP_FIREBASE_DATABASE,
    projectId: api.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: api.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: api.REACT_APP_FIREBASE_SENDER_ID,
    appId: api.REACT_APP_FIREBASE_APP_ID,
    measurementId: api.REACT_APP_FIREBASE_MEASUREMENT_ID,
  });
}

export const setFI_2Y_Data = (FIData) => {
  return {
    type: "FI_2Y_SUCCESS",
    payload: FIData,
  };
};

export const setFI_4Y_Data = (FIData) => {
  return {
    type: "FI_4Y_SUCCESS",
    payload: FIData,
  };
};

export const setFI_Transaction_Data = (FIData) => {
  return {
    type: "FI_TR_SUCCESS",
    payload: FIData,
  };
};

export const FI_2Y_Actions = (credentials) => {
  return async (dispatch, getState) => {
    dispatch({ type: "FI_2Y_LOADING", loading: true });

    try {
      await firebase
        .firestore()
        .collection("fractionalPropertiesTwoYears")
        .onSnapshot((snapshot) => {
          if (snapshot.empty) {
            return;
          }

          var FI_2Y_Data = [];
          var User = {};
          snapshot.forEach(async (doc) => {
            FI_2Y_Data.push({
              id: doc.id,
              ...doc.data(),
            });
          });

          // alert(JSON.stringify(propertyData.slice(0,2)))

          dispatch(setFI_2Y_Data(FI_2Y_Data));
        });
    } catch (error) {
      //alert('error')
      dispatch({ type: "FI_2Y_ERROR", payload: error });
    }
  };
};

export const FI_4Y_Actions = (credentials) => {
  return async (dispatch, getState) => {
    dispatch({ type: "FI_4Y_LOADING", loading: true });

    try {
      await firebase
        .firestore()
        .collection("fractionalPropertiesFourYears")
        .onSnapshot((snapshot) => {
          if (snapshot.empty) {
            return;
          }

          var FI_4Y_Data = [];
          var User = {};
          snapshot.forEach(async (doc) => {
            FI_4Y_Data.push({
              id: doc.id,
              ...doc.data(),
            });
          });

          // alert(JSON.stringify(propertyData.slice(0,2)))

          dispatch(setFI_4Y_Data(FI_4Y_Data));
        });
    } catch (error) {
      //alert('error')
      dispatch({ type: "FI_4Y_ERROR", payload: error });
    }
  };
};

export const FI_TR_Actions = (credentials) => {
  return async (dispatch, getState) => {
    dispatch({ type: "FI_TR_LOADING", loading: true });

    try {
      await firebase
        .firestore()
        .collection("fractionalTransactions")
        .onSnapshot((snapshot) => {
          if (snapshot.empty) {
            return;
          }

          var FI_Transaction_Data = [];

          snapshot.forEach(async (doc) => {
            FI_Transaction_Data.push({
              id: doc.id,
              ...doc.data(),
            });
          });

          dispatch(setFI_Transaction_Data(FI_Transaction_Data));
        });
    } catch (error) {
      //alert('error')
      dispatch({ type: "FI_TR_ERROR", payload: error });
    }
  };
};

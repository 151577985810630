import React from "react";
// reactstrap components

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  Alert,
  Modal,
  Form,
  FormGroup,
  Input,
  Badge,
} from "reactstrap";
import Label from "reactstrap/lib/Label";

// core components
import Header from "components/Headers/Header";
import moment from "moment";
import SelectSearch from "react-select-search";

import ReactDOM from "react-dom";
import MaterialTable, { MTableToolbar, MTableCell } from "material-table";
import {
  dateFormater,
  currencyFormat,
  UserRolePermission,
} from "utils/functions";



import firebase from "firebase/app";

import { connect } from "react-redux";
import CountUp from "react-countup";
import { isEmpty, isArray, isObject } from "lodash";

import { Group_Actions } from "store/actions/groupAction";
import { Group_Inv_Actions } from "store/actions/groupInviteAction";
import { Group_Dep_Actions } from "store/actions/groupDepAction";

//

//DepositData

class RegistrationsGroup extends React.Component {
  state = {
    inviteModal: false,
    paymentModal: false,
    groupProperty: "",
    invitedDataset: [],
    paymentDataset: [],
    groupFullDetail: false,
    groupFullDetailId: "",
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.Group_Actions();
    this.props.Group_Inv_Actions();
    this.props.Group_Dep_Actions();
  }

  getPaymentSum(id) {

    let sum = 0;
    (this.props.GroupDepositReducer?.groupDepData || []).map((data, i) => {
      if (data.stow_group_id === id) {
        sum = sum + parseInt(data.amount);
      }
    });

    return sum;
  }


  getMemberDeposit = (id) => {
    
     let sum = 0;
    const match = this.props.GroupDepositReducer?.groupDepData.filter(
      (data) => data.uid === id
    );

    match.map((data, i) => {

         sum = sum + parseInt(data.amount);

    })

    return sum;
    
  }

  getInvitedUsers(id) {
    
    const match = this.props.GroupInviteReducer?.groupInviteData.filter(
      (data) => data.stow_group_id === id
    );

    this.setState({ invitedDataset: [] });
    this.setState({ invitedDataset: match });
  }

  getUserPropertyDeposits(id) {
     //console.log(id);
   const match = this.props.GroupDepositReducer?.groupDepData.filter(
      (data) => data.stow_group_id === id
    );

    this.setState({ paymentDataset: [] });
    this.setState({ paymentDataset: match }); 
  }


    rowStyle = {
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    verticalAlign: "middle",

    fontSize: "0.6125rem",
    whiteSpace: "nowrap",
    padding: "1rem",
    borderTop: "1px solid #e9ecef",
    fontFamily: "Open Sans, sans-serif",
  };


  getMemberStowGroupDeposits = (id) => {

    console.log(id)
    const match = this.props.GroupDepositReducer?.groupDepData.filter(
      (data) =>
        data.stow_group_id === this.state.groupFullDetailId.id && data.uid ===id
    );

    return match;

    //console.log(match);
    
  }

  render() {
    const groupRegData = this.props.GroupRegisterReducer?.groupRegData;
    const groupInviteData = this.props.GroupInviteReducer?.groupInviteData;
    const groupDepData = this.props.GroupDepositReducer?.groupDepData;

    //
    // console.log(JSON.stringify(groupInviteData));

    return (
      <>
        <Header />

        <Container className="mt--7" fluid>
          <Modal
            id="paymentModal"
            style={{ maxWidth: 70 + "%" }}
            className="modal-dialog-centered"
            isOpen={this.state.paymentModal}
            // toggle={() => this.toggleModal("exampleModal")}
          >
            <div className="modal-header">
              <h3 className="modal-title" id="paymentModallabel">
                {this.state.groupProperty} Members Deposits
              </h3>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ paymentModal: false });
                }}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Property</th>
                    <th scope="col">Amount</th>

                    <th scope="col">Reference</th>
                    <th scope="col">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {isObject(this.state.paymentDataset) &&
                    (this.state.paymentDataset || []).map((data, i) => (
                      <tr>
                        <td>{data.name}</td>
                        <td>{data.p_name}</td>
                        <td>{currencyFormat(parseInt(data.amount))}</td>
                        <td>{data.reference}</td>
                        <td>
                          {isEmpty(data.date_time)
                            ? null
                            : dateFormater(data.date_time)}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </Modal>

          <Modal
            id="inviteModal"
            style={{ maxWidth: 70 + "%" }}
            className="modal-dialog-centered"
            isOpen={this.state.inviteModal}
            // toggle={() => this.toggleModal("exampleModal")}
          >
            <div className="modal-header">
              <h3 className="modal-title" id="exampleModalLabel">
                {this.state.groupProperty} Members/Invites
              </h3>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ inviteModal: false });
                }}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">email</th>
                    <th scope="col">User</th>

                    <th scope="col">Property</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {isObject(this.state.invitedDataset) &&
                    (this.state.invitedDataset || []).map((data, i) => (
                      <tr key={data.invited_uid}>
                        <td>{data.invited_fullname}</td>
                        <td>{data.invited_email}</td>
                        <td>{data.invited_uid}</td>
                        <td>{data.stow_group_id}</td>
                        <td>
                          {data.invited_status ? (
                            <Badge
                              color=""
                              style={{ fontSize: 10, color: "#000" }}
                              className="badge-dot"
                            >
                              {" "}
                              <i className="bg-success" />
                              Accepted
                            </Badge>
                          ) : (
                            <Badge
                              color=""
                              style={{ fontSize: 10, color: "#ccc" }}
                              className="badge-dot"
                            >
                              <i className="bg-danger" />
                              Pending
                            </Badge>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </Modal>

          <Modal
            id="model_property_new"
            className="modal-dialog-centered"
            isOpen={this.state.groupFullDetail}
            style={{ maxWidth: 80 + "%" }}
            // toggle={() => this.toggleModal("exampleModal")}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                FULL GROUP DETAILS
              </h5>

              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={(e) => {
                  e.preventDefault();

                  this.setState({ groupFullDetail: false });
                  // document.location.reload()
                }}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <Row>
                <Col>
                  <div
                    style={{
                      backgroundColor: "rgb(245, 247, 249)",
                      padding: "1.25rem",
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderColor: "rgb(230, 236, 241)",
                      borderImage: "initial",
                      borderRadius: "0.25rem",
                    }}
                  >
                    <Alert
                      color="secondary"
                      style={{ backgroundColor: "#e5eaec" }}
                    >
                      GROUP NAME - <b>{this.state.groupFullDetailId.name}</b>
                    </Alert>

                    <div
                      className="form-group"
                      style={{ marginBottom: "0.3rem", marginLeft: 20 }}
                    >
                      <Label
                        style={{
                          fontSize: 10,
                          marginBottom: -10,
                          marginLeft: 5,
                        }}
                      >
                        GroupId
                      </Label>
                      <div>{this.state.groupFullDetailId.id}</div>
                    </div>

                    <div
                      className="form-group"
                      style={{ marginBottom: "0.3rem", marginLeft: 20 }}
                    >
                      <Label
                        style={{
                          fontSize: 10,
                          marginBottom: -10,
                          marginLeft: 5,
                        }}
                      >
                        Group size
                      </Label>
                      <div>{this.state.groupFullDetailId.size}</div>
                    </div>

                    <div
                      className="form-group"
                      style={{ marginBottom: "0.3rem", marginLeft: 20 }}
                    >
                      <Label
                        style={{
                          fontSize: 10,
                          marginBottom: -10,
                          marginLeft: 5,
                        }}
                      >
                        {" "}
                        Property id
                      </Label>
                      <div>{this.state.groupFullDetailId.p_id}</div>
                    </div>

                    <div
                      className="form-group"
                      style={{ marginBottom: "0.3rem", marginLeft: 20 }}
                    >
                      <Label
                        style={{
                          fontSize: 10,
                          marginBottom: -10,
                          marginLeft: 5,
                        }}
                      >
                        Property name
                      </Label>
                      <div>{this.state.groupFullDetailId.p_name}</div>
                    </div>

                    <div
                      className="form-group"
                      style={{ marginBottom: "0.3rem", marginLeft: 20 }}
                    >
                      <Label
                        style={{
                          fontSize: 10,
                          marginBottom: -10,
                          marginLeft: 5,
                        }}
                      >
                        Property amount
                      </Label>
                      <div>
                        {currencyFormat(this.state.groupFullDetailId.p_amount)}
                      </div>
                    </div>

                    <div
                      className="form-group"
                      style={{ marginBottom: "0.3rem", marginLeft: 20 }}
                    >
                      <Label
                        style={{
                          fontSize: 10,
                          marginBottom: -10,
                          marginLeft: 5,
                        }}
                      >
                        Created by
                      </Label>
                      <div>
                        {this.state.groupFullDetailId.c_name} -{" "}
                        <span style={{ fontSize: 10, color: "blue" }}>
                          <a
                            href={
                              "mailto:" + this.state.groupFullDetailId.c_email
                            }
                          >
                            {this.state.groupFullDetailId.c_email}
                          </a>
                        </span>
                      </div>
                    </div>

                    <div
                      className="form-group"
                      style={{ marginBottom: "0.3rem", marginLeft: 20 }}
                    >
                      <Label
                        style={{
                          fontSize: 10,
                          marginBottom: -10,
                          marginLeft: 5,
                        }}
                      >
                        Date created
                      </Label>
                      <div>
                        {dateFormater(this.state.groupFullDetailId.date_time)}
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      marginTop: 20,
                      backgroundColor: "rgb(245, 247, 249)",
                      padding: "1.25rem",
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderColor: "rgb(230, 236, 241)",
                      borderImage: "initial",
                      borderRadius: "0.25rem",
                    }}
                  >
                    <Alert
                      color="secondary"
                      style={{ backgroundColor: "#e5eaec" }}
                    >
                      GROUP MEMEBERS
                    </Alert>

                    <Table className="align-items-center table-flush">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">email</th>
                          <th scope="col">User-ID</th>

                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isObject(this.state.invitedDataset) &&
                          (this.state.invitedDataset || []).map((data, i) => (
                            <tr key={data.invited_uid}>
                              <td>{data.invited_fullname}</td>
                              <td>{data.invited_email}</td>

                              <td>{data.stow_group_id}</td>
                              <td>
                                {data.invited_status ? (
                                  <Badge
                                    color=""
                                    style={{ fontSize: 10, color: "#000" }}
                                    className="badge-dot"
                                  >
                                    {" "}
                                    <i className="bg-success" />
                                    Accepted
                                  </Badge>
                                ) : (
                                  <Badge
                                    color=""
                                    style={{ fontSize: 10, color: "#ccc" }}
                                    className="badge-dot"
                                  >
                                    <i className="bg-danger" />
                                    Pending
                                  </Badge>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </Col>
                <Col>
                  <Alert
                    color="secondary"
                    style={{
                      backgroundColor: "#e5eaec",
                      marginTop: 25,
                      textTransform: "uppercase",
                    }}
                  >
                    {this.state.groupProperty} Members Deposits
                    <Button
                      color="info"
                      className="float-right"
                      href="#"
                      style={{
                        fontSize: 15,
                        marginBottom: 10,
                        marginTop: -5,
                      }}
                      size="sm"
                      onClick={() => {
                        //if(!(UserRolePermission(this.props.currentUser.UserData.role,'add_trans')))
                        //{
                        //}
                      }}
                    >
                      {currencyFormat(
                        parseInt(
                          this.getPaymentSum(this.state.groupFullDetailId.id)
                        )
                      )}
                    </Button>
                  </Alert>

                  <MaterialTable
                    title=""
                    columns={[
                      { title: "Name", field: "invited_fullname" },
                      { title: "Email", field: "invited_email" },
                      {
                        title: "Property",
                        field: "invited_email",
                        render: (rowData) =>
                          this.state.groupFullDetailId.p_name,
                      },
                      {
                        title: "Deposited",
                        field: "invited_email",
                        render: (rowData) =>
                          currencyFormat(parseInt(this.getMemberDeposit(rowData.invited_uid))),
                        // lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
                      },
                    ]}
                    data={this.state.invitedDataset || []}
                    options={{
                      // exportButton: true,
                      // exportFileName: "Property_Data_" + new Date(),
                      //grouping: true,
                      pageSize: 10,
                      pageSizeOptions: [
                        5, 10, 20, 30, 50, 75, 100, 200, 500, 1000, 2000, 3000,
                        5000, 8000, 10000,
                      ],
                      toolbar: true,
                      // paging: true,

                      rowStyle: {
                        paddingLeft: "1.2rem",
                        paddingRight: "1.2rem",
                        verticalAlign: "middle",

                        fontSize: "0.7125rem",
                        whiteSpace: "nowrap",
                        padding: "1rem",
                        borderTop: "1px solid #e9ecef",
                        fontFamily: "Open Sans, sans-serif",
                      },

                      headerStyle: {
                        paddingTop: "0.55rem",
                        paddingBottom: "0.55rem",
                        fontSize: "0.65rem",
                        textTransform: "uppercase",
                        letterPpacing: "1px",
                        borderBottom: "1px solid #e9ecef",
                        fontWeight: "bolder",
                        paddingLeft: "1.2rem",
                        paddingRight: "1.2rem",
                        backgroundColor: "#f6f9fc",
                        color: "#8898aa",
                        verticalAlign: "middle",
                        borderColor: "#e9ecef",
                        fontFamily: "Open Sans, sans-serif",
                      },
                    }}
                    detailPanel={(rowData) => {

                      let userData = this.getMemberStowGroupDeposits(rowData.invited_uid);
                      if (Object.keys(userData).length > 0)
                      {
                        return (
                          <div
                            style={{
                              margin: "30px",
                              marginTop: "50px",
                              marginBottom: "50px",
                            }}
                          >
                            <Table
                              className="align-items-center table-flush"
                              responsive
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col">Name</th>
                                  <th scope="col">Property</th>
                                  <th scope="col">Amount</th>

                                  <th scope="col">Reference</th>
                                  <th scope="col">Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                {isObject(userData) &&
                                  (userData || []).map((data, i) => (
                                    <tr>
                                      <td style={this.rowStyle}>{data.name}</td>
                                      <td style={this.rowStyle}>
                                        {data.p_name}
                                      </td>
                                      <td style={this.rowStyle}>
                                        {currencyFormat(parseInt(data.amount))}
                                      </td>
                                      <td style={this.rowStyle}>
                                        {data.reference}
                                      </td>
                                      <td style={this.rowStyle}>
                                        {isEmpty(data.date_time)
                                          ? null
                                          : dateFormater(data.date_time)}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </Table>
                          </div>
                        );
                        
                      }
                      else
                      {
                        return (
                          <div style={{ margin: "50px" }}>
                           <center style={{fontSize:'10px'}}>
                              USER HAVE NOT MADE ANY DEPOSIT
                              </center>
                          </div>
                        );
                        }
                      
                    }}
                  />

                  
                </Col>
              </Row>
            </div>
          </Modal>

          <Row>
            <div className="col">
              <Card className="shadow border-0">
                <div style={{ maxWidth: "100%", borderRadius: 20 }}>
                  <MaterialTable
                    columns={[
                      {
                        title: "Group Name",
                        field: "name",
                        /* defaultGroupOrder: 0 , */ render: (rowData) => {
                          return rowData.name;
                        },
                      },

                      {
                        title: "Fullname",
                        field: "c_name",
                        /* defaultGroupOrder: 0 , */ render: (rowData) => {
                          return rowData.c_name;
                        },
                      },

                      {
                        title: "Email",
                        field: "c_email",
                        /* defaultGroupOrder: 0 , */ render: (rowData) => {
                          return rowData.c_email;
                        },
                      },
                      {
                        title: "Size",
                        field: "size",
                        render: (rowData) => {
                          return (
                            <>
                              <Badge
                                color=""
                                style={{ fontSize: 10, color: "#000" }}
                                className="badge-dot"
                              >
                                <div
                                  style={{
                                    fontSize: 13,
                                    textAlign: "left",
                                  }}
                                >
                                  {rowData.size}
                                </div>
                              </Badge>
                              <Button
                                color="secondary"
                                href="#"
                                style={{ fontSize: 9, marginLeft: 15 }}
                                size="sm"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.getInvitedUsers(rowData.id);
                                  this.setState({
                                    inviteModal: true,
                                    groupProperty: rowData.p_name,
                                  });
                                }}
                              >
                                <i className="fa fa-ellipsis-v" />
                              </Button>
                            </>
                          );
                        },
                      },

                      {
                        title: "Property",
                        field: "p_name",
                        render: (rowData) => {
                          return rowData.p_name;
                        },
                      },

                      {
                        title: "Plan",
                        field: "paymentPlan",
                        render: (rowData) => {
                          return rowData.paymentPlan;
                        },
                      },
                      {
                        title: "Price",
                        field: "p_amount",
                        render: (rowData) => {
                          return currencyFormat(parseInt(rowData.p_amount));
                          // return rowData.p_amount;
                        },
                      },

                      {
                        title: "Deposited",
                        field: "p_amount",
                        render: (rowData) => {
                          return (
                            <>
                              <Badge
                                color=""
                                style={{ fontSize: 10, color: "#000" }}
                                className="badge-dot"
                              >
                                <div
                                  style={{
                                    fontSize: 13,
                                    textAlign: "left",
                                  }}
                                >
                                  {currencyFormat(
                                    parseInt(this.getPaymentSum(rowData.id))
                                  )}
                                </div>
                              </Badge>

                              <Button
                                color="secondary"
                                href="#"
                                style={{ fontSize: 9, marginLeft: 15 }}
                                size="sm"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.getUserPropertyDeposits(rowData.id);

                                  this.setState({
                                    paymentModal: true,
                                    groupProperty: rowData.p_name,
                                  });
                                }}
                              >
                                <i className="fa fa-ellipsis-v" />
                              </Button>
                            </>
                          );
                          // return rowData.p_amount;
                        },
                      },

                      {
                        title: "Date",
                        field: "date_time",
                        defaultSort: "desc",
                        render: (rowData) => {
                          return isEmpty(rowData.date_time)
                            ? null
                            : dateFormater(rowData.date_time);
                        },
                      },
                    ]}
                    options={{
                      exportButton: true,
                      exportFileName: "Property_Data_" + new Date(),
                      //grouping: true,
                      pageSize: 10,
                      pageSizeOptions: [
                        5, 10, 20, 30, 50, 75, 100, 200, 500, 1000, 2000, 3000,
                        5000, 8000, 10000,
                      ],
                      toolbar: true,
                      // paging: true,

                      rowStyle: {
                        paddingLeft: "1.5rem",
                        paddingRight: "1.5rem",
                        verticalAlign: "middle",

                        fontSize: "0.8125rem",
                        whiteSpace: "nowrap",
                        padding: "1rem",
                        borderTop: "1px solid #e9ecef",
                        fontFamily: "Open Sans, sans-serif",
                      },

                      headerStyle: {
                        paddingTop: "0.75rem",
                        paddingBottom: "0.75rem",
                        fontSize: "0.65rem",
                        textTransform: "uppercase",
                        letterPpacing: "1px",
                        borderBottom: "1px solid #e9ecef",
                        fontWeight: "bolder",
                        paddingLeft: "1.5rem",
                        paddingRight: "1.5rem",
                        backgroundColor: "#f6f9fc",
                        color: "#8898aa",
                        verticalAlign: "middle",
                        borderColor: "#e9ecef",
                        fontFamily: "Open Sans, sans-serif",
                      },
                    }}
                    actions={[
                      {
                        icon: (row) => (
                          <Button
                            color="info"
                            href="#"
                            style={{ fontSize: 9 }}
                            size="sm"
                            onClick={(e) => {}}
                          >
                            <i className="fa fa-eye" />
                          </Button>
                        ),

                        tooltip: "View Group",

                        onClick: (event, row) => {
                          this.getUserPropertyDeposits(row.id);
                          this.getInvitedUsers(row.id);
                          this.setState({
                            groupFullDetail: true,
                            groupFullDetailId: row,
                            groupProperty: row.p_name,
                          });

                          //  alert(JSON.stringify(row.uid));
                          // this.GetRegisterationData(event, row.uid);
                        },
                      },
                    ]}
                    data={groupRegData}
                    title={" "}
                    components={{
                      Toolbar: (props) => (
                        <p className="h3" style={{ padding: 10 }}>
                          <MTableToolbar {...props} />
                          <div style={{ marginTop: -40 }}>
                            {"Total"}{" "}
                            <CountUp
                              end={Object.keys(groupRegData || []).length}
                            />
                          </div>
                        </p>
                      ),
                    }}
                  />
                </div>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    Group_Actions: (param) => dispatch(Group_Actions(param)),
    Group_Inv_Actions: (param) => dispatch(Group_Inv_Actions(param)),
    Group_Dep_Actions: (param) => dispatch(Group_Dep_Actions(param)),

    //
  };
};

const mapStateToProps = (state) => ({
  GroupRegisterReducer: state.GroupRegisterReducer,
  GroupInviteReducer: state.GroupInviteReducer,
  GroupDepositReducer: state.GroupDepositReducer,
  currentUser: state.authReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationsGroup);

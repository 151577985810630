import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

import axios from "axios";

import sgMail from "@sendgrid/mail";
import { ContentLoader, BulletList } from "react-content-loader";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  Modal,
  Form,
  FormGroup,
  Input,
  Badge,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";

import { connect } from "react-redux";

import {
  transactionActions,
  TransactionCount,
} from "./../store/actions/transactionActions";

import { UserActions, UserCount } from "./../store/actions/userActions";

import {
  PaymentActions,
  PaymentCount,
} from "./../store/actions/paymentActions";

import { promoActions } from "./../store/actions/promoActions";

import {
  propertyActions,
  PropertyCount,
  propertyOptionsAction,
  propertyFeaturesAction,
  propertyDeveloperAction,
  propertyProgressAction,
} from "./../store/actions/propertyActions";

import { PropertySingle } from "./../store/actions/propertyActions";
import { FListActions } from "./../store/actions/featuredListActions";

import { currencyFormat, dateFormater } from "../utils/functions";

//import api from "../../utils/config";
import Axios from "axios";
import store from "store/store";
import { isEmpty, isArray, isObject } from "lodash";
import api from "../utils/config";

import Header from "components/Headers/Header.js";

import firebase from "firebase/app";

//import "firebase/auth";
//import swal from "sweetalert";
//require("firebase/firestore");

class Dashboard extends React.Component {
  state = {
    transactionCount: 0,
    transactionRecord: {},

    userCount: 0,
    userRecord: {},

    paymentCount: 0,
    paymentRecord: {},
    tester: {},

    showUserProfile: false,

    modelFirstname: "",
    modelLastname: "",
    modelPhone: "",
    modelEmail: "",
    modelCountry: "",
    modelCountryCode: "",
    modelUid: "",
    modelReferal: "",
    modalDate: "",
  };

  GetProperty(e) {
    let array_ = this.state.transactionRecord;
    //let id =  e.target.getAttribute('data-id')
    let id = e.target.getAttribute("data-id");

    for (var i = 0, len = array_.length; i < len; i++) {
      if (array_[i].id === id) {
        //console.log(array_[i].property)

        if (array_[i].property) {
          this.props.PropertySingle(array_[i].property);
          this.props.history.push("/admin/single/property");
        } else {
          alert("PROPRTY DOES NOT EXIST OR HAVE BEEN DELETED");
        }
      }
    }
  }

  formatJSON(data) {
    var result = [];
    if (!Array.isArray(data)) {
      //if is not an array
      result.push(data); // push it as the first item of an array
    } else {
      result = data;
    }

    return result;
  }

  componentDidMount() {
    this.props.transactionActions();
    this.props.PaymentActions();
    this.props.UserActions();
    this.props.propertyActions();
    this.props.propertyOptionsAction();
    this.props.propertyFeaturesAction();
    this.props.propertyDeveloperAction();
    this.props.promoActions();
    this.props.FListActions();
    this.props.propertyProgressAction();

    if (!firebase.apps.length) {
      firebase.initializeApp({
        apiKey: api.REACT_APP_FIREBASE_APP_ID,
        authDomain: api.REACT_APP_FIREBASE_DOMAIN,
        databaseURL: api.REACT_APP_FIREBASE_DATABASE,
        projectId: api.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: api.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: api.REACT_APP_FIREBASE_SENDER_ID,
        appId: api.REACT_APP_FIREBASE_APP_ID,
        measurementId: api.REACT_APP_FIREBASE_MEASUREMENT_ID,
      });
    }

    //alert(fireadmin.app.length)
  }

  constructor(props) {
    super(props);
    this.state = {
      activeNav: 1,
      chartExample1Data: "data1",
    };
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }
  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1",
    });
  };

  getUserFromUid = async (uid, index) => {
    // alert(uid + " - " + index);
    await firebase
      .firestore()
      .collection("users")
      .doc(uid)
      //.doc(`${uid}`)
      .get()
      .then(function (snapshot) {
        var PropertyUser = [];

        PropertyUser.push(snapshot.data());

        console.log(JSON.stringify(snapshot.data()));

        if (snapshot.data()) {
          if (document.getElementById("row_" + index)) {
            if (
              isEmpty(
                document
                  .getElementById("row_" + index)
                  .getAttribute("data-user")
              )
            ) {
              document.getElementById("row_" + index).innerHTML =
                "" +
                PropertyUser[0].lastName +
                " " +
                PropertyUser[0].firstName +
                "";
              document
                .getElementById("row_" + index)
                .setAttribute("data-user", JSON.stringify(PropertyUser));

              document
                .getElementById("row_" + index)
                .setAttribute("data-lastname", PropertyUser[0].lastName);
              document
                .getElementById("row_" + index)
                .setAttribute("data-firstname", PropertyUser[0].firstName);
              document
                .getElementById("row_" + index)
                .setAttribute("data-email", PropertyUser[0].email);
              document
                .getElementById("row_" + index)
                .setAttribute(
                  "data-phone",
                  PropertyUser[0].countryCode + PropertyUser[0].phoneNumber
                );
              document
                .getElementById("row_" + index)
                .setAttribute("data-country", PropertyUser[0].country);
              document
                .getElementById("row_" + index)
                .setAttribute("data-uid", uid);

              document
                .getElementById("row_" + index)
                .setAttribute("data-date", PropertyUser[0].dateCreated);

              document
                .getElementById("row_" + index)
                .setAttribute("data-referal", PropertyUser[0].referalCode);
            }
          }
        }

        //
        //
        //console.log(JSON.stringify(PropertyUser[0].lastName +' '+PropertyUser[0].firstName))
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const transactionReducer = this.props.transactionReducer;
    const UserReducer = this.props.UserReducer;
    const PaymentReducer = this.props.PaymentReducer;
    const PropertyReducer = this.props.PropertyReducer;

    //alert(JSON.stringify(UserReducer))

    const home = this;

    if (
      isEmpty(this.state.transactionRecord) ||
      this.props.transactionReducer.transactionCount <= 0
    ) {
      //console.log(JSON.stringify(transactionReducer))

      const size = 12;
      const items = transactionReducer.hasOwnProperty("transactionsData")
        ? transactionReducer.transactionsData.slice(0, size)
        : null; //.reverse()

      if (transactionReducer.hasOwnProperty("transactionsData")) {
        //this.props.transactionActions();

        //alert(home.props.transactionReducer.transactionCount )

        if (home.props.transactionReducer.transactionCount <= 0) {
          if (isEmpty(this.state.transactionRecord)) {
            home.setState({ transactionRecord: items });
          }
          //home.props.TransactionCount(Object.keys(transactionReducer.transactionsData).length);
        } else {
          if (isEmpty(this.state.transactionRecord)) {
            home.setState({ transactionRecord: items });
          }
        }
      }
    }

    if (
      isEmpty(this.state.userRecord) ||
      this.props.UserReducer.UseractionCount <= 0
    ) {
      //console.log(Object.keys(transactionRe6ucer.transactionsData).length)

      const size = 16;
      const items = UserReducer.hasOwnProperty("UseractionsData")
        ? UserReducer.UseractionsData.slice(0, size)
        : null; //.reverse()

      if (UserReducer.hasOwnProperty("UseractionsData")) {
        if (isEmpty(this.state.userRecord)) {
          home.setState({ userRecord: items });
        }

        //home.props.UserCount(Object.keys(UserReducer.UseractionsData).length);
        //alert(Object.keys(UserReducer.UseractionsData).length);
      }
    }

    if (
      isEmpty(this.state.paymentRecord) ||
      this.props.PaymentReducer.PaymentactionCount <= 0
    ) {
      //console.log(Object.keys(PaymentReducer.PaymentactionsData).length)

      const size = 9;
      const items = PaymentReducer.hasOwnProperty("PaymentactionsData")
        ? PaymentReducer.PaymentactionsData.slice(0, size)
        : null; //.reverse()

      if (PaymentReducer.hasOwnProperty("PaymentactionsData")) {
        if (isEmpty(this.state.paymentRecord)) {
          this.setState({ paymentRecord: items });
        }
        //this.props.PaymentCount(Object.keys(PaymentReducer.PaymentactionsData).length);
        //alert(Object.keys(UserReducer.UseractionsData).length);
      }
    }

    if (
      isEmpty(this.state.propertyRecord) ||
      this.props.PropertyReducer.PropertyCount <= 0
    ) {
      //alert('huhuhuh')

      //console.log(Object.keys(propertyRecord.propertyData).length)

      const size = 9;
      const items = PropertyReducer.hasOwnProperty("propertyData")
        ? PropertyReducer.propertyData.slice(0, size)
        : null; //.reverse()

      if (PropertyReducer.hasOwnProperty("propertyData")) {
        if (isEmpty(this.state.propertyRecord)) {
          this.setState({ propertyRecord: items });
        }
        //this.props.PropertyCount(Object.keys(PropertyReducer.propertyData).length);
        //alert(Object.keys(UserReducer.UseractionsData).length);
      }
    }

    // console.log(JSON.stringify(this.props.transactionReducer.transactionsData))
    return (
      <>
        {isEmpty(this.state.paymentRecord) &&
        isEmpty(this.state.transactionRecord) &&
        isEmpty(this.state.propertyRecord) &&
        isEmpty(this.state.userRecord) ? (
          <div className='header bg-gradient-info pb-8 pt-5 pt-md-8'>
            <Container fluid>
              <div className='header-body'>
                <div
                  style={{
                    alignItems: "center",
                    height: 150,
                    fontSize: 11,
                    justifyContent: "center",
                    width: 100 + "%",
                  }}>
                  Loading property data...{" "}
                  <i className='fa fa-spinner fa-spin'></i>
                </div>
              </div>
            </Container>
          </div>
        ) : (
          <Header />
        )}
        {/* Page content */}
        <Container className='mt--7' fluid>
          <Row>
            <Col className='mb-5 mb-xl-0' xl='8'>
              <Card className='bg-gradient-default shadow'>
                <CardHeader className='bg-transparent'>
                  <Row className='align-items-center'>
                    <div className='col'>
                      <h6 className='text-uppercase text-light ls-1 mb-1'>
                        Overview
                      </h6>
                      <h2 className='text-white mb-0'>Sales value</h2>
                    </div>
                    <div className='col'>
                      <Nav className='justify-content-end' pills>
                        <NavItem>
                          <NavLink
                            className={classnames("py-2 px-3", {
                              active: this.state.activeNav === 1,
                            })}
                            href='#stow'
                            onClick={(e) => this.toggleNavs(e, 1)}>
                            <span className='d-none d-md-block'>Month</span>
                            <span className='d-md-none'>M</span>
                          </NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink
                            className={classnames("py-2 px-3", {
                              active: this.state.activeNav === 1,
                            })}
                            href='#stow'
                            onClick={async (e) => {
                              const data = {
                                dealId: "AC12000098765",
                                utcTime: "2021-03-04T10:15:30Z",
                              };

                              await axios
                                .post(
                                  "https://192.168.1.70:8443/mmsservices/service/validate",
                                  data,
                                  {
                                    headers: {
                                      Authorization: `Basic bW1zYnJva2VyMTpUZXN0MUAjJA==`,
                                      //'Access-Control-Allow-Origin' : '*',
                                      //'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                                    },
                                  }
                                )
                                .then((res) => {
                                  console.log(JSON.stringify(res));
                                })
                                .catch((error) => {
                                  console.error(JSON.stringify(error));
                                });
                            }}>
                            <span className='d-none d-md-block'>MMS</span>
                            <span className='d-md-none'>M</span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  {/* Chart */}
                  <div className='chart'>
                    <Line
                      data={chartExample1[this.state.chartExample1Data]}
                      options={chartExample1.options}
                      getDatasetAtEvent={(e) => console.log(e)}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl='4'>
              <Card className='shadow'>
                <CardHeader className='bg-transparent'>
                  <Row className='align-items-center'>
                    <div className='col'>
                      <h6 className='text-uppercase text-muted ls-1 mb-1'>
                        Performance
                      </h6>
                      <h2 className='mb-0'>Total orders</h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  {/* Chart */}
                  <div className='chart'>
                    <Bar
                      data={chartExample2.data}
                      options={chartExample2.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className='mt-5'>
            <Col className='mb-5 mb-xl-0' xl='8'>
              <Card className='shadow'>
                <CardHeader className='border-0'>
                  <Row className='align-items-center'>
                    <div className='col'>
                      <h3 className='mb-0'>Transaction History</h3>
                    </div>
                    <div className='col text-right'>
                      <Button
                        color='primary'
                        href='#stow'
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.history.push("/admin/transaction");
                        }}
                        size='sm'>
                        See all
                      </Button>
                    </div>
                  </Row>
                </CardHeader>

                <Modal
                  className='modal-dialog-centered'
                  isOpen={this.state.showUserProfile}
                  // toggle={() => this.toggleModal("exampleModal")}
                >
                  <div className='modal-header'>
                    <h5 className='modal-title' id='exampleModalLabel'>
                      User information
                    </h5>
                    <button
                      aria-label='Close'
                      className='close'
                      data-dismiss='modal'
                      type='button'
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ showUserProfile: false });
                      }}>
                      <span aria-hidden={true}>×</span>
                    </button>
                  </div>
                  <div className='modal-body'>
                    <Form>
                      <div className='pl-lg-4'>
                        <Row>
                          <Col lg='6'>
                            <FormGroup>
                              <label
                                className='form-control-label'
                                htmlFor='input-first-name'>
                                First name
                              </label>
                              <Input
                                className='form-control-alternative'
                                //defaultValue="Lucky"
                                value={this.state.modelFirstname}
                                id='input_firstname'
                                placeholder='First name'
                                type='text'
                              />
                            </FormGroup>
                          </Col>
                          <Col lg='6'>
                            <FormGroup>
                              <label
                                className='form-control-label'
                                htmlFor='input-last-name'>
                                Last name
                              </label>
                              <Input
                                className='form-control-alternative'
                                // defaultValue="Jesse"
                                value={this.state.modelLastname}
                                id='input_lastname'
                                placeholder='Last name'
                                type='text'
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg='6'>
                            <FormGroup>
                              <label
                                className='form-control-label'
                                htmlFor='input-username'>
                                Phone
                              </label>
                              <Input
                                className='form-control-alternative'
                                //defaultValue="lucky.jesse"
                                id='input_phone'
                                value={this.state.modelPhone}
                                placeholder='Username'
                                type='text'
                              />
                            </FormGroup>
                          </Col>
                          <Col lg='6'>
                            <FormGroup>
                              <label
                                className='form-control-label'
                                htmlFor='input-email'>
                                Email address
                              </label>
                              <Input
                                className='form-control-alternative'
                                id='input_email'
                                value={this.state.modelEmail}
                                placeholder='jesse@example.com'
                                type='email'
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg='6'>
                            <FormGroup>
                              <label
                                className='form-control-label'
                                htmlFor='input-username'>
                                Referal Code
                              </label>
                              <Input
                                className='form-control-alternative'
                                //defaultValue="lucky.jesse"
                                id='input_referal'
                                value={this.state.modelReferal}
                                // placeholder="Username"
                                type='text'
                              />
                            </FormGroup>
                          </Col>
                          <Col lg='6'>
                            <FormGroup>
                              <label
                                className='form-control-label'
                                htmlFor='input-email'>
                                Date Created
                              </label>
                              <Input
                                className='form-control-alternative'
                                id='input_date'
                                value={this.state.modalDate}
                                // placeholder="jesse@example.com"
                                type='text'
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <FormGroup>
                              <label
                                className='form-control-label'
                                htmlFor='input-username'>
                                User-ID
                              </label>
                              <Input
                                className='form-control-alternative'
                                //defaultValue="lucky.jesse"
                                id='input_uid'
                                value={this.state.modelUid}
                                placeholder='Username'
                                type='text'
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </div>
                  <div className='modal-footer'>
                    <Button
                      color='secondary'
                      data-dismiss='modal'
                      type='button'
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ showUserProfile: false });
                      }}>
                      Close
                    </Button>
                  </div>
                </Modal>

                <Table className='align-items-center table-flush' responsive>
                  <thead className='thead-light'>
                    <tr>
                      <th scope='col'>Property</th>
                      <th scope='col'>Status</th>
                      <th scope='col'>User</th>

                      <th scope='col'>Paid</th>
                      <th scope='col'>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isEmpty(this.state.transactionRecord) ? (
                      <tr>
                        <td colSpan='5'>
                          <BulletList />
                        </td>
                      </tr>
                    ) : null}

                    {isObject(this.state.transactionRecord) &&
                      this.state.transactionRecord.map((data, i) =>
                        !isEmpty(data.pnm) ? (
                          <tr>
                            <th>
                              <Button
                                style={{ marginRight: 10 }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.GetProperty(e);
                                }}
                                //data-all={(data[i])}
                                data-id={data.id}
                                data-pid={data.pid}
                                data-uid={data.uid}
                                data-property={JSON.stringify(data.property)}
                                data-pplan={JSON.stringify(data.pplan)}
                                color='primary'
                                href='#'
                                size='sm'>
                                <i
                                  className='fa fa-eye'
                                  onClick={(e) => {
                                    e.preventDefault();
                                    //this.GetProperty(e)
                                  }}
                                  data-property={JSON.stringify(data[i])}
                                  data-pid={data.pid}
                                  data-id={data.id}
                                  data-uid={data.uid}
                                  data-property={JSON.stringify(data.property)}
                                  data-pplan={JSON.stringify(data.pplan)}
                                />
                              </Button>

                              {data.pnm}

                              <div
                                style={{
                                  marginLeft: 40,
                                  color: "#ccc",
                                  fontSize: 12,
                                }}>
                                <i className='ni ni-shop text-success mr-1' />

                                {data.hasOwnProperty("pplan")
                                  ? data.pplan.hasOwnProperty("title")
                                    ? data.pplan.title
                                    : null
                                  : null}
                              </div>
                            </th>
                            <td
                              style={{
                                paddingBottom:
                                  Object.keys(this.state.transactionRecord)
                                    .length ===
                                  i + 1
                                    ? 65
                                    : 0,
                              }}>
                              {data.status == "pending" ? (
                                <Badge color='' className='badge-dot'>
                                  <i className='bg-danger' />
                                  On going
                                </Badge>
                              ) : (
                                <Badge color='' className='badge-dot'>
                                  <i className='bg-success' />
                                  completed
                                </Badge>
                              )}
                            </td>

                            <td>
                              <a
                                data-user=''
                                data-email=''
                                data-firstname=''
                                data-lastname=''
                                data-phone=''
                                data-country=''
                                data-uid=''
                                id={"row_" + i}
                                href='#stow'
                                onClick={(e) => {
                                  e.preventDefault();

                                  this.getUserFromUid(data.uid, i);

                                  //alert(document.getElementById('row_'+i).getAttribute('data-firstname'))

                                  this.setState({ showUserProfile: true });

                                  this.setState({
                                    modelFirstname: document
                                      .getElementById("row_" + i)
                                      .getAttribute("data-firstname"),
                                    modelLastname: document
                                      .getElementById("row_" + i)
                                      .getAttribute("data-lastname"),
                                    modelEmail: document
                                      .getElementById("row_" + i)
                                      .getAttribute("data-email"),
                                    modelCountry: document
                                      .getElementById("row_" + i)
                                      .getAttribute("data-country"),
                                    modelPhone: document
                                      .getElementById("row_" + i)
                                      .getAttribute("data-phone"),
                                    modelUid: document
                                      .getElementById("row_" + i)
                                      .getAttribute("data-uid"),

                                    modelReferal: document
                                      .getElementById("row_" + i)
                                      .getAttribute("data-referal"),
                                    modalDate: document
                                      .getElementById("row_" + i)
                                      .getAttribute("data-date"),
                                  });
                                }}>
                                {data.unm
                                  ? data.unm
                                  : this.getUserFromUid(data.uid, i)}{" "}
                              </a>
                            </td>
                            <td>{currencyFormat(parseInt(data.price))}</td>
                            <td>{dateFormater(data.createdDate)}</td>
                          </tr>
                        ) : null
                      )}
                  </tbody>
                </Table>
              </Card>
            </Col>
            <Col xl='4'>
              <Card className='shadow'>
                <CardHeader className='border-0'>
                  <Row className='align-items-center'>
                    <div className='col'>
                      <h3 className='mb-0'>Customers</h3>
                    </div>
                    <div className='col text-right'>
                      <Button
                        color='primary'
                        href='#stow'
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.history.push("/admin/subscribers");
                        }}
                        size='sm'>
                        See all
                      </Button>
                    </div>
                  </Row>
                </CardHeader>

                <Table className='align-items-center table-flush' responsive>
                  <thead className='thead-light'>
                    <tr>
                      <th scope='col'>Name</th>
                      <th scope='col'>Email</th>
                      <th scope='col'>Phone</th>
                      <th scope='col' />
                    </tr>
                  </thead>

                  <tbody>
                    {isEmpty(this.state.userRecord) ? (
                      <tr>
                        <td colSpan='5'>
                          <BulletList />
                        </td>
                      </tr>
                    ) : null}

                    {isObject(this.state.userRecord) &&
                      this.state.userRecord.map((data, i) => (
                        <tr>
                          <th>
                            <Button
                              style={{ marginRight: 10 }}
                              id={"user_id_" + i}
                              color='Secondary'
                              href='#'
                              data-profile={JSON.stringify(data)}
                              onClick={(e) => {
                                e.preventDefault();
                                let data = document
                                  .getElementById("user_id_" + i)
                                  .getAttribute("data-profile");
                                console.log(JSON.parse(data));

                                let dataMain = JSON.parse(data);

                                this.setState({ showUserProfile: true });

                                this.setState({
                                  modelFirstname: dataMain.firstName,
                                  modelLastname: dataMain.lastName,
                                  modelEmail: dataMain.email,
                                  modelCountry: dataMain.countryCode,
                                  modelPhone:
                                    dataMain.countryCode + dataMain.phoneNumber,
                                  modelUid: dataMain.id,
                                  modalDate: dataMain.dateCreated,
                                  modelReferal: dataMain.referalCode,
                                });
                              }}
                              size='sm'>
                              <i className='fa fa-eye' />
                            </Button>

                            {data.lastName + " " + data.firstName}
                          </th>

                          <td>{data.email}</td>
                          <td>{data.phoneNumber}</td>
                          <td>#</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    transactionActions: (param) => dispatch(transactionActions(param)),
    TransactionCount: (param) => dispatch(TransactionCount(param)),

    UserActions: (param) => dispatch(UserActions(param)),
    UserCount: (param) => dispatch(UserCount(param)),

    PaymentActions: (param) => dispatch(PaymentActions(param)),
    PaymentCount: (param) => dispatch(PaymentCount(param)),
    promoActions: (param) => dispatch(promoActions(param)),

    propertyActions: (param) => dispatch(propertyActions(param)),
    PropertyCount: (param) => dispatch(PropertyCount(param)),

    PropertySingle: (param) => dispatch(PropertySingle(param)),
    propertyOptionsAction: (param) => dispatch(propertyOptionsAction(param)),
    FListActions: (param) => dispatch(FListActions(param)),
    propertyFeaturesAction: (param) => dispatch(propertyFeaturesAction(param)),
    propertyDeveloperAction: (param) =>
      dispatch(propertyDeveloperAction(param)),
    propertyProgressAction: (param) => dispatch(propertyProgressAction(param)),

    //propertyFeaturesAction
  };
};

const mapStateToProps = (state) => ({
  currentUser: state.authReducer,
  transactionReducer: state.transactionReducer,
  UserReducer: state.UserReducer,
  PaymentReducer: state.PaymentReducer,
  PropertyReducer: state.PropertyReducer,
  PromoReducer: state.PromoReducer,
  FeaturedListReducer: state.FeaturedListReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

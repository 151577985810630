import { isObject } from "lodash";
import isEmpty from "lodash/isEmpty";

const iniState = {
 
  //transactionsData: {},
  transLoading: true,
  transError: true,
  transErrorMsg:'',
  transLoadingCompleted:false,
  transactionCount:0
};

const transactionReducer = (state = iniState, action) => {
  switch (action.type) {



      case "TRANS_COUNT":
      return {
        ...state,
        transactionCount: action.payload,
      }
    case "TRANSACTION_LOADING":

    return {
        ...state,
        transLoading: action.transLoading,
        transError: false,
        transLoadingCompleted:false,
        //transErrorMsg:''
      };

    case "TRANSACTION_SUCCESS":
     
    //alert(JSON.stringify(action.payload))
      return {
        ...state,
        transactionsData: action.payload,
        //transactionCount: (isObject(action.payload)?(Object.keys(action.payload).length):0),
        transError: false,
        transLoadingCompleted:true,
        transLoading: false,
      };
    case "TRANSACTION_ERROR":
     
    //alert('error')
      return {
        ...state,
        transError: true,
        transLoadingCompleted: false,
         transErrorMsg:action.payload,
         transLoaded:false,
      };

    
    default:
      return state;
  }
};

export default transactionReducer;

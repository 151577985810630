import  firebase from "firebase/app";
import "firebase/auth";
import api from '../utils/config';



const app = firebase.initializeApp({
  apiKey: api.REACT_APP_FIREBASE_KEY,
  authDomain: api.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: api.REACT_APP_FIREBASE_DATABASE,
  projectId: api.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: api.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: api.REACT_APP_FIREBASE_SENDER_ID,
  appId: api.REACT_APP_FIREBASE_APP_ID,
  measurementId: api.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

//alert('--')


export default app;
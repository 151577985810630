
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";

import app from './../../variables/base'


import { connect } from "react-redux";
import { signIn,Reset_Login } from "store/actions/authActions";
import { Link } from "react-router-dom";
import api from "../../utils/config";
import Axios from "axios";
import store from "store/store";
import { isEmpty } from "lodash";

import  firebase from "firebase/app";
import "firebase/auth";
require("firebase/firestore");

class Login extends React.Component {

  state = {
    VerifyResent: false,
    VerifyResentErr: null,
    VerifyResentMsg: false,
    VerifyResentErrMsg: null,
    VerifyLoading: false,
  };
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });

    //alert(e.target.value +' - ' + e.target.id)
  };
  handleSubmit = (e) => {

    e.preventDefault();
    this.setState({ VerifyResentErr: false, VerifyResent: false });

    this.props.login(this.state,this.props);
  };



  componentWillMount()
  {
    //alert(JSON.stringify(this.props.currentUser.loading)+ ' - '+JSON.stringify(this.props.currentUser))


              if(typeof this.props.currentUser === "object" && this.props.currentUser.currentUserEmail !=null)
                {
                   if(!isEmpty(this.props.currentUser.currentUser))
                   {
                     //alert(JSON.stringify(this.props.currentUser))
                     this.props.history.push("/admin/index");

                   }


                }
                else{


                    if(this.props.currentUser.loading)
                    {
                      this.props.Reset_Login()
                    }

                }
  }


  render() {

    const { authError, authErrorCode, currentUser } = this.props;
    const {
      VerifyResent,
      VerifyResentErr,
      VerifyResentMsg,
      VerifyResentErrMsg,
      VerifyLoading,
    } = this.state;

    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-muted text-center mt-2 mb-3" style={{display:'block'}}>
                <small>Login with your Email and Password.</small>
              </div>
              <div className="btn-wrapper text-center" style={{display:'none'}}>
                <Button
                  className="btn-neutral btn-icon"
                  color="default"
                  href="#stow"
                  onClick={e => e.preventDefault()}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("assets/img/icons/common/github.svg")}
                    />
                  </span>
                  <span className="btn-inner--text">Github</span>
                </Button>
                <Button
                  className="btn-neutral btn-icon"
                  color="default"
                  href="#stow"
                  onClick={e => e.preventDefault()}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("assets/img/icons/common/google.svg")}
                    />
                  </span>
                  <span className="btn-inner--text">Google</span>
                </Button>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4" style={{display:'none'}}>
                <small>Login with your email and password</small>
              </div>
              <Form role="form" onSubmit={this.handleSubmit}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    {/* <Input placeholder="Email" type="email" autoComplete="new-email"/> */}

                     <Input
                      placeholder="Email"
                      type="email"
                      id="email"
                      autoComplete="new-email"
                      onChange={this.handleChange}
                    />

                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    {/* <Input placeholder="Password" type="password" autoComplete="new-password"/> */}

                    <Input
                      placeholder="Password"
                      type="password"
                      id="password"
                      autoComplete="new-password"
                      onChange={this.handleChange}
                    />

                  </InputGroup>
                </FormGroup>
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor=" customCheckLogin"
                  >
                    <span className="text-muted">Remember me</span>
                  </label>
                </div>
                <div className="text-center">
                 <Button
                    className="my-4"
                    disabled={currentUser.loading}
                    color="primary"
                    type="submit"
                  >

                     {currentUser.loading ? (
                      // <img src={require("assets/img/loader.gif")} />
                      <>
                        Loading... <i className="fa fa-spinner fa-spin"></i>
                      </>
                    ) : (
                      "Sign in"
                    )}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <a
                className="text-light"
                href="#stow"
                onClick={e => e.preventDefault()}
              >
                <small>Forgot password?</small>
              </a>
            </Col>
            <Col className="text-right" xs="6" style={{display:'none'}}>
              <a
                className="text-light"
                href="#stow"
                onClick={e => e.preventDefault()}
              >
                <small>Create new account</small>
              </a>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

//export default Login;

const mapDispatchToProps = (dispatch) => {
  return {
    login: (credentials,props) => dispatch(signIn(credentials,props)),
    Reset_Login: () => dispatch(Reset_Login())


  };
};

const mapStateToProps = (state) => ({
  currentUser: state.authReducer,
  authError: state.authReducer.authError,
  authErrorCode: state.authReducer.authErrorCode,
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);


import { isObject } from "lodash";
import isEmpty from "lodash/isEmpty";

const iniState = {
 
  //PaymentactionsData: {},
  PaymentLoading: true,
  PaymentError: true,
  PaymentErrorMsg:'',
  PaymentLoadingCompleted:false,
  PaymentactionCount:0
};

const PaymentReducer = (state = iniState, action) => {
  switch (action.type) {

    case "PAYMENT_COUNT":
     // alert(action.payload)
      return {
        ...state,
        PaymentactionCount: action.payload,
      }
    case "PAYMENT_LOADING":

    return {
        ...state,
        PaymentLoading: action.PaymentLoading,
        PaymentError: false,
        PaymentLoadingCompleted:false,
        //PaymentErrorMsg:''
      };

    case "PAYMENT_SUCCESS":
     
    //alert(JSON.stringify(action.payload))
      return {
        ...state,
        PaymentactionsData: action.payload,
        //PaymentactionCount: (isObject(action.payload)?(Object.keys(action.payload).length):0),
        PaymentError: false,
        PaymentLoadingCompleted:true,
        PaymentLoading: false,
      };
    case "PAYMENT_ERROR":
     
    //alert('error')
      return {
        ...state,
        PaymentError: true,
        PaymentLoadingCompleted: false,
         PaymentErrorMsg:action.payload,
         PaymentLoaded:false,
      };

    
    default:
      return state;
  }
};

export default PaymentReducer;

import { isObject } from "lodash";
import isEmpty from "lodash/isEmpty";

const iniState = {
  //propertysData: {},
  coopRegLoading: true,
  coopRegError: true,
  coopRegErrorMsg: "",
  coopRegLoadingCompleted: false,
  coopRegCount: 0,
};

const CoopRegisterReducer = (state = iniState, action) => {
  switch (action.type) {
    case "REGISTER_LOADING":
      return {
        ...state,
        coopRegLoading: action.propLoading,
        coopRegError: false,
        coopRegLoadingCompleted: false,
        //propErrorMsg:''
      };

    case "REGISTER_1_SUCCESS":
      return {
        ...state,
        coopRegData1: action.payload,
        coopRegError: false,
        coopRegLoadingCompleted: true,
        coopRegLoading: false,
      };

    case "REGISTER_2_SUCCESS":
      return {
        ...state,
        coopRegData2: action.payload,
        coopRegError: false,
        coopRegLoadingCompleted: true,
        coopRegLoading: false,
      };

    case "REGISTER_3_SUCCESS":
      return {
        ...state,
        coopRegData3: action.payload,
        coopRegError: false,
        coopRegLoadingCompleted: true,
        coopRegLoading: false,
      };
    case "REGISTER_ERROR":
      return {
        ...state,
        coopRegError: true,
        coopRegLoadingCompleted: false,
        coopRegErrorMsg: action.payload,
        coopRegLoaded: false,
      };

    default:
      return state;
  }
};

export default CoopRegisterReducer;

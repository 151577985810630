

const iniState = {
  //propertysData: {},
  groupInviteLoading: true,
  groupInviteError: true,
  groupInviteErrorMsg: "",
  groupInviteLoadingCompleted: false,
  groupInviteCount: 0,
};

const GroupInviteReducer = (state = iniState, action) => {
  switch (action.type) {
    case "GROUP_INT_LOADING":
      return {
        ...state,
        groupInviteLoading: action.propLoading,
        groupInviteError: false,
        groupInviteLoadingCompleted: false,
        //propErrorMsg:''
      };

    case "GROUP_INT_SUCCESS":
      return {
        ...state,
        groupInviteData: action.payload,
        groupInviteError: false,
        groupInviteLoadingCompleted: true,
        groupInviteLoading: false,
      };

    case "GROUP_INT_ERROR":
      return {
        ...state,
        groupInviteError: true,
        groupInviteLoadingCompleted: false,
        groupInviteErrorMsg: action.payload,
        groupInviteLoaded: false,
      };

    default:
      return state;
  }
};

export default GroupInviteReducer;

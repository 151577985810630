import Registrations from "views/coop/registration";
import Activation from "views/coop/activation";
import Deposit from "views/coop/deposit";

var routesCoop = [
  {
    path: "/coop/registration",
    name: "Coop Registration",
    icon: "ni ni-circle-08 text-warning",
    component: Registrations,
    layout: "/admin",
  },

  {
    path: "/coop/activation",
    name: "Activated Accounts",
    icon: "ni ni-palette text-warning",
    component: Activation,
    layout: "/admin",
  },

  {
    path: "/coop/deposits",
    name: "Deposits",
    icon: "ni ni-delivery-fast text-warning",
    component: Deposit,
    layout: "/admin",
  },

  /*  {
    path: "/coop/settings",
    name: "Settings",
    icon: "ni ni-user-run text-warning",
    component: null,
    layout: "/admin",
  }, */
];
export default routesCoop;

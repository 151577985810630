import axios from "axios";

import api from "../../utils/config";

import firebase from "firebase/app";
import "firebase/auth";
import app from "./../../variables/base";
import swal from "sweetalert";
import { isEmpty } from "lodash";

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: api.REACT_APP_FIREBASE_APP_ID,
    authDomain: api.REACT_APP_FIREBASE_DOMAIN,
    databaseURL: api.REACT_APP_FIREBASE_DATABASE,
    projectId: api.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: api.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: api.REACT_APP_FIREBASE_SENDER_ID,
    appId: api.REACT_APP_FIREBASE_APP_ID,
    measurementId: api.REACT_APP_FIREBASE_MEASUREMENT_ID,
  });
}

export const TransactionCount = (count) => {
  return (dispatch, getState) => {
    dispatch({ type: "TRANS_COUNT", payload: count });
  };
};

export const setTransactionData = (transactionsData) => {
  return {
    type: "TRANSACTION_SUCCESS",
    payload: transactionsData,
  };
};

export const transactionDeleteActions = (id) => {
  return async (dispatch, getState) => {
    console.log(id);
    //document.getElementById("trans_row_" + id).style.display = "none";
    firebase
      .firestore()
      .collection("transactions")
      .doc(id)
      .delete()
      .then((data) => {
        //console.log(data);
        swal(
          "Transaction Deleted",
          "You have succesfully deleted the transaction",
          {
            icon: "success",
          }
        );

        document.location.reload();

        //document.getElementById("trans_row_" + id).style.display = "none";
      })
      .catch(function (error) {
        swal("Unexpected Error", "Unable to delete the record \n" + error, {
          icon: "error",
        });

        console.error("Error deleting transaction: ", error);
      });
  };
};

export const transactionActions = (credentials) => {
  return async (dispatch, getState) => {
    dispatch({ type: "TRANSACTION_LOADING", loading: true });

    try {
      await firebase
        .firestore()
        .collection("transactions")
        //.orderBy("order","asc")
        .onSnapshot((snapshot) => {
          if (snapshot.empty) {
            return;
          }
          //alert('ok')

          var transactionsData = [];
          var User = {};
          snapshot.forEach(async (doc) => {
            //console.log(doc.data().uid)

            // if(doc.data().hasOwnProperty('uid'))
            //{
            /*  User= await firebase 
                                    .firestore()
                                    .collection("users")
                                    .doc(`${doc.data().uid}`)
                                    .get(); */

            //}
            //else{
            //User= {};

            //}
            /* */
            //console.log(User.data())

            transactionsData.push({
              id: doc.id,
              //User: (isEmpty(User)?User:User.data()),
              ...doc.data(),
            });
          });

          // alert(JSON.stringify(transactionsData.slice(0,2)))

          dispatch(setTransactionData(transactionsData));
        });
    } catch (error) {
      alert(error);
      dispatch({ type: "LOGIN_ERROR", payload: error });
    }
  };
};

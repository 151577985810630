import isEmpty from "lodash/isEmpty";

const iniState = {
  isAuthenticated: false,
  currentUser: {},
  currentUserID:null,
  currentUserEmail:null,
  currentUserEmail:null,
  loading: true,
};

const authReducer = (state = iniState, action) => {
  switch (action.type) {
    case "AUTH_LOADING":
      //alert('AUTH_LOADING')
      return {
        ...state,
        loading: action.loading,
        authError: null,
        authErrorCode: null,
      };

      case "USER_DATA":
        // alert(action.payload)
         return {
           ...state,
           UserData: action.payload,
         }

      case "LOGIN_RESET":
        return {
          loading:false,
           //authError: null,

        };

    case "LOGIN_SUCCESS":
     // alert('LOGIN_SUCCESS')
     //alert((action.payload.user))
       //console.log("login success");
      // alert(action.payload.UserData)
      return {
        ...state,
        currentUser: action.payload,
        userData: action.payload.UserData,
        currentUserID: action.payload.uid,
        currentUserEmail: action.payload.email,
        isAuthenticated: !isEmpty(action.payload),
        authError: null,
        loading: false,
      };
    case "LOGIN_ERROR":
      // alert('LOGIN_ERROR')
      // console.log(action.err);
      return {
        ...state,
        authError: action.err,
        authErrorCode: action.code,
        loading: false,
      };

    case "LOGOUT_SUCCESS":
       //alert('LOGOUT_SUCCESS')
      // console.log("LOGOUT_SUCCESS");
      return {
        ...state,
        currentUser: {},
        isAuthenticated: false,
        loading: false,
      };

    case "SIGNUP_SUCCESS":
     // console.log("SIGNUP_SUCCESS");
      return {
        ...state,
        authError: null,
        loading: false,
        signupSuccess: true,
      };

    case "SIGNUP_ERROR":
      //console.log("SIGNUP_ERROR");
      return {
        ...state,
        authError: action.err,
        loading: false,
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        authError: null,
        authErrorCode: null,
      };

    default:
      return state;
  }
};

export default authReducer;

import React from "react";
//import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
// import { PropTypes } from "prop-types";
// import CountUp from "react-countup";
// import axios from "axios";
import { isObject } from "lodash";

// reactstrap components
import {
  Button,
  Badge,
  Card,
  CardImg,
  CardHeader,
  Modal,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledAlert,
} from "reactstrap";

import swal from "sweetalert";
import firebase from "firebase/app";
import "firebase/storage";
import { connect } from "react-redux";
import Compress from "react-image-file-resizer";
import { dateFormater, UserRolePermission } from "utils/functions";
// import MaterialTable, { MTableToolbar, MTableCell } from "material-table";
// import { array } from "js-sha512";
//import { promoActions } from "../../../store/actions/promoActions";
// import { Editor } from "@tinymce/tinymce-react";

import {
  //propertyDeveloperAction,
  propertyProgressAction,
} from "../../../store/actions/propertyActions";

class PropertyProgressData extends React.Component {
  state = {
    progTitle: null,
    progImage: null,
    progSummary: null,

    progTitle2: null,
    progSummary2: null,

    EprogId: null,
    EprogTitle: null,
    EprogImage: [],
    EprogSummary: null,
    progImageEdit: null,

    propertyProg: this.props.PropertyReducer.hasOwnProperty("propertyProgress")
      ? this.props.PropertyReducer.propertyProgress
      : null,
    startProgUpload: false,
    startProgUpdate: false,
    startProgSingle: false,
    showEditProgressModal: false,
    proType: "image",
  };

  uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  validateYouTubeUrl = (urlToParse) => {
    if (urlToParse) {
      var regExp =
        /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      if (urlToParse.match(regExp)) {
        return true;
      }
    }
    return false;
  };

  extractFileExtension = (filename) => {
    //alert(filename)
    var ext = /(?:\.([^.]+))?$/.exec(filename);
    if (ext != null && ext[0] != null) {
      return ext[0];
    } else {
      return "";
    }
  };

  removeByIndex = (array, index) => {
    return array.filter(function (elem, _index) {
      return index != _index;
    });
  };

  componentDidMount() {
    // this.props.propertyDeveloperAction();
    //alert(this.props.PropertyReducer.propertySingle.id);
  }

  render() {
    return (
      <div>
        <Form>
          <div
            className="edit_form"
            style={{
              width: 100 + "%",
            }}
          >
            <Row>
              <Col>
                <UncontrolledAlert
                  id="validate_form_msg"
                  className=" alert-warning"
                  style={{ display: "none" }}
                  fade={false}
                >
                  <span className="alert-inner--icon">
                    <i className="ni ni-like-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    <strong>Validation!</strong> All fields are mandatory
                  </span>
                </UncontrolledAlert>
              </Col>
            </Row>

            <Card style={{ padding: 20, border: "1px solid #ccc" }}>
              <Row style={{ backgroundColor: "black" }}>
                <Col lg="12">
                  <FormGroup id="proTitleD">
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                      style={{
                        fontWeight: "normal",
                        fontSize: 11,
                        color: "white",
                      }}
                    >
                      Progress Title
                    </label>
                    <Input
                      className="form-control-alternative"
                      // defaultValue={this.state.editPromoTitle}
                      required
                      id="progTitle"
                      placeholder="Progress Title"
                      onChange={(data) => {
                        this.setState({
                          progTitle: data.target.value,
                        });
                      }}
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row style={{ backgroundColor: "black" }}>
                <Col lg="12">
                  <FormGroup id="proTitleD">
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                      style={{
                        fontWeight: "normal",
                        fontSize: 11,
                        color: "white",
                      }}
                    >
                      Progress Summary
                    </label>
                    <Input
                      className="form-control-alternative"
                      // defaultValue={this.state.editPromoTitle}
                      required
                      rows="4"
                      id="progSummary"
                      placeholder="Progress Summary"
                      onChange={(data) => {
                        this.setState({
                          progSummary: data.target.value,
                        });
                      }}
                      type="textarea"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row style={{ backgroundColor: "black" }}>
                <Col lg="12">
                  <FormGroup id="proTitleD">
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                      style={{
                        fontWeight: "normal",
                        fontSize: 11,
                        color: "white",
                      }}
                    >
                      Progress Type
                    </label>
                    <select
                      style={{ border: "1px solid #9393f3" }}
                      className="form-control-alternative form-control"
                      id="proType"
                      onChange={(data) => {
                        this.setState({ proType: data.target.value });
                      }}
                    >
                      <option> -- select type --</option>

                      <option value="image">Image</option>
                      <option value="video">Video</option>
                    </select>
                  </FormGroup>
                </Col>
              </Row>

              <Row style={{ backgroundColor: "black" }}>
                <Col lg="8">
                  <FormGroup id="devLogoD">
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                      style={{
                        fontWeight: "normal",
                        fontSize: 11,
                        color: "white",
                      }}
                    >
                      Progress {this.state.proType}
                    </label>

                    {this.state.proType == "image" ? (
                      <Input
                        className="form-control alternative"
                        style={{ fontSize: 10 }}
                        id="progImage"
                        accept="image/*"
                        onChange={(data) => {
                          this.setState({ progImage: data.target.files[0] });
                        }}
                        type="file"
                      />
                    ) : (
                      <Input
                        className="form-control-alternative"
                        // defaultValue={this.state.editPromoTitle}
                        required
                        id="progImage"
                        placeholder="Progress Youtube Video Link"
                        onChange={(data) => {
                          this.setState({
                            progImage: data.target.value,
                          });
                        }}
                        type="text"
                      />
                    )}
                  </FormGroup>
                </Col>

                <Col lg="4" style={{ paddingTop: 35 }}>
                  <Button
                    color="primary btn-block"
                    href="#"
                    style={{ padding: 12 }}
                    size="md"
                    disabled={UserRolePermission(
                      this.props.currentUser.UserData.role,
                      "add_progress"
                    )}
                    onClick={async (e) => {
                      e.preventDefault();

                      if (
                        this.state.progTitle == null ||
                        this.state.progImage == null ||
                        this.state.progSummary == null
                      ) {
                        swal({
                          title: "Some fields are still empty",
                          text: "Kindly provide the Progress Title, Summary and Image to continue",
                          icon: "error",
                        });
                      } else {
                        var home = this;

                        home.setState({ startProgUpload: true });

                        var now = new Date();
                        var formattedToday =
                          now
                            .toLocaleDateString("en-GB", {
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                            })
                            .split(" ")
                            .join("-") + "";

                        if (this.state.proType == "image") {
                          //const image = e.target.files[0];
                          const image = this.state.progImage;
                          //console.log(JSON.stringify(this.state.photoObject))

                          Compress.imageFileResizer(
                            image, // the file from input
                            500, // width
                            "auto", // height
                            "JPEG", // compress format WEBP, JPEG, PNG
                            100, // quality
                            0, // rotation
                            (uri) => {
                              this.setState(
                                {
                                  mobileUri: uri,
                                },
                                () => {
                                  var strImage0 =
                                    this.state.mobileUri.split(",")[0];

                                  var strImage1 =
                                    this.state.mobileUri.split(",")[1];
                                  var message = strImage1;

                                  const fileName =
                                    this.uuidv4() +
                                    this.extractFileExtension(
                                      this.state.progImage.name
                                    );

                                  const storage = firebase.storage();
                                  const uploadPhotoTask = storage
                                    .ref("propertyProgress/" + fileName)
                                    .putString(message, "base64", {
                                      contentType: "image/jpg",
                                    });
                                  //.put(image);

                                  uploadPhotoTask.on(
                                    "state_changed",
                                    (snapshot) => {},
                                    (error) => {
                                      swal({
                                        title:
                                          "Property Progress Image Upload Error",
                                        text: "An unexpected error occured while uploading your property progress image.\n Kindly confirm your internet connection or contact an admin",
                                        icon: "error",
                                      });

                                      //console.log(error)
                                    },
                                    async () => {
                                      storage
                                        .ref("propertyProgress")
                                        .child(fileName)
                                        .getDownloadURL()
                                        .then(async (url) => {
                                          var image = [];
                                          image.push(url);

                                          var DevData = {
                                            progImage: image,
                                            pid: this.props.PropertyReducer
                                              .propertySingle.id,
                                            date_time: formattedToday,
                                            progTitle: this.state.progTitle,
                                            progSummary: this.state.progSummary,
                                          };

                                          await firebase
                                            .firestore()
                                            .collection("propertyProgress")
                                            .add(DevData)
                                            /* 
                                  .doc(
                                    this.props.PropertyReducer.propertySingle.id
                                  )
                                  .set(DevData) */
                                            .then(function () {
                                              home.setState({
                                                startProgUpload: false,
                                              });

                                              swal(
                                                "Property Progress added to property",
                                                "You have successfully added " +
                                                  home.state.progTitle +
                                                  " to the property",
                                                "success"
                                              ).then((value) => {
                                                home.props.propertyProgressAction(
                                                  home.props.PropertyReducer
                                                    .propertySingle.id
                                                );
                                                document.location.reload();
                                              });
                                            });
                                        });
                                    }
                                  );
                                }
                              );
                            },
                            "base64" // blob or base64 default base64
                          );
                        } else {
                          var DevData = {
                            progImage: this.state.progImage,
                            pid: this.props.PropertyReducer.propertySingle.id,
                            date_time: formattedToday,
                            progTitle: this.state.progTitle,
                            progSummary: this.state.progSummary,
                          };

                          await firebase
                            .firestore()
                            .collection("propertyProgress")
                            .add(DevData)
                            .then(function () {
                              home.setState({
                                startProgUpload: false,
                              });

                              swal(
                                "Property Progress added to property",
                                "You have successfully added " +
                                  home.state.progTitle +
                                  " to the property",
                                "success"
                              ).then((value) => {
                                home.props.propertyProgressAction(
                                  home.props.PropertyReducer.propertySingle.id
                                );
                                document.location.reload();
                              });
                            });
                        }
                      }
                    }}
                    style={{
                      fontSize: 12,
                      display: UserRolePermission(
                        this.props.currentUser.UserData.role,
                        "add_progress"
                      )
                        ? "none"
                        : "block",
                    }}
                  >
                    {this.state.startProgUpload ? (
                      <>
                        saving... <i className="fa fa-spinner fa-spin"></i>
                      </>
                    ) : (
                      "Add Property Progress"
                    )}
                  </Button>
                </Col>
              </Row>

              <hr style={{ padding: 10 }}></hr>

              <Modal
                id="model_featured_edit"
                className="modal-dialog-centered"
                isOpen={this.state.showEditProgressModal}
                // toggle={() => this.toggleModal("exampleModal")}
              >
                <div className="modal-header">
                  <h5 className="modal-title" id="">
                    EDIT <span id="progress_edit_title_div"></span>
                  </h5>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ showEditProgressModal: false });
                    }}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Row
                    style={{
                      backgroundColor: "#bec1c3",
                      padding: 10,
                      paddingTop: 20,
                    }}
                  >
                    <Col lg="12">
                      <FormGroup>
                        <Input
                          className="form-control-alternative"
                          id="progress_edit_title_text"
                          placeholder="Progress Title"
                          type="text"
                          onChange={(data) => {
                            this.setState({
                              progTitle2: data.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="12">
                      <FormGroup>
                        <Input
                          className="form-control-alternative"
                          id="progress_edit_summary"
                          placeholder="Progress Summary"
                          rows="3"
                          type="textarea"
                          onChange={(data) => {
                            this.setState({
                              progSummary2: data.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="12">
                      <Button
                        color="primary btn-block"
                        href="#"
                        style={{ padding: 12 }}
                        size="md"
                        disabled={UserRolePermission(
                          this.props.currentUser.UserData.role,
                          "edit_progress"
                        )}
                        style={{
                          fontSize: 12,
                          display: UserRolePermission(
                            this.props.currentUser.UserData.role,
                            "edit_progress"
                          )
                            ? "none"
                            : "block",
                        }}
                        // onClick={async () => {
                        onClick={async (e) => {
                          if (
                            this.state.progTitle2 == null ||
                            //this.state.progImage == null ||
                            this.state.progSummary2 == null
                          ) {
                            swal({
                              title: "Some fields are still empty",
                              text: "Kindly provide the Progress Title, Summary to continue Update",
                              icon: "error",
                            });
                          } else {
                            var DevData = {
                              // progImage: image,
                              //date_time: formattedToday,
                              progTitle: this.state.progTitle2,
                              progSummary: this.state.progSummary2,
                            };

                            var home = this;

                            //alert(home.state.EprogId+'====');

                            home.setState({ startProgUpdate: true });

                            await firebase
                              .firestore()
                              .collection("propertyProgress")

                              .doc(home.state.EprogId)
                              .update(DevData)
                              /*.set(DevData) */
                              .then(function () {
                                home.setState({
                                  startProgUpload: false,
                                });

                                swal(
                                  "Property Progress added to property",
                                  "You have successfully added " +
                                    home.state.progTitle2 +
                                    " to the property",
                                  "success"
                                ).then((value) => {
                                  home.setState({ startProgUpdate: false });
                                  home.props.propertyProgressAction(
                                    this.props.PropertyReducer.propertySingle.id
                                  );
                                  document.location.reload();
                                });
                              });
                          }
                        }}
                      >
                        {this.state.startProgUpdate ? (
                          <>
                            updating...{" "}
                            <i className="fa fa-spinner fa-spin"></i>
                          </>
                        ) : (
                          "Update"
                        )}
                      </Button>
                    </Col>
                  </Row>

                  <hr></hr>
                  <div
                    style={{
                      paddingBottom: 20,
                      fontSize: 12,
                      fontWeight: "600",
                      // marginTop: -15,
                    }}
                  >
                    MANAGE PROGRESS PHOTOS
                  </div>

                  <Row className="">
                    {Object.keys(this.state.EprogImage).length > 0
                      ? this.state.EprogImage.map((data, i) => {
                          return (
                            <Col lg="4" md="4">
                              <button
                                className=" btn-icon-clipboard delete_FeturedList"
                                //id="tooltip982655500"
                                type="button"
                              >
                                <div>
                                  {/* <i className=" ni ni-active-40" /> */}
                                  <Badge
                                    color="primary"
                                    href="#stow"
                                    style={{
                                      fontSize: 9,
                                      position: "absolute",
                                      left: 10,
                                      top: 1,
                                    }}
                                  >
                                    {i + 1}
                                  </Badge>

                                  {data.includes("youtube") ? (
                                    <div
                                      class=""
                                      style={{
                                        paddingLeft: 25,
                                        margin: 0,
                                        paddingTop: 10,
                                        fontSize: 10,
                                      }}
                                    >
                                      <i class="ni ni-button-play" />
                                    </div>
                                  ) : (
                                    <CardImg
                                      className="text-center"
                                      style={{ height: 70, width: 70 }}
                                      src={data}
                                      top
                                    />
                                  )}
                                </div>

                                <span id={"prog_edit_msg_" + i}></span>
                              </button>

                              <Badge
                                color="danger"
                                href="#stow"
                                style={{
                                  fontSize: 12,
                                  position: "absolute",
                                  right: 10,
                                  top: 1,
                                }}
                                onClick={(e) => {
                                  e.preventDefault();

                                  swal({
                                    title: "Delete Photo?",
                                    // html:true,
                                    text:
                                      "Are you sure you want to the selected Progress Photo " +
                                      (i + 1) +
                                      " ?",
                                    icon: "warning",
                                    buttons: true,
                                    buttons: {
                                      cancel: "Cancel",
                                      catch: {
                                        text: "Delete",
                                        //value: "catch",
                                      },
                                      //defeat: true,
                                    },
                                    dangerMode: true,
                                  }).then((willDelete) => {
                                    if (willDelete) {
                                      let arr = this.state.EprogImage;
                                      let index = i;

                                      document.getElementById(
                                        "prog_edit_msg_" + index
                                      ).innerHTML =
                                        '<div style="font-size:9px">Deleting Photo... <i class="fa fa-spinner fa-spin"></i></div>';

                                      this.setState(
                                        {
                                          EprogImage: this.removeByIndex(
                                            arr,
                                            index
                                          ),
                                        },
                                        async () => {
                                          //console.log(this.state.EprogImage);

                                          const EprogImage = {
                                            progImage: this.state.EprogImage,
                                          };

                                          var home = this;
                                          await firebase
                                            .firestore()
                                            .collection("propertyProgress")
                                            .doc(this.state.EprogId)
                                            .update(EprogImage)
                                            .then(async () => {
                                              home.props.propertyProgressAction(
                                                this.props.PropertyReducer
                                                  .propertySingle.id
                                              );

                                              swal(
                                                "Progress Photo Deleted",
                                                "You have successfully the selected Progress Photo",
                                                {
                                                  icon: "success",
                                                }
                                              ).then((value) => {
                                                document.getElementById(
                                                  "prog_edit_msg_" + index
                                                ).innerHTML = null;

                                                //document.location.reload()
                                              });
                                            });
                                        }
                                      );
                                    }
                                  });
                                }}
                              >
                                <i className="ni ni-fat-remove"></i>
                              </Badge>
                            </Col>
                          );
                        })
                      : null}
                  </Row>

                  <hr></hr>
                  <Row>
                    <Col lg="8">
                      <FormGroup id="devLogoD">
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                          style={{
                            fontWeight: "normal",
                            fontSize: 11,
                            //color: "white",
                          }}
                        >
                          {this.state.EprogImage.toString().includes("youtube")
                            ? "Edit Youtube Video Link"
                            : "Add New Progress Photo"}
                        </label>
                        {this.state.EprogImage.toString().includes(
                          "youtube"
                        ) ? (
                          <span>
                            <Input
                              className="form-control alternative"
                              style={{ fontSize: 10 }}
                              id="progImageEdit"
                              // defaultValue={this.state.EprogImage}
                              //accept="image/*"
                              onChange={(data) => {
                                this.setState({
                                  progImageEdit: data.target.value,
                                });
                              }}
                              type="text"
                            />

                            <span style={{ fontSize: 9, color: "grey" }}>
                              {this.state.EprogImage}
                            </span>
                          </span>
                        ) : (
                          <Input
                            className="form-control alternative"
                            style={{ fontSize: 10 }}
                            id="progImageEdit"
                            accept="image/*"
                            onChange={(data) => {
                              this.setState({
                                progImageEdit: data.target.files[0],
                              });
                            }}
                            type="file"
                          />
                        )}
                      </FormGroup>
                    </Col>

                    <Col lg="4" style={{ paddingTop: 35 }}>
                      <Button
                        color="primary btn-block"
                        href="#"
                        style={{ padding: 12 }}
                        size="md"
                        disabled={UserRolePermission(
                          this.props.currentUser.UserData.role,
                          "edit_progress"
                        )}
                        style={{
                          fontSize: 12,
                          display: UserRolePermission(
                            this.props.currentUser.UserData.role,
                            "edit_progress"
                          )
                            ? "none"
                            : "block",
                        }}
                        onClick={async () => {
                          if (this.state.progImageEdit == null) {
                            if (
                              this.state.EprogImage.toString().includes(
                                "youtube"
                              )
                            ) {
                              swal({
                                title: "Youtube video link is  empty",
                                text: "Kindly provide a Video Link to continue Update",
                                icon: "error",
                              });
                            } else {
                              swal({
                                title: "Image fields are still empty",
                                text: "Kindly provide the Progress Image file to continue Update",
                                icon: "error",
                              });
                            }
                          } else {
                            if (
                              this.state.EprogImage.toString().includes(
                                "youtube"
                              )
                            ) {
                              var home = this;
                              home.setState({ startProgSingle: true });

                              const EprogImage = {
                                progImage: this.state.progImageEdit,
                              };

                              await firebase
                                .firestore()
                                .collection("propertyProgress")
                                .doc(this.state.EprogId)
                                .update(EprogImage)
                                .then(async () => {
                                  home.setState({
                                    startProgSingle: false,
                                  });
                                  home.props.propertyProgressAction(
                                    this.props.PropertyReducer.propertySingle.id
                                  );

                                  swal(
                                    "Progress Youtube Link Updated",
                                    "You have successfully updated your progress video youtube Link",
                                    {
                                      icon: "success",
                                    }
                                  ).then((value) => {
                                    document.location.reload();
                                  });
                                });
                            } else {
                              var home = this;
                              const image = this.state.progImageEdit;
                              const fileName =
                                this.uuidv4() +
                                this.extractFileExtension(
                                  this.state.progImageEdit.name
                                );
                              const storage = firebase.storage();
                              const uploadPhotoTask = storage
                                .ref("propertyProgress/" + fileName)
                                .put(image);

                              home.setState({ startProgSingle: true });

                              var now = new Date();
                              var formattedToday =
                                now
                                  .toLocaleDateString("en-GB", {
                                    day: "numeric",
                                    month: "short",
                                    year: "numeric",
                                  })
                                  .split(" ")
                                  .join("-") + "";

                              uploadPhotoTask.on(
                                "state_changed",
                                (snapshot) => {},
                                (error) => {
                                  swal({
                                    title:
                                      "Property Progress Image Upload Error",
                                    text: "An unexpected error occured while uploading your property progress image.\n Kindly confirm your internet connection or contact an admin",
                                    icon: "error",
                                  });

                                  //console.log(error)
                                },
                                async () => {
                                  storage
                                    .ref("propertyProgress")
                                    .child(fileName)
                                    .getDownloadURL()
                                    .then(async (url) => {
                                      var image = [];
                                      image.push(url);

                                      let img = this.state.EprogImage;
                                      img.push(url);

                                      const EprogImage = {
                                        progImage: img,
                                      };

                                      var home = this;
                                      await firebase
                                        .firestore()
                                        .collection("propertyProgress")
                                        .doc(this.state.EprogId)
                                        .update(EprogImage)
                                        .then(async () => {
                                          home.setState({
                                            startProgSingle: false,
                                          });
                                          home.props.propertyProgressAction(
                                            this.props.PropertyReducer
                                              .propertySingle.id
                                          );

                                          swal(
                                            "Progress Photo Addedd",
                                            "You have successfully  added a new  Progress Photo",
                                            {
                                              icon: "success",
                                            }
                                          ).then((value) => {
                                            //document.location.reload()
                                          });
                                        });
                                    });
                                }
                              );
                            }
                          }
                        }}
                      >
                        {this.state.startProgSingle ? (
                          <>
                            updating...{" "}
                            <i className="fa fa-spinner fa-spin"></i>
                          </>
                        ) : (
                          "Save New Photo"
                        )}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Modal>

              {isObject(this.state.propertyProg)
                ? this.state.propertyProg.map((data, i) => {
                    return (
                      <div class="row">
                        <div class="col-1">
                          {data.progImage.includes("youtube") ? (
                            <div
                              class=""
                              style={{
                                paddingLeft: 15,
                                margin: 0,
                                paddingTop: 10,
                                fontSize: 20,
                              }}
                            >
                              <i class="ni ni-button-play" />
                            </div>
                          ) : (
                            <img
                              className="text-center"
                              style={{
                                height: 40,
                                width: 50,

                                paddingLeft: 10,
                                paddingRight: 10,
                              }}
                              src={data.progImage}
                              top
                            />
                          )}
                        </div>
                        <div
                          class="col-11"
                          style={{
                            background: "#fff",
                            padding: "10px",
                            borderRadius: "5px",
                            boxShadow: "0 0 12px #e2e7ef",
                            marginBottom: "10px",
                            fontSize: 11,
                          }}
                        >
                          <div>
                            &nbsp;&nbsp;&nbsp;
                            <span style={{ fontSize: 12 }}>
                              {data.progTitle}{" "}
                            </span>
                          </div>

                          <span
                            id={"delete_dev_" + i}
                            className="float-right"
                            style={{ float: "right" }}
                          ></span>

                          <Button
                            data-title={data.progTitle}
                            data-id={data.id}
                            data-summary={data.progSummary}
                            data-img={data.progImage}
                            className="mr-20"
                            style={{
                              float: "right",
                              marginTop: -25,
                              marginRight: 70,
                              fontSize: 9,

                              display: UserRolePermission(
                                this.props.currentUser.UserData.role,
                                "edit_progress"
                              )
                                ? "none"
                                : "block",
                            }}
                            title={"Edit  " + data.progTitle}
                            color="warning"
                            href="#stow"
                            size="sm"
                            onClick={(e) => {
                              let button = e;
                              let home = this;

                              var ProImageMapper = button.target
                                .getAttribute("data-img")
                                .split(",");

                              this.setState({
                                EprogTitle:
                                  button.target.getAttribute("data-title"),
                                EprogSummary:
                                  button.target.getAttribute("data-summary"),

                                EprogImage: ProImageMapper,
                                EprogId: button.target.getAttribute("data-id"),

                                progTitle2:
                                  button.target.getAttribute("data-title"),

                                progSummary2:
                                  button.target.getAttribute("data-summary"),
                              });

                              //console.log(ProImageMapper);

                              this.setState(
                                { showEditProgressModal: true },
                                () => {
                                  var tt = setTimeout(function () {
                                    document.getElementById(
                                      "progress_edit_title_div"
                                    ).innerHTML =
                                      home.state.EprogTitle.toUpperCase();

                                    document.getElementById(
                                      "progress_edit_title_text"
                                    ).value = home.state.EprogTitle;

                                    document.getElementById(
                                      "progress_edit_summary"
                                    ).value = home.state.EprogSummary;

                                    //

                                    clearTimeout(tt);
                                  }, 800);
                                }
                              );
                            }}
                          >
                            Edit
                          </Button>

                          <Button
                            className="mr-2"
                            style={{
                              float: "right",
                              marginTop: -25,
                              fontSize: 9,
                              display: UserRolePermission(
                                this.props.currentUser.UserData.role,
                                "delete_progress"
                              )
                                ? "none"
                                : "block",
                            }}
                            title={"Delete  " + data.progTitle}
                            color="info"
                            href="#stow"
                            onClick={(e) => {
                              swal({
                                title: "Delete Property Progress ?",
                                // html:true,
                                text: "Delete " + data.progTitle + " from List",
                                icon: "warning",
                                buttons: true,
                                buttons: {
                                  cancel: "Cancel",
                                  catch: {
                                    text: "Delete",
                                    //value: "catch",
                                  },
                                  //defeat: true,
                                },
                                dangerMode: true,
                              }).then(async (willDelete) => {
                                if (willDelete) {
                                  e.preventDefault();
                                  //let arr = this.state.propertyProg;

                                  document.getElementById(
                                    "delete_dev_" + i
                                  ).innerHTML =
                                    '<div style="font-size:9px">Deleting Property Progress... <i class="fa fa-spinner fa-spin"></i></div>';

                                  await firebase
                                    .firestore()
                                    .collection("propertyProgress")
                                    .doc(data.id)
                                    .delete()
                                    .then(async () => {
                                      //document.getElementById('delete_feature_'+i).innerHTML= '';

                                      swal(
                                        "Property Progress Deleted",
                                        "You have successfully Deleted the Property Progress - " +
                                          data.progTitle,
                                        {
                                          icon: "success",
                                        }
                                      ).then((value) => {
                                        document.location.reload();
                                      });
                                    });
                                }
                              });
                            }}
                            size="sm"
                          >
                            Delete
                          </Button>
                        </div>
                      </div>
                    );
                  })
                : null}
            </Card>
          </div>
        </Form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    //promoActions: (param) => dispatch(promoActions(param)),
    // propertyDeveloperAction: (param) => dispatch(propertyDeveloperAction(param)),

    propertyProgressAction: (param) => dispatch(propertyProgressAction(param)),
  };
};

const mapStateToProps = (state) => ({
  //PromoReducer: state.PromoReducer,
  PropertyReducer: state.PropertyReducer,
  currentUser: state.authReducer,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertyProgressData);

import React from "react";
// node.js library that concatenates classes (strings)



// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  Modal,
  Form,
  FormGroup,
  Input,
  Badge,
  Alert 
} from "reactstrap";

import moment from 'moment';



import   firebase from "firebase/app";

import { useCollectionData } from "react-firebase-hooks/firestore";




import api from "../../../utils/config";
import { currencyFormat } from '../../../utils/functions';

//import "firebase/auth";
import { isEmpty } from "lodash";
//require("firebase/firestore");



const SinglePropertyFeatureList = (props) => {

    const [ PaymentData, setPaymentData ] = React.useState([]);
    const [ dataLoading, setdataLoading ] = React.useState(true);

    

const { featureList } = props;
  //const { tid, paid, pplan } = transaction;

  
    return (featureList?
         
      <Col  sm="2" className="text-center" style={{paddingRight:5,paddingLeft:5}}>

        <Card style={{ width: "auto", marginTop:5,paddingTop:10}}>
          {(featureList.hasOwnProperty('icon')?(
            <CardImg
            className='text-center'
            style={{height:85,paddingLeft:10,paddingRight:10}}
             src={featureList.icon.src}
            top
          />
          ):null)}
          <CardBody>
            <CardTitle style={{fontSize:10}}>{featureList.text}</CardTitle>
            
            
          </CardBody>
        </Card>

      
    </Col>:null
           
        )


   

  // return ('')


}

export default SinglePropertyFeatureList;
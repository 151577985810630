import axios from "axios";

import api from "../../utils/config";

import  firebase from "firebase/app";
import "firebase/auth";
import app from './../../variables/base'
import swal from "sweetalert";
import { isEmpty } from "lodash";


  
  if (!firebase.apps.length)
    {
    

        firebase.initializeApp({
            apiKey: api.REACT_APP_FIREBASE_APP_ID,
            authDomain: api.REACT_APP_FIREBASE_DOMAIN,
            databaseURL: api.REACT_APP_FIREBASE_DATABASE,
            projectId: api.REACT_APP_FIREBASE_PROJECT_ID,
            storageBucket: api.REACT_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: api.REACT_APP_FIREBASE_SENDER_ID,
            appId: api.REACT_APP_FIREBASE_APP_ID,
            measurementId: api.REACT_APP_FIREBASE_MEASUREMENT_ID,
          });

    }


    
    export const ChatCount = (count) =>{

      return (dispatch, getState) => {
         dispatch({ type: "CHAT_COUNT", payload: count });
      }

    }

    export const ChatArchiveCount = (count) => {
      return (dispatch, getState) => {
        dispatch({ type: "CHAT_ARCHIVE_COUNT", payload: count });
      };
    };



export const setChatData = (ChatData, key) => {
  const data = [...new Map(ChatData.map((item) => [key(item), item])).values()];
  return {
    type: "CHAT_SUCCESS",
    payload: data,
  };
};


export const setChatArchiveData = (ChatData, key) => {
  const data = [...new Map(ChatData.map((item) => [key(item), item])).values()];
  return {
    type: "CHAT_ARCHIVE_SUCCESS",
    payload: data,
  };
};




export const ChatActions = (credentials) => {
  

  

  return async (dispatch, getState)  => {

    dispatch({ type: "CHAT_LOADING", loading: true });
 console.log("ChatActions");

    try {

            
      
      
      await firebase
        .firestore()
        .collection("chats")
        .orderBy("createdAt", "desc")
        .onSnapshot((snapshot) => {
          const chat = [];

          snapshot.forEach((doc) => {
            let allConversation = doc.data();
            chat.push(allConversation);
          });

          // console.log(chat, 'mychats');

          //chat, (item) => item.user.userEmail)
          dispatch(setChatData(chat, (item) => item.user.userEmail));

          /* this.setState({
            setChatThread: this.removeDuplicates(
              chat,
              (item) => item.user.userEmail
            ),
          }); */
        });
       /*  .catch((err) => {
           //alert("1 :: " + err);
           dispatch({ type: "CHAT_ERROR", payload: err });
        }); */


    }
    catch(error)
    {

       // alert('2 :: '+error)
         dispatch({ type: "CHAT_ERROR",  payload: error, });

    }





  }


}


export const ChatArchiveActions = (credentials) => {
 
  return async (dispatch, getState) => {
    dispatch({ type: "CHAT_ARCHIVE_LOADING", loading: true });

    try {
      await firebase
        .firestore()
        .collection("chatsArchive")
        //.get()
        .orderBy("createdAt", "desc")
        .onSnapshot((snapshot) => {
          const chat = [];

          snapshot.forEach((doc) => {
            let allConversation = doc.data();
            chat.push(allConversation);
          });
          //alert("sdsd");

          // console.log(chat, 'mychats');

          //chat, (item) => item.user.userEmail)
          dispatch(setChatArchiveData(chat, (item) => item.user.userEmail));

          /* this.setState({
            setChatThread: this.removeDuplicates(
              chat,
              (item) => item.user.userEmail
            ),
          }); */
        })
       /*  .catch((err) => {
          alert("1 :: " + err);
          dispatch({ type: "CHAT_ARCHIVE_ERROR", payload: err });
        }); */
    } catch (error) {
       //alert('2 :: '+error)
      dispatch({ type: "CHAT_ARCHIVE_ERROR", payload: error });
    }
  };
};
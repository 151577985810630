import { isObject } from "lodash";
import isEmpty from "lodash/isEmpty";

const iniState = {
 
  //FListactionsData: {},
  FListLoading: true,
  FListError: true,
  FListErrorMsg:'',
  FListLoadingCompleted:false,
  FListactionCount:0
};

const FeaturedListReducer = (state = iniState, action) => {
  switch (action.type) {

    case "FLIST_COUNT":
     // alert(action.payload)
      return {
        ...state,
        FListactionCount: action.payload,
      }
    case "FLIST_LOADING":

    return {
        ...state,
        FListLoading: action.FListLoading,
        FListError: false,
        FListLoadingCompleted:false,
        //FListErrorMsg:''
      };

    case "FLIST_SUCCESS":
     
    //alert(JSON.stringify(action.payload))
      return {
        ...state,
        FListactionsData: action.payload,
        //FListactionCount: (isObject(action.payload)?(Object.keys(action.payload).length):0),
        FListError: false,
        FListLoadingCompleted:true,
        FListLoading: false,
      };
    case "FLIST_ERROR":
     
    //alert('error')
      return {
        ...state,
        FListError: true,
        FListLoadingCompleted: false,
         FListErrorMsg:action.payload,
         FListLoaded:false,
      };

    
    default:
      return state;
  }
};

export default FeaturedListReducer;

import React from "react";
//import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import CountUp from "react-countup";
import axios from "axios";

// reactstrap components
import {
  Button,
  Card,
  CardImg,
  CardHeader,
  Modal,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledAlert,
} from "reactstrap";

import swal from "sweetalert";
import firebase from "firebase/app";
import "firebase/storage";
import { connect } from "react-redux";
import MaterialTable, { MTableToolbar, MTableCell } from "material-table";
import { array } from "js-sha512";
import Datetime from "react-datetime";

import { dateFormater, UserRolePermission } from "utils/functions";
//import { promoActions } from "../../../store/actions/promoActions";
import { Editor } from "@tinymce/tinymce-react";

import { propertyDeveloperAction } from "../../../store/actions/propertyActions";
import { couponActions } from "../../../store/actions/couponActions";

class CouponData extends React.Component {
  state = {
    couponCode: null,
    devLogo: null,

    devName_edit: null,

    devName_edit_title: "",
    devName_edit_id: null,

    //////////////////////////////////
    name_edit: "",

    id_edit: null,

    summary_edit: null,

    status_edit: null,

    percentage_edit: null,

    ExpirationDate_edit: null,
    /////////////////////////////////////

    //////////////////////////////////
    name_edit_val: null,

    id_edit_val: null,

    summary_edit_val: null,

    status_edit_val: null,

    percentage_edit_val: null,

    ExpirationDate_edit_val: null,

    ////////////////////////////
    couponCodeData: this.props.CouponReducer.hasOwnProperty("CouponactionsData")
      ? this.props.CouponReducer.CouponactionsData
      : [],
    startDevUpload: false,
    showEditCouponModal: false,
    startDeveUpdate: false,
  };

  componentDidMount() {
    this.props.couponActions();

    //console.log(this.state.couponCodeData);
  }

  render() {
    return (
      <div>
        <Form>
          <div
            className="edit_form"
            style={{
              width: 100 + "%",
            }}
          >
            <Row>
              <Col>
                <UncontrolledAlert
                  id="validate_form_msg"
                  className=" alert-warning"
                  style={{ display: "none" }}
                  fade={false}
                >
                  <span className="alert-inner--icon">
                    <i className="ni ni-like-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    <strong>Validation!</strong> All fields are mandatory
                  </span>
                </UncontrolledAlert>
              </Col>
            </Row>

            <Card style={{ padding: 20, border: "1px solid #ccc" }}>
              <Row
                style={{
                  backgroundColor: "black",
                  paddingLeft: 20 + "%",
                  paddingRight: 20 + "%",
                }}
              >
                <Col lg="12">
                  <FormGroup id="proTitleD">
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                      style={{
                        fontWeight: "normal",
                        fontSize: 11,
                        color: "white",
                      }}
                    >
                      Coupon Name
                    </label>
                    <Input
                      className="form-control-alternative"
                      //defaultValue={this.state.editPromoTitle}
                      required
                      id="couponCode"
                      placeholder="Coupon Code"
                      onChange={(data) => {
                        this.setState({
                          couponCode: data.target.value,
                        });
                      }}
                      type="text"
                    />
                  </FormGroup>
                </Col>

                <Col lg="12" style={{ marginTop: -20 }}>
                  <FormGroup id="proTitleD">
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                      style={{
                        fontWeight: "normal",
                        fontSize: 11,
                        color: "white",
                      }}
                    >
                      Percentage
                    </label>
                    <Input
                      className="form-control-alternative"
                      //defaultValue={this.state.editPromoTitle}
                      required
                      id="couponPercentage"
                      placeholder="Coupon Percentage"
                      onChange={(data) => {
                        this.setState({
                          couponPercentage: data.target.value,
                        });
                      }}
                      type="text"
                    />
                  </FormGroup>
                </Col>

                <Col lg="12" style={{ marginTop: -20 }}>
                  <FormGroup id="proTitleD">
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                      style={{
                        fontWeight: "normal",
                        fontSize: 11,
                        color: "white",
                      }}
                    >
                      Status
                    </label>
                    <select
                      style={{ border: "1px solid #eee" }}
                      className="form-control-alternative form-control"
                      id="couponStatus"
                      onChange={(data) => {
                        // console.log(data.target.value)
                        this.setState({
                          couponStatus: data.target.value,
                        });
                      }}
                    >
                      {/* <option> -- No Promo --</option> */}

                      <option
                        value={false}
                        selected={
                          this.state.couponStatus === false ? "selected" : null
                        }
                      >
                        False
                      </option>
                      <option
                        value={true}
                        selected={
                          this.state.couponStatus === true ? "selected" : null
                        }
                      >
                        True
                      </option>
                    </select>
                  </FormGroup>
                </Col>

                <Col lg="12" style={{ marginTop: -20 }}>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-first-name"
                      style={{
                        fontWeight: "normal",
                        fontSize: 11,
                        color: "white",
                      }}
                    >
                      Expiration Date
                    </label>

                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-calendar-grid-58" />
                        </InputGroupText>
                      </InputGroupAddon>

                      <Datetime
                        locale="de"
                        timeFormat={false}
                        dateFormat="M/D/YYYY"
                        renderDay={(props, currentDate, selectedDate) => {
                          let classes = props.className;

                          return (
                            <td {...props} className={classes}>
                              {currentDate.date()}
                            </td>
                          );
                        }}
                        value={null}
                        onChange={(e) => {
                          this.setState({
                            couponExpiration: e.format("M/D/YYYY"),
                          });

                          //console.log(this.state.startDate);
                        }}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>

                <Col lg="12" style={{ marginTop: -20 }}>
                  <FormGroup>
                    <label
                      style={{
                        fontWeight: "normal",
                        fontSize: 11,
                        color: "white",
                      }}
                    >
                      Code Summary
                    </label>
                    <Input
                      className="form-control-alternative"
                      placeholder="Coupon ummary"
                      rows="4"
                      id="couponSummary"
                      defaultValue={null}
                      type="textarea"
                      onChange={(data) => {
                        this.setState({
                          couponSummary: data.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col lg="12"  style={{paddingBottom:20}}>
                  <Button
                    color="primary btn-block"
                    href="#"
                    style={{ padding: 12 }}
                    size="md"
                   
                    onClick={async (e) => {
                      e.preventDefault();

                      if (
                        this.state.couponCode == null ||
                        this.state.couponPercentage == null ||
                        this.state.couponStatus == null ||
                        this.state.couponExpiration == null ||
                        this.state.couponSummary == null
                      ) {
                        swal({
                          title: "Some fields are still empty",
                          text: "Kindly provide all Coupon Data to add new promo coupon",
                          icon: "error",
                        });
                      } else {
                        var home = this;

                        var now = new Date();
                        var formattedToday =
                          now
                            .toLocaleDateString("en-GB", {
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                            })
                            .split(" ")
                            .join("-") + "";

                        var DevData = {
                          name: this.state.couponCode,
                          percentage: this.state.couponPercentage,
                          status:
                            this.state.couponStatus == "true" ? true : false,
                          ExpirationDate: this.state.couponExpiration,
                          summary: this.state.couponSummary,
                          dateCreated: formattedToday,
                        };

                        this.setState({ startDevUpload: true }, async () => {

                          await firebase
                            .firestore()
                            .collection("promoCoupon")
                            //.doc(this.state.editPromoId)
                            .add(DevData)
                            .then(function () {
                              home.setState({
                                startDevUpload: false,
                              });

                              swal(
                                "Promo Coupon added to STOW",
                                "You have successfully added the " +
                                  home.state.couponCode +
                                  " to STOW",
                                "success"
                              ).then((value) => {
                                document.location.reload();
                              });
                            });
                          
                        });

                        
                      }
                    }}
                    style={{
                      fontSize: 12,
                      display: UserRolePermission(
                        this.props.currentUser.UserData.role,
                        "edit_promo"
                      )
                        ? "none"
                        : "block",
                    }}
                  >
                    {this.state.startDevUpload ? (
                      <>
                        saving... <i className="fa fa-spinner fa-spin"></i>
                      </>
                    ) : (
                      "Add Coupon"
                    )}
                  </Button>
                </Col>
              </Row>

              <hr style={{ padding: 10 }}></hr>

              {this.state.couponCodeData &&
                this.state.couponCodeData.map((data, i) => {
                  return (
                    <div class="row">
                      <div
                        class="col-11"
                        style={{
                          background: "#fff",
                          padding: "10px",
                          borderRadius: "5px",
                          boxShadow: "0 0 12px #e2e7ef",
                          marginBottom: "10px",
                          fontSize: 11,
                        }}
                      >
                        <div>
                          &nbsp;&nbsp;&nbsp;
                          <span style={{ fontSize: 14 }}>{data.name} </span>
                        </div>

                        <Modal
                          // id="model_featured_edit"
                          className="modal-dialog-centered"
                          isOpen={this.state.showEditCouponModal}
                          // toggle={() => this.toggleModal("exampleModal")}
                        >
                          <div className="modal-header">
                            <h5 className="modal-title" id="">
                              EDIT - {this.state.name_edit.toUpperCase()}
                            </h5>
                            <button
                              aria-label="Close"
                              className="close"
                              data-dismiss="modal"
                              type="button"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                  showEditCouponModal: false,
                                });
                              }}
                            >
                              <span aria-hidden={true}>×</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <Row
                              style={{
                                backgroundColor: "#000",
                                padding: 10,
                                paddingTop: 20,
                              }}
                            >
                              <Col lg="12">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-username"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: 11,
                                      color: "white",
                                    }}
                                  >
                                    Coupon Name
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    //id="devName_edit"
                                    placeholder="Coupon Name"
                                    defaultValue={this.state.name_edit}
                                    type="text"
                                    onChange={(data) => {
                                      this.setState({
                                        name_edit_val: data.target.value,
                                      });
                                    }}
                                  />
                                </FormGroup>
                              </Col>

                              <Col lg="12" style={{ marginTop: -20 }}>
                                <FormGroup id="proTitleD">
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-username"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: 11,
                                      color: "white",
                                    }}
                                  >
                                    Percentage
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    defaultValue={this.state.percentage_edit}
                                    required
                                    //id="couponCode"
                                    placeholder="Coupon Percentage"
                                    onChange={(data) => {
                                      this.setState({
                                        percentage_edit_val: data.target.value,
                                      });
                                    }}
                                    type="text"
                                  />
                                </FormGroup>
                              </Col>

                              <Col lg="12" style={{ marginTop: -20 }}>
                                <FormGroup id="proTitleD">
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-username"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: 11,
                                      color: "white",
                                    }}
                                  >
                                    Coupon Status{" "}
                                  </label>
                                  <select
                                    style={{ border: "1px solid #eee" }}
                                    className="form-control-alternative form-control"
                                    //id="couponStatus"
                                    onChange={(data) => {
                                      // console.log(data.target.value)
                                      this.setState({
                                        status_edit_val: data.target.value,
                                      });
                                    }}
                                  >
                                    {/* <option> -- No Promo --</option> */}
                                    {this.state.status_edit === true ||
                                    this.state.status_edit == "true" ? (
                                      <option value={true}>True</option>
                                    ) : (
                                      <option value={false}>False</option>
                                    )}

                                    {this.state.status_edit === false ||
                                    this.state.status_edit == "false" ? (
                                      <option value={true}>True</option>
                                    ) : (
                                      <option value={false}>False</option>
                                    )}
                                  </select>
                                </FormGroup>
                              </Col>

                              <Col lg="12" style={{ marginTop: -20 }}>
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-first-name"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: 11,
                                      color: "white",
                                    }}
                                  >
                                    Expiration Date
                                  </label>

                                  <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                      <InputGroupText>
                                        <i className="ni ni-calendar-grid-58" />
                                      </InputGroupText>
                                    </InputGroupAddon>

                                    <Datetime
                                      locale="de"
                                      timeFormat={false}
                                      dateFormat="M/D/YYYY"
                                      renderDay={(
                                        props,
                                        currentDate,
                                        selectedDate
                                      ) => {
                                        let classes = props.className;

                                        return (
                                          <td {...props} className={classes}>
                                            {currentDate.date()}
                                          </td>
                                        );
                                      }}
                                      value={this.state.ExpirationDate_edit}
                                      onChange={(e) => {
                                        this.setState({
                                          ExpirationDate_edit_val:
                                            e.format("M/D/YYYY"),
                                        });

                                        //console.log(this.state.startDate);
                                      }}
                                    />
                                  </InputGroup>
                                </FormGroup>
                              </Col>

                              <Col lg="12" style={{ marginTop: -20 }}>
                                <FormGroup id="proTitleD">
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-username"
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: 11,
                                      color: "white",
                                    }}
                                  >
                                    Coupon Summary
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    defaultValue={this.state.summary_edit}
                                    required
                                    //id="couponCode"
                                    placeholder="Coupon Summary"
                                    onChange={(data) => {
                                      this.setState({
                                        summary_edit_val: data.target.value,
                                      });
                                    }}
                                    type="textarea"
                                  />
                                </FormGroup>
                              </Col>

                              <Col lg="12">
                                <Button
                                  color="primary btn-block"
                                  href="#"
                                  style={{ padding: 12 }}
                                  size="md"
                                  disabled={UserRolePermission(
                                    this.props.currentUser.UserData.role,
                                    "edit_promo"
                                  )}
                                  style={{
                                    fontSize: 12,
                                    display: UserRolePermission(
                                      this.props.currentUser.UserData.role,
                                      "edit_promo"
                                    )
                                      ? "none"
                                      : "block",
                                  }}
                                  // onClick={async () => {
                                  onClick={async (e) => {
                                    if (
                                      this.state.name_edit_val == null ||
                                      this.state.ExpirationDate_edit_val ==
                                        null ||
                                      this.state.summary_edit_val == null ||
                                      this.state.percentage_edit_val == null ||
                                      //this.state.summary_edit_val == null ||
                                      this.state.status_edit_val == null
                                    ) {
                                      swal({
                                        title: "Some fields are still empty",
                                        text: "Kindly provide the Coupon Details to continue Update",
                                        icon: "error",
                                      });
                                    } else {
                                      var now = new Date();
                                      var formattedToday =
                                        now
                                          .toLocaleDateString("en-GB", {
                                            day: "numeric",
                                            month: "short",
                                            year: "numeric",
                                          })
                                          .split(" ")
                                          .join("-") + "";

                                      var DevData = {
                                        name: this.state.name_edit_val,
                                        percentage:
                                          this.state.percentage_edit_val,
                                        ExpirationDate:
                                          this.state.name_edit_val,
                                        percentage:
                                          this.state.percentage_edit_val,
                                        status:
                                          this.state.status_edit_val == "true"
                                            ? true
                                            : false,

                                        summary: this.state.summary_edit_val,
                                        dateCreated: formattedToday,
                                        //name: this.state.name_edit_val,
                                      };

                                      var home = this;

                                      //alert(home.state.EprogId+'====');

                                      home.setState({ startDeveUpdate: true });


                                      await firebase
                                        .firestore()
                                        .collection("promoCoupon")

                                        .doc(home.state.id_edit)
                                        .update(DevData)
                                        /*.set(DevData) */
                                        .then(function () {
                                          home.setState({
                                            startDeveUpdate: false,
                                          });

                                          swal(
                                            "Coupon updated",
                                            "You have successfully updated " +
                                              home.state.name_edit +
                                              " developer",
                                            "success"
                                          ).then((value) => {
                                            home.setState({
                                              startDeveUpdate: false,
                                            });

                                            document.location.reload();
                                          });
                                        });
                                    }
                                  }}
                                >
                                  {this.state.startDeveUpdate ? (
                                    <>
                                      updating...{" "}
                                      <i className="fa fa-spinner fa-spin"></i>
                                    </>
                                  ) : (
                                    "Update"
                                  )}
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Modal>

                        <Button
                          data-ExpirationDate={data.ExpirationDate}
                          data-percentage={data.percentage}
                          data-name={data.name}
                          data-dateCreated={data.dateCreated}
                          data-status={data.status}
                          data-summary={data.summary}
                          data-id={data.id}
                          className="mr-20"
                          style={{
                            float: "right",
                            marginTop: -25,
                            marginRight: 70,
                            fontSize: 9,

                            display: UserRolePermission(
                              this.props.currentUser.UserData.role,
                              "edit_promo"
                            )
                              ? "none"
                              : "block",
                          }}
                          title={"Edit  " + data.name}
                          color="warning"
                          href="#stow"
                          size="sm"
                          onClick={(e) => {
                            //alert(e.target.getAttribute("data-id"));
                            this.setState(
                              {
                                showEditCouponModal: true,
                                name_edit: e.target.getAttribute("data-name"),
                                name_edit_val:
                                  e.target.getAttribute("data-name"),

                                id_edit: e.target.getAttribute("data-id"),

                                summary_edit:
                                  e.target.getAttribute("data-summary"),
                                summary_edit_val:
                                  e.target.getAttribute("data-summary"),

                                status_edit:
                                  e.target.getAttribute("data-status"),
                                status_edit_val:
                                  e.target.getAttribute("data-status"),

                                percentage_edit:
                                  e.target.getAttribute("data-percentage"),
                                percentage_edit_val:
                                  e.target.getAttribute("data-percentage"),

                                ExpirationDate_edit: e.target.getAttribute(
                                  "data-ExpirationDate"
                                ),
                                ExpirationDate_edit_val: e.target.getAttribute(
                                  "data-ExpirationDate"
                                ),

                                //
                                //devName_edit: data.name,
                                //devName_edit_id: data.id,
                              },
                              () => {
                                var tt = setTimeout(function () {
                                  clearTimeout(tt);
                                }, 800);
                              }
                            );
                          }}
                        >
                          Edit
                        </Button>

                        <span
                          id={"delete_dev_" + i}
                          className="float-right"
                          style={{ float: "right" }}
                        ></span>

                        <Button
                          data-id={data.id}
                          data-name={data.name}
                          className="mr-2"
                          style={{
                            float: "right",
                            marginTop: -25,
                            fontSize: 9,
                            display: UserRolePermission(
                              this.props.currentUser.UserData.role,
                              "edit_promo"
                            )
                              ? "none"
                              : "block",
                          }}
                          title={"Delete  " + data.name}
                          color="info"
                          href="#stow"
                          onClick={(e) => {
                            //id_edit

                            this.setState(
                              {
                                id_edit: e.target.getAttribute("data-id"),
                                name_edit: e.target.getAttribute("data-name"),
                              },
                              () => {
                                swal({
                                  title: "Delete Coupon ?",
                                  // html:true,
                                  text: "Delete " + data.name + " from List",
                                  icon: "warning",
                                  buttons: true,
                                  buttons: {
                                    cancel: "Cancel",
                                    catch: {
                                      text: "Delete",
                                      //value: "catch",
                                    },
                                    //defeat: true,
                                  },
                                  dangerMode: true,
                                }).then(async (willDelete) => {
                                  if (willDelete) {
                                    e.preventDefault();
                                    //let arr = this.state.couponCodeData;

                                    document.getElementById(
                                      "delete_dev_" + i
                                    ).innerHTML =
                                      '<div style="font-size:9px">... <i class="fa fa-spinner fa-spin"></i></div>';

                                    await firebase
                                      .firestore()
                                      .collection("promoCoupon")
                                      .doc(this.state.id_edit)
                                      .delete()
                                      .then(async () => {
                                        //document.getElementById('delete_feature_'+i).innerHTML= '';

                                        swal(
                                          "Coupon Deleted",
                                          "You have successfully Deleted the Coupon - " +
                                            this.state.name_edit,
                                          {
                                            icon: "success",
                                          }
                                        ).then((value) => {
                                          document.location.reload();
                                        });
                                      });
                                  }
                                });
                              }
                            );
                          }}
                          size="sm"
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                  );
                })}
            </Card>
          </div>
        </Form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    //promoActions: (param) => dispatch(promoActions(param)),
    propertyDeveloperAction: (param) =>
      dispatch(propertyDeveloperAction(param)),
    couponActions: (param) => dispatch(couponActions(param)),
  };
};

const mapStateToProps = (state) => ({
  //PromoReducer: state.PromoReducer,
  PropertyReducer: state.PropertyReducer,
  currentUser: state.authReducer,
  CouponReducer: state.CouponReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(CouponData);

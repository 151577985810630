import React from "react";
// reactstrap components

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  Modal,
  Form,
  FormGroup,
  Input,
  Badge,
} from "reactstrap";

import ReactDOM from "react-dom";
import MaterialTable, { MTableToolbar, MTableCell } from "material-table";
import CountUp from "react-countup";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { dateFormater, currencyFormat } from "utils/functions";

class DepositData extends React.Component {
  state = {
    showRegisterModal: false,
  };
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <div id="modal_body_print">
          <div>
            <MaterialTable
              columns={[
                {
                  title: "Plan",
                  field: "plan",
                  render: (rowData) => {
                    return (
                      <>
                        <div> {rowData.plan}</div>
                      </>
                    );
                  },
                },

                {
                  title: "Email",
                  field: "email",
                  /* defaultGroupOrder: 0 , */ render: (rowData) => {
                    return (
                      <>
                        <div> {rowData.email}</div>
                        <code style={{ fontSize: 8 }}>{rowData.uid}</code>
                      </>
                    );
                  },
                },

                {
                  title: "amount",
                  field: "amount",
                  render: (rowData) => {
                    return currencyFormat(rowData.amount);
                  },
                },

                {
                  title: "Card Type",
                  field: "card_type",
                  /* defaultGroupOrder: 0 , */ render: (rowData) => {
                    return rowData.card_type;
                  },
                },
                {
                  title: "Last4 Digit",
                  field: "last4",
                  /* defaultGroupOrder: 0 , */ render: (rowData) => {
                    return rowData.last4;
                  },
                },

                {
                  title: "Payment Ref",
                  field: "reference",
                  /* defaultGroupOrder: 0 , */ render: (rowData) => {
                    return rowData.reference;
                  },
                },

                {
                  title: "Date",
                  field: "date_time",
                  defaultSort: "desc",
                  render: (rowData) => {
                    return isEmpty(rowData.date_time)
                      ? null
                      : dateFormater(rowData.date_time);
                  },
                },

                //
              ]}
              options={{
                exportButton: true,
                exportFileName: "Deposit_Single_Data_" + new Date(),
                // grouping: true,
                //showGroupingCount: true,
                pageSize: 5,
                pageSizeOptions: [
                  5, 10, 20, 30, 50, 75, 100, 200, 500, 1000, 2000, 3000, 5000,
                  8000, 10000,
                ],
                toolbar: true,
                // paging: true,

                rowStyle: {
                  paddingLeft: "1.5rem",
                  paddingRight: "1.5rem",
                  verticalAlign: "middle",

                  fontSize: "0.8125rem",
                  whiteSpace: "nowrap",
                  padding: "1rem",
                  borderTop: "1px solid #e9ecef",
                  fontFamily: "Open Sans, sans-serif",
                },

                headerStyle: {
                  paddingTop: "0.75rem",
                  paddingBottom: "0.75rem",
                  fontSize: "0.65rem",
                  textTransform: "uppercase",
                  letterPpacing: "1px",
                  borderBottom: "1px solid #e9ecef",
                  fontWeight: "bolder",
                  paddingLeft: "1.5rem",
                  paddingRight: "1.5rem",
                  backgroundColor: "#f6f9fc",
                  color: "#8898aa",
                  verticalAlign: "middle",
                  borderColor: "#e9ecef",
                  fontFamily: "Open Sans, sans-serif",
                },
              }}
              actions={[]}
              data={this.props.data}
              title={" "}
              components={{
                Toolbar: (props) => (
                  <p className="h3" style={{ padding: 10 }}>
                    <MTableToolbar {...props} />
                    <div style={{ marginTop: -40 }}>
                      {"Total"}{" "}
                      <CountUp
                        end={Object.keys(this.props.data || []).length}
                      />
                    </div>
                  </p>
                ),
              }}
            />
          </div>
        </div>
      </>
    );
  }
}

export default connect(null, null)(DepositData);

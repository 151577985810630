import React from "react";
//import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import CountUp from "react-countup";
import axios from "axios";

// reactstrap components
import {
  Button,
  Card,
  CardImg,
  CardHeader,
  Modal,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledAlert,
} from "reactstrap";
import { TabContent, TabPane } from "reactstrap";



import swal from "sweetalert";
import firebase from "firebase/app";
import "firebase/storage";
import { connect } from "react-redux";
import MaterialTable, { MTableToolbar, MTableCell } from "material-table";
import { array } from "js-sha512";
import { dateFormater, UserRolePermission } from "utils/functions";
import { promoActions } from "../../../store/actions/promoActions";
import { couponActions } from "../../../store/actions/couponActions";

//
import { Editor } from "@tinymce/tinymce-react";
import CouponData from "./CouponData";

class PromoManager extends React.Component {
  state = {
    //propertyBed:this.props.PropertyReducer.propertyOptions[0].beds,
    savePromoLoader: false,
    savePromoEditLoader: false,
    showPromoEditModal: false,
    startMobileUpload: false,
    startWebUpload: false,
    title_msg: null,
    pers_msg: null,
    body_msg: null,

    editPromoId: null,
    editPromoTitle: null,
    editPromoStatus: null,
    editCouponPromoStatus: null,
    editPromoImg: null,
    editPromoPercentage: null,
    activeTab: 1,
  };

  constructor(props) {
    super(props);
    //this.activeRoute.bind(this);
  }

  componentDidMount(props) {
    //this.props.propertyOptionsAction()
    this.props.promoActions();
    //console.log(this.props.PromoReducer.PromoactionsData)
  }

  uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  extractFileExtension = (filename) => {
    //alert(filename)
    var ext = /(?:\.([^.]+))?$/.exec(filename);
    if (ext != null && ext[0] != null) {
      return ext[0];
    } else {
      return "";
    }
  };

  handleEditorChange = (content, editor) => {
    this.setState({
      editPromoTerms: content,
    });
    //console.log("Content was updated:", content);
  };

  toggle = (tab) => {
    this.setState({ activeTab: tab });
    //if(activeTab !== tab) setActiveTab(tab);
  };

  render() {
    return (
      <div class="container" style={{ border: "0px solid red" }}>
        <Modal
          id="model_promo_edit"
          className="modal-dialog-centered"
          isOpen={this.state.showPromoEditModal}
          // toggle={() => this.toggleModal("exampleModal")}
        >
          <div className="modal-header">
            <h4 className="modal-title" id="exampleModalLabel">
              Promo Edit
              <span id="user_data"></span>
            </h4>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ showPromoEditModal: false });
              }}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {/* <input type='hidden' id="editPromoId" ></input> */}

            <Form>
              <div
                className="edit_form"
                style={{
                  width: 100 + "%",
                }}
              >
                <Row>
                  <Col>
                    <UncontrolledAlert
                      id="validate_form_msg"
                      className=" alert-warning"
                      style={{ display: "none" }}
                      fade={false}
                    >
                      <span className="alert-inner--icon">
                        <i className="ni ni-like-2" />
                      </span>{" "}
                      <span className="alert-inner--text">
                        <strong>Validation!</strong> All fields are mandatory
                      </span>
                    </UncontrolledAlert>
                  </Col>
                </Row>

                <Card style={{ padding: 20, border: "1px solid #ccc" }}>
                  <Row>
                    <Col lg="12">
                      <FormGroup id="proTitleD">
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                          style={{ fontWeight: "normal", fontSize: 11 }}
                        >
                          Title of Promo
                        </label>
                        <Input
                          className="form-control-alternative"
                          defaultValue={this.state.editPromoTitle}
                          required
                          id="proTitle"
                          placeholder="Title of Promo"
                          onChange={(data) => {
                            // console.log(data.target.value)
                            this.setState({
                              editPromoTitle: data.target.value,
                            });
                          }}
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12">
                      <FormGroup id="proTitleD">
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                          style={{ fontWeight: "normal", fontSize: 11 }}
                        >
                          Percentage of Promo
                        </label>
                        <Input
                          className="form-control-alternative"
                          defaultValue={this.state.editPromoPercentage}
                          required
                          id="proPers"
                          placeholder="Percentage of Promo"
                          onChange={(data) => {
                            // console.log(data.target.value)
                            this.setState({
                              editPromoPercentage: data.target.value,
                            });
                          }}
                          type="number"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12">
                      <FormGroup id="proTitleD">
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                          style={{ fontWeight: "normal", fontSize: 11 }}
                        >
                          Status of Promo
                        </label>

                        <select
                          style={{ border: "1px solid #eee" }}
                          className="form-control-alternative form-control"
                          id="proPromo"
                          onChange={(data) => {
                            // console.log(data.target.value)
                            this.setState({
                              editPromoStatus: data.target.value,
                            });
                          }}
                        >
                          {/* <option> -- No Promo --</option> */}

                          <option
                            value={false}
                            selected={
                              this.state.editPromoStatus === false
                                ? "selected"
                                : null
                            }
                          >
                            False
                          </option>
                          <option
                            value={true}
                            selected={
                              this.state.editPromoStatus === true
                                ? "selected"
                                : null
                            }
                          >
                            True
                          </option>
                        </select>
                      </FormGroup>
                    </Col>

                    
                  </Row>

                  <Row>
                    <Col lg="12">
                      <FormGroup id="proTitleD">
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                          style={{ fontWeight: "normal", fontSize: 11 }}
                        >
                          Status of Coupon Promo
                        </label>

                        <select
                          style={{ border: "1px solid #eee" }}
                          className="form-control-alternative form-control"
                          id="proCoupomPromo"
                          onChange={(data) => {
                            // console.log(data.target.value)
                            this.setState({
                              editCouponPromoStatus: data.target.value,
                            });
                          }}
                        >
                          {/* <option> -- No Promo --</option> */}

                          <option
                            value={false}
                            selected={
                              this.state.editCouponPromoStatus === false
                                ? "selected"
                                : null
                            }
                          >
                            False
                          </option>
                          <option
                            value={true}
                            selected={
                              this.state.editCouponPromoStatus === true
                                ? "selected"
                                : null
                            }
                          >
                            True
                          </option>
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12">
                      <FormGroup id="proTitleD">
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                          style={{ fontWeight: "normal", fontSize: 11 }}
                        >
                          Promo Summary
                        </label>
                        <Input
                          className="form-control-alternative"
                          defaultValue={this.state.editPromoSummary}
                          required
                          id="proSummary"
                          placeholder="Promo Summary"
                          onChange={(data) => {
                            // console.log(data.target.value)
                            this.setState({
                              editPromoSummary: data.target.value,
                            });
                          }}
                          rows="4"
                          type="textarea"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12">
                      <FormGroup id="proTitleD">
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                          style={{ fontWeight: "normal", fontSize: 11 }}
                        >
                          Promo Terms and Agreement
                        </label>
                        <Editor
                          initialValue={this.state.editPromoTerms}
                          apiKey="iswoe77jbas62javv1txe8fr8jnv3dvrrgmebp8ei4lm9wpx"
                          init={{
                            height: 500,
                            menubar: false,
                            plugins: [
                              "advlist autolink lists link image charmap print preview anchor",
                              "searchreplace visualblocks code fullscreen",
                              "insertdatetime media table paste code help wordcount",
                            ],
                            toolbar:
                              "undo code redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
            fullscreen bullist | casechange checklist formatpainter pageembed permanentpen table | numlist outdent indent | removeformat |",
                          }}
                          onEditorChange={this.handleEditorChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        color="primary"
                        href="#"
                        block
                        disabled={this.state.savePromoEditLoader}
                        style={{ fontSize: 11, paddingBottom: 12 }}
                        //size="sm"

                        onClick={(e) => {
                          e.preventDefault();

                          this.setState({ savePromoEditLoader: true });

                          //alert(this.state.editPromoId)
                          const promoData = {
                            title: this.state.editPromoTitle,
                            details: this.state.editPromoSummary,
                            status:
                              /* this.state.editPromoStatus, */
                              this.state.editPromoStatus == "true"
                                ? true
                                : false,
                            statusCoupon:
                              this.state.editCouponPromoStatus == "true"
                                ? true
                                : false,
                            terms: this.state.editPromoTerms,
                            img: this.state.editPromoImg,
                            percentage: parseInt(
                              this.state.editPromoPercentage
                            ),
                          };

                          var home = this;

                          firebase
                            .firestore()
                            .collection("promo")
                            .doc(home.state.editPromoId)
                            .update(promoData)
                            .then(async function () {
                              await firebase
                                .firestore()
                                .collection("property")
                                .get()
                                .then(function (querySnapshot) {
                                  querySnapshot.forEach(function (doc) {
                                    doc.ref.update({
                                      promo:
                                        home.state.editPromoStatus == "true"
                                          ? true
                                          : false,
                                    });
                                  });

                                  home.setState({
                                    savePromoEditLoader: false,
                                  });

                                  swal(
                                    "Promo Update",
                                    "You have successfully updated " +
                                      home.state.editPromoTitle +
                                      " promo",
                                    "success"
                                  ).then((value) => {
                                    document.location.reload();
                                  });
                                });
                            });
                        }}
                      >
                        {this.state.savePromoEditLoader ? (
                          // <img src={require("assets/img/loader.gif")} />
                          <>
                            Updating...{" "}
                            <i className="fa fa-spinner fa-spin"></i>
                          </>
                        ) : (
                          "Update Promo"
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Card>

                <Card
                  style={{
                    padding: 20,
                    border: "1px solid #ccc",
                    marginTop: 40,
                  }}
                >
                  <Row>
                    <Col lg="10">
                      <FormGroup id="proTitleD">
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                          style={{ fontWeight: "900", fontSize: 11 }}
                        >
                          Change <span style={{ color: "red" }}>Mobile</span>{" "}
                          Promo Image
                        </label>

                        {
                          //startMobileUpload
                          this.state.startMobileUpload ? (
                            <div className="mt-1">
                              {this.state.serverMessage}{" "}
                              <i className="fa fa-spinner fa-spin"></i>
                            </div>
                          ) : (
                            <Input
                              className="form-control alternative"
                              style={{ fontSize: 10 }}
                              id="proImage"
                              accept="image/*"
                              onChange={(data) => {
                                var home = this;

                                const image = data.target.files[0];
                                const fileName =
                                  this.uuidv4() +
                                  this.extractFileExtension(image.name);
                                const storage = firebase.storage();
                                const uploadPhotoTask = storage
                                  .ref("promo/" + fileName)
                                  .put(image);

                                this.setState({ startMobileUpload: true });

                                uploadPhotoTask.on(
                                  "state_changed",
                                  (snapshot) => {},
                                  (error) => {
                                    swal({
                                      title: "Mobile Promo Upload Error",
                                      text: "An unexpected error occured while uploading your mobile promo image.\n Kindly confirm your internet connection or contact an admin",
                                      icon: "error",
                                    });

                                    //console.log(error)
                                  },
                                  async () => {
                                    storage
                                      .ref("promo")
                                      .child(fileName)
                                      .getDownloadURL()
                                      .then(async (url) => {
                                        var MobileImage = {
                                          img: url,
                                        };

                                        await firebase
                                          .firestore()
                                          .collection("promo")
                                          .doc(this.state.editPromoId)
                                          .update(MobileImage)
                                          .then(function () {
                                            home.setState({
                                              startMobileUpload: false,
                                            });

                                            swal(
                                              "Mobile promo updated",
                                              "You have successfully updated" +
                                                home.state.editPromoTitle +
                                                " mobile image",
                                              "success"
                                            ).then((value) => {
                                              document.location.reload();
                                            });
                                          });
                                      });
                                  }
                                );
                              }}
                              type="file"
                            />
                          )
                        }
                      </FormGroup>
                    </Col>

                    <Col>
                      {this.state.editPromoImg == "" ? (
                        "No Image"
                      ) : (
                        <img src={this.state.editPromoImg} height="70" />
                      )}
                    </Col>
                  </Row>
                </Card>

                <Card
                  style={{
                    padding: 20,
                    border: "1px solid #ccc",
                    marginTop: 40,
                  }}
                >
                  <Row>
                    <Col lg="10">
                      <FormGroup id="proTitleD">
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                          style={{ fontWeight: "900", fontSize: 11 }}
                        >
                          Change <span style={{ color: "red" }}>Web</span> Promo
                          Image
                        </label>

                        {
                          //startMobileUpload
                          this.state.startWebUpload ? (
                            <div className="mt-1">
                              {"Uploading..."}{" "}
                              <i className="fa fa-spinner fa-spin"></i>
                            </div>
                          ) : (
                            <Input
                              className="form-control alternative"
                              style={{ fontSize: 10 }}
                              id="proImageWeb"
                              accept="image/*"
                              onChange={(data) => {
                                var home = this;

                                const image = data.target.files[0];
                                const fileName =
                                  this.uuidv4() +
                                  this.extractFileExtension(image.name);
                                const storage = firebase.storage();
                                const uploadPhotoTask = storage
                                  .ref("promo/" + fileName)
                                  .put(image);

                                this.setState({ startWebUpload: true });

                                uploadPhotoTask.on(
                                  "state_changed",
                                  (snapshot) => {},
                                  (error) => {
                                    swal({
                                      title: "Web Promo Upload Error",
                                      text: "An unexpected error occured while uploading your mobile promo image.\n Kindly confirm your internet connection or contact an admin",
                                      icon: "error",
                                    });

                                    //console.log(error)
                                  },
                                  async () => {
                                    storage
                                      .ref("promo")
                                      .child(fileName)
                                      .getDownloadURL()
                                      .then(async (url) => {
                                        var WebImage = {
                                          img_web: url,
                                        };

                                        await firebase
                                          .firestore()
                                          .collection("promo")
                                          .doc(this.state.editPromoId)
                                          .update(WebImage)
                                          .then(function () {
                                            home.setState({
                                              startWebUpload: false,
                                            });

                                            swal(
                                              "Web Promo updated",
                                              "You have successfully updated" +
                                                home.state.editPromoTitle +
                                                " web image",
                                              "success"
                                            ).then((value) => {
                                              document.location.reload();
                                            });
                                          });
                                      });
                                  }
                                );
                              }}
                              type="file"
                            />
                          )
                        }
                      </FormGroup>
                    </Col>

                    <Col>
                      {this.state.editPromoImgWeb == "" ? (
                        "No Image"
                      ) : (
                        <img src={this.state.editPromoImgWeb} height="70" />
                      )}
                    </Col>
                  </Row>
                </Card>
              </div>
            </Form>
          </div>
        </Modal>

        <Nav tabs>
          <NavItem>
            <NavLink
              style={{ pointer: "cursor" }}
              className={this.state.activeTab == 1 ? "active" : null}
              onClick={() => {
                this.toggle("1");
              }}
            >
              Promo
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={this.state.activeTab == 2 ? "active" : null}
              onClick={() => {
                this.toggle("2");
              }}
            >
              Coupon Promo
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane
            tabId="1"
            className={this.state.activeTab == 1 ? "active" : null}
          >
            <Row>
              <div className="col">
                <MaterialTable
                  columns={[
                    {
                      title: "Title",
                      field: "title",
                      render: (rowData) => {
                        return rowData.title;
                      },
                    },
                    {
                      title: "Percentage",
                      field: "percentage",
                      render: (rowData) => {
                        return rowData.percentage;
                      },
                    },
                    {
                      title: "Status",
                      field: "status",
                      render: (rowData) => {
                        return (
                          <div>
                            <span style={{ fontSize: 8 }}>
                              {JSON.stringify(rowData.status)}
                            </span>
                            <div className="custom-control custom-control-alternative custom-radio mb-3">
                              <input
                                className="custom-control-input"
                                defaultChecked={rowData.status ? true : false}
                                id="customRadio1"
                                name="custom-radio-1"
                                type="radio"
                                disabled
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customRadio1"
                              >
                                Enable
                              </label>
                            </div>
                            <div className="custom-control custom-control-alternative custom-radio mb-3">
                              <input
                                className="custom-control-input"
                                defaultChecked={!rowData.status ? true : false}
                                id="customRadio2"
                                name="custom-radio-1"
                                type="radio"
                                disabled
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customRadio2"
                              >
                                Disable
                              </label>
                            </div>
                          </div>
                        );
                      },
                    },
                    {
                      title: "Code Status",
                      field: "statusCoupon",
                      render: (rowData) => {
                        return (
                          <div>
                            <span style={{ fontSize: 8 }}>
                              {JSON.stringify(rowData.statusCoupon)}
                            </span>
                            <div className="custom-control custom-control-alternative custom-radio mb-3">
                              <input
                                className="custom-control-input"
                                defaultChecked={
                                  rowData.statusCoupon ? true : false
                                }
                                id="statusCoupon1"
                                name="custom-radio-1"
                                type="radio"
                                disabled
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="statusCoupon1"
                              >
                                Enable
                              </label>
                            </div>
                            <div className="custom-control custom-control-alternative custom-radio mb-3">
                              <input
                                className="custom-control-input"
                                defaultChecked={
                                  !rowData.statusCoupon ? true : false
                                }
                                id="statusCoupon2"
                                name="custom-radio-1"
                                type="radio"
                                disabled
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="statusCoupon2"
                              >
                                Disable
                              </label>
                            </div>
                          </div>
                        );
                      },
                    },
                    {
                      title: "Image",
                      field: "img",
                      render: (rowData) => {
                        return (
                          <div>
                            <div>
                              <img src={rowData.img} height="50" />
                            </div>

                            <div>
                              <img
                                style={{ marginTop: 20 }}
                                src={rowData.img_web}
                                width="100%"
                              />
                            </div>
                          </div>
                        );
                      },
                    },
                  ]}
                  actions={[
                    {
                      icon: () => (
                        <Button
                          color="primary"
                          href="#"
                          style={{ fontSize: 11, paddingBottom: 6 }}
                          size="sm"
                        >
                          Add Promo
                        </Button>
                      ),
                      tooltip: "Add Promo",
                      isFreeAction: true,
                      onClick: (event, rowData) => {
                        this.setState(
                          {
                            showPushModal: true,
                          },
                          () => {}
                        );
                      },
                    },
                    {
                      icon: () => (
                        <Button
                          color="primary"
                          href="#"
                          style={{ fontSize: 11, paddingBottom: 6 }}
                          size="sm"
                          disabled={UserRolePermission(
                            this.props.currentUser.UserData.role,
                            "edit_promo"
                          )}
                          style={{
                            fontSize: 9,
                            display: UserRolePermission(
                              this.props.currentUser.UserData.role,
                              "edit_promo"
                            )
                              ? "none"
                              : "block",
                          }}
                        >
                          Edit Promo
                        </Button>
                      ),
                      tooltip: "Edit Promo",
                      //isFreeAction: true,
                      onClick: (event, rowData) => {
                        var home = this;

                        if (
                          !UserRolePermission(
                            this.props.currentUser.UserData.role,
                            "edit_promo"
                          )
                        ) {
                          this.setState(
                            {
                              showPromoEditModal: true,
                            },
                            () => {
                              var tim = setTimeout(function () {
                                //document.getElementById('editPromoId').value= rowData.id

                                home.setState({
                                  editPromoId: rowData.id,
                                  editPromoTitle: rowData.title,
                                  editPromoStatus: rowData.status,
                                  editCouponPromoStatus: rowData.statusCoupon,
                                  editPromoImg: rowData.img,
                                  editPromoImgWeb: rowData.img_web,
                                  editPromoPercentage: rowData.percentage,
                                  editPromoTerms: rowData.terms,
                                  editPromoSummary: rowData.details,
                                });
                              }, 600);
                            }
                          );
                        }
                      },
                    },
                  ]}
                  data={this.props.PromoReducer.PromoactionsData}
                  options={{
                    //  grouping: true,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 20, 30, 50, 75, 100],
                    toolbar: true,
                    // paging: true,

                    rowStyle: {
                      paddingLeft: "1.5rem",
                      paddingRight: "1.5rem",
                      verticalAlign: "middle",

                      fontSize: "0.8125rem",
                      whiteSpace: "nowrap",
                      padding: "1rem",
                      borderTop: "1px solid #e9ecef",
                      fontFamily: "Open Sans, sans-serif",
                    },

                    headerStyle: {
                      paddingTop: "0.75rem",
                      paddingBottom: "0.75rem",
                      fontSize: "0.65rem",
                      textTransform: "uppercase",
                      letterPpacing: "1px",
                      borderBottom: "1px solid #e9ecef",
                      fontWeight: "bolder",
                      paddingLeft: "1.5rem",
                      paddingRight: "1.5rem",
                      backgroundColor: "#f6f9fc",
                      color: "#8898aa",
                      verticalAlign: "middle",
                      borderColor: "#e9ecef",
                      fontFamily: "Open Sans, sans-serif",
                    },
                  }}
                  title={" "}
                  components={{
                    Toolbar: (props) => (
                      <p className="h3" style={{ padding: 10 }}>
                        <MTableToolbar {...props} />
                        <div style={{ marginTop: -40, fontSize: 15 }}>
                          {"Total"}{" "}
                          <CountUp
                            end={
                              Object.keys(
                                this.props.PromoReducer.PromoactionsData
                              ).length
                            }
                          />
                        </div>
                      </p>
                    ),
                  }}
                ></MaterialTable>

                {/* {JSON.stringify(this.props.UserReducer.UserPushActionsData)} */}
              </div>
            </Row>
          </TabPane>

          <TabPane
            tabId="2"
            className={this.state.activeTab == 2 ? "active" : null}
          >
            <CouponData />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    promoActions: (param) => dispatch(promoActions(param)),
    couponActions: (param) => dispatch(couponActions(param)),
  };
};

const mapStateToProps = (state) => ({
  PromoReducer: state.PromoReducer,
  currentUser: state.authReducer,
 
});

export default connect(mapStateToProps, mapDispatchToProps)(PromoManager);

import { isObject } from "lodash";
import isEmpty from "lodash/isEmpty";

const iniState = {
  //UseractionsData: {},
  UserLoading: true,
  UserError: true,
  UserErrorMsg: "",
  UserLoadingCompleted: false,
  UseractionCount: 0,

  DeviceLoading: false,
  //DeviceData: {},
  //PushSegmentData:{},
  DeviceErrorStatus: false,
  DeviceErrorMsg: "",
};

const UserReducer = (state = iniState, action) => {
  switch (action.type) {
    case "USER_COUNT":
      // alert(action.payload)
      return {
        ...state,
        UseractionCount: action.payload,
      };
    case "USER_LOADING":
      return {
        ...state,
        UserLoading: action.UserLoading,
        UserError: false,
        UserLoadingCompleted: false,
        //UserErrorMsg:''
      };

    case "USER_SUCCESS":
      //alert(JSON.stringify(action.payload))
      return {
        ...state,
        UseractionsData: action.payload,
        //UseractionCount: (isObject(action.payload)?(Object.keys(action.payload).length):0),
        UserError: false,
        UserLoadingCompleted: true,
        UserLoading: false,
      };
    case "USER_ERROR":
      //alert('error')
      return {
        ...state,
        UserError: true,
        UserLoadingCompleted: false,
        UserErrorMsg: action.payload,
        UserLoaded: false,
      };

    //

    //USER_GROUP_PUSH_SUCCESS

    case "USER_GROUP_PUSH_LOADING":
      return {
        ...state,
        UserGroupPushLoading: action.UserPushLoading,
        UserGroupPushError: false,
        UserGroupPushLoadingCompleted: false,
        //UserErrorMsg:''
      };

    case "USER_GROUP_PUSH_SUCCESS":
      return {
        ...state,
        UserGroupPushActionsData: action.payload,
        UserGroupPushError: false,
        UserGroupPushLoadingCompleted: true,
        UserGroupPushLoading: false,
      };

    case "USER_GROUP_PUSH_ERROR":
      return {
        ...state,
        UserGroupPushError: true,
        UserGroupPushLoadingCompleted: false,
        UserGroupPushErrorMsg: action.payload,
        UserGroupPushLoaded: false,
      };

    case "USER_PUSH_LOADING":
      return {
        ...state,
        UserPushLoading: action.UserPushLoading,
        UserPushError: false,
        UserPushLoadingCompleted: false,
        //UserErrorMsg:''
      };

    case "USER_PUSH_SUCCESS":
      //alert(JSON.stringify(action.payload))
      return {
        ...state,
        UserPushActionsData: action.payload,
        //UseractionCount: (isObject(action.payload)?(Object.keys(action.payload).length):0),
        UserPushError: false,
        UserPushLoadingCompleted: true,
        UserPushLoading: false,
      };
    case "USER_PUSH_ERROR":
      return {
        ...state,
        UserPushError: true,
        UserPushLoadingCompleted: false,
        UserPushErrorMsg: action.payload,
        UserPushLoaded: false,
      };

    case "DEVICE_LOADING":
      return {
        ...state,
        DeviceLoading: true,
        DeviceErrorMsg: null,
        DeviceErrorStatus: false,
      };

    case "DEVICE_SUCCESSFUL":
      return {
        ...state,
        DeviceLoading: false,
        DeviceData: action.payload,
        DeviceErrorMsg: null,
        DeviceErrorStatus: false,
      };

    case "DEVICE_ERROR":
      return {
        ...state,
        DeviceLoading: false,
        DeviceData: null,
        DeviceErrorStatus: true,
        DeviceErrorMsg: action.payload,
      };

    default:
      return state;
  }
};

export default UserReducer;

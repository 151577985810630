import axios from "axios";

import api from "../../utils/config";

import  firebase from "firebase/app";
import "firebase/auth";
import app from './../../variables/base'
import swal from "sweetalert";
import { isEmpty } from "lodash";


  
  if (!firebase.apps.length)
    {
    

        firebase.initializeApp({
            apiKey: api.REACT_APP_FIREBASE_APP_ID,
            authDomain: api.REACT_APP_FIREBASE_DOMAIN,
            databaseURL: api.REACT_APP_FIREBASE_DATABASE,
            projectId: api.REACT_APP_FIREBASE_PROJECT_ID,
            storageBucket: api.REACT_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: api.REACT_APP_FIREBASE_SENDER_ID,
            appId: api.REACT_APP_FIREBASE_APP_ID,
            measurementId: api.REACT_APP_FIREBASE_MEASUREMENT_ID,
          });

    }


    
    export const PaymentCount = (count) =>{

      //alert(count+'====>')
      return (dispatch, getState) => {
         dispatch({ type: "PAYMENT_COUNT", payload: count });
      }

    }

export const setPaymentCountData = (PaymentCountData) => {

        return {
             type: "PAYMENT_SUCCESS",
             payload: PaymentCountData,

        };
};


export const PaymentActions =  (credentials) => {
  return async (dispatch, getState)  => {

    dispatch({ type: "PAYMENT_LOADING", loading: true });

    try {

            await firebase
                .firestore()
                .collection("payments")
                 //.orderBy("order","asc")
                .onSnapshot( snapshot => {

                     if (snapshot.empty) {
                        return;
                    }
                    
                      var PaymentCountData = [];
                      //var Payment ={}
                        snapshot.forEach(async doc => {
                            
                                    //console.log(Payment.data())

                            PaymentCountData.push({
                           // id: doc.id,
                            //Payment: (isEmpty(Payment)?Payment:Payment.data()),
                            ...doc.data()
                            });
                        });

                       // alert(JSON.stringify(PaymentCountData.slice(0,2)))

                dispatch(setPaymentCountData((PaymentCountData)))
                  
              })


    }
    catch(error)
    {

        //alert('error')
         dispatch({ type: "PAYMENT_ERROR",  payload: error, });

    }





  }


}